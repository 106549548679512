import { LoadingButton } from '@mui/lab';
import { Alert } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import PhoneInput from 'components/shared/PhoneInput';
import { theme } from 'context/ThemeProvider';
import useSavePhoneNumber from 'hooks/mutations/useSavePhoneNumber';

interface FormValues {
  phoneNumber: string;
}

interface Props {
  onSuccess: () => void;
}

const PhoneNumberForm: React.FC<Props> = ({ onSuccess }) => {
  const { mutate, isLoading, error } = useSavePhoneNumber({ onSuccess });

  const onSubmit = ({ phoneNumber }: FormValues) => {
    mutate(phoneNumber);
  };

  const { values, errors, touched, handleSubmit, setFieldValue, handleBlur } =
    useFormik({
      initialValues: { phoneNumber: '' },
      validationSchema: yup.object().shape({
        phoneNumber: yup
          .string()
          .required('Mobile phone number is required')
          .test(
            'phoneLength',
            'Invalid phone number',
            (value?: string) => value?.length === 12,
          ),
      }),
      onSubmit,
      validateOnBlur: false,
    });

  return (
    <>
      {error && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(3) }}
        >
          {error.displayMessage}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <PhoneInput
          name="phoneNumber"
          label="Mobile phone number"
          value={values.phoneNumber}
          error={!!(touched.phoneNumber && errors.phoneNumber)}
          errorText={errors.phoneNumber}
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
          fullWidth
          showCountryCode
          autoFocus
        />

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
          sx={{ marginTop: theme.spacing(3) }}
        >
          Send Code
        </LoadingButton>
      </form>
    </>
  );
};

export default PhoneNumberForm;
