import { Card, CardContent, Divider, Link, Typography } from '@mui/material';
import { AdaContext } from 'context/AdaProvider';
import { useContext } from 'react';

import { theme } from 'context/ThemeProvider';
import useGetApplications from 'hooks/queries/useGetApplications';
import useApplicationStatus from 'hooks/useApplicationStatus';

const NY_CERTIFICATE_URL =
  'https://kovo-web-app-assets.s3.amazonaws.com/public/NY_Certificate.pdf';
const TX_CERTIFICATE_URL =
  'https://kovo-web-app-assets.s3.amazonaws.com/public/TX_Certificate.pdf';

const TermsCard: React.FC = () => {
  const { data } = useApplicationStatus();

  const { data: applicationsData } = useGetApplications();

  const { startBot } = useContext(AdaContext);

  if (!applicationsData) {
    return null;
  }

  const { chargeAccountApplication } = applicationsData;

  if (
    !chargeAccountApplication ||
    chargeAccountApplication.status !== 'completed'
  ) {
    return null;
  }

  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h5">TERMS (CREDIT PROTECTION)</Typography>

        <Divider sx={{ margin: theme.spacing(2, 0) }} />

        {data?.applicationDetails?.state === 'TX' && (
          <Link
            href={TX_CERTIFICATE_URL}
            target="_blank"
            rel="noopener noreferrer"
            display="inline-block"
            marginBottom={theme.spacing(2)}
          >
            Insurance Certificate
          </Link>
        )}

        {data?.applicationDetails?.state === 'NY' && (
          <Link
            href={NY_CERTIFICATE_URL}
            target="_blank"
            rel="noopener noreferrer"
            display="inline-block"
            marginBottom={theme.spacing(2)}
          >
            Insurance Certificate
          </Link>
        )}

        <Typography variant="footnote">
          To stop upcoming renewal,{' '}
          <Link
            component="button"
            onClick={() => startBot()}
            variant="footnote"
          >
            get in touch
          </Link>
          .
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TermsCard;
