import { Link as MuiLink, Typography } from '@mui/material';
import { AdaContext } from 'context/AdaProvider';
import { useContext } from 'react';

import Page from 'components/Page';

const UnrecognizedStatusPage: React.FC = () => {
  const { startBot } = useContext(AdaContext);

  return (
    <Page>
      <Typography variant="h1" gutterBottom>
        Something went wrong
      </Typography>

      <Typography variant="body1">
        Please{' '}
        <MuiLink
          component="a"
          style={{ verticalAlign: 'baseline', cursor: 'pointer' }}
          onClick={() =>
            startBot({
              subject: 'Unrecognized status',
              openAsLink: false,
            })
          }
        >
          get in touch
        </MuiLink>{' '}
        for assistance.
      </Typography>
    </Page>
  );
};

export default UnrecognizedStatusPage;
