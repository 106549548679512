import { Model } from './model';

export interface Product extends Model {
  id: LoanProductId;
  type: ProductTypes;
}

export const PRODUCT_TYPES = ['loan_installment', 'charge_revolving'] as const;
export type ProductTypes = typeof PRODUCT_TYPES[number];

export const CHARGE_ACCOUNT_PRODUCT_ID = 'pro_7';
export const INSTALLMENTS_PRODUCT_ID = 'pro_1';

const PRODUCT_IDS = [
  CHARGE_ACCOUNT_PRODUCT_ID,
  INSTALLMENTS_PRODUCT_ID,
] as const;
export type LoanProductId = typeof PRODUCT_IDS[number];

interface ProductConfig {
  id: LoanProductId;
  infoRoute: string;
  label: string;
}

export const productConfigs: Record<ProductTypes, ProductConfig> = {
  loan_installment: {
    id: INSTALLMENTS_PRODUCT_ID,
    infoRoute: '/installment-plan',
    label: 'Installment Plan',
  },
  charge_revolving: {
    id: CHARGE_ACCOUNT_PRODUCT_ID,
    infoRoute: '/credit-line',
    label: 'Credit Line',
  },
};
