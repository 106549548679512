import PopupDialog from 'components/PopupDialog';

const OffersDisclosure: React.FC = () => {
  return (
    <PopupDialog
      variant="body1"
      dialogText={
        <>
          The offers that appear on this site are from partners from which Kovo
          Credit Inc. receives compensation. Kovo Credit Inc. strives to provide
          a wide array of offers and products but these offers do not represent
          all companies or available products.
          <br />
          <br />
          THIS IS A LOAN SOLICITATION ONLY. KOVO CREDIT INC. IS NOT THE LENDER.
          INFORMATION RECEIVED WILL BE SHARED WITH ONE OR MORE THIRD PARTIES IN
          CONNECTION WITH YOUR LOAN INQUIRY. THE LENDER MAY NOT BE SUBJECT TO
          ALL VERMONT LENDING LAWS. THE LENDER MAY BE SUBJECT TO FEDERAL LENDING
          LAWS.
        </>
      }
    >
      Advertiser Disclosure
    </PopupDialog>
  );
};

export default OffersDisclosure;
