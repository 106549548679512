import { Box, Typography } from '@mui/material';
import { useRifm } from 'rifm';

import TextField from 'components/shared/TextField';
import { DARK_BLUE, LIGHT_GREY, theme } from 'context/ThemeProvider';
import { INTEGER_REGEX, NON_INTEGER_REGEX } from 'helpers/constants';
import { parseDigits } from 'helpers/utils';

interface Props {
  name: string;
  label: string;
  value: string;
  error?: boolean;
  errorText?: string;
  setFieldValue: (field: string, value: string) => void;
  onBlur: (e: React.ChangeEvent<any>) => void;
  fullWidth?: boolean;
  showCountryCode?: boolean;
  autoFocus?: boolean;
}

const formatPhoneNumber = (phoneNumber: string): string => {
  const digits = parseDigits(phoneNumber).substring(0, 10);
  const chars = digits.split('');
  const formatted = chars.reduce(
    (prev, current, index) =>
      index === 3 || index === 6 ? `${prev}-${current}` : `${prev}${current}`,
    '',
  );

  return formatted;
};

const PhoneInput: React.FC<Props> = ({
  name,
  label,
  value,
  error,
  errorText,
  setFieldValue,
  onBlur,
  fullWidth,
  showCountryCode,
  autoFocus,
}) => {
  const rifm = useRifm({
    value,
    onChange: (value: string) => {
      setFieldValue(name, value);
    },
    format: formatPhoneNumber,
    accept: INTEGER_REGEX,
    mask:
      value.length < 6 && NON_INTEGER_REGEX.test(value[3])
        ? undefined
        : value.length >= 14,
  });

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <TextField
        type="tel"
        pattern="[0-9\-]*"
        name={name}
        label={label}
        value={rifm.value}
        error={error}
        errorText={errorText}
        onChange={rifm.onChange}
        onBlur={onBlur}
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        sx={{
          ...(showCountryCode && {
            '.MuiFormLabel-root': {
              left: '77px',
            },
            '.MuiInputBase-root': {
              paddingLeft: '77px',
            },
            '.MuiInputBase-input': {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
            '&:focus-within + div': {
              color: DARK_BLUE,
            },
          }),
        }}
      />

      {showCountryCode && (
        <Box
          sx={{
            position: 'absolute',
            top: 1,
            bottom: 1,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            borderRight: `1px solid ${LIGHT_GREY}`,
            padding: theme.spacing(0, 1.25),
            zIndex: 999,
            color: value ? DARK_BLUE : LIGHT_GREY,
          }}
        >
          <Typography
            sx={{
              color: 'inherit',
            }}
          >
            (US) <strong>+1</strong>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PhoneInput;
