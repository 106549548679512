import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Link as MuiLink, Typography } from '@mui/material';
import { theme } from 'context/ThemeProvider';
import { useProductConfig } from 'hooks/useProductConfig';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ProductTypes } from 'types/schemas';

const CatchupPaymentHeader: FC<{ productType: ProductTypes }> = ({
  productType,
}) => {
  const productConfig = useProductConfig(productType);
  return (
    <>
      <MuiLink
        component={Link}
        to={productConfig.infoRoute}
        underline="none"
        variant="body1"
        color={theme.palette.primary.main}
        marginBottom={theme.spacing(2)}
        display="inline-block"
      >
        <ArrowBackIcon
          sx={{ verticalAlign: 'top', marginRight: theme.spacing(1) }}
        />
        <Box component="strong">{productConfig.label}</Box>
      </MuiLink>

      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            textAlign: 'center',
          },
        }}
      >
        <Typography variant="h1" marginBottom={theme.spacing(2)}>
          Get Back on Track
        </Typography>

        <Typography marginBottom={theme.spacing(3)}>
          Catch up on past due payments to continue building your credit.
        </Typography>
      </Box>
    </>
  );
};

export default CatchupPaymentHeader;
