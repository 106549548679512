import { Auth, CognitoUser } from '@aws-amplify/auth';
import {
  UserAuthDetails,
  CognitoUserWithAttributes,
} from 'context/AuthProvider';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { KovoError } from 'libs/KovoError';
import { getCognitoUserAttributes } from './getCognitoUserAttributes';
import {
  PENDING_EMAIL_ATTRIBUTE_KEY,
  PENDING_EMAIL_TIMESTAMP_ATTRIBUTE_KEY,
} from './types';

class MissingEmailAttributeError extends Error {
  identityId: string;

  constructor(identityId: string) {
    super(`Cognito user missing the email_verified attribute`);
    this.identityId = identityId;
  }
}

export class MissingAuthCredential extends Error {
  username: string;

  constructor(user: CognitoUser) {
    super('Missing Cognito credential for a logged in user');

    this.username = user.getUsername();
  }
}

/**
 * Use this on sign in to parse and populate a few more fields than
 * just the default Cognito User fields.
 */
export const getCognitoUserAuthDetails = async (
  cognitoUser?: CognitoUser,
): Promise<UserAuthDetails> => {
  try {
    if (!cognitoUser) {
      return {
        email: '',
        emailVerified: false,
        identityId: '',
        username: '',
      };
    }

    const session = cognitoUser.getSignInUserSession();

    if (!session) {
      return {
        email: '',
        emailVerified: false,
        identityId: '',
        username: '',
      };
    }

    let identityId = '';

    try {
      const credential = await Auth.currentCredentials();
      identityId = credential.identityId;
    } catch (error) {
      /**
       * The identityId is not available in the local environment and we only use it as the userId if
       * it is available. If it is not available, the userId is generated on the server.
       */
    }

    /**
     * Fetch the attributes from the JWT claim via the built in methods
     * just in case Cognito JS changes the object shape without warning.
     */
    const userAttributes = await getCognitoUserAttributes(cognitoUser);

    const emailVerifiedAttribute = userAttributes.find((a) => {
      return a.Name === 'email_verified';
    });

    const emailAttribute = userAttributes.find((a) => {
      return a.Name === 'email';
    });

    if (!emailAttribute) {
      const e = new MissingEmailAttributeError(identityId);

      if (rollbarEnabled) {
        rollbar.error(e);
      } else {
        console.error(e);
      }
    }

    /**
     * Email verified attribute is not handled properly with the
     * local cognito mock.
     *
     * Default to true so we don't have a bunch of potential error
     * scenarios only for local env users.
     */
    let emailVerified =
      typeof emailVerifiedAttribute?.Value === 'string'
        ? emailVerifiedAttribute.Value === 'true'
        : undefined;

    emailVerified = emailVerified === undefined ? true : emailVerified;

    const email = emailAttribute?.Value ? emailAttribute.Value : '';

    const attributes = (cognitoUser as CognitoUserWithAttributes).attributes;

    /**
     * The attributes object seems to be set by amplify on all environments but it is
     * not documented. However, the "sub" attribute is the correct way to get the user pool
     * user id, so use it over the cognitoUser.getUsername() method which returns email in
     * local
     */
    const username = attributes?.sub ?? cognitoUser.getUsername(); // user pool user id

    const pendingEmailAttribute = userAttributes.find((a) => {
      return a.Name === PENDING_EMAIL_ATTRIBUTE_KEY;
    });

    const pendingEmailTimestampAttribute = userAttributes.find((a) => {
      return a.Name === PENDING_EMAIL_TIMESTAMP_ATTRIBUTE_KEY;
    });

    return {
      identityId,
      email,
      emailVerified,
      username,
      pendingEmail: pendingEmailAttribute
        ? pendingEmailAttribute.Value
        : undefined,
      pendingEmailTimestamp: pendingEmailTimestampAttribute
        ? pendingEmailTimestampAttribute.Value
        : undefined,
    };
  } catch (error) {
    if (error instanceof KovoError) {
      throw error;
    }

    throw new KovoError('Error getting cognito user auth details', {
      error,
    });
  }
};
