import { Box, CircularProgress } from '@mui/material';
import { zxcvbnAsync, zxcvbnOptions, ZxcvbnResult } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import TextField from 'components/shared/TextField';
import { theme } from 'context/ThemeProvider';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import useDebounce from 'hooks/useDebounce';
import { useEffect, useState } from 'react';

const PASSWORD_SCORE_LABELS = [
  {
    label: 'Weak password',
    color: theme.palette.primary.main,
  },
  {
    label: 'Weak password',
    color: theme.palette.primary.main,
  },
  {
    label: 'Strong password',
    color: theme.palette.success.main,
  },
  {
    label: 'Strong password',
    color: theme.palette.success.main,
  },
  {
    label: 'Very strong password',
    color: theme.palette.success.main,
  },
];

const options = {
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  useLevenshteinDistance: true,
  translations: zxcvbnEnPackage.translations,
};
zxcvbnOptions.setOptions(options);

const usePasswordStrength = (password: string) => {
  const [result, setResult] = useState<ZxcvbnResult | null>(null);
  const [isCalculating, setIsCalculating] = useState<boolean>(false);

  const debouncedPassword = useDebounce(password, 500);

  useEffect(() => {
    if (password === '') {
      setResult(null);
      setIsCalculating(false);
      return;
    }
    setIsCalculating(true);
  }, [password]);

  useEffect(() => {
    if (
      debouncedPassword === '' ||
      passwordValidityStatus(debouncedPassword)?.valid === false
    ) {
      setResult(null);
      setIsCalculating(false);
      return;
    }
    zxcvbnAsync(debouncedPassword)
      .then((response) => {
        setResult(response);
        setIsCalculating(false);
      })
      .catch((error) => {
        rollbarEnabled &&
          rollbar.error('Error calculating password strength', error);
      });
  }, [debouncedPassword]);

  return {
    score: result?.score,
    isCalculating,
  };
};

const passwordValidityStatus = (
  password: string,
):
  | {
      valid: boolean;
      message?: string;
    }
  | undefined => {
  if (password === '') {
    return {
      valid: true,
    };
  }
  if (password.length < 8) {
    return {
      valid: false,
      message: 'Password must be at least 8 characters long',
    };
  }
  if (!/\d/.test(password)) {
    return {
      valid: false,
      message: 'Password must contain at least one number',
    };
  }
  return {
    valid: true,
  };
};

type PasswordInputProps = {
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  error: boolean;
  errorText?: string;
  onBlur: (e: React.ChangeEvent<any>) => void;
} & Partial<React.ComponentProps<typeof TextField>>;

const PasswordInputWithStrength = ({
  value,
  onChange,
  error,
  errorText,
  onBlur,
  ...other
}: PasswordInputProps) => {
  const passwordValidity = passwordValidityStatus(value);
  const { score, isCalculating } = usePasswordStrength(value);
  const isFieldErroredOut = error || passwordValidity?.valid === false;

  const passwordStrengthLabel = () => {
    if (isFieldErroredOut) {
      return null;
    }
    if (isCalculating) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: theme.spacing(1),
          }}
        >
          <CircularProgress data-testid="spinner" size={10} />
          <span>Calculating...</span>
        </Box>
      );
    }
    if (score !== undefined) {
      return (
        <span
          style={{
            color: PASSWORD_SCORE_LABELS[score].color,
          }}
        >
          {PASSWORD_SCORE_LABELS[score].label}
        </span>
      );
    }

    return null;
  };

  return (
    <Box>
      <TextField
        type="password"
        name="password"
        label="Password"
        autoComplete="new-password"
        value={value}
        error={isFieldErroredOut}
        errorText={errorText || passwordValidity?.message}
        helperText={passwordStrengthLabel()}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth
        {...other}
      />
    </Box>
  );
};

export default PasswordInputWithStrength;
