import { Box, Typography } from '@mui/material';

interface Props {
  title: string;
  value: string | undefined;
  width: string;
}

const OfferContentListItem: React.FC<Props> = ({
  title,
  value,
  width,
}: Props) => {
  if (!value) {
    return null;
  }

  return (
    <Box width={width}>
      <Typography variant="footnote" fontWeight={700}>
        {title}
      </Typography>

      <Typography variant="footnote">{value}</Typography>
    </Box>
  );
};

export default OfferContentListItem;
