import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Link as MuiLink,
  Skeleton,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { theme } from 'context/ThemeProvider';
import useCheckoutStatusV2 from 'hooks/useCheckoutStatusV2';

import ApplePay from 'assets/img/icons/apple-pay.svg';
import CreditCard from 'assets/img/icons/credit-card.svg';
import GooglePay from 'assets/img/icons/google-pay.svg';
import { ROUTES } from 'components/Routes';
import { trackProductAnalytics } from 'libs/productAnalyticsTracking';

const PaymentMethodCard: React.FC = () => {
  const { data, isLoading } = useCheckoutStatusV2();

  if (isLoading) {
    return <Skeleton variant="rounded" height={133} />;
  }

  if (!data) {
    return null;
  }

  const isV2Response = 'loanProvider' in data;

  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h5">PAYMENT METHOD</Typography>

        <Divider sx={{ margin: theme.spacing(2, 0) }} />

        <Box sx={{ display: 'flex' }}>
          {isV2Response && data.paymentMethod?.walletType === null && (
            <>
              <Box
                component="img"
                src={CreditCard}
                marginRight={theme.spacing(1)}
              />
              <Typography>Card ••{data.paymentMethod.last4}</Typography>
            </>
          )}

          {isV2Response && data.paymentMethod?.walletType === 'apple_pay' && (
            <>
              <Box
                component="img"
                src={ApplePay}
                marginRight={theme.spacing(1)}
              />
              <Typography>Apple Pay</Typography>
            </>
          )}

          {isV2Response && data.paymentMethod?.walletType === 'google_pay' && (
            <>
              <Box
                component="img"
                src={GooglePay}
                marginRight={theme.spacing(1)}
              />
              <Typography>Google Pay ••{data.paymentMethod.last4}</Typography>
            </>
          )}
        </Box>
      </CardContent>

      <CardActions sx={{ marginTop: theme.spacing(2) }}>
        <MuiLink
          component={Link}
          to={ROUTES.INSTALLMENT_PLAN_UPDATE_CARD}
          variant="footnote"
          onClick={() =>
            trackProductAnalytics({
              namespace: 'ui',
              event: 'payment.update-payment-method.clicked',
            })
          }
        >
          Update payment method
        </MuiLink>
      </CardActions>
    </Card>
  );
};

export default PaymentMethodCard;
