import { Box } from '@mui/material';
import { GREEN } from 'context/ThemeProvider';

type TransactionAmountProps = {
  amountCents: number;
};

const TransactionAmount = ({ amountCents }: TransactionAmountProps) => {
  return (
    <Box
      component="span"
      sx={{
        ...(amountCents < 0 && { color: GREEN }),
        marginLeft: 'auto',
      }}
    >
      {amountCents < 0 && '-'}${Math.abs(amountCents / 100).toFixed(2)}
    </Box>
  );
};

export default TransactionAmount;
