import {
  CatchupPayment,
  CatchupPaymentHeader,
} from 'components/CatchupPayment';
import Page from 'components/Page';
import { ProductTypes } from 'types/schemas';

interface CatchupPaymentPageProps {
  productType: ProductTypes;
}

const CatchupPaymentPage: React.FC<CatchupPaymentPageProps> = ({
  productType,
}) => {
  return (
    <Page>
      <CatchupPaymentHeader productType={productType} />
      <CatchupPayment productType={productType} />
    </Page>
  );
};

export default CatchupPaymentPage;
