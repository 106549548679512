import PageLoader from 'components/PageLoader';
import useCurrentUser from 'hooks/queries/useCurrentUser';
import UpdatePaymentMethodPage from 'pages/account/UpdatePaymentMethodPage';
import { Redirect } from 'react-router-dom';
import { closedProductAccessRemainsStatuses } from 'types/schemas';

const InstallmentPlanUpdatePaymentMethodPage = () => {
  const { data: user } = useCurrentUser();

  if (!user) {
    return <PageLoader />;
  }

  if (closedProductAccessRemainsStatuses.includes(user.status)) {
    return <Redirect to="/installment-plan" />;
  }

  return (
    <UpdatePaymentMethodPage
      productType="loan_installment"
      billingProvider={user.defaultPaymentProvider}
    />
  );
};

export default InstallmentPlanUpdatePaymentMethodPage;
