import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';

import Page from 'components/Page';
import PopupDialog from 'components/PopupDialog';
import SummaryOfBenefits from 'components/credit-protection/SummaryOfBenefits';
import { theme } from 'context/ThemeProvider';

import Checkmark from 'assets/img/icons/checkmark-circle-green.svg';
import IdMonitoringFeatureCard from 'components/IdMonitoringFeatureCard';
import { ID_PROTECTION_DIGITAL_SERVICE_ID } from 'hooks/queries/useGetDigitalService';
import useGetPurchases from 'hooks/queries/useGetPurchases';

const ID_THEFT_MONITORING_ITEMS = [
  'Affidavit assistance',
  'Reporting fraudulent activity to local authorities',
  'Creditor notification and followup',
  'Lost wallet assistance',
  'Emergency cash and travel arrangements',
  'Medical records correction',
  '24/7 expertise',
  'Spanish support',
];

const CreditProtectionPage: React.FC = () => {
  const {
    data: { purchases: [activePurchase] = [] } = {},
    isLoading: isLoadingPurchases,
  } = useGetPurchases({
    status: 'active',
    digitalServiceId: ID_PROTECTION_DIGITAL_SERVICE_ID,
  });

  const idProtectionElement = () => {
    if (isLoadingPurchases) {
      return (
        <>
          <Grid item xs={12}>
            <Skeleton
              data-testid="id-protection-skeleton"
              sx={{
                marginTop: '-130px',
              }}
              height={700}
              width="100%"
            />
          </Grid>
        </>
      );
    }

    if (!activePurchase) {
      return null;
    }

    return (
      <>
        <Grid item xs={12}>
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h5">ID THEFT RESOLUTION</Typography>

              <Divider sx={{ margin: theme.spacing(2, 0, 1) }} />

              <List disablePadding sx={{ marginBottom: theme.spacing(2) }}>
                <ListItem disableGutters disablePadding>
                  <ListItemIcon
                    sx={{ minWidth: 0, marginRight: theme.spacing(1.5) }}
                  >
                    <Box component="img" src={Checkmark} alt="" />
                  </ListItemIcon>

                  <ListItemText sx={{ margin: theme.spacing(1, 0) }}>
                    <Typography>Active</Typography>
                  </ListItemText>
                </ListItem>
              </List>

              <Typography variant="footnote" marginBottom={theme.spacing(1)}>
                Go beyond traditional credit report restoration if your identity
                is stolen. Services include:
              </Typography>

              <Box
                component="ul"
                margin={theme.spacing(0, 0, 2)}
                padding={theme.spacing(0, 0, 0, 2)}
              >
                {ID_THEFT_MONITORING_ITEMS.map((item) => (
                  <Box
                    key={item}
                    component="li"
                    sx={{
                      '&::marker': {
                        fontFamily: ['Inter', 'sans-serif'].join(','),
                        fontSize: '12px',
                      },
                    }}
                  >
                    <Typography variant="footnote">{item}</Typography>
                  </Box>
                ))}
              </Box>

              <Typography variant="footnote">
                For matters related to ID Theft Resolution, you can call +1
                (833) 602-5717 and request assistance.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h5">ID FRAUD INSURANCE</Typography>

              <Divider sx={{ margin: theme.spacing(2, 0, 1) }} />

              <List disablePadding sx={{ marginBottom: theme.spacing(2) }}>
                <ListItem disableGutters disablePadding>
                  <ListItemIcon
                    sx={{ minWidth: 0, marginRight: theme.spacing(1.5) }}
                  >
                    <Box component="img" src={Checkmark} alt="" />
                  </ListItemIcon>

                  <ListItemText sx={{ margin: theme.spacing(1, 0) }}>
                    <Typography>Active</Typography>
                  </ListItemText>
                </ListItem>
              </List>

              <Typography variant="footnote" marginBottom={theme.spacing(2)}>
                Get reimbursed for out-of-pocket expenses if your identity is
                stolen, up to $1,000,000. Insurance, with $0 deductible,
                reimburses you for expenses related to ID Theft Resolution,
                including replacement of documents, travel expenses, loss of
                income, excess dependent care, and legal costs.
              </Typography>

              <Typography variant="footnote">
                ID Fraud Insurance is underwritten and administered by American
                Bankers Insurance Company of Florida, an Assurant company. Refer
                to{' '}
                <PopupDialog
                  dialogText={<SummaryOfBenefits />}
                  variant="footnote"
                >
                  Summary of Benefits
                </PopupDialog>{' '}
                for coverage terms, conditions, and exclusions.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </>
    );
  };

  return (
    <Page>
      <Box
        maxWidth="sm"
        sx={{
          [theme.breakpoints.up('md')]: {
            textAlign: 'center',
            margin: '0 auto',
          },
        }}
      >
        <Typography variant="h1" marginBottom={theme.spacing(2)}>
          Credit Protection
        </Typography>

        <Typography marginBottom={theme.spacing(2)}>
          Protecting your credit from identity fraud.
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <IdMonitoringFeatureCard />
        </Grid>

        {idProtectionElement()}
      </Grid>
    </Page>
  );
};

export default CreditProtectionPage;
