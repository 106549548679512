import IosShareIcon from '@mui/icons-material/IosShare';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import Close from 'assets/img/icons/close.svg';
import TitleImage from 'assets/img/welcome/share-title.svg';
import { ReferralsCopyLink } from 'components/ReferralsShareCode/ReferralsShareCode';
import {
  SHARE_DATA_TEXT,
  SHARE_DATA_TITLE,
} from 'components/WelcomeDialog/WelcomeDialog';
import { AuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';
import {
  INVITING_REWARD_AMOUNT,
  REFERRED_REWARD_AMOUNT,
} from 'helpers/referralClaims';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { useReferralCode } from 'hooks/useReferralCode';
import { KovoError } from 'libs/KovoError';
import { trackProductAnalytics } from 'libs/productAnalyticsTracking';

interface Props {
  onComplete?(): void;
}

const ShareReferralCode: React.FC<Props> = ({ onComplete }) => {
  const { username } = useContext(AuthContext);
  const [hasSentRollbarError, setHasSentRollbarError] = useState(false);

  const { data: referralCode, isFetched: isReferralCodeFetched } =
    useReferralCode();

  const [isFallbackSharing, setIsFallbackSharing] = useState(false);

  useEffect(() => {
    if (!referralCode && isReferralCodeFetched && !hasSentRollbarError) {
      rollbarEnabled &&
        rollbar.error('No referral code found when loading the share dialog', {
          referralCode,
        });

      setHasSentRollbarError(true);
    }

    return () => {};
  }, [referralCode, isReferralCodeFetched, hasSentRollbarError]);

  const shareNative = async () => {
    if (!referralCode) {
      throw new KovoError('No referral code found').setDisplayMessage(
        'Error sharing referral code',
      );
    }

    const referralCodeUrl = `https://kovocredit.com/r/${referralCode?.id}`;

    const shareData = {
      title: SHARE_DATA_TITLE,
      text: `${SHARE_DATA_TEXT} ${referralCodeUrl}`,
      url: referralCodeUrl,
    };

    try {
      await navigator.share!(shareData);
    } catch (error: any) {
      // this "error" occurs when a user cancels/closes the native share dialog
      if (error.name === 'AbortError') {
        return;
      }

      if (rollbarEnabled) {
        rollbar.error(
          'Error sharing welcome referral code: navigator.share()',
          {
            username,
            name: error.name,
            error,
          },
        );
      }
    }
  };

  const shareFallback = () => {
    setIsFallbackSharing(true);
  };

  const onCloseFallbackDialog = () => {
    if (onComplete) {
      onComplete();
    }

    setIsFallbackSharing(false);
  };

  const shareLink = async () => {
    trackProductAnalytics({
      namespace: 'ui',
      event: 'referral.share-link.clicked',
    });

    // ts workaround; see: https://github.com/microsoft/TypeScript/issues/42809#issuecomment-995372375
    if (navigator['share']) {
      await shareNative();

      if (onComplete) {
        onComplete();
      }
    } else {
      shareFallback();
    }
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<IosShareIcon />}
        onClick={shareLink}
        fullWidth
        sx={{
          marginBottom: theme.spacing(1),

          [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
          },

          [theme.breakpoints.up('md')]: {
            marginTop: 0,
            marginBottom: 0,
          },
        }}
      >
        Share your link
      </Button>

      <Dialog
        open={isFallbackSharing}
        data-testid="share-drawer"
        onClose={onCloseFallbackDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: 0,
          }}
        >
          <IconButton
            sx={{ marginLeft: '-5px', marginBottom: theme.spacing(1) }}
            onClick={onCloseFallbackDialog}
            data-testid="CloseIcon"
          >
            <Box component="img" src={Close} alt="✕" />
          </IconButton>
          <Box
            width="100%"
            maxWidth="294px"
            component="img"
            alt="Invite Friends"
            src={TitleImage}
            sx={{
              marginBottom: theme.spacing(1),

              [theme.breakpoints.up('sm')]: {
                marginBottom: theme.spacing(2),
              },
            }}
          />
          <Typography
            sx={{
              marginBottom: theme.spacing(2),
            }}
            variant="body1"
          >
            Get {INVITING_REWARD_AMOUNT} for you & {REFERRED_REWARD_AMOUNT} for
            a friend!
          </Typography>

          {referralCode ? (
            <ReferralsCopyLink code={referralCode.id} />
          ) : (
            <Typography>
              No referral code found. Please contact support for more
              information.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShareReferralCode;
