import { API } from 'aws-amplify';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { ReferralClaim } from 'helpers/referralClaims';

interface FetchReferralClaimsResponse {
  status: string;
  referralClaims: ReferralClaim[];
}

export const useReferralClaims = () => {
  const { identityId, username } = useContext(AuthContext);

  const fetchReferralClaims =
    async (): Promise<FetchReferralClaimsResponse> => {
      return API.get(
        'billing',
        '/billing/referral-claims',
        amplifyRequestContext(identityId, username),
      );
    };

  return useQuery<FetchReferralClaimsResponse, Error, ReferralClaim[]>(
    ['referralClaim'],
    () => fetchReferralClaims(),
    {
      select: (data) => data.referralClaims,
    },
  );
};
