/**
 * This function will get the user's timezone.
 */
const getUserTimezone = (defaultTimezone = 'America/Los_Angeles') => {
  let timezone: string | undefined = undefined;

  try {
    /**
     * supported in most browsers, but not all
     * https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_resolvedoptions_computed_timezone
     *
     * if we do not get the timezone here, we default the value to Pacific Time in the back end
     */
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (err) {
    timezone = defaultTimezone;
  }

  return timezone;
};

export default getUserTimezone;
