import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { theme } from 'context/ThemeProvider';
import { STATES } from 'helpers/constants';

interface Props {
  name: string;
  label: string;
  value: string;
  error?: boolean;
  errorText?: string;
  onChange: (e: SelectChangeEvent<string>) => void;
  onBlur: (e: React.ChangeEvent<any>) => void;
  fullWidth?: boolean;
}

const StateSelect: React.FC<Props> = ({
  name,
  label,
  value,
  error,
  errorText,
  onChange,
  onBlur,
  fullWidth,
}) => {
  return (
    <FormControl
      error={error}
      sx={{ position: 'relative' }}
      variant="filled"
      fullWidth={fullWidth}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>

      <Select
        native
        variant="filled"
        id={name}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        sx={{
          borderRadius: theme.spacing(1),
        }}
      >
        <option value="" disabled></option>

        {STATES.map(({ label, value }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Select>

      {error && errorText && (
        <FormHelperText
          sx={{
            position: 'absolute',
            top: '100%',
          }}
        >
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default StateSelect;
