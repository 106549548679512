import { AxiosError } from 'axios';
import fastRedact from 'fast-redact';
import { LogLevel } from './logger';

const redact = fastRedact({
  paths: ['ssn', '*.ssn', '*.*.ssn'],
  serialize: false,
});

export interface KovoErrorOptions {
  /**
   * The original error that was thrown. This is useful for wrapping errors thrown
   * by 3rd party libraries. This can be an instance of an Error or an unknown object.
   */
  error?: Error | unknown;
  /**
   * If true, the error message will be exposed to the client.
   * Default: false
   */
  isClientFriendlyMessage?: boolean;
  /**
   * The display message to use for the error.
   * This can be overridden using setDisplayMessage.
   */
  displayMessage?: string;
  /**
   * The default display message to use if the error does not have a display message.
   * This can be overridden using setDefaultDisplayMessage.
   * Default: 'Something went wrong. Please try again.'
   */
  defaultDisplayMessage?: string;
  /**
   * The log level to use for the error.
   * Default: 'error'
   */
  logLevel?: LogLevel;

  code?: string;
  status?: number;
}

type AxiosAsJson = {
  config?: {
    data?: {};
  };
} & Record<string, unknown>;

interface KovoErrorDetails {
  message: string;
  stack: string | undefined;
  displayMessage: string;
  originalError?: {
    message?: string | {};
    stack?: string;
    details?: KovoErrorDetails | undefined;
    name?: string;
    axiosError?: AxiosAsJson;
    unknownError?: unknown;
  };
}

export class KovoError<
  Metadata extends Record<string, unknown> = Record<string, unknown>,
> extends Error {
  options: KovoErrorOptions;
  metadata: Metadata;

  constructor(
    public message: string,
    options: KovoErrorOptions = {},
    metadata: Metadata = {} as Metadata,
  ) {
    super(message);

    this.message = message;
    this.options = options;
    this.metadata = metadata;

    // Set the name of the error to match the class name
    this.name = this.constructor.name;

    if (options.error) {
      this.setError(options.error);
    }
  }

  /**
   * A KovoServicesError is an error that is returned from the Kovo services.
   * An error is considered a KovoServicesError if it is an AxiosError and has a userMessage.
   */
  static isKovoServicesError(error: unknown): error is AxiosError<{
    data: { errorCode: string; userMessage: string; status: string };
  }> {
    return Boolean(
      error instanceof AxiosError && error.response?.data?.data?.userMessage,
    );
  }

  static isAxiosError(error: unknown): error is AxiosError {
    return error instanceof AxiosError;
  }

  static isError(error: unknown): error is Error {
    return error instanceof Error;
  }

  static isKovoError(error: unknown): error is KovoError {
    return error instanceof KovoError;
  }

  /**
   * Details about the error that will be logged to Rollbar.
   * By default, the details object will include the message, stack, and metadata.
   * If there error on the KovoError options is an instance of an Error, the details
   * will include the original error's message and stack. In many cases, the KovoError
   * will wrap an error thrown by a 3rd party library, so we want to retain the context
   * of the original error.
   */
  get details(): KovoErrorDetails {
    const originalError = this.options.error;

    return {
      ...this.metadata,
      message: this.message,
      stack: this.stack,
      displayMessage: this.displayMessage,
      ...(this.options.error
        ? {
            originalError: {
              ...(KovoError.isError(originalError)
                ? {
                    message: originalError.message,
                    stack: originalError.stack,
                    name: originalError.name,
                  }
                : {
                    unknownError: originalError,
                  }),
              ...(KovoError.isAxiosError(originalError)
                ? {
                    /**
                     * Prior to setting the axios error, first
                     * remove any sensitive information.
                     */
                    axiosError: redact(
                      originalError.toJSON() ?? {},
                    ) as AxiosAsJson,
                  }
                : {}),
              ...(KovoError.isKovoError(originalError)
                ? { details: originalError.details }
                : {}),
            },
          }
        : {}),
    };
  }

  /**
   * The display message to use for the error.
   * This can be overridden using setDisplayMessage.
   * If a display message is set, it will be returned.
   * If the original error was thrown by Kovo services and contains a userMessage,
   * that will be returned.
   * If the message is client friendly, the message will be returned.
   * If the message is not client friendly, the default display message will be returned.
   */
  get displayMessage() {
    if (this.options.displayMessage) {
      return this.options.displayMessage;
    }

    return this.options.isClientFriendlyMessage
      ? this.message
      : this.defaultDisplayMessage;
  }

  setError(error: unknown) {
    this.setOptions({ error });

    if (error instanceof Error) {
      this.stack = error.stack;
    }

    /**
     * If the error is an AxiosError, set the log level based on the status code.
     * If the status code is less than 500, set the log level to 'warn'.
     * If the status code is 500 or greater, set the log level to 'error'.
     * If the log level is already set, do not override it.
     */
    if (KovoError.isAxiosError(error) && !this.options.logLevel) {
      if (error.response?.status) {
        this.setOptions({
          logLevel: error.response.status < 500 ? 'warn' : 'error',
        });

        /**
         * If the error is an AxiosError with a code of ERR_NETWORK, set the log level to 'warn'.
         * This is a catch all for network errors that don't have a status code.
         * We don't want to set the log level to 'error' because this is a common error
         * that can be retried.
         */
      } else if (error.code === AxiosError.ERR_NETWORK) {
        this.setOptions({
          logLevel: 'warn',
        });
      }
    }

    if (KovoError.isAxiosError(error)) {
      this.setOptions({
        status: error.response?.status,
      });
    }

    /**
     * If the error is a KovoServicesError, set the display message to the userMessage if it exists.
     */
    if (KovoError.isKovoServicesError(this.options.error)) {
      const displayMessage =
        this.options.error.response?.data?.data?.userMessage;
      if (displayMessage) {
        this.setOptions({ displayMessage });
      }
    }

    return this;
  }

  setDisplayMessage(displayMessage: string) {
    return this.setOptions({ displayMessage });
  }

  setLogLevel(logLevel: LogLevel) {
    return this.setOptions({ logLevel });
  }

  get logLevel(): LogLevel {
    return this.options.logLevel ?? 'error';
  }

  setDefaultDisplayMessage(defaultDisplayMessage: string) {
    return this.setOptions({ defaultDisplayMessage });
  }

  get defaultDisplayMessage() {
    return (
      this.options.defaultDisplayMessage ??
      'Something went wrong. Please try again.'
    );
  }

  setCode(code: string) {
    return this.setOptions({ code });
  }

  get code() {
    return this.options.code ?? this.name;
  }

  setStatus(status: number) {
    return this.setOptions({ status });
  }

  get status() {
    return this.options.status;
  }

  setOptions(options: KovoErrorOptions) {
    this.options = {
      ...this.options,
      ...options,
    };

    return this;
  }

  addMetadata(metadata: Record<string, unknown>) {
    this.metadata = {
      ...this.metadata,
      ...metadata,
    };

    return this;
  }

  exposeMessage() {
    return this.setOptions({ isClientFriendlyMessage: true });
  }
}

export class KovoUnauthenticatedError extends KovoError {
  constructor(message: string, options: KovoErrorOptions = {}) {
    super(message, options);

    this.name = this.constructor.name;
  }
}

export class KovoEmailVerificationError extends KovoError {
  constructor(message: string, options: KovoErrorOptions = {}) {
    super(message, options);

    this.name = this.constructor.name;
  }
}

export class KovoUserCreationError extends KovoError {
  constructor(message: string, options: KovoErrorOptions = {}) {
    super(message, options);

    this.name = this.constructor.name;
  }
}
