import { Box, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import { Screen } from '../WelcomeDialog';

interface Props {
  screen: Screen;
  activeStep: number;
  index: number;
}

const WelcomeDialogScreen: React.FC<Props> = ({
  screen,
  activeStep,
  index,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1 0 100%',
        padding: theme.spacing(1),
        paddingBottom: 0,
        transition: 'opacity 0.4s linear',
        opacity: 0,

        ...(activeStep === index && {
          opacity: 1,
          transition: 'opacity 0.4s linear 0.2s',
        }),
      }}
      key={screen.heading}
    >
      <Box>
        <Box
          component="img"
          alt=""
          src={screen.image}
          sx={{
            maxWidth: '220px',
            marginBottom: theme.spacing(2),

            [theme.breakpoints.up('sm')]: {
              marginBottom: theme.spacing(2),
              maxWidth: '320px',
            },

            [theme.breakpoints.up('md')]: {
              marginBottom: theme.spacing(2),
              maxWidth: '360px',
            },
          }}
        />
      </Box>

      <Box
        sx={{
          width: '296px',

          [theme.breakpoints.up('sm')]: {
            width: '346px',
          },

          [theme.breakpoints.up('md')]: {
            width: '360px',
          },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            marginBottom: theme.spacing(1),

            [theme.breakpoints.down('sm')]: {
              fontSize: '24px',
            },

            [theme.breakpoints.up('md')]: {
              marginBottom: theme.spacing(2),
            },
          }}
        >
          {screen.heading}
        </Typography>

        {screen.body.map((text) => (
          <Typography
            variant="body1"
            key={text}
            sx={{
              marginBottom: theme.spacing(2),

              [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(4.5),
              },
            }}
          >
            {text}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default WelcomeDialogScreen;
