import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Skeleton,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { INVITING_REWARD_AMOUNT } from 'helpers/referralClaims';

import ReferralRedemptionDialog from 'components/ReferralRedemptionDialog';
import { theme } from 'context/ThemeProvider';
import { useUnclaimedReferralClaims } from 'hooks/useUnclaimedReferralClaims';

interface Props {
  hideClaimSection?: boolean;
}

const ReferralsBalance: React.FC<Props> = ({ hideClaimSection }) => {
  const [showDialog, setShowDialog] = useState(false);

  const {
    isLoading,
    totalUnclaimedAmount,
    unclaimedReferralClaimIds,
    claimableRewardCategoryIds,
  } = useUnclaimedReferralClaims();

  const handleClickClaim = () => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const hasEarningsToClaim = totalUnclaimedAmount > 0;
  const formattedTotalUnclaimAmount = `$${totalUnclaimedAmount
    .toFixed(2)
    .replace(/\.00$/, '')}`;

  return (
    <Box sx={{ marginBottom: theme.spacing(3) }}>
      {showDialog && (
        <ReferralRedemptionDialog
          onClose={handleClose}
          open={showDialog}
          referralClaimIds={unclaimedReferralClaimIds}
          claimableRewardCategoryIds={claimableRewardCategoryIds}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: theme.spacing(3),
        }}
      >
        <Card
          elevation={0}
          sx={{
            marginRight: theme.spacing(1),

            [theme.breakpoints.up('xs')]: {
              padding: theme.spacing(1.5, 2),
            },

            ...(hasEarningsToClaim && {
              background: `linear-gradient(white, white) padding-box, linear-gradient(to right, #20D385, #0877B8) border-box`,
              border: '3px solid transparent',
            }),
          }}
        >
          <CardContent>
            <Typography variant="h4">
              {isLoading ? (
                <Skeleton width="22px" />
              ) : (
                formattedTotalUnclaimAmount
              )}
            </Typography>
          </CardContent>
        </Card>
        <Typography variant="h4">earnings to claim</Typography>
      </Box>

      {!hideClaimSection && (
        <>
          {hasEarningsToClaim ? (
            <LoadingButton
              variant="contained"
              onClick={handleClickClaim}
              loading={isLoading}
              fullWidth
            >
              Claim Earnings
            </LoadingButton>
          ) : (
            <Card elevation={0}>
              <CardContent sx={{ marginBottom: theme.spacing(2) }}>
                <Typography>
                  Tip: You can easily start earning by inviting friends to join
                  Kovo!
                </Typography>
              </CardContent>
              <CardActions>
                <LoadingButton
                  variant="contained"
                  component={Link}
                  to="/referrals"
                  loading={isLoading}
                  fullWidth
                >
                  Invite Friends & get {INVITING_REWARD_AMOUNT}
                </LoadingButton>
              </CardActions>
            </Card>
          )}
        </>
      )}
    </Box>
  );
};

export default ReferralsBalance;
