import { LoadingButton } from '@mui/lab';
import { Alert, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import { ROUTES } from 'components/Routes';
import { theme } from 'context/ThemeProvider';
import useStartApplication from 'hooks/mutations/useStartApplication';
import useGetApplications from 'hooks/queries/useGetApplications';

const ApplyButton: React.FC = () => {
  const { data, isLoading: isLoadingApplications } = useGetApplications();
  const chargeAccountApplication = data?.chargeAccountApplication;

  const { mutate, isLoading, error } = useStartApplication();

  const handleClick = () => {
    mutate(chargeAccountApplication);
  };

  return (
    <>
      {error && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(2) }}
        >
          There was an error. Please try again.
        </Alert>
      )}

      {chargeAccountApplication?.status === 'started' ? (
        <Button
          component={Link}
          to={ROUTES.CREDIT_LINE_CONTRACT}
          variant="contained"
          fullWidth
        >
          Get Started
        </Button>
      ) : (
        <LoadingButton
          loading={isLoading || isLoadingApplications}
          onClick={handleClick}
          disabled={!chargeAccountApplication}
          variant="contained"
          fullWidth
        >
          Get Started
        </LoadingButton>
      )}
    </>
  );
};

export default ApplyButton;
