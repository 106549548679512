import { Auth } from 'aws-amplify';
import { normalizeEmail } from 'helpers/utils';
import { KovoError } from 'libs/KovoError';

import {
  PENDING_EMAIL_ATTRIBUTE_KEY,
  PENDING_EMAIL_TIMESTAMP_ATTRIBUTE_KEY,
} from './types';

/**
 * Update the email attribute, but put this in a pending state. With the Cognito User Pool
 * set to confirm required field updates, this will send a confirmation code to the new email but
 * keep the old email as their sign in email until they confirm the new email.
 */
export const updateEmailAttribute = async (email: string) => {
  try {
    const normalizedEmail = normalizeEmail(email);

    const currentUser = await Auth.currentAuthenticatedUser();
    const now = new Date().toISOString();

    await Auth.updateUserAttributes(currentUser, {
      email: normalizedEmail,
      [PENDING_EMAIL_ATTRIBUTE_KEY]: normalizedEmail,
      /**
       * Save a timestamp as a custom attribute. If the user refreshes the application
       * within 24 hours, we want to prompt them to confirm their new email address.
       */
      [PENDING_EMAIL_TIMESTAMP_ATTRIBUTE_KEY]: now,
    });

    const userAfterUpdate = await Auth.currentAuthenticatedUser();
    return userAfterUpdate;
  } catch (err) {
    throw new KovoError('Error updating email attribute', {
      error: err,
    }).setDisplayMessage('Error updating your email. Please try again.');
  }
};
