import { Divider, Grid, Skeleton, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import { useRewardsQuery } from 'hooks/queries/useRewardsQuery';
import RewardCard from './RewardCard';

export interface Reward {
  id: string;
  name: string;
  description: string;
  categoryId: string;
  categoryName: string;
  rewardAmountDollars: null | number;
  status: 'locked' | 'unlocked' | 'claimable' | 'claimed';
  giftCardLink: null | string;
}

const RewardsList: React.FC = () => {
  const { data: rewards, isLoading } = useRewardsQuery();

  if (isLoading) {
    return (
      <>
        <Skeleton
          variant="rounded"
          height={150}
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <Skeleton variant="rounded" height={150} />
      </>
    );
  }

  // <OfferEligibleBanner /> displays a banner for this case
  if (rewards?.length === 0) {
    return null;
  }

  const lockedRewards = rewards?.filter(({ status }) => status === 'locked');

  const availableRewards = rewards?.filter(
    ({ status }) => status === 'unlocked',
  );

  const usedRewards = rewards?.filter(
    ({ status }) => status === 'claimable' || status === 'claimed',
  );

  return (
    <>
      <Grid container spacing={2} data-testid="rewards-list">
        {lockedRewards?.map((reward) => (
          <Grid item xs={12} key={reward.id}>
            <RewardCard reward={reward} />
          </Grid>
        ))}
      </Grid>

      {!!availableRewards?.length && (
        <>
          <Typography variant="h5" marginTop={theme.spacing(3)}>
            AVAILABLE
          </Typography>

          <Divider sx={{ margin: theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            {availableRewards.map((reward) => (
              <Grid item xs={12} key={reward.id}>
                <RewardCard reward={reward} />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {!!usedRewards?.length && (
        <>
          <Typography variant="h5" marginTop={theme.spacing(3)}>
            USED
          </Typography>

          <Divider sx={{ margin: theme.spacing(2, 0) }} />

          <Grid container spacing={2}>
            {usedRewards.map((reward) => (
              <Grid item xs={12} key={reward.id}>
                <RewardCard reward={reward} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default RewardsList;
