import { API } from 'aws-amplify';
import { useAuthContext } from 'context/AuthProvider';

import { ClientsService } from 'libs/ClientsService';
import { useEffect, useState } from 'react';

export const useClientsService = () => {
  const { identityId: userId, username: userPoolUserId } = useAuthContext();

  const [clientsService] = useState<ClientsService>(
    new ClientsService(
      API,
      import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
      userId,
      userPoolUserId,
    ),
  );

  useEffect(() => {
    if (
      userId !== clientsService.userId ||
      userPoolUserId !== clientsService.userPoolUserId
    ) {
      clientsService.setHeaderOptions({
        userId,
        userPoolUserId,
      });
    }
  }, [userId, userPoolUserId, clientsService]);

  return clientsService;
};
