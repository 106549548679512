import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import OffersDisclosure from 'components/OffersDisclosure';
import Page from 'components/Page';
import PublicOffersList from 'components/PublicOffersList';
import { AuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';

const PublicOffersPage: React.FC = () => {
  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/offer-categories" />;
  }

  return (
    <>
      <Helmet>
        <title>Offers - Kovo</title>
        <meta
          name="description"
          content="View loans and credit cards offered by lenders through Kovo. It’s time to do work with brands you can trust."
        />
      </Helmet>

      <Page maxWidth="746px">
        <Box
          maxWidth="sm"
          sx={{
            [theme.breakpoints.up('md')]: {
              textAlign: 'center',
              margin: '0 auto',
            },
          }}
        >
          <Typography variant="h1" marginBottom={theme.spacing(2)}>
            Offers
          </Typography>

          <Typography marginBottom={theme.spacing(3)}>
            <div>View offers from in-network partners.</div>
            <OffersDisclosure />
          </Typography>
        </Box>

        <PublicOffersList />
      </Page>
    </>
  );
};

export default PublicOffersPage;
