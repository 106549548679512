import { Box, Button, Typography } from '@mui/material';

import { DARK_BLUE, GREY, theme } from 'context/ThemeProvider';

interface Props {
  handleClick: () => void;
}

const LeaveReviewButtonView: React.FC<Props> = ({ handleClick }) => {
  return (
    <Box sx={{ height: '116px', backgroundColor: GREY }}>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          padding: theme.spacing(2, 0),
          zIndex: '999',
          background:
            'radial-gradient(77.51% 77.36% at 51.21% 95.28%, rgba(0, 141, 224, 0.50) 0%, rgba(8, 119, 184, 0.00) 100%)',
        }}
      >
        <Box
          sx={{
            padding: theme.spacing(0, 1.5),

            [theme.breakpoints.up('sm')]: {
              width: '390px',
              maxWidth: '100%',
              margin: '0 auto',
              padding: theme.spacing(0, 3),
            },

            [theme.breakpoints.up('md')]: {
              width: '516px',
              padding: 0,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              borderRadius: '50px',
              padding: theme.spacing(1.5, 2),
              background:
                'linear-gradient(94deg, #0877B8 11.31%, #20D385 96.33%)',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.common.white,
                flexGrow: 1,
                textAlign: 'center',
                fontSize: '19px',
                fontWeight: '900',
                lineHeight: '22px',
                letterSpacing: '-0.19px',
                marginRight: theme.spacing(0.5),

                [theme.breakpoints.up('sm')]: {
                  fontSize: '23px',
                  lineHeight: '26px',
                  letterSpacing: '-0.46px',
                  margin: 0,
                },

                [theme.breakpoints.up('md')]: {
                  fontSize: '28px',
                  lineHeight: '28px',
                },
              }}
            >
              Enjoying{' '}
              <Box component="span" display="inline-block">
                the Boost?
              </Box>
            </Typography>

            <Button
              type="button"
              sx={{
                backgroundColor: theme.palette.common.white,
                color: DARK_BLUE,
                flex: '0 0 153px',
                padding: theme.spacing(2.25, 0),

                [theme.breakpoints.up('sm')]: {
                  flex: '0 0 170px',
                },

                [theme.breakpoints.up('md')]: {
                  flex: '0 0 185px',
                },

                '&:hover': {
                  backgroundColor: GREY,
                },
              }}
              variant="contained"
              onClick={handleClick}
            >
              Rate Us!
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LeaveReviewButtonView;
