import { Box, Link, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';

import CreditScoreSVG from 'components/CreditScoreSVG';
import PageLoader from 'components/PageLoader';
import { theme } from 'context/ThemeProvider';
import useCreditScore from 'hooks/useCreditScore';

import FICOLogo from 'assets/img/logos/FICO-logo.svg';

const MAX_SCORE = 850;
const MIN_SCORE = 300;

const isSvgPath = (
  element: HTMLElement | SVGPathElement,
): element is SVGPathElement => {
  if (!element) {
    return false;
  }

  return element instanceof SVGPathElement;
};

const getSvgPathById = (id: string): SVGPathElement | null => {
  const element = document.getElementById(id);

  if (!element) {
    return null;
  }

  if (isSvgPath(element)) {
    return element;
  }

  return null;
};

const getScorePosition = (score: string) => {
  const path = getSvgPathById('credit-score-svg-path');

  if (!path) {
    return null;
  }

  const percent = (parseInt(score, 10) - MIN_SCORE) / (MAX_SCORE - MIN_SCORE);
  const pathLength = path.getTotalLength();
  const point = path.getPointAtLength(pathLength * percent);

  return { x: point.x, y: point.y };
};

export const formatScoreDate = (date: string) => {
  return moment.utc(date).format('MM/DD/YY');
};

const CreditScoreGraph: React.FC = () => {
  const [markerPosition, setMarkerPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const { data, isLoading } = useCreditScore();

  useEffect(() => {
    if (data?.creditScore?.ficoScore) {
      const position = getScorePosition(data.creditScore.ficoScore);
      setMarkerPosition(position);
    }
  }, [data?.creditScore?.ficoScore]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!data) {
    return null;
  }

  const formattedScoreDate = formatScoreDate(data.creditScore?.scoreDate || '');

  if (data.creditScore && data.creditScore.ficoScore === '000') {
    return (
      <>
        <Typography marginBottom={theme.spacing(1)}>
          You are building your credit history with Kovo, but your credit
          history is too new to calculate a score.
        </Typography>
        <Typography marginBottom={theme.spacing(1)}>
          FICO® needs to see at least 6 months of credit history before it can
          calculate a FICO® Score for you.
        </Typography>
        <Typography>
          Continue making on-time payments to your Kovo Installment Plan so
          FICO® can calculate a score for you soon.
        </Typography>
      </>
    );
  }

  return (
    <>
      <Box component="img" src={FICOLogo} alt="FICO Score" />

      <Box
        sx={{
          position: 'relative',
          width: '312px',
          height: '317.45px',
          maxWidth: '100%',
          marginBottom: theme.spacing(3),
          marginLeft: theme.spacing(-1),
        }}
      >
        {markerPosition && (
          <Box
            sx={{
              position: 'absolute',
              left: markerPosition.x,
              top: markerPosition.y,
              transform: 'translate3d(-50%, -50%, 0)',
              height: '28px',
              width: '28px',
              borderRadius: '50%',
              backgroundColor: theme.palette.primary.main,
              border: `4px solid ${theme.palette.common.white}`,
              boxShadow: '0 0 10px 0 rgb(0, 0, 0, 30%)',
              zIndex: 9,
            }}
          />
        )}

        <Box
          sx={{
            maxWidth: '100%',
          }}
        >
          <CreditScoreSVG />
        </Box>

        {data.creditScore && (
          <>
            <Box
              sx={{
                position: 'absolute',
                top: theme.spacing(5),
                left: theme.spacing(2),
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    fontSize: '72px',
                    fontWeight: '600',
                    lineHeight: '1',
                  },
                }}
              >
                {data.creditScore.ficoScore}
              </Typography>

              <Typography variant="body2">
                FICO® Score 8 from TransUnion®
              </Typography>
              <Typography variant="body2">
                updated {formattedScoreDate}
              </Typography>
            </Box>

            {['300', '580', '670', '740', '850'].map((score) => {
              const pos = getScorePosition(score);

              if (!pos) return null;

              return (
                <Typography
                  sx={{
                    position: 'absolute',
                    top: pos.y,
                    left: pos.x,
                    transform: 'translate3d(12px, 10px, 0)',
                    color: theme.palette.grey[600],
                  }}
                  key={score}
                >
                  {score}
                </Typography>
              );
            })}
          </>
        )}

        {!data.creditScore && (
          <Typography
            variant="h4"
            sx={{
              position: 'absolute',
              top: theme.spacing(5),
              left: theme.spacing(0),
            }}
          >
            Coming soon
          </Typography>
        )}
      </Box>

      <Typography textAlign="center" paddingTop={theme.spacing(3)}>
        <Link
          href="https://www.ficoscore.com/faqsaboutficoscores"
          target="_blank"
          rel="noopener noreferrer"
        >
          FAQs about FICO® Scores
        </Link>
      </Typography>
    </>
  );
};

export default CreditScoreGraph;
