import { CardActionArea, CardMedia, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { theme } from 'context/ThemeProvider';
import { GiftCard, GiftCardMerchant } from 'types';

import AmazonCard from 'assets/img/products/amazon-card.svg';
import VisaCard from 'assets/img/products/visa-card.svg';
import WalmartCard from 'assets/img/products/walmart-card.svg';

const giftCardBackgroundImageLookup: Record<GiftCardMerchant, string> = {
  walmart: WalmartCard,
  amazon: AmazonCard,
  visa: VisaCard,
};

interface Props {
  giftCard: GiftCard;
}

const GiftCardGridItem: React.FC<Props> = ({ giftCard }) => {
  const amountInDollars = `$${giftCard.amount / 100}`;
  const claimedAtDateObject = moment(giftCard.createdAt);
  const claimedAtFormatted = claimedAtDateObject.format('M/D/YYYY');
  const link = giftCard.link;
  const cardImg = giftCardBackgroundImageLookup[giftCard.merchantName];

  return (
    <Grid item xs={12} data-testid="GiftCardGridItem">
      <Card elevation={4}>
        <CardActionArea
          component="a"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          disableRipple
        >
          {cardImg && <CardMedia component="img" src={cardImg} />}

          {!cardImg && (
            <Typography variant="h5" marginBottom={theme.spacing(2)}>
              Gift Card
            </Typography>
          )}

          <Typography
            variant="h4"
            sx={{
              ...(cardImg
                ? {
                    color: theme.palette.common.white,
                    position: 'absolute',
                    top: theme.spacing(6),
                    right: theme.spacing(6),
                  }
                : {
                    marginBottom: theme.spacing(2),
                  }),

              [theme.breakpoints.up('xs')]: {
                fontSize: '30px',
              },
            }}
          >
            {amountInDollars}
          </Typography>

          <Typography
            sx={{
              ...(cardImg
                ? {
                    color: theme.palette.common.white,
                    position: 'absolute',
                    bottom: theme.spacing(8),
                    left: theme.spacing(6),
                  }
                : {}),
            }}
          >
            Generated on {claimedAtFormatted}
          </Typography>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default GiftCardGridItem;
