import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';

import PageLoader from 'components/PageLoader';
import config from 'config';
import { theme } from 'context/ThemeProvider';
import useLogOut from 'hooks/useLogOut';
import posthog from 'posthog-js';

interface Props {}

const LogOut: React.FC<Props> = () => {
  const { mutate, isLoading } = useLogOut();

  useEffect(() => {
    mutate();
    posthog.reset();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Box textAlign="center">
      <Typography variant="h1" marginBottom={theme.spacing(2)}>
        You've logged out
      </Typography>

      <Typography marginBottom={theme.spacing(3)}>
        We hope to see you again soon.
      </Typography>

      <Button
        variant="contained"
        component="a"
        href={
          config.VITE_STAGE !== 'prod' || window.ReactNativeWebView
            ? '/'
            : 'https://kovocredit.com/'
        }
        fullWidth
      >
        Back to Home
      </Button>
    </Box>
  );
};

export default LogOut;
