// https://stackoverflow.com/a/76797904
import createTheme from '@mui/material/styles/createTheme';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';

import GradientBackgroundDesktop from 'assets/img/illustrations/gradient-background-desktop.png';

/**
 * Custom Typography variants require typing help
 */
declare module '@mui/material/styles' {
  interface TypographyVariants {
    disclaimer: React.CSSProperties;
    body3: React.CSSProperties;
    footnote: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    disclaimer?: React.CSSProperties;
    body3?: React.CSSProperties;
    footnote?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    disclaimer: true;
    body3: true;
    footnote: true;
  }
}

declare module '@mui/material/styles' {
  interface ButtonVariants {
    'outlined-gradient': React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface ButtonVariantsOptions {
    'outlined-gradient'?: React.CSSProperties;
  }
}

// Update the Button's variant prop options
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'outlined-gradient': true;
  }
}

declare module '@mui/material/styles' {
  interface PaperVariants {
    gradient: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface PaperVariantsOptions {
    gradient?: React.CSSProperties;
  }
}

// Update the Paper's variant prop options
declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    gradient: true;
  }
}

interface Props {
  children: React.ReactNode;
}

export const DARK_BLUE = '#1B1230';
export const KOVO_BLUE = '#0877B8';
export const KOVO_LIGHT_BLUE = '#DAF1FF';
export const WHITE = '#FFFFFF';
export const GREEN = '#13A660';
export const KOVO_GREEN = '#20D385';
export const KOVO_LIGHT_GREEN = '#A5F0CF';
export const KOVO_ALERT_LIGHT_GREEN = '#D8FFE1';
export const GREY = '#ECECEC';
export const KOVO_GREY = '#8C8C93';
export const KOVO_ORANGE = '#FF8F33';
export const KOVO_LIGHT_ORANGE = '#FFE7D3';
export const KOVO_PINK = '#F6C';
export const LIGHT_GREY = '#757680';
export const RED = '#F45252';
export const KOVO_RED_ALERT = '#D31900';
export const KOVO_LIGHT_RED = '#FFE0E0';

export const shadowLinkCard = '0px 3px 10px 0px rgba(0, 0, 0, 0.16);';
export const shadowContentCard = '0px 0px 5px 0px rgba(0, 0, 0, 0.10);';

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 390,
      md: 810,
      lg: 1200,
      xl: 1536,
    },
  },
});

export let theme = createTheme(defaultTheme, {
  palette: {
    primary: {
      main: DARK_BLUE,
    },
    success: {
      main: GREEN,
    },
    info: {
      main: '#1C2123',
    },
    error: {
      main: '#D32F2F',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: ['Inter', 'sans-serif'].join(','),
          boxShadow: 'none',
          borderRadius: '1000px',
          fontWeight: 800,
          fontSize: '20px',
          lineHeight: '24px',
          letterSpacing: '-0.15px',
          padding: '18px 16px',
          textAlign: 'center',
        },
        sizeSmall: {
          fontSize: '16px',
          padding: '8px 16px',
        },
        outlined: {
          ':hover': {
            backgroundColor: GREY,
          },
        },
        contained: {
          boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.3)',

          ':hover': {
            backgroundColor: KOVO_BLUE,
          },

          ':active': {
            boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.3)',
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined-gradient' },
          style: {
            color: DARK_BLUE,
            background: `linear-gradient(white, white) padding-box, linear-gradient(to right, ${KOVO_BLUE}, #FF66CC) border-box`,
            border: '2px solid transparent',
            overflow: 'hidden',
            transition: 'background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            padding: defaultTheme.spacing(2),

            ':hover': {
              background: `linear-gradient(${GREY}, ${GREY}) padding-box, linear-gradient(to right, ${KOVO_BLUE}, #FF66CC) border-box`,
            },
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState }: any) => {
          switch (ownerState.variant) {
            case 'button':
              return {
                textDecoration: 'none',
                borderRadius: '12px',
                fontWeight: 'bold',
                padding: '8px 20px',
                backgroundColor: KOVO_BLUE,
                color: '#000',
                display: 'inline-block',
              };

            default:
              return {
                fontFamily: ['Inter', 'sans-serif'].join(','),
                color: KOVO_BLUE,
                textDecorationColor: KOVO_BLUE,
              };
          }
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: defaultTheme.spacing(2),

          [defaultTheme.breakpoints.up('sm')]: {
            borderRadius: defaultTheme.spacing(3),
          },
          padding: defaultTheme.spacing(2.5),
          boxShadow: shadowContentCard,
          ':has(a:active)': {
            boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.3)',
          },
        },
      },
      variants: [
        {
          props: { variant: 'gradient' },
          style: {
            backgroundImage: `url(${GradientBackgroundDesktop})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            color: defaultTheme.palette.common.white,

            p: {
              color: 'inherit',
            },
          },
        },
      ],
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0',

          '&:last-child': {
            padding: '0',
          },
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          boxSizing: 'content-box',
          margin: defaultTheme.spacing(-2.5),
          padding: defaultTheme.spacing(2.5),
        },
        focusHighlight: {
          margin: defaultTheme.spacing(-1.5),
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standard: {
          color: '#131618',
          fontSize: '16px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '8px',
          textAlign: 'left',
        },
        standardSuccess: {
          background: '#E7F6EF',
          borderColor: '#89D2B0',
        },
        standardWarning: {
          background: '#FFF6C9',
          borderColor: '#FEF2B3',
        },
        standardInfo: {
          background: '#F7F8F8',
          borderColor: '#EFF0F1',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          disclaimer: 'p',
          footnote: 'p',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          backgroundColor: WHITE,
        },

        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: DARK_BLUE,
          border: `1px solid ${LIGHT_GREY}`,
          borderRadius: '8px',
          backgroundColor: defaultTheme.palette.common.white,
          transition:
            'box-shadow 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',

          ':hover': {
            borderColor: KOVO_BLUE,
            backgroundColor: defaultTheme.palette.common.white,
          },

          ':focus-within': {
            boxShadow: `0px 0px 5px 0px ${KOVO_BLUE}`,
            borderColor: KOVO_BLUE,
            backgroundColor: defaultTheme.palette.common.white,
          },

          '&:before': {
            content: 'none',
          },
          '&:after': {
            content: 'none',
          },

          '&.Mui-error': {
            borderColor: defaultTheme.palette.error.main,

            ':hover': {
              borderColor: KOVO_BLUE,
            },

            ':focus-within': {
              boxShadow: `0px 0px 5px 0px ${KOVO_BLUE}`,
              borderColor: KOVO_BLUE,
            },
          },

          input: {
            borderRadius: 'inherit',
            paddingLeft: 14,
          },

          select: {
            borderRadius: 'inherit',
            backgroundColor: 'transparent',

            ':focus-within': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: LIGHT_GREY,

          '&.Mui-focused': {
            color: KOVO_BLUE,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '2px', // fix vertical misalignment
          left: '2px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: ['Inter', 'sans-serif'].join(','),
          lineHeight: 1.25,
          margin: 0,
          marginTop: 2,
          marginLeft: 14,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderImageSource:
            'linear-gradient(to left, rgba(8, 119, 184, 0), rgba(8, 119, 184, 0.6))',
          borderImageSlice: '1',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 99999,
        },

        paper: {
          borderRadius: defaultTheme.spacing(2),
          margin: defaultTheme.spacing(2),
          padding: defaultTheme.spacing(2),
          minWidth: '272px',

          [defaultTheme.breakpoints.up('sm')]: {
            padding: defaultTheme.spacing(3),
            borderRadius: defaultTheme.spacing(4),
          },

          [defaultTheme.breakpoints.up('md')]: {
            padding: defaultTheme.spacing(6),
            borderRadius: defaultTheme.spacing(7.5),
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: defaultTheme.spacing(5),

          [defaultTheme.breakpoints.up('md')]: {
            paddingTop: defaultTheme.spacing(6),
            paddingBottom: defaultTheme.spacing(4),
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontFamily: ['Inter', 'sans-serif'].join(','),
          color: DARK_BLUE,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          fontFamily: ['Inter', 'sans-serif'].join(','),
          color: DARK_BLUE,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: 100000, // This is silly but the Dialog is 99999, meaning the Popper is behind it
        },
      },
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: {
      color: DARK_BLUE,
      fontFamily: ['Raleway', 'sans-serif'].join(','),
      fontWeight: 900,
      fontSize: '24px',
      lineHeight: '28px',
      letterSpacing: '-0.1px',

      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
        letterSpacing: '-0.2px',
      },

      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '36px',
        lineHeight: '40px',
        letterSpacing: '-0.05px',
      },
    },
    h2: {
      color: DARK_BLUE,
      fontFamily: ['Raleway', 'sans-serif'].join(','),
      fontWeight: 900,
      fontSize: '22px',
      lineHeight: '28px',
      letterSpacing: 0,

      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '30px',
        lineHeight: '32px',
      },
    },
    h3: {
      color: DARK_BLUE,
      fontFamily: ['Raleway', 'sans-serif'].join(','),
      fontWeight: 800,
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: 0,

      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '26px',
        letterSpacing: '-0.2px',
      },

      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '24px',
        lineHeight: '30px',
      },
    },
    h4: {
      color: DARK_BLUE,
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      letterSpacing: 0,

      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
      },

      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '24px',
        lineHeight: '28px',
      },
    },
    h5: {
      color: DARK_BLUE,
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '20px',
      letterSpacing: 0,
    },
    h6: {
      color: DARK_BLUE,
      fontFamily: ['Raleway', 'sans-serif'].join(','),
      fontWeight: 800,
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: 0,

      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '23px',
        lineHeight: '26px',
        letterSpacing: '-0.2px',
      },

      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '25px',
        lineHeight: '30px',
      },
    },
    subtitle1: {
      color: DARK_BLUE,
      fontSize: '16px',
      letterSpacing: 0,
    },
    subtitle2: {
      color: DARK_BLUE,
      fontSize: '1rem', // 16px
      letterSpacing: 0,
    },
    body1: {
      color: DARK_BLUE,
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: 0,
    },
    body2: {
      color: DARK_BLUE,
      fontFamily: ['Inter', 'sans-serif'].join(','),
      letterSpacing: 0,
    },
    body3: {
      color: DARK_BLUE,
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '12px',
      letterSpacing: 0,
    },
    disclaimer: {
      fontSize: '14px',
      color: '#9ea8ad',
      fontFamily: 'Inter',
      letterSpacing: 0,
    },
    footnote: {
      color: DARK_BLUE,
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '15px',
      letterSpacing: 0,

      [defaultTheme.breakpoints.up('sm')]: {
        lineHeight: '16px',
      },
    },
  },
});

const ThemeProvider: React.FC<Props> = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
