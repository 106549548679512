import { Dialog, DialogProps, IconButton } from '@mui/material';
import { theme } from 'context/ThemeProvider';

type KovoDialogProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  closeIcon?: React.ReactNode;
  children: React.ReactNode;
};

export const KovoDialog = (props: KovoDialogProps) => {
  const { closeIcon, onClose, ...rest } = props;
  return (
    <Dialog maxWidth="sm" fullWidth {...rest}>
      {closeIcon && (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(0.5),

            [theme.breakpoints.up('sm')]: {
              top: theme.spacing(2),
              left: theme.spacing(1.5),
            },

            [theme.breakpoints.up('md')]: {
              top: theme.spacing(4.5),
              left: theme.spacing(4.5),
            },
          }}
        >
          {closeIcon}
        </IconButton>
      )}

      {props.children}
    </Dialog>
  );
};

export default KovoDialog;
