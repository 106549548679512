import { Card, CardContent, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';

const UpfrontPaymentBanner: React.FC = () => {
  return (
    <Card
      elevation={0}
      sx={{
        marginBottom: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '400px',
        textAlign: 'center',
      }}
    >
      <CardContent>
        <Typography>Thank you for purchasing Kovo Grow!</Typography>
      </CardContent>
    </Card>
  );
};

export default UpfrontPaymentBanner;
