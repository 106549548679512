import { Box, Card, CardContent, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';

const IneligibleForRewardsBanner: React.FC = () => {
  return (
    <Card variant="gradient" sx={{ marginBottom: theme.spacing(2) }}>
      <CardContent>
        <Typography>
          <Box component="strong">Keep up the great work!</Box> You will be
          eligible for rewards after your first 4 monthly payments.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default IneligibleForRewardsBanner;
