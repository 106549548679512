import { Box, Card, CardContent, Typography } from '@mui/material';

const CreditScorePastDueMessage: React.FC = () => {
  return (
    <Card variant="gradient">
      <CardContent>
        <Typography>
          <Box component="strong">💰 Stay focused on your objectives.</Box> We
          get it - things happen. Now that you’re here, it’s important to know
          that payment history is the biggest factor of your credit score. Catch
          up on your Kovo Installment Plan to build up better payment history on
          your credit profile.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CreditScorePastDueMessage;
