import { logger } from 'libs/logger';

export const copyText = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    /**
     * Log the error but do not bother logging it to rollbar as something
     * to alert on.
     */
    logger.info('Failed to copy text: ', {
      error,
    });
    return false;
  }
};
