export type WalletType = 'google_pay' | 'apple_pay';

export type onConfirmPaymentMethod = (options: {
  token: string;
  values?: CreditCardFormValues;
  last4?: string;
  walletType?: WalletType;
  scheme: string;
}) => Promise<any>;

export type CreditCardFormValues = {
  name: string;
  zipcode: string;
};

export enum PaymentMode {
  PAYMENT = 'PAYMENT',
  UPDATE_METHOD = 'UPDATE_CARD',
}

export type WalletComponentProps = {
  onConfirmPaymentMethod: onConfirmPaymentMethod;
  onLoad: (isSupportedPaymentMethod: boolean) => void;
  disabled?: boolean;
  amountInDollars: number;
  paymentMode: PaymentMode;
  onError: (error: string | Error) => void;
};
