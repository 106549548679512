import { Box, Link as MuiLink, Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';

import LogOutButton from 'components/LogOutButton';
import Page from 'components/Page';
import { AdaContext } from 'context/AdaProvider';
import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { useContext } from 'react';

const ClosedPage: React.FC = () => {
  const { data, isFetching } = useApplicationStatus();
  const { startBot } = useContext(AdaContext);

  if (!isFetching && data && data.applicationStatus !== 'closed') {
    return <Redirect to="/" />;
  }

  return (
    <Page hideMenuButton>
      <Box maxWidth="350px" margin="0 auto" sx={{ textAlign: 'center' }}>
        <Typography variant="h3" component="h1" marginBottom={theme.spacing(2)}>
          Your Account is Closed
        </Typography>

        <Typography marginBottom={theme.spacing(3)}>
          Your account has been closed. Please{' '}
          <MuiLink
            component="button"
            style={{ verticalAlign: 'baseline' }}
            onClick={() => startBot()}
          >
            get in touch
          </MuiLink>{' '}
          with any questions.
        </Typography>

        <LogOutButton />
      </Box>
    </Page>
  );
};

export default ClosedPage;
