import { Grid } from '@mui/material';

import { GiftCard } from 'types';
import GiftCardGridItem from './GiftCardGridItem';

interface Props {
  giftCards: GiftCard[];
}

const GiftCardsGrid: React.FC<Props> = ({ giftCards }) => {
  return (
    <Grid container spacing={2}>
      {giftCards.map((card) => (
        <GiftCardGridItem giftCard={card} key={card.id}></GiftCardGridItem>
      ))}
    </Grid>
  );
};

export default GiftCardsGrid;
