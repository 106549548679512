import { Box, Card, CardContent, Typography } from '@mui/material';

const CreditScoreOnTimeMessage: React.FC = () => {
  return (
    <Card variant="gradient">
      <CardContent>
        <Typography>
          <Box component="strong">🎉 Keep it up!</Box> You’ve paid your Kovo
          Installments on time. Payment history is the biggest factor of your
          credit score. Keep up the great work!
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CreditScoreOnTimeMessage;
