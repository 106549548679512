import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserAttribute } from 'context/AuthProvider';
import { KovoError } from 'libs/KovoError';

/**
 * This function will get the attributes for a given Cognito user.
 * If there is an error getting the attributes, this function will throw a KovoError
 */
export const getCognitoUserAttributes = (
  cognitoUser: CognitoUser,
): Promise<CognitoUserAttribute[]> => {
  return new Promise((res, rej) => {
    cognitoUser.getUserAttributes((err, attributes) => {
      if (err) {
        return rej(
          new KovoError('Error getting user attributes', { error: err }),
        );
      }

      res(attributes || []);
    });
  });
};
