import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from 'react-stripe-elements';
import * as yup from 'yup';

import TextField from 'components/shared/TextField';
import { theme } from 'context/ThemeProvider';

export interface FormValues {
  name: string;
}

interface Props {
  onSubmit: (values: FormValues) => void;
  isLoading: boolean;
  buttonText?: string;
}

const StripeForm: React.FC<Props> = ({
  onSubmit,
  isLoading,
  buttonText = 'Start',
}) => {
  const { touched, errors, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        name: '',
      },
      validationSchema: yup.object().shape({
        name: yup.string().required('Name is required'),
      }),
      onSubmit,
    });

  return (
    <form onSubmit={handleSubmit} data-testid="stripe-form">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: theme.spacing(3),
        }}
      >
        <TextField
          name="name"
          label="Name on card"
          error={!!(touched.name && errors.name)}
          errorText={errors.name}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Box
          sx={{
            border: '1px solid',
            borderRadius: '8px',
            padding: theme.spacing(2.5, 1.5),
            backgroundColor: theme.palette.common.white,
          }}
        >
          <CardNumberElement />
        </Box>
        <Box
          sx={{
            border: '1px solid',
            borderRadius: '8px',
            padding: theme.spacing(2.5, 1.5),
            backgroundColor: theme.palette.common.white,
          }}
        >
          <CardExpiryElement />
        </Box>
        <Box
          sx={{
            border: '1px solid',
            borderRadius: '8px',
            padding: theme.spacing(2.5, 1.5),
            backgroundColor: theme.palette.common.white,
          }}
        >
          <CardCvcElement />
        </Box>
      </Box>

      <Box sx={{ margin: theme.spacing(4, 0) }}>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
        >
          {buttonText}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default StripeForm;
