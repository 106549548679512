import {
  Box,
  Dialog,
  IconButton,
  Skeleton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import Close from 'assets/img/icons/close.svg';
import { AlertLevel } from 'components/IdMonitoringFeatureCard/IdMonitoringFeatureCard';
import { GREY, theme } from 'context/ThemeProvider';
import {
  AlertDetailsEntry,
  useAlertsList,
} from 'hooks/queries/useIdMonitoringAlerts';

type IdMonitoringAlertDetailsDialogProps = {
  open: boolean;
  onClose: () => void;
  alertLevel: AlertLevel;
};

const IdMonitoringAlertDetailsDialog = ({
  open,
  onClose,
  alertLevel,
}: IdMonitoringAlertDetailsDialogProps) => {
  const { data, isLoading } = useAlertsList(alertLevel);
  const title = (): string => {
    let alertLevelString: string = '';
    switch (alertLevel) {
      case AlertLevel.LOW:
        alertLevelString = 'Low';
        break;
      case AlertLevel.MEDIUM:
        alertLevelString = 'Medium';
        break;
      case AlertLevel.HIGH:
        alertLevelString = 'High';
        break;
      default:
        return ((_level: never) => '')(alertLevel);
    }
    return `${alertLevelString} risk alerts`;
  };

  const content = () => {
    if (isLoading) {
      return (
        <>
          <Typography variant="h1" marginBottom={theme.spacing(3)}>
            <Skeleton data-testid="skeleton" variant="text" width="100%" />
          </Typography>
          {[1, 2, 3, 4, 5].map((index) => (
            <AlertDetailEntry
              key={index}
              isLoading={isLoading}
              sx={{
                marginBottom: index === 2 ? 0 : theme.spacing(2),
              }}
            />
          ))}
        </>
      );
    }

    if (!data || !data.alerts || data.alerts.length === 0) {
      return (
        <Typography marginBottom={theme.spacing(2)}>No alerts found</Typography>
      );
    }
    return (
      <>
        <Typography
          variant="h1"
          sx={{
            marginBottom: theme.spacing(2),

            [theme.breakpoints.up('md')]: {
              marginBottom: theme.spacing(3),
            },
          }}
        >
          {title()}
        </Typography>
        {data?.alerts.map((alertDetailsEntry, index) => (
          <AlertDetailEntry
            key={index}
            isLoading={isLoading}
            alertDetailsEntry={alertDetailsEntry}
            sx={{
              marginBottom:
                index === data.alerts.length - 1 ? 0 : theme.spacing(2),
            }}
          />
        ))}
      </>
    );
  };

  return (
    <Dialog
      data-testid="alert-detail-dialog"
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <IconButton
        onClick={onClose}
        aria-label="Close dialog"
        data-testid="close-button"
        sx={{
          width: '42px',
          height: '42px',
          marginBottom: theme.spacing(2),
        }}
      >
        <Box component="img" src={Close} alt="✕" />
      </IconButton>
      {content()}
    </Dialog>
  );
};

const AlertDetailEntry = ({
  isLoading,
  alertDetailsEntry,
  sx,
}: {
  isLoading: boolean;
  alertDetailsEntry?: AlertDetailsEntry;
  sx?: SxProps<Theme>;
}) => {
  const headerContent = () => {
    if (isLoading || !alertDetailsEntry) {
      return (
        <>
          <Typography variant="footnote">
            <Skeleton variant="text" width="40%" />
          </Typography>
          <Typography variant="footnote">
            <Skeleton variant="text" width="40%" />
          </Typography>
        </>
      );
    }
    const { title, dateFound } = alertDetailsEntry;

    const formattedDate = new Date(dateFound).toLocaleDateString('en-US');
    return (
      <>
        <Typography variant="footnote" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="footnote">{formattedDate}</Typography>
      </>
    );
  };

  const descriptionContent = () => {
    if (isLoading || !alertDetailsEntry) {
      return (
        <Typography variant="footnote">
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
        </Typography>
      );
    }
    const { description } = alertDetailsEntry;
    return <Typography variant="footnote">{description}</Typography>;
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ ...sx }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          backgroundColor: GREY,
          padding: theme.spacing(0.5),
        }}
      >
        {headerContent()}
      </Box>
      <Box
        sx={{
          padding: theme.spacing(0.5),
        }}
      >
        {descriptionContent()}
      </Box>
    </Box>
  );
};

export default IdMonitoringAlertDetailsDialog;
