import Cookies from 'js-cookie';

import config from 'config';

const KOVO_REFERRAL_CODE_KEY = 'kovo_referral_code';

/**
 * You would need to manually set cookies in local and staging
 * Copy and paste the following code to your browser console to set the cookie

  const expireDate = new Date();
  expireDate.setTime(expireDate.getTime() + 7889400000); // 3 months
  let expires = "expires="+ expireDate.toUTCString();
  document.cookie = "kovo_referral_code=" + <your_referrla_code> + ";" + expires + ";Domain=<your-domain-name>;path=/";

 */

export const getReferralCodeFromCookies = (): string | undefined => {
  const referralCode = Cookies.get(KOVO_REFERRAL_CODE_KEY);

  return referralCode;
};

export const removeReferralCodeFromCookies = (): void => {
  const domain =
    config.VITE_STAGE === 'prod' ? 'kovocredit.com' : window.location.hostname;

  Cookies.remove(KOVO_REFERRAL_CODE_KEY, {
    path: '/',
    domain,
  });
};
