import {
  Box,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';
import { theme } from 'context/ThemeProvider';

import Checkmark from 'assets/img/icons/checkmark-circle-green.svg';
import InactiveIcon from 'assets/img/icons/x-circle-grey.svg';
import IdMonitoringAlertCountCard from 'components/IdMonitoringAlertCountCard';
import IdMonitoringAlertDetailsDialog from 'components/IdMonitoringAlertDetailsDialog';
import { isInstallmentAccountValidForIdMonitoring } from 'helpers/accounts';
import useGetAccounts from 'hooks/queries/useGetAccounts';
import { useAlertsSummary } from 'hooks/queries/useIdMonitoringAlerts';
import { useState } from 'react';
import { KovoAlert } from 'components/KovoAlert';

export enum AlertLevel {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

const IdMonitoringFeatureCard = () => {
  const [selectedAlertLevelView, setSelectedAlertLevelView] =
    useState<AlertLevel | null>(null);
  const { data: alertSummary, isLoading: isLoadingAlertSummary } =
    useAlertsSummary();
  const { data: accountsData, isLoading: isLoadingAccountsData } =
    useGetAccounts();

  const installmentAccount = accountsData?.installmentAccount;
  const isIdMonitoringActive =
    isInstallmentAccountValidForIdMonitoring(installmentAccount);

  if (isLoadingAccountsData || isLoadingAlertSummary) {
    return <Skeleton data-testid="skeleton" variant="rounded" height={342} />;
  }

  const statusText = () => {
    if (!isIdMonitoringActive) {
      return 'Inactive';
    }
    return 'Active';
  };

  const statusIcon = () => {
    if (!isIdMonitoringActive) {
      return InactiveIcon;
    }
    return Checkmark;
  };

  return (
    <>
      {selectedAlertLevelView && (
        <IdMonitoringAlertDetailsDialog
          open={true}
          onClose={() => setSelectedAlertLevelView(null)}
          alertLevel={selectedAlertLevelView}
        />
      )}
      <Card elevation={0} data-testid="id-monitoring-card">
        <CardContent>
          <Typography variant="h5">ID MONITORING</Typography>

          <Divider sx={{ margin: theme.spacing(2, 0, 3) }} />

          <Box
            display="flex"
            alignItems="center"
            marginBottom={theme.spacing(3)}
          >
            <Box
              component="img"
              src={statusIcon()}
              alt=""
              marginRight={theme.spacing(1.5)}
            />
            <Typography>{statusText()}</Typography>
          </Box>
          {alertSummary ? (
            <Box display="flex" gap={theme.spacing(1)}>
              <IdMonitoringAlertCountCard
                isLoading={isLoadingAlertSummary}
                label="High risk alerts"
                count={alertSummary.highSeverityCount}
                onViewAlertsClick={() =>
                  setSelectedAlertLevelView(AlertLevel.HIGH)
                }
              />
              <IdMonitoringAlertCountCard
                isLoading={isLoadingAlertSummary}
                label="Medium risk alerts"
                count={alertSummary.mediumSeverityCount}
                onViewAlertsClick={() =>
                  setSelectedAlertLevelView(AlertLevel.MEDIUM)
                }
              />
              <IdMonitoringAlertCountCard
                isLoading={isLoadingAlertSummary}
                label="Low risk alerts"
                count={alertSummary.lowSeverityCount}
                onViewAlertsClick={() =>
                  setSelectedAlertLevelView(AlertLevel.LOW)
                }
              />
            </Box>
          ) : (
            <KovoAlert
              severity="warning"
              message="There was a problem loading your alerts. Please try again later."
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default IdMonitoringFeatureCard;
