import { useMutation } from 'react-query';

import { useClientsService } from 'hooks/useClientsService';
import { ForgotEmailResponse } from 'types/schemas';
import { KovoError } from 'libs/KovoError';
import { AxiosError } from 'axios';

export const USE_FORGOT_EMAIL_MUTATION_KEY = 'forgotEmail';

export type ForgotEmailInput = {
  firstName: string;
  lastName: string;
  ssn: string;
  dob: string; // YYYY-MM-DD
};

type UseForgotEmailOptions = {
  onSuccess?: (data: ForgotEmailResponse) => void;
};

export const useForgotEmail = (options: UseForgotEmailOptions = {}) => {
  const clientsService = useClientsService();

  const forgotEmail = async (input: ForgotEmailInput) => {
    try {
      const result = await clientsService.post<ForgotEmailResponse>(
        '/public/forgot-email',
        input,
        { public: true },
      );
      return result;
    } catch (error) {
      if (error instanceof AxiosError) {
        const e = new KovoError('Error retrieving email, please try again');

        if (error.response?.status === 400) {
          e.setLogLevel('warn');
        } else {
          e.setLogLevel('error');
        }

        throw e;
      }

      throw error;
    }
  };

  return useMutation<ForgotEmailResponse, KovoError, ForgotEmailInput>(
    forgotEmail,
    {
      mutationKey: USE_FORGOT_EMAIL_MUTATION_KEY,
      onSuccess: options.onSuccess,
    },
  );
};
