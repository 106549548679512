import { Logger } from './logger';

export const REACT_QUERY_UNKNOWN_ERROR_MESSAGE =
  'An unknown error was caught by react-query';

export const reactQueryOnError = (logger: Logger) => (error: unknown) => {
  logger.error(error, {
    prefix: `React Query Error`,
  });
};
