import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

import { formatScoreDate } from 'components/CreditScoreGraph';
import { theme } from 'context/ThemeProvider';
import useCreditScore from 'hooks/useCreditScore';

const CreditScoreReasons: React.FC = () => {
  const { data, isLoading } = useCreditScore();

  if (isLoading) {
    return null;
  }

  if (!data || !data.creditScore) {
    return null;
  }

  if (data.creditScore && data.creditScore.ficoScore === '000') {
    return null;
  }

  return (
    <>
      <Card elevation={0} sx={{ marginBottom: theme.spacing(3) }}>
        <CardContent>
          {data.creditScore.reasons.length > 0 && (
            <Typography>
              <strong>Key Factors affecting your FICO® Score:</strong>
            </Typography>
          )}

          {data.creditScore.reasons.length ? (
            <List>
              {data.creditScore.reasons.map(
                ({ reasonStatement, fullDescription, keepInMind }, index) =>
                  index < 2 ? (
                    <ListItem
                      key={reasonStatement}
                      sx={{
                        position: 'relative',
                        paddingLeft: theme.spacing(2.5),
                      }}
                    >
                      <ListItemText>
                        <Typography sx={{ position: 'absolute', left: '0' }}>
                          <strong>{index + 1}.</strong>
                        </Typography>
                        <Typography sx={{ marginBottom: theme.spacing(1) }}>
                          <strong>{reasonStatement}</strong>
                        </Typography>
                        <Typography sx={{ marginBottom: theme.spacing(2) }}>
                          {fullDescription}
                        </Typography>
                        <Typography sx={{ marginBottom: theme.spacing(2) }}>
                          <strong>Keep in mind: </strong>
                          {keepInMind}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  ) : null,
              )}
            </List>
          ) : (
            <>
              <Typography marginBottom={theme.spacing(1)}>
                <strong>Congratulations!</strong>
              </Typography>
              <Typography>
                There are no key factors affecting your FICO® Score. Your FICO®
                Score indicates to lenders that you’re an exceptional borrower.
              </Typography>
            </>
          )}
        </CardContent>
      </Card>

      <Typography variant="footnote" marginBottom={theme.spacing(2)}>
        Your FICO® Score 8 based on TransUnion data is the same score that Kovo
        pulled on {formatScoreDate(data?.creditScore?.scoreDate)} and uses to
        manage your account.
      </Typography>

      <Typography variant="footnote" marginBottom={theme.spacing(2)}>
        FICO and “The score lenders use” are registered trademarks of Fair Isaac
        Corporation in the United States and other countries.
      </Typography>

      <Typography variant="footnote" marginBottom={theme.spacing(2)}>
        FICO® Score and associated educational content are provided solely for
        your own non-commercial personal educational review, use and benefit.
        Kovo and Fair Isaac are not credit repair organizations as defined under
        federal or state law, including the Credit Repair Organizations Act.
      </Typography>

      <Typography variant="footnote">
        Kovo and Fair Isaac do not provide "credit repair" services or advice or
        assistance regarding "rebuilding" or "improving" your credit record,
        credit history or credit rating.
      </Typography>
    </>
  );
};

export default CreditScoreReasons;
