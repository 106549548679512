import PageLoader from 'components/PageLoader';
import { ROUTES } from 'components/Routes';
import { useAuthContext } from 'context/AuthProvider';
import { useClientsService } from 'hooks/useClientsService';
import { useHandlePostUserSignup } from 'hooks/useHandlePostUserSignup';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const MAX_RETRIES = 5;

/**
 * This page is used to create a user in the database after they have signed up if something went wrong
 * during the signup process. This is done because the user is not fully signed up until this step
 * is complete and there is a record in the database. The AuthRoute component will redirect to this
 * page if the user is authenticated with Cognito but does not have a record in the database.
 */
const SignupPendingPage = () => {
  const history = useHistory();
  const clientsService = useClientsService();
  const { currentUser: cognitoUser } = useAuthContext();

  const {
    mutateAsync: handlePostUserSignup,
    isLoading,
    reset,
  } = useHandlePostUserSignup({
    retry: (failureCount, error) => {
      if (failureCount > MAX_RETRIES) {
        reset();
        history.push(ROUTES.ERROR_500_ROUTE, {
          state: {
            error: error.displayMessage ?? error.message,
          },
        });

        return false;
      }

      return true;
    },
    retryDelay: 5000,
  });

  useEffect(() => {
    if (!cognitoUser) {
      return;
    }

    if (isLoading) {
      return;
    }

    handlePostUserSignup({
      clientsService,
      cognitoUser,
    });
  }, [handlePostUserSignup, clientsService, cognitoUser, isLoading]);

  return <PageLoader />;
};

export default SignupPendingPage;
