const html = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
    <style type="text/css">
      @import url(https://themes.googleusercontent.com/fonts/css?kit=rfSAlb2JfKqknMZbyNv1qSTeU1KaNBXdA9eGdIHX70SXjljuBNhoLc9bs1Eq7En9);
      ul.lst-kix_ashu82itcirg-0 {
        list-style-type: none;
      }
      .lst-kix_c8tyj5o2bti-6 > li {
        counter-increment: lst-ctn-kix_c8tyj5o2bti-6;
      }
      ol.lst-kix_tku7q72rmqwe-3.start {
        counter-reset: lst-ctn-kix_tku7q72rmqwe-3 0;
      }
      .lst-kix_4ac75qr0o3cc-5 > li {
        counter-increment: lst-ctn-kix_4ac75qr0o3cc-5;
      }
      .lst-kix_tku7q72rmqwe-8 > li {
        counter-increment: lst-ctn-kix_tku7q72rmqwe-8;
      }
      ol.lst-kix_l1vsz70ouva-3 {
        list-style-type: none;
      }
      ol.lst-kix_l1vsz70ouva-4 {
        list-style-type: none;
      }
      ol.lst-kix_l1vsz70ouva-1 {
        list-style-type: none;
      }
      ol.lst-kix_l1vsz70ouva-2 {
        list-style-type: none;
      }
      .lst-kix_86e2w64ccxrx-6 > li:before {
        content: "\x025cf   ";
      }
      .lst-kix_86e2w64ccxrx-8 > li:before {
        content: "\x025a0   ";
      }
      ol.lst-kix_l1vsz70ouva-7 {
        list-style-type: none;
      }
      ol.lst-kix_l1vsz70ouva-2.start {
        counter-reset: lst-ctn-kix_l1vsz70ouva-2 0;
      }
      ol.lst-kix_l1vsz70ouva-8 {
        list-style-type: none;
      }
      ol.lst-kix_l1vsz70ouva-5 {
        list-style-type: none;
      }
      ol.lst-kix_l1vsz70ouva-6 {
        list-style-type: none;
      }
      .lst-kix_86e2w64ccxrx-7 > li:before {
        content: "\x025cb   ";
      }
      .lst-kix_ijms3idnkxgo-7 > li:before {
        content: "\x025cb   ";
      }
      .lst-kix_ijms3idnkxgo-8 > li:before {
        content: "\x025a0   ";
      }
      ol.lst-kix_l1vsz70ouva-0 {
        list-style-type: none;
      }
      .lst-kix_4ok82ztopnic-0 > li {
        counter-increment: lst-ctn-kix_4ok82ztopnic-0;
      }
      .lst-kix_blprch6em4x6-5 > li {
        counter-increment: lst-ctn-kix_blprch6em4x6-5;
      }
      ol.lst-kix_ashu82itcirg-1.start {
        counter-reset: lst-ctn-kix_ashu82itcirg-1 0;
      }
      .lst-kix_ijms3idnkxgo-0 > li:before {
        content: "  ";
      }
      .lst-kix_ashu82itcirg-1 > li {
        counter-increment: lst-ctn-kix_ashu82itcirg-1;
      }
      ol.lst-kix_blprch6em4x6-5.start {
        counter-reset: lst-ctn-kix_blprch6em4x6-5 0;
      }
      ol.lst-kix_f08e3j4nu4u-2.start {
        counter-reset: lst-ctn-kix_f08e3j4nu4u-2 0;
      }
      .lst-kix_f08e3j4nu4u-8 > li {
        counter-increment: lst-ctn-kix_f08e3j4nu4u-8;
      }
      ol.lst-kix_86e2w64ccxrx-1.start {
        counter-reset: lst-ctn-kix_86e2w64ccxrx-1 0;
      }
      .lst-kix_ijms3idnkxgo-4 > li:before {
        content: "\x025cb   ";
      }
      .lst-kix_86e2w64ccxrx-0 > li:before {
        content: "  ";
      }
      .lst-kix_ijms3idnkxgo-5 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_ijms3idnkxgo-6 > li:before {
        content: "\x025cf   ";
      }
      .lst-kix_e9f8hgxz8m9q-7 > li {
        counter-increment: lst-ctn-kix_e9f8hgxz8m9q-7;
      }
      .lst-kix_86e2w64ccxrx-2 > li:before {
        content: "" counter(lst-ctn-kix_86e2w64ccxrx-2, decimal) ". ";
      }
      .lst-kix_86e2w64ccxrx-4 > li:before {
        content: "\x025cb   ";
      }
      ol.lst-kix_wzvrlxw34w8k-4.start {
        counter-reset: lst-ctn-kix_wzvrlxw34w8k-4 0;
      }
      .lst-kix_wzvrlxw34w8k-7 > li {
        counter-increment: lst-ctn-kix_wzvrlxw34w8k-7;
      }
      ol.lst-kix_w2x0s74x94go-3.start {
        counter-reset: lst-ctn-kix_w2x0s74x94go-3 0;
      }
      ol.lst-kix_4ok82ztopnic-1.start {
        counter-reset: lst-ctn-kix_4ok82ztopnic-1 0;
      }
      .lst-kix_86e2w64ccxrx-1 > li:before {
        content: "" counter(lst-ctn-kix_86e2w64ccxrx-1, decimal) ". ";
      }
      .lst-kix_86e2w64ccxrx-5 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_ijms3idnkxgo-3 > li:before {
        content: "\x025cf   ";
      }
      ul.lst-kix_ashu82itcirg-8 {
        list-style-type: none;
      }
      ul.lst-kix_ashu82itcirg-7 {
        list-style-type: none;
      }
      ol.lst-kix_r7iinn8l2grv-7.start {
        counter-reset: lst-ctn-kix_r7iinn8l2grv-7 0;
      }
      ul.lst-kix_ashu82itcirg-6 {
        list-style-type: none;
      }
      ul.lst-kix_ashu82itcirg-5 {
        list-style-type: none;
      }
      .lst-kix_ijms3idnkxgo-1 > li:before {
        content: "" counter(lst-ctn-kix_ijms3idnkxgo-1, decimal) ". ";
      }
      .lst-kix_ijms3idnkxgo-2 > li:before {
        content: "\x025a0   ";
      }
      ul.lst-kix_ashu82itcirg-4 {
        list-style-type: none;
      }
      ul.lst-kix_ashu82itcirg-3 {
        list-style-type: none;
      }
      .lst-kix_86e2w64ccxrx-3 > li:before {
        content: "\x025cf   ";
      }
      ul.lst-kix_ashu82itcirg-2 {
        list-style-type: none;
      }
      ol.lst-kix_wzvrlxw34w8k-8 {
        list-style-type: none;
      }
      ol.lst-kix_wzvrlxw34w8k-7 {
        list-style-type: none;
      }
      .lst-kix_w2x0s74x94go-0 > li {
        counter-increment: lst-ctn-kix_w2x0s74x94go-0;
      }
      ol.lst-kix_wzvrlxw34w8k-6 {
        list-style-type: none;
      }
      ol.lst-kix_wzvrlxw34w8k-5 {
        list-style-type: none;
      }
      ol.lst-kix_wzvrlxw34w8k-4 {
        list-style-type: none;
      }
      ol.lst-kix_wzvrlxw34w8k-3 {
        list-style-type: none;
      }
      ol.lst-kix_c8tyj5o2bti-3.start {
        counter-reset: lst-ctn-kix_c8tyj5o2bti-3 0;
      }
      ol.lst-kix_wzvrlxw34w8k-2 {
        list-style-type: none;
      }
      ol.lst-kix_tku7q72rmqwe-8.start {
        counter-reset: lst-ctn-kix_tku7q72rmqwe-8 0;
      }
      ol.lst-kix_wzvrlxw34w8k-1 {
        list-style-type: none;
      }
      ol.lst-kix_wzvrlxw34w8k-0 {
        list-style-type: none;
      }
      ol.lst-kix_4ac75qr0o3cc-5.start {
        counter-reset: lst-ctn-kix_4ac75qr0o3cc-5 0;
      }
      ol.lst-kix_l1vsz70ouva-7.start {
        counter-reset: lst-ctn-kix_l1vsz70ouva-7 0;
      }
      .lst-kix_4ok82ztopnic-7 > li {
        counter-increment: lst-ctn-kix_4ok82ztopnic-7;
      }
      .lst-kix_e9f8hgxz8m9q-0 > li {
        counter-increment: lst-ctn-kix_e9f8hgxz8m9q-0;
      }
      ol.lst-kix_blprch6em4x6-0.start {
        counter-reset: lst-ctn-kix_blprch6em4x6-0 0;
      }
      .lst-kix_4ok82ztopnic-4 > li {
        counter-increment: lst-ctn-kix_4ok82ztopnic-4;
      }
      .lst-kix_l1vsz70ouva-6 > li {
        counter-increment: lst-ctn-kix_l1vsz70ouva-6;
      }
      .lst-kix_4ac75qr0o3cc-1 > li {
        counter-increment: lst-ctn-kix_4ac75qr0o3cc-1;
      }
      ol.lst-kix_r7iinn8l2grv-2.start {
        counter-reset: lst-ctn-kix_r7iinn8l2grv-2 0;
      }
      .lst-kix_w2x0s74x94go-3 > li {
        counter-increment: lst-ctn-kix_w2x0s74x94go-3;
      }
      ol.lst-kix_4ac75qr0o3cc-3.start {
        counter-reset: lst-ctn-kix_4ac75qr0o3cc-3 0;
      }
      .lst-kix_r7iinn8l2grv-0 > li:before {
        content: "" counter(lst-ctn-kix_r7iinn8l2grv-0, decimal) ". ";
      }
      .lst-kix_r7iinn8l2grv-2 > li:before {
        content: "" counter(lst-ctn-kix_r7iinn8l2grv-2, lower-roman) ". ";
      }
      ol.lst-kix_e9f8hgxz8m9q-3.start {
        counter-reset: lst-ctn-kix_e9f8hgxz8m9q-3 0;
      }
      .lst-kix_blprch6em4x6-1 > li {
        counter-increment: lst-ctn-kix_blprch6em4x6-1;
      }
      ol.lst-kix_r7iinn8l2grv-0.start {
        counter-reset: lst-ctn-kix_r7iinn8l2grv-0 0;
      }
      .lst-kix_w2x0s74x94go-3 > li:before {
        content: "" counter(lst-ctn-kix_w2x0s74x94go-3, decimal) ". ";
      }
      .lst-kix_r7iinn8l2grv-6 > li:before {
        content: "" counter(lst-ctn-kix_r7iinn8l2grv-6, decimal) ". ";
      }
      .lst-kix_r7iinn8l2grv-4 > li:before {
        content: "" counter(lst-ctn-kix_r7iinn8l2grv-4, lower-latin) ". ";
      }
      .lst-kix_r7iinn8l2grv-8 > li:before {
        content: "" counter(lst-ctn-kix_r7iinn8l2grv-8, lower-roman) ". ";
      }
      .lst-kix_tku7q72rmqwe-1 > li {
        counter-increment: lst-ctn-kix_tku7q72rmqwe-1;
      }
      .lst-kix_w2x0s74x94go-1 > li:before {
        content: "" counter(lst-ctn-kix_w2x0s74x94go-1, lower-latin) ". ";
      }
      .lst-kix_l1vsz70ouva-2 > li {
        counter-increment: lst-ctn-kix_l1vsz70ouva-2;
      }
      ol.lst-kix_4ac75qr0o3cc-0.start {
        counter-reset: lst-ctn-kix_4ac75qr0o3cc-0 0;
      }
      ol.lst-kix_wzvrlxw34w8k-6.start {
        counter-reset: lst-ctn-kix_wzvrlxw34w8k-6 0;
      }
      .lst-kix_w2x0s74x94go-4 > li {
        counter-increment: lst-ctn-kix_w2x0s74x94go-4;
      }
      ul.lst-kix_w2cw0m9hcgl9-8 {
        list-style-type: none;
      }
      ul.lst-kix_w2cw0m9hcgl9-7 {
        list-style-type: none;
      }
      ul.lst-kix_w2cw0m9hcgl9-6 {
        list-style-type: none;
      }
      ul.lst-kix_w2cw0m9hcgl9-5 {
        list-style-type: none;
      }
      .lst-kix_e9f8hgxz8m9q-4 > li {
        counter-increment: lst-ctn-kix_e9f8hgxz8m9q-4;
      }
      ol.lst-kix_l1vsz70ouva-0.start {
        counter-reset: lst-ctn-kix_l1vsz70ouva-0 0;
      }
      ul.lst-kix_w2cw0m9hcgl9-0 {
        list-style-type: none;
      }
      ul.lst-kix_w2cw0m9hcgl9-4 {
        list-style-type: none;
      }
      ul.lst-kix_w2cw0m9hcgl9-3 {
        list-style-type: none;
      }
      ol.lst-kix_e9f8hgxz8m9q-1.start {
        counter-reset: lst-ctn-kix_e9f8hgxz8m9q-1 0;
      }
      ol.lst-kix_w2x0s74x94go-0.start {
        counter-reset: lst-ctn-kix_w2x0s74x94go-0 0;
      }
      ol.lst-kix_e9f8hgxz8m9q-0 {
        list-style-type: none;
      }
      ul.lst-kix_w1ox63mihjsr-0 {
        list-style-type: none;
      }
      ol.lst-kix_e9f8hgxz8m9q-2 {
        list-style-type: none;
      }
      ul.lst-kix_w1ox63mihjsr-3 {
        list-style-type: none;
      }
      ul.lst-kix_k0g1n0qbkr6g-7 {
        list-style-type: none;
      }
      .lst-kix_4ac75qr0o3cc-8 > li {
        counter-increment: lst-ctn-kix_4ac75qr0o3cc-8;
      }
      ol.lst-kix_e9f8hgxz8m9q-1 {
        list-style-type: none;
      }
      ul.lst-kix_w1ox63mihjsr-2 {
        list-style-type: none;
      }
      .lst-kix_c8tyj5o2bti-3 > li {
        counter-increment: lst-ctn-kix_c8tyj5o2bti-3;
      }
      ul.lst-kix_k0g1n0qbkr6g-8 {
        list-style-type: none;
      }
      ol.lst-kix_f08e3j4nu4u-3 {
        list-style-type: none;
      }
      ol.lst-kix_f08e3j4nu4u-4 {
        list-style-type: none;
      }
      ol.lst-kix_f08e3j4nu4u-1 {
        list-style-type: none;
      }
      .lst-kix_f08e3j4nu4u-1 > li {
        counter-increment: lst-ctn-kix_f08e3j4nu4u-1;
      }
      ol.lst-kix_f08e3j4nu4u-2 {
        list-style-type: none;
      }
      ol.lst-kix_tku7q72rmqwe-5.start {
        counter-reset: lst-ctn-kix_tku7q72rmqwe-5 0;
      }
      ol.lst-kix_f08e3j4nu4u-0 {
        list-style-type: none;
      }
      .lst-kix_4ac75qr0o3cc-2 > li {
        counter-increment: lst-ctn-kix_4ac75qr0o3cc-2;
      }
      .lst-kix_wzvrlxw34w8k-0 > li {
        counter-increment: lst-ctn-kix_wzvrlxw34w8k-0;
      }
      ol.lst-kix_blprch6em4x6-2.start {
        counter-reset: lst-ctn-kix_blprch6em4x6-2 0;
      }
      .lst-kix_r7iinn8l2grv-3 > li {
        counter-increment: lst-ctn-kix_r7iinn8l2grv-3;
      }
      ol.lst-kix_e9f8hgxz8m9q-4 {
        list-style-type: none;
      }
      ol.lst-kix_e9f8hgxz8m9q-3 {
        list-style-type: none;
      }
      .lst-kix_4ok82ztopnic-7 > li:before {
        content: "" counter(lst-ctn-kix_4ok82ztopnic-7, lower-latin) ". ";
      }
      ol.lst-kix_e9f8hgxz8m9q-6 {
        list-style-type: none;
      }
      ol.lst-kix_e9f8hgxz8m9q-5 {
        list-style-type: none;
      }
      .lst-kix_c8tyj5o2bti-2 > li {
        counter-increment: lst-ctn-kix_c8tyj5o2bti-2;
      }
      ol.lst-kix_e9f8hgxz8m9q-8 {
        list-style-type: none;
      }
      ol.lst-kix_e9f8hgxz8m9q-7 {
        list-style-type: none;
      }
      ol.lst-kix_1gzzhso2s78a-2.start {
        counter-reset: lst-ctn-kix_1gzzhso2s78a-2 0;
      }
      .lst-kix_4ok82ztopnic-3 > li:before {
        content: "" counter(lst-ctn-kix_4ok82ztopnic-3, decimal) ". ";
      }
      .lst-kix_4ok82ztopnic-5 > li:before {
        content: "" counter(lst-ctn-kix_4ok82ztopnic-5, lower-roman) ". ";
      }
      ol.lst-kix_w2x0s74x94go-1.start {
        counter-reset: lst-ctn-kix_w2x0s74x94go-1 0;
      }
      ol.lst-kix_ashu82itcirg-1 {
        list-style-type: none;
      }
      ol.lst-kix_f08e3j4nu4u-7.start {
        counter-reset: lst-ctn-kix_f08e3j4nu4u-7 0;
      }
      ol.lst-kix_tku7q72rmqwe-6.start {
        counter-reset: lst-ctn-kix_tku7q72rmqwe-6 0;
      }
      ol.lst-kix_blprch6em4x6-3.start {
        counter-reset: lst-ctn-kix_blprch6em4x6-3 0;
      }
      ol.lst-kix_c8tyj5o2bti-0.start {
        counter-reset: lst-ctn-kix_c8tyj5o2bti-0 0;
      }
      ol.lst-kix_f08e3j4nu4u-7 {
        list-style-type: none;
      }
      ol.lst-kix_f08e3j4nu4u-8 {
        list-style-type: none;
      }
      .lst-kix_w2x0s74x94go-5 > li:before {
        content: "" counter(lst-ctn-kix_w2x0s74x94go-5, lower-roman) ". ";
      }
      ol.lst-kix_f08e3j4nu4u-5 {
        list-style-type: none;
      }
      ol.lst-kix_f08e3j4nu4u-6 {
        list-style-type: none;
      }
      ul.lst-kix_w1ox63mihjsr-5 {
        list-style-type: none;
      }
      ul.lst-kix_k0g1n0qbkr6g-5 {
        list-style-type: none;
      }
      ul.lst-kix_w1ox63mihjsr-4 {
        list-style-type: none;
      }
      ul.lst-kix_k0g1n0qbkr6g-6 {
        list-style-type: none;
      }
      .lst-kix_4ok82ztopnic-1 > li:before {
        content: "" counter(lst-ctn-kix_4ok82ztopnic-1, lower-latin) ". ";
      }
      ul.lst-kix_w1ox63mihjsr-7 {
        list-style-type: none;
      }
      ul.lst-kix_k0g1n0qbkr6g-3 {
        list-style-type: none;
      }
      ul.lst-kix_w1ox63mihjsr-6 {
        list-style-type: none;
      }
      ul.lst-kix_k0g1n0qbkr6g-4 {
        list-style-type: none;
      }
      ul.lst-kix_w1ox63mihjsr-8 {
        list-style-type: none;
      }
      ul.lst-kix_k0g1n0qbkr6g-2 {
        list-style-type: none;
      }
      .lst-kix_w2x0s74x94go-7 > li:before {
        content: "" counter(lst-ctn-kix_w2x0s74x94go-7, lower-latin) ". ";
      }
      ul.lst-kix_k0g1n0qbkr6g-0 {
        list-style-type: none;
      }
      ul.lst-kix_lmy2waf0o3x-2 {
        list-style-type: none;
      }
      .lst-kix_tku7q72rmqwe-2 > li:before {
        content: "" counter(lst-ctn-kix_tku7q72rmqwe-2, lower-roman) ". ";
      }
      ul.lst-kix_lmy2waf0o3x-0 {
        list-style-type: none;
      }
      .lst-kix_blprch6em4x6-6 > li {
        counter-increment: lst-ctn-kix_blprch6em4x6-6;
      }
      ol.lst-kix_r7iinn8l2grv-4.start {
        counter-reset: lst-ctn-kix_r7iinn8l2grv-4 0;
      }
      .lst-kix_tku7q72rmqwe-6 > li:before {
        content: "" counter(lst-ctn-kix_tku7q72rmqwe-6, decimal) ". ";
      }
      ul.lst-kix_ijms3idnkxgo-0 {
        list-style-type: none;
      }
      ul.lst-kix_ijms3idnkxgo-2 {
        list-style-type: none;
      }
      .lst-kix_tku7q72rmqwe-5 > li:before {
        content: "" counter(lst-ctn-kix_tku7q72rmqwe-5, lower-roman) ". ";
      }
      ul.lst-kix_ijms3idnkxgo-3 {
        list-style-type: none;
      }
      ul.lst-kix_ijms3idnkxgo-4 {
        list-style-type: none;
      }
      .lst-kix_k0g1n0qbkr6g-2 > li:before {
        content: "\x025a0   ";
      }
      ol.lst-kix_c8tyj5o2bti-1.start {
        counter-reset: lst-ctn-kix_c8tyj5o2bti-1 0;
      }
      ul.lst-kix_lmy2waf0o3x-8 {
        list-style-type: none;
      }
      ul.lst-kix_lmy2waf0o3x-7 {
        list-style-type: none;
      }
      ul.lst-kix_lmy2waf0o3x-6 {
        list-style-type: none;
      }
      ul.lst-kix_lmy2waf0o3x-5 {
        list-style-type: none;
      }
      ul.lst-kix_lmy2waf0o3x-4 {
        list-style-type: none;
      }
      ul.lst-kix_lmy2waf0o3x-3 {
        list-style-type: none;
      }
      .lst-kix_l1vsz70ouva-0 > li:before {
        content: "" counter(lst-ctn-kix_l1vsz70ouva-0, decimal) ". ";
      }
      .lst-kix_4ac75qr0o3cc-4 > li:before {
        content: "" counter(lst-ctn-kix_4ac75qr0o3cc-4, lower-latin) ". ";
      }
      .lst-kix_4ac75qr0o3cc-5 > li:before {
        content: "" counter(lst-ctn-kix_4ac75qr0o3cc-5, lower-roman) ". ";
      }
      .lst-kix_l1vsz70ouva-4 > li:before {
        content: "" counter(lst-ctn-kix_l1vsz70ouva-4, lower-latin) ". ";
      }
      .lst-kix_4ac75qr0o3cc-0 > li:before {
        content: "" counter(lst-ctn-kix_4ac75qr0o3cc-0, decimal) ". ";
      }
      .lst-kix_4ac75qr0o3cc-8 > li:before {
        content: "" counter(lst-ctn-kix_4ac75qr0o3cc-8, lower-roman) ". ";
      }
      .lst-kix_l1vsz70ouva-5 > li:before {
        content: "" counter(lst-ctn-kix_l1vsz70ouva-5, lower-roman) ". ";
      }
      ul.lst-kix_ijms3idnkxgo-5 {
        list-style-type: none;
      }
      ul.lst-kix_ijms3idnkxgo-6 {
        list-style-type: none;
      }
      ol.lst-kix_f08e3j4nu4u-5.start {
        counter-reset: lst-ctn-kix_f08e3j4nu4u-5 0;
      }
      ul.lst-kix_ijms3idnkxgo-7 {
        list-style-type: none;
      }
      ul.lst-kix_ijms3idnkxgo-8 {
        list-style-type: none;
      }
      .lst-kix_86e2w64ccxrx-1 > li {
        counter-increment: lst-ctn-kix_86e2w64ccxrx-1;
      }
      ol.lst-kix_4ac75qr0o3cc-7.start {
        counter-reset: lst-ctn-kix_4ac75qr0o3cc-7 0;
      }
      .lst-kix_4ac75qr0o3cc-1 > li:before {
        content: "" counter(lst-ctn-kix_4ac75qr0o3cc-1, lower-latin) ". ";
      }
      .lst-kix_f08e3j4nu4u-0 > li {
        counter-increment: lst-ctn-kix_f08e3j4nu4u-0;
      }
      .lst-kix_e9f8hgxz8m9q-3 > li:before {
        content: "" counter(lst-ctn-kix_e9f8hgxz8m9q-3, decimal) ". ";
      }
      .lst-kix_4ok82ztopnic-8 > li {
        counter-increment: lst-ctn-kix_4ok82ztopnic-8;
      }
      .lst-kix_l1vsz70ouva-1 > li:before {
        content: "" counter(lst-ctn-kix_l1vsz70ouva-1, lower-latin) ". ";
      }
      ol.lst-kix_blprch6em4x6-8.start {
        counter-reset: lst-ctn-kix_blprch6em4x6-8 0;
      }
      .lst-kix_tku7q72rmqwe-1 > li:before {
        content: "" counter(lst-ctn-kix_tku7q72rmqwe-1, lower-latin) ". ";
      }
      .lst-kix_e9f8hgxz8m9q-2 > li:before {
        content: "" counter(lst-ctn-kix_e9f8hgxz8m9q-2, lower-roman) ". ";
      }
      ol.lst-kix_wzvrlxw34w8k-1.start {
        counter-reset: lst-ctn-kix_wzvrlxw34w8k-1 0;
      }
      .lst-kix_e9f8hgxz8m9q-7 > li:before {
        content: "" counter(lst-ctn-kix_e9f8hgxz8m9q-7, lower-latin) ". ";
      }
      ol.lst-kix_w1ox63mihjsr-1.start {
        counter-reset: lst-ctn-kix_w1ox63mihjsr-1 0;
      }
      .lst-kix_e9f8hgxz8m9q-6 > li {
        counter-increment: lst-ctn-kix_e9f8hgxz8m9q-6;
      }
      .lst-kix_r7iinn8l2grv-4 > li {
        counter-increment: lst-ctn-kix_r7iinn8l2grv-4;
      }
      .lst-kix_e9f8hgxz8m9q-6 > li:before {
        content: "" counter(lst-ctn-kix_e9f8hgxz8m9q-6, decimal) ". ";
      }
      .lst-kix_blprch6em4x6-4 > li {
        counter-increment: lst-ctn-kix_blprch6em4x6-4;
      }
      ol.lst-kix_1gzzhso2s78a-2 {
        list-style-type: none;
      }
      .lst-kix_l1vsz70ouva-8 > li:before {
        content: "" counter(lst-ctn-kix_l1vsz70ouva-8, lower-roman) ". ";
      }
      ul.lst-kix_86e2w64ccxrx-0 {
        list-style-type: none;
      }
      ul.lst-kix_86e2w64ccxrx-3 {
        list-style-type: none;
      }
      ul.lst-kix_86e2w64ccxrx-4 {
        list-style-type: none;
      }
      ol.lst-kix_w2cw0m9hcgl9-2 {
        list-style-type: none;
      }
      ol.lst-kix_w2cw0m9hcgl9-1 {
        list-style-type: none;
      }
      ol.lst-kix_1gzzhso2s78a-1 {
        list-style-type: none;
      }
      ol.lst-kix_blprch6em4x6-3 {
        list-style-type: none;
      }
      ul.lst-kix_86e2w64ccxrx-7 {
        list-style-type: none;
      }
      ol.lst-kix_blprch6em4x6-2 {
        list-style-type: none;
      }
      ul.lst-kix_86e2w64ccxrx-8 {
        list-style-type: none;
      }
      ol.lst-kix_blprch6em4x6-5 {
        list-style-type: none;
      }
      ul.lst-kix_86e2w64ccxrx-5 {
        list-style-type: none;
      }
      ol.lst-kix_blprch6em4x6-4 {
        list-style-type: none;
      }
      ul.lst-kix_86e2w64ccxrx-6 {
        list-style-type: none;
      }
      .lst-kix_wzvrlxw34w8k-6 > li:before {
        content: "" counter(lst-ctn-kix_wzvrlxw34w8k-6, decimal) ". ";
      }
      ol.lst-kix_blprch6em4x6-7 {
        list-style-type: none;
      }
      ol.lst-kix_blprch6em4x6-6 {
        list-style-type: none;
      }
      ol.lst-kix_blprch6em4x6-8 {
        list-style-type: none;
      }
      .lst-kix_wzvrlxw34w8k-7 > li:before {
        content: "" counter(lst-ctn-kix_wzvrlxw34w8k-7, lower-latin) ". ";
      }
      ol.lst-kix_blprch6em4x6-1 {
        list-style-type: none;
      }
      ol.lst-kix_blprch6em4x6-0 {
        list-style-type: none;
      }
      .lst-kix_k0g1n0qbkr6g-3 > li:before {
        content: "\x025cf   ";
      }
      ol.lst-kix_f08e3j4nu4u-4.start {
        counter-reset: lst-ctn-kix_f08e3j4nu4u-4 0;
      }
      .lst-kix_c8tyj5o2bti-1 > li {
        counter-increment: lst-ctn-kix_c8tyj5o2bti-1;
      }
      .lst-kix_wzvrlxw34w8k-3 > li:before {
        content: "" counter(lst-ctn-kix_wzvrlxw34w8k-3, decimal) ". ";
      }
      .lst-kix_wzvrlxw34w8k-2 > li:before {
        content: "" counter(lst-ctn-kix_wzvrlxw34w8k-2, lower-roman) ". ";
      }
      .lst-kix_4ok82ztopnic-1 > li {
        counter-increment: lst-ctn-kix_4ok82ztopnic-1;
      }
      .lst-kix_k0g1n0qbkr6g-6 > li:before {
        content: "\x025cf   ";
      }
      .lst-kix_k0g1n0qbkr6g-7 > li:before {
        content: "\x025cb   ";
      }
      ol.lst-kix_wzvrlxw34w8k-2.start {
        counter-reset: lst-ctn-kix_wzvrlxw34w8k-2 0;
      }
      .lst-kix_ashu82itcirg-6 > li:before {
        content: "\x025cf   ";
      }
      ol.lst-kix_4ac75qr0o3cc-8 {
        list-style-type: none;
      }
      .lst-kix_r7iinn8l2grv-1 > li:before {
        content: "" counter(lst-ctn-kix_r7iinn8l2grv-1, lower-latin) ". ";
      }
      .lst-kix_f08e3j4nu4u-3 > li {
        counter-increment: lst-ctn-kix_f08e3j4nu4u-3;
      }
      ol.lst-kix_c8tyj5o2bti-8.start {
        counter-reset: lst-ctn-kix_c8tyj5o2bti-8 0;
      }
      .lst-kix_wzvrlxw34w8k-2 > li {
        counter-increment: lst-ctn-kix_wzvrlxw34w8k-2;
      }
      .lst-kix_r7iinn8l2grv-6 > li {
        counter-increment: lst-ctn-kix_r7iinn8l2grv-6;
      }
      .lst-kix_1gzzhso2s78a-2 > li {
        counter-increment: lst-ctn-kix_1gzzhso2s78a-2;
      }
      .lst-kix_r7iinn8l2grv-5 > li:before {
        content: "" counter(lst-ctn-kix_r7iinn8l2grv-5, lower-roman) ". ";
      }
      ol.lst-kix_w2cw0m9hcgl9-1.start {
        counter-reset: lst-ctn-kix_w2cw0m9hcgl9-1 0;
      }
      ol.lst-kix_4ac75qr0o3cc-6.start {
        counter-reset: lst-ctn-kix_4ac75qr0o3cc-6 0;
      }
      .lst-kix_ashu82itcirg-2 > li:before {
        content: "\x025a0   ";
      }
      ol.lst-kix_k0g1n0qbkr6g-1.start {
        counter-reset: lst-ctn-kix_k0g1n0qbkr6g-1 0;
      }
      .lst-kix_4ac75qr0o3cc-0 > li {
        counter-increment: lst-ctn-kix_4ac75qr0o3cc-0;
      }
      .lst-kix_w2x0s74x94go-0 > li:before {
        content: "" counter(lst-ctn-kix_w2x0s74x94go-0, decimal) ". ";
      }
      ol.lst-kix_blprch6em4x6-7.start {
        counter-reset: lst-ctn-kix_blprch6em4x6-7 0;
      }
      ol.lst-kix_f08e3j4nu4u-1.start {
        counter-reset: lst-ctn-kix_f08e3j4nu4u-1 0;
      }
      .lst-kix_e9f8hgxz8m9q-3 > li {
        counter-increment: lst-ctn-kix_e9f8hgxz8m9q-3;
      }
      .lst-kix_blprch6em4x6-2 > li {
        counter-increment: lst-ctn-kix_blprch6em4x6-2;
      }
      .lst-kix_f08e3j4nu4u-5 > li {
        counter-increment: lst-ctn-kix_f08e3j4nu4u-5;
      }
      ol.lst-kix_r7iinn8l2grv-3.start {
        counter-reset: lst-ctn-kix_r7iinn8l2grv-3 0;
      }
      ol.lst-kix_l1vsz70ouva-3.start {
        counter-reset: lst-ctn-kix_l1vsz70ouva-3 0;
      }
      ol.lst-kix_l1vsz70ouva-6.start {
        counter-reset: lst-ctn-kix_l1vsz70ouva-6 0;
      }
      ol.lst-kix_wzvrlxw34w8k-0.start {
        counter-reset: lst-ctn-kix_wzvrlxw34w8k-0 0;
      }
      ol.lst-kix_w1ox63mihjsr-1 {
        list-style-type: none;
      }
      ol.lst-kix_c8tyj5o2bti-5.start {
        counter-reset: lst-ctn-kix_c8tyj5o2bti-5 0;
      }
      .lst-kix_e9f8hgxz8m9q-1 > li {
        counter-increment: lst-ctn-kix_e9f8hgxz8m9q-1;
      }
      ol.lst-kix_4ac75qr0o3cc-7 {
        list-style-type: none;
      }
      .lst-kix_wzvrlxw34w8k-4 > li {
        counter-increment: lst-ctn-kix_wzvrlxw34w8k-4;
      }
      ol.lst-kix_4ac75qr0o3cc-6 {
        list-style-type: none;
      }
      .lst-kix_w2x0s74x94go-2 > li {
        counter-increment: lst-ctn-kix_w2x0s74x94go-2;
      }
      ol.lst-kix_4ac75qr0o3cc-5 {
        list-style-type: none;
      }
      ol.lst-kix_4ac75qr0o3cc-4 {
        list-style-type: none;
      }
      ol.lst-kix_2z0rpj1q4k1z-1.start {
        counter-reset: lst-ctn-kix_2z0rpj1q4k1z-1 0;
      }
      ol.lst-kix_4ac75qr0o3cc-3 {
        list-style-type: none;
      }
      ol.lst-kix_4ac75qr0o3cc-2 {
        list-style-type: none;
      }
      ol.lst-kix_4ac75qr0o3cc-1 {
        list-style-type: none;
      }
      ol.lst-kix_4ac75qr0o3cc-0 {
        list-style-type: none;
      }
      .lst-kix_w2cw0m9hcgl9-7 > li:before {
        content: "\x025cb   ";
      }
      ol.lst-kix_tku7q72rmqwe-1.start {
        counter-reset: lst-ctn-kix_tku7q72rmqwe-1 0;
      }
      .lst-kix_lmy2waf0o3x-7 > li:before {
        content: "\x025cb   ";
      }
      .lst-kix_l1vsz70ouva-4 > li {
        counter-increment: lst-ctn-kix_l1vsz70ouva-4;
      }
      ol.lst-kix_k0g1n0qbkr6g-1 {
        list-style-type: none;
      }
      ol.lst-kix_c8tyj5o2bti-6.start {
        counter-reset: lst-ctn-kix_c8tyj5o2bti-6 0;
      }
      ol.lst-kix_r7iinn8l2grv-6.start {
        counter-reset: lst-ctn-kix_r7iinn8l2grv-6 0;
      }
      ol.lst-kix_l1vsz70ouva-4.start {
        counter-reset: lst-ctn-kix_l1vsz70ouva-4 0;
      }
      .lst-kix_tku7q72rmqwe-4 > li {
        counter-increment: lst-ctn-kix_tku7q72rmqwe-4;
      }
      .lst-kix_4ok82ztopnic-3 > li {
        counter-increment: lst-ctn-kix_4ok82ztopnic-3;
      }
      ol.lst-kix_4ac75qr0o3cc-8.start {
        counter-reset: lst-ctn-kix_4ac75qr0o3cc-8 0;
      }
      .lst-kix_blprch6em4x6-7 > li:before {
        content: "" counter(lst-ctn-kix_blprch6em4x6-7, lower-latin) ". ";
      }
      .lst-kix_lmy2waf0o3x-3 > li:before {
        content: "\x025cf   ";
      }
      .lst-kix_w2x0s74x94go-7 > li {
        counter-increment: lst-ctn-kix_w2x0s74x94go-7;
      }
      .lst-kix_blprch6em4x6-3 > li:before {
        content: "" counter(lst-ctn-kix_blprch6em4x6-3, decimal) ". ";
      }
      .lst-kix_4ok82ztopnic-2 > li:before {
        content: "" counter(lst-ctn-kix_4ok82ztopnic-2, lower-roman) ". ";
      }
      .lst-kix_4ok82ztopnic-6 > li:before {
        content: "" counter(lst-ctn-kix_4ok82ztopnic-6, decimal) ". ";
      }
      ol.lst-kix_f08e3j4nu4u-0.start {
        counter-reset: lst-ctn-kix_f08e3j4nu4u-0 0;
      }
      .lst-kix_w1ox63mihjsr-2 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_w2x0s74x94go-4 > li:before {
        content: "" counter(lst-ctn-kix_w2x0s74x94go-4, lower-latin) ". ";
      }
      ol.lst-kix_tku7q72rmqwe-0.start {
        counter-reset: lst-ctn-kix_tku7q72rmqwe-0 0;
      }
      ol.lst-kix_l1vsz70ouva-5.start {
        counter-reset: lst-ctn-kix_l1vsz70ouva-5 0;
      }
      ol.lst-kix_r7iinn8l2grv-5.start {
        counter-reset: lst-ctn-kix_r7iinn8l2grv-5 0;
      }
      .lst-kix_e9f8hgxz8m9q-8 > li {
        counter-increment: lst-ctn-kix_e9f8hgxz8m9q-8;
      }
      ol.lst-kix_ijms3idnkxgo-1 {
        list-style-type: none;
      }
      .lst-kix_w2x0s74x94go-8 > li:before {
        content: "" counter(lst-ctn-kix_w2x0s74x94go-8, lower-roman) ". ";
      }
      ol.lst-kix_c8tyj5o2bti-7.start {
        counter-reset: lst-ctn-kix_c8tyj5o2bti-7 0;
      }
      .lst-kix_w1ox63mihjsr-6 > li:before {
        content: "\x025cf   ";
      }
      ol.lst-kix_tku7q72rmqwe-2 {
        list-style-type: none;
      }
      ol.lst-kix_tku7q72rmqwe-3 {
        list-style-type: none;
      }
      ol.lst-kix_tku7q72rmqwe-4 {
        list-style-type: none;
      }
      ol.lst-kix_tku7q72rmqwe-5 {
        list-style-type: none;
      }
      .lst-kix_w2cw0m9hcgl9-1 > li {
        counter-increment: lst-ctn-kix_w2cw0m9hcgl9-1;
      }
      ol.lst-kix_tku7q72rmqwe-0 {
        list-style-type: none;
      }
      .lst-kix_r7iinn8l2grv-1 > li {
        counter-increment: lst-ctn-kix_r7iinn8l2grv-1;
      }
      ol.lst-kix_tku7q72rmqwe-1 {
        list-style-type: none;
      }
      ol.lst-kix_f08e3j4nu4u-8.start {
        counter-reset: lst-ctn-kix_f08e3j4nu4u-8 0;
      }
      .lst-kix_2z0rpj1q4k1z-1 > li {
        counter-increment: lst-ctn-kix_2z0rpj1q4k1z-1;
      }
      .lst-kix_c8tyj5o2bti-6 > li:before {
        content: "" counter(lst-ctn-kix_c8tyj5o2bti-6, decimal) ". ";
      }
      ol.lst-kix_tku7q72rmqwe-6 {
        list-style-type: none;
      }
      ol.lst-kix_tku7q72rmqwe-7 {
        list-style-type: none;
      }
      .lst-kix_c8tyj5o2bti-5 > li:before {
        content: "" counter(lst-ctn-kix_c8tyj5o2bti-5, lower-roman) ". ";
      }
      ol.lst-kix_tku7q72rmqwe-8 {
        list-style-type: none;
      }
      ol.lst-kix_r7iinn8l2grv-1.start {
        counter-reset: lst-ctn-kix_r7iinn8l2grv-1 0;
      }
      .lst-kix_c8tyj5o2bti-4 > li:before {
        content: "" counter(lst-ctn-kix_c8tyj5o2bti-4, lower-latin) ". ";
      }
      ol.lst-kix_4ok82ztopnic-7.start {
        counter-reset: lst-ctn-kix_4ok82ztopnic-7 0;
      }
      .lst-kix_c8tyj5o2bti-3 > li:before {
        content: "" counter(lst-ctn-kix_c8tyj5o2bti-3, decimal) ". ";
      }
      .lst-kix_l1vsz70ouva-8 > li {
        counter-increment: lst-ctn-kix_l1vsz70ouva-8;
      }
      .lst-kix_f08e3j4nu4u-8 > li:before {
        content: "" counter(lst-ctn-kix_f08e3j4nu4u-8, lower-roman) ". ";
      }
      ol.lst-kix_4ac75qr0o3cc-4.start {
        counter-reset: lst-ctn-kix_4ac75qr0o3cc-4 0;
      }
      ul.lst-kix_1gzzhso2s78a-8 {
        list-style-type: none;
      }
      ul.lst-kix_1gzzhso2s78a-6 {
        list-style-type: none;
      }
      ul.lst-kix_1gzzhso2s78a-7 {
        list-style-type: none;
      }
      ol.lst-kix_e9f8hgxz8m9q-2.start {
        counter-reset: lst-ctn-kix_e9f8hgxz8m9q-2 0;
      }
      ul.lst-kix_1gzzhso2s78a-4 {
        list-style-type: none;
      }
      ul.lst-kix_1gzzhso2s78a-5 {
        list-style-type: none;
      }
      ul.lst-kix_1gzzhso2s78a-3 {
        list-style-type: none;
      }
      .lst-kix_c8tyj5o2bti-7 > li:before {
        content: "" counter(lst-ctn-kix_c8tyj5o2bti-7, lower-latin) ". ";
      }
      .lst-kix_2z0rpj1q4k1z-8 > li:before {
        content: "\x025a0   ";
      }
      ul.lst-kix_1gzzhso2s78a-0 {
        list-style-type: none;
      }
      .lst-kix_c8tyj5o2bti-8 > li:before {
        content: "" counter(lst-ctn-kix_c8tyj5o2bti-8, lower-roman) ". ";
      }
      .lst-kix_2z0rpj1q4k1z-5 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_2z0rpj1q4k1z-7 > li:before {
        content: "\x025cb   ";
      }
      .lst-kix_2z0rpj1q4k1z-3 > li:before {
        content: "\x025cf   ";
      }
      ol.lst-kix_w2cw0m9hcgl9-2.start {
        counter-reset: lst-ctn-kix_w2cw0m9hcgl9-2 0;
      }
      .lst-kix_blprch6em4x6-7 > li {
        counter-increment: lst-ctn-kix_blprch6em4x6-7;
      }
      .lst-kix_2z0rpj1q4k1z-6 > li:before {
        content: "\x025cf   ";
      }
      .lst-kix_1gzzhso2s78a-0 > li:before {
        content: "  ";
      }
      .lst-kix_1gzzhso2s78a-1 > li:before {
        content: "" counter(lst-ctn-kix_1gzzhso2s78a-1, decimal) ". ";
      }
      .lst-kix_1gzzhso2s78a-3 > li:before {
        content: "\x025cf   ";
      }
      .lst-kix_2z0rpj1q4k1z-4 > li:before {
        content: "\x025cb   ";
      }
      .lst-kix_1gzzhso2s78a-2 > li:before {
        content: "" counter(lst-ctn-kix_1gzzhso2s78a-2, lower-latin) ". ";
      }
      .lst-kix_wzvrlxw34w8k-5 > li {
        counter-increment: lst-ctn-kix_wzvrlxw34w8k-5;
      }
      .lst-kix_w2cw0m9hcgl9-3 > li:before {
        content: "\x025cf   ";
      }
      ol.lst-kix_blprch6em4x6-6.start {
        counter-reset: lst-ctn-kix_blprch6em4x6-6 0;
      }
      .lst-kix_1gzzhso2s78a-5 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_1gzzhso2s78a-7 > li:before {
        content: "\x025cb   ";
      }
      .lst-kix_w2cw0m9hcgl9-1 > li:before {
        content: "" counter(lst-ctn-kix_w2cw0m9hcgl9-1, decimal) ". ";
      }
      .lst-kix_w2cw0m9hcgl9-2 > li:before {
        content: "" counter(lst-ctn-kix_w2cw0m9hcgl9-2, lower-latin) ". ";
      }
      .lst-kix_1gzzhso2s78a-8 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_1gzzhso2s78a-4 > li:before {
        content: "\x025cb   ";
      }
      .lst-kix_f08e3j4nu4u-6 > li {
        counter-increment: lst-ctn-kix_f08e3j4nu4u-6;
      }
      .lst-kix_2z0rpj1q4k1z-1 > li:before {
        content: "" counter(lst-ctn-kix_2z0rpj1q4k1z-1, decimal) ". ";
      }
      .lst-kix_2z0rpj1q4k1z-2 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_w2cw0m9hcgl9-0 > li:before {
        content: "  ";
      }
      ol.lst-kix_f08e3j4nu4u-3.start {
        counter-reset: lst-ctn-kix_f08e3j4nu4u-3 0;
      }
      .lst-kix_2z0rpj1q4k1z-0 > li:before {
        content: "  ";
      }
      .lst-kix_1gzzhso2s78a-6 > li:before {
        content: "\x025cf   ";
      }
      ol.lst-kix_tku7q72rmqwe-2.start {
        counter-reset: lst-ctn-kix_tku7q72rmqwe-2 0;
      }
      ol.lst-kix_ijms3idnkxgo-1.start {
        counter-reset: lst-ctn-kix_ijms3idnkxgo-1 0;
      }
      ol.lst-kix_4ok82ztopnic-2.start {
        counter-reset: lst-ctn-kix_4ok82ztopnic-2 0;
      }
      .lst-kix_c8tyj5o2bti-0 > li:before {
        content: "" counter(lst-ctn-kix_c8tyj5o2bti-0, decimal) ". ";
      }
      .lst-kix_f08e3j4nu4u-7 > li:before {
        content: "" counter(lst-ctn-kix_f08e3j4nu4u-7, lower-latin) ". ";
      }
      ol.lst-kix_r7iinn8l2grv-8.start {
        counter-reset: lst-ctn-kix_r7iinn8l2grv-8 0;
      }
      .lst-kix_c8tyj5o2bti-2 > li:before {
        content: "" counter(lst-ctn-kix_c8tyj5o2bti-2, lower-roman) ". ";
      }
      ol.lst-kix_l1vsz70ouva-1.start {
        counter-reset: lst-ctn-kix_l1vsz70ouva-1 0;
      }
      .lst-kix_f08e3j4nu4u-5 > li:before {
        content: "" counter(lst-ctn-kix_f08e3j4nu4u-5, lower-roman) ". ";
      }
      .lst-kix_f08e3j4nu4u-6 > li:before {
        content: "" counter(lst-ctn-kix_f08e3j4nu4u-6, decimal) ". ";
      }
      ol.lst-kix_l1vsz70ouva-8.start {
        counter-reset: lst-ctn-kix_l1vsz70ouva-8 0;
      }
      .lst-kix_c8tyj5o2bti-1 > li:before {
        content: "" counter(lst-ctn-kix_c8tyj5o2bti-1, lower-latin) ". ";
      }
      .lst-kix_f08e3j4nu4u-3 > li:before {
        content: "" counter(lst-ctn-kix_f08e3j4nu4u-3, decimal) ". ";
      }
      .lst-kix_f08e3j4nu4u-4 > li:before {
        content: "" counter(lst-ctn-kix_f08e3j4nu4u-4, lower-latin) ". ";
      }
      .lst-kix_4ac75qr0o3cc-7 > li {
        counter-increment: lst-ctn-kix_4ac75qr0o3cc-7;
      }
      ol.lst-kix_w2x0s74x94go-4.start {
        counter-reset: lst-ctn-kix_w2x0s74x94go-4 0;
      }
      ol.lst-kix_c8tyj5o2bti-4.start {
        counter-reset: lst-ctn-kix_c8tyj5o2bti-4 0;
      }
      .lst-kix_f08e3j4nu4u-0 > li:before {
        content: "" counter(lst-ctn-kix_f08e3j4nu4u-0, lower-latin) ". ";
      }
      .lst-kix_c8tyj5o2bti-4 > li {
        counter-increment: lst-ctn-kix_c8tyj5o2bti-4;
      }
      .lst-kix_f08e3j4nu4u-1 > li:before {
        content: "" counter(lst-ctn-kix_f08e3j4nu4u-1, decimal) ". ";
      }
      .lst-kix_f08e3j4nu4u-2 > li:before {
        content: "" counter(lst-ctn-kix_f08e3j4nu4u-2, lower-roman) ". ";
      }
      .lst-kix_tku7q72rmqwe-6 > li {
        counter-increment: lst-ctn-kix_tku7q72rmqwe-6;
      }
      .lst-kix_tku7q72rmqwe-2 > li {
        counter-increment: lst-ctn-kix_tku7q72rmqwe-2;
      }
      .lst-kix_4ok82ztopnic-5 > li {
        counter-increment: lst-ctn-kix_4ok82ztopnic-5;
      }
      .lst-kix_l1vsz70ouva-1 > li {
        counter-increment: lst-ctn-kix_l1vsz70ouva-1;
      }
      .lst-kix_ashu82itcirg-7 > li:before {
        content: "\x025cb   ";
      }
      ol.lst-kix_blprch6em4x6-1.start {
        counter-reset: lst-ctn-kix_blprch6em4x6-1 0;
      }
      ol.lst-kix_4ok82ztopnic-0.start {
        counter-reset: lst-ctn-kix_4ok82ztopnic-0 0;
      }
      .lst-kix_ashu82itcirg-1 > li:before {
        content: "" counter(lst-ctn-kix_ashu82itcirg-1, decimal) ". ";
      }
      .lst-kix_ashu82itcirg-3 > li:before {
        content: "\x025cf   ";
      }
      ol.lst-kix_blprch6em4x6-4.start {
        counter-reset: lst-ctn-kix_blprch6em4x6-4 0;
      }
      ol.lst-kix_w2x0s74x94go-2.start {
        counter-reset: lst-ctn-kix_w2x0s74x94go-2 0;
      }
      .lst-kix_ashu82itcirg-5 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_4ok82ztopnic-6 > li {
        counter-increment: lst-ctn-kix_4ok82ztopnic-6;
      }
      ol.lst-kix_wzvrlxw34w8k-3.start {
        counter-reset: lst-ctn-kix_wzvrlxw34w8k-3 0;
      }
      ol.lst-kix_e9f8hgxz8m9q-0.start {
        counter-reset: lst-ctn-kix_e9f8hgxz8m9q-0 0;
      }
      ol.lst-kix_tku7q72rmqwe-7.start {
        counter-reset: lst-ctn-kix_tku7q72rmqwe-7 0;
      }
      .lst-kix_f08e3j4nu4u-2 > li {
        counter-increment: lst-ctn-kix_f08e3j4nu4u-2;
      }
      .lst-kix_r7iinn8l2grv-8 > li {
        counter-increment: lst-ctn-kix_r7iinn8l2grv-8;
      }
      ol.lst-kix_tku7q72rmqwe-4.start {
        counter-reset: lst-ctn-kix_tku7q72rmqwe-4 0;
      }
      ol.lst-kix_86e2w64ccxrx-1 {
        list-style-type: none;
      }
      ol.lst-kix_86e2w64ccxrx-2 {
        list-style-type: none;
      }
      ul.lst-kix_2z0rpj1q4k1z-0 {
        list-style-type: none;
      }
      ul.lst-kix_2z0rpj1q4k1z-3 {
        list-style-type: none;
      }
      ul.lst-kix_2z0rpj1q4k1z-4 {
        list-style-type: none;
      }
      ul.lst-kix_2z0rpj1q4k1z-2 {
        list-style-type: none;
      }
      .lst-kix_w2x0s74x94go-5 > li {
        counter-increment: lst-ctn-kix_w2x0s74x94go-5;
      }
      .lst-kix_wzvrlxw34w8k-1 > li {
        counter-increment: lst-ctn-kix_wzvrlxw34w8k-1;
      }
      ul.lst-kix_2z0rpj1q4k1z-7 {
        list-style-type: none;
      }
      ul.lst-kix_2z0rpj1q4k1z-8 {
        list-style-type: none;
      }
      ul.lst-kix_2z0rpj1q4k1z-5 {
        list-style-type: none;
      }
      ul.lst-kix_2z0rpj1q4k1z-6 {
        list-style-type: none;
      }
      ol.lst-kix_c8tyj5o2bti-2.start {
        counter-reset: lst-ctn-kix_c8tyj5o2bti-2 0;
      }
      .lst-kix_w2cw0m9hcgl9-4 > li:before {
        content: "\x025cb   ";
      }
      .lst-kix_w2cw0m9hcgl9-6 > li:before {
        content: "\x025cf   ";
      }
      .lst-kix_lmy2waf0o3x-4 > li:before {
        content: "\x025cb   ";
      }
      .lst-kix_lmy2waf0o3x-8 > li:before {
        content: "\x025a0   ";
      }
      ol.lst-kix_1gzzhso2s78a-1.start {
        counter-reset: lst-ctn-kix_1gzzhso2s78a-1 0;
      }
      ol.lst-kix_86e2w64ccxrx-2.start {
        counter-reset: lst-ctn-kix_86e2w64ccxrx-2 0;
      }
      .lst-kix_w2cw0m9hcgl9-8 > li:before {
        content: "\x025a0   ";
      }
      ol.lst-kix_4ac75qr0o3cc-1.start {
        counter-reset: lst-ctn-kix_4ac75qr0o3cc-1 0;
      }
      .lst-kix_lmy2waf0o3x-6 > li:before {
        content: "\x025cf   ";
      }
      ol.lst-kix_4ok82ztopnic-5 {
        list-style-type: none;
      }
      ol.lst-kix_4ok82ztopnic-4 {
        list-style-type: none;
      }
      ol.lst-kix_4ok82ztopnic-7 {
        list-style-type: none;
      }
      ol.lst-kix_4ok82ztopnic-6 {
        list-style-type: none;
      }
      ol.lst-kix_4ok82ztopnic-8 {
        list-style-type: none;
      }
      .lst-kix_lmy2waf0o3x-0 > li:before {
        content: "  ";
      }
      .lst-kix_blprch6em4x6-8 > li:before {
        content: "" counter(lst-ctn-kix_blprch6em4x6-8, lower-roman) ". ";
      }
      .lst-kix_w1ox63mihjsr-1 > li {
        counter-increment: lst-ctn-kix_w1ox63mihjsr-1;
      }
      .lst-kix_c8tyj5o2bti-8 > li {
        counter-increment: lst-ctn-kix_c8tyj5o2bti-8;
      }
      .lst-kix_4ac75qr0o3cc-3 > li {
        counter-increment: lst-ctn-kix_4ac75qr0o3cc-3;
      }
      .lst-kix_wzvrlxw34w8k-6 > li {
        counter-increment: lst-ctn-kix_wzvrlxw34w8k-6;
      }
      .lst-kix_lmy2waf0o3x-2 > li:before {
        content: "\x025a0   ";
      }
      ol.lst-kix_f08e3j4nu4u-6.start {
        counter-reset: lst-ctn-kix_f08e3j4nu4u-6 0;
      }
      .lst-kix_blprch6em4x6-6 > li:before {
        content: "" counter(lst-ctn-kix_blprch6em4x6-6, decimal) ". ";
      }
      .lst-kix_e9f8hgxz8m9q-5 > li {
        counter-increment: lst-ctn-kix_e9f8hgxz8m9q-5;
      }
      .lst-kix_w1ox63mihjsr-7 > li:before {
        content: "\x025cb   ";
      }
      .lst-kix_blprch6em4x6-0 > li:before {
        content: "" counter(lst-ctn-kix_blprch6em4x6-0, decimal) ". ";
      }
      .lst-kix_blprch6em4x6-4 > li:before {
        content: "" counter(lst-ctn-kix_blprch6em4x6-4, lower-latin) ". ";
      }
      ol.lst-kix_4ac75qr0o3cc-2.start {
        counter-reset: lst-ctn-kix_4ac75qr0o3cc-2 0;
      }
      ol.lst-kix_4ok82ztopnic-1 {
        list-style-type: none;
      }
      ol.lst-kix_wzvrlxw34w8k-5.start {
        counter-reset: lst-ctn-kix_wzvrlxw34w8k-5 0;
      }
      ol.lst-kix_4ok82ztopnic-0 {
        list-style-type: none;
      }
      ol.lst-kix_4ok82ztopnic-3 {
        list-style-type: none;
      }
      .lst-kix_w1ox63mihjsr-1 > li:before {
        content: "" counter(lst-ctn-kix_w1ox63mihjsr-1, decimal) ". ";
      }
      ol.lst-kix_4ok82ztopnic-2 {
        list-style-type: none;
      }
      .lst-kix_blprch6em4x6-2 > li:before {
        content: "" counter(lst-ctn-kix_blprch6em4x6-2, lower-roman) ". ";
      }
      .lst-kix_f08e3j4nu4u-7 > li {
        counter-increment: lst-ctn-kix_f08e3j4nu4u-7;
      }
      .lst-kix_w1ox63mihjsr-3 > li:before {
        content: "\x025cf   ";
      }
      ol.lst-kix_w2x0s74x94go-0 {
        list-style-type: none;
      }
      ol.lst-kix_w2x0s74x94go-2 {
        list-style-type: none;
      }
      ol.lst-kix_w2x0s74x94go-1 {
        list-style-type: none;
      }
      ol.lst-kix_w2x0s74x94go-4 {
        list-style-type: none;
      }
      ol.lst-kix_w2x0s74x94go-3 {
        list-style-type: none;
      }
      ol.lst-kix_w2x0s74x94go-6 {
        list-style-type: none;
      }
      .lst-kix_blprch6em4x6-0 > li {
        counter-increment: lst-ctn-kix_blprch6em4x6-0;
      }
      ol.lst-kix_w2x0s74x94go-5 {
        list-style-type: none;
      }
      .lst-kix_w1ox63mihjsr-5 > li:before {
        content: "\x025a0   ";
      }
      ol.lst-kix_w2x0s74x94go-8 {
        list-style-type: none;
      }
      ol.lst-kix_w2x0s74x94go-7 {
        list-style-type: none;
      }
      .lst-kix_wzvrlxw34w8k-8 > li {
        counter-increment: lst-ctn-kix_wzvrlxw34w8k-8;
      }
      .lst-kix_tku7q72rmqwe-3 > li:before {
        content: "" counter(lst-ctn-kix_tku7q72rmqwe-3, decimal) ". ";
      }
      .lst-kix_l1vsz70ouva-7 > li {
        counter-increment: lst-ctn-kix_l1vsz70ouva-7;
      }
      ol.lst-kix_4ok82ztopnic-4.start {
        counter-reset: lst-ctn-kix_4ok82ztopnic-4 0;
      }
      .lst-kix_tku7q72rmqwe-4 > li:before {
        content: "" counter(lst-ctn-kix_tku7q72rmqwe-4, lower-latin) ". ";
      }
      .lst-kix_k0g1n0qbkr6g-1 > li:before {
        content: "" counter(lst-ctn-kix_k0g1n0qbkr6g-1, decimal) ". ";
      }
      .lst-kix_k0g1n0qbkr6g-0 > li:before {
        content: "  ";
      }
      .lst-kix_r7iinn8l2grv-0 > li {
        counter-increment: lst-ctn-kix_r7iinn8l2grv-0;
      }
      .lst-kix_c8tyj5o2bti-5 > li {
        counter-increment: lst-ctn-kix_c8tyj5o2bti-5;
      }
      .lst-kix_tku7q72rmqwe-7 > li:before {
        content: "" counter(lst-ctn-kix_tku7q72rmqwe-7, lower-latin) ". ";
      }
      .lst-kix_tku7q72rmqwe-8 > li:before {
        content: "" counter(lst-ctn-kix_tku7q72rmqwe-8, lower-roman) ". ";
      }
      .lst-kix_4ac75qr0o3cc-6 > li {
        counter-increment: lst-ctn-kix_4ac75qr0o3cc-6;
      }
      ol.lst-kix_wzvrlxw34w8k-7.start {
        counter-reset: lst-ctn-kix_wzvrlxw34w8k-7 0;
      }
      .lst-kix_4ac75qr0o3cc-6 > li:before {
        content: "" counter(lst-ctn-kix_4ac75qr0o3cc-6, decimal) ". ";
      }
      .lst-kix_l1vsz70ouva-6 > li:before {
        content: "" counter(lst-ctn-kix_l1vsz70ouva-6, decimal) ". ";
      }
      .lst-kix_tku7q72rmqwe-7 > li {
        counter-increment: lst-ctn-kix_tku7q72rmqwe-7;
      }
      .lst-kix_4ac75qr0o3cc-7 > li:before {
        content: "" counter(lst-ctn-kix_4ac75qr0o3cc-7, lower-latin) ". ";
      }
      .lst-kix_e9f8hgxz8m9q-1 > li:before {
        content: "" counter(lst-ctn-kix_e9f8hgxz8m9q-1, lower-latin) ". ";
      }
      .lst-kix_w2x0s74x94go-8 > li {
        counter-increment: lst-ctn-kix_w2x0s74x94go-8;
      }
      .lst-kix_e9f8hgxz8m9q-0 > li:before {
        content: "" counter(lst-ctn-kix_e9f8hgxz8m9q-0, decimal) ". ";
      }
      .lst-kix_l1vsz70ouva-3 > li:before {
        content: "" counter(lst-ctn-kix_l1vsz70ouva-3, decimal) ". ";
      }
      .lst-kix_r7iinn8l2grv-2 > li {
        counter-increment: lst-ctn-kix_r7iinn8l2grv-2;
      }
      .lst-kix_4ac75qr0o3cc-2 > li:before {
        content: "" counter(lst-ctn-kix_4ac75qr0o3cc-2, lower-roman) ". ";
      }
      ol.lst-kix_w2x0s74x94go-6.start {
        counter-reset: lst-ctn-kix_w2x0s74x94go-6 0;
      }
      .lst-kix_l1vsz70ouva-2 > li:before {
        content: "" counter(lst-ctn-kix_l1vsz70ouva-2, lower-roman) ". ";
      }
      .lst-kix_w2cw0m9hcgl9-2 > li {
        counter-increment: lst-ctn-kix_w2cw0m9hcgl9-2;
      }
      .lst-kix_tku7q72rmqwe-0 > li:before {
        content: "" counter(lst-ctn-kix_tku7q72rmqwe-0, decimal) ". ";
      }
      .lst-kix_4ac75qr0o3cc-3 > li:before {
        content: "" counter(lst-ctn-kix_4ac75qr0o3cc-3, decimal) ". ";
      }
      .lst-kix_e9f8hgxz8m9q-8 > li:before {
        content: "" counter(lst-ctn-kix_e9f8hgxz8m9q-8, lower-roman) ". ";
      }
      .lst-kix_w2x0s74x94go-6 > li {
        counter-increment: lst-ctn-kix_w2x0s74x94go-6;
      }
      ol.lst-kix_wzvrlxw34w8k-8.start {
        counter-reset: lst-ctn-kix_wzvrlxw34w8k-8 0;
      }
      .lst-kix_e9f8hgxz8m9q-4 > li:before {
        content: "" counter(lst-ctn-kix_e9f8hgxz8m9q-4, lower-latin) ". ";
      }
      .lst-kix_e9f8hgxz8m9q-5 > li:before {
        content: "" counter(lst-ctn-kix_e9f8hgxz8m9q-5, lower-roman) ". ";
      }
      .lst-kix_lmy2waf0o3x-1 > li {
        counter-increment: lst-ctn-kix_lmy2waf0o3x-1;
      }
      .lst-kix_l1vsz70ouva-7 > li:before {
        content: "" counter(lst-ctn-kix_l1vsz70ouva-7, lower-latin) ". ";
      }
      .lst-kix_wzvrlxw34w8k-4 > li:before {
        content: "" counter(lst-ctn-kix_wzvrlxw34w8k-4, lower-latin) ". ";
      }
      .lst-kix_ijms3idnkxgo-1 > li {
        counter-increment: lst-ctn-kix_ijms3idnkxgo-1;
      }
      .lst-kix_wzvrlxw34w8k-5 > li:before {
        content: "" counter(lst-ctn-kix_wzvrlxw34w8k-5, lower-roman) ". ";
      }
      ol.lst-kix_e9f8hgxz8m9q-5.start {
        counter-reset: lst-ctn-kix_e9f8hgxz8m9q-5 0;
      }
      .lst-kix_wzvrlxw34w8k-8 > li:before {
        content: "" counter(lst-ctn-kix_wzvrlxw34w8k-8, lower-roman) ". ";
      }
      .lst-kix_blprch6em4x6-8 > li {
        counter-increment: lst-ctn-kix_blprch6em4x6-8;
      }
      ol.lst-kix_4ok82ztopnic-3.start {
        counter-reset: lst-ctn-kix_4ok82ztopnic-3 0;
      }
      .lst-kix_k0g1n0qbkr6g-8 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_k0g1n0qbkr6g-5 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_k0g1n0qbkr6g-4 > li:before {
        content: "\x025cb   ";
      }
      ol.lst-kix_e9f8hgxz8m9q-4.start {
        counter-reset: lst-ctn-kix_e9f8hgxz8m9q-4 0;
      }
      .lst-kix_c8tyj5o2bti-7 > li {
        counter-increment: lst-ctn-kix_c8tyj5o2bti-7;
      }
      .lst-kix_tku7q72rmqwe-0 > li {
        counter-increment: lst-ctn-kix_tku7q72rmqwe-0;
      }
      .lst-kix_wzvrlxw34w8k-1 > li:before {
        content: "" counter(lst-ctn-kix_wzvrlxw34w8k-1, decimal) ". ";
      }
      .lst-kix_4ac75qr0o3cc-4 > li {
        counter-increment: lst-ctn-kix_4ac75qr0o3cc-4;
      }
      .lst-kix_tku7q72rmqwe-3 > li {
        counter-increment: lst-ctn-kix_tku7q72rmqwe-3;
      }
      .lst-kix_k0g1n0qbkr6g-1 > li {
        counter-increment: lst-ctn-kix_k0g1n0qbkr6g-1;
      }
      .lst-kix_l1vsz70ouva-3 > li {
        counter-increment: lst-ctn-kix_l1vsz70ouva-3;
      }
      .lst-kix_wzvrlxw34w8k-0 > li:before {
        content: "" counter(lst-ctn-kix_wzvrlxw34w8k-0, lower-latin) ". ";
      }
      .lst-kix_l1vsz70ouva-0 > li {
        counter-increment: lst-ctn-kix_l1vsz70ouva-0;
      }
      .lst-kix_ashu82itcirg-8 > li:before {
        content: "\x025a0   ";
      }
      ol.lst-kix_e9f8hgxz8m9q-6.start {
        counter-reset: lst-ctn-kix_e9f8hgxz8m9q-6 0;
      }
      .lst-kix_r7iinn8l2grv-7 > li {
        counter-increment: lst-ctn-kix_r7iinn8l2grv-7;
      }
      .lst-kix_r7iinn8l2grv-3 > li:before {
        content: "" counter(lst-ctn-kix_r7iinn8l2grv-3, decimal) ". ";
      }
      .lst-kix_w2x0s74x94go-2 > li:before {
        content: "" counter(lst-ctn-kix_w2x0s74x94go-2, lower-roman) ". ";
      }
      .lst-kix_w1ox63mihjsr-0 > li:before {
        content: "  ";
      }
      .lst-kix_r7iinn8l2grv-7 > li:before {
        content: "" counter(lst-ctn-kix_r7iinn8l2grv-7, lower-latin) ". ";
      }
      .lst-kix_wzvrlxw34w8k-3 > li {
        counter-increment: lst-ctn-kix_wzvrlxw34w8k-3;
      }
      ol.lst-kix_w2x0s74x94go-5.start {
        counter-reset: lst-ctn-kix_w2x0s74x94go-5 0;
      }
      ol.lst-kix_c8tyj5o2bti-1 {
        list-style-type: none;
      }
      .lst-kix_ashu82itcirg-4 > li:before {
        content: "\x025cb   ";
      }
      ol.lst-kix_c8tyj5o2bti-0 {
        list-style-type: none;
      }
      ol.lst-kix_c8tyj5o2bti-3 {
        list-style-type: none;
      }
      ol.lst-kix_c8tyj5o2bti-2 {
        list-style-type: none;
      }
      ol.lst-kix_lmy2waf0o3x-1.start {
        counter-reset: lst-ctn-kix_lmy2waf0o3x-1 0;
      }
      ol.lst-kix_c8tyj5o2bti-8 {
        list-style-type: none;
      }
      ol.lst-kix_c8tyj5o2bti-5 {
        list-style-type: none;
      }
      ol.lst-kix_c8tyj5o2bti-4 {
        list-style-type: none;
      }
      ol.lst-kix_c8tyj5o2bti-7 {
        list-style-type: none;
      }
      ol.lst-kix_c8tyj5o2bti-6 {
        list-style-type: none;
      }
      ol.lst-kix_4ok82ztopnic-5.start {
        counter-reset: lst-ctn-kix_4ok82ztopnic-5 0;
      }
      ol.lst-kix_4ok82ztopnic-8.start {
        counter-reset: lst-ctn-kix_4ok82ztopnic-8 0;
      }
      .lst-kix_ashu82itcirg-0 > li:before {
        content: "  ";
      }
      .lst-kix_c8tyj5o2bti-0 > li {
        counter-increment: lst-ctn-kix_c8tyj5o2bti-0;
      }
      .lst-kix_r7iinn8l2grv-5 > li {
        counter-increment: lst-ctn-kix_r7iinn8l2grv-5;
      }
      .lst-kix_lmy2waf0o3x-5 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_4ok82ztopnic-2 > li {
        counter-increment: lst-ctn-kix_4ok82ztopnic-2;
      }
      .lst-kix_w2cw0m9hcgl9-5 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_tku7q72rmqwe-5 > li {
        counter-increment: lst-ctn-kix_tku7q72rmqwe-5;
      }
      ol.lst-kix_w2x0s74x94go-7.start {
        counter-reset: lst-ctn-kix_w2x0s74x94go-7 0;
      }
      ol.lst-kix_e9f8hgxz8m9q-8.start {
        counter-reset: lst-ctn-kix_e9f8hgxz8m9q-8 0;
      }
      ol.lst-kix_r7iinn8l2grv-8 {
        list-style-type: none;
      }
      ol.lst-kix_r7iinn8l2grv-7 {
        list-style-type: none;
      }
      ol.lst-kix_r7iinn8l2grv-6 {
        list-style-type: none;
      }
      ol.lst-kix_r7iinn8l2grv-5 {
        list-style-type: none;
      }
      ol.lst-kix_r7iinn8l2grv-4 {
        list-style-type: none;
      }
      ol.lst-kix_r7iinn8l2grv-3 {
        list-style-type: none;
      }
      ol.lst-kix_r7iinn8l2grv-2 {
        list-style-type: none;
      }
      ol.lst-kix_lmy2waf0o3x-1 {
        list-style-type: none;
      }
      .lst-kix_l1vsz70ouva-5 > li {
        counter-increment: lst-ctn-kix_l1vsz70ouva-5;
      }
      ol.lst-kix_r7iinn8l2grv-1 {
        list-style-type: none;
      }
      ol.lst-kix_r7iinn8l2grv-0 {
        list-style-type: none;
      }
      .lst-kix_w2x0s74x94go-1 > li {
        counter-increment: lst-ctn-kix_w2x0s74x94go-1;
      }
      .lst-kix_4ok82ztopnic-8 > li:before {
        content: "" counter(lst-ctn-kix_4ok82ztopnic-8, lower-roman) ". ";
      }
      .lst-kix_lmy2waf0o3x-1 > li:before {
        content: "" counter(lst-ctn-kix_lmy2waf0o3x-1, decimal) ". ";
      }
      .lst-kix_blprch6em4x6-5 > li:before {
        content: "" counter(lst-ctn-kix_blprch6em4x6-5, lower-roman) ". ";
      }
      .lst-kix_4ok82ztopnic-4 > li:before {
        content: "" counter(lst-ctn-kix_4ok82ztopnic-4, lower-latin) ". ";
      }
      ol.lst-kix_e9f8hgxz8m9q-7.start {
        counter-reset: lst-ctn-kix_e9f8hgxz8m9q-7 0;
      }
      .lst-kix_w1ox63mihjsr-8 > li:before {
        content: "\x025a0   ";
      }
      .lst-kix_blprch6em4x6-1 > li:before {
        content: "" counter(lst-ctn-kix_blprch6em4x6-1, lower-latin) ". ";
      }
      .lst-kix_1gzzhso2s78a-1 > li {
        counter-increment: lst-ctn-kix_1gzzhso2s78a-1;
      }
      .lst-kix_86e2w64ccxrx-2 > li {
        counter-increment: lst-ctn-kix_86e2w64ccxrx-2;
      }
      ol.lst-kix_w2x0s74x94go-8.start {
        counter-reset: lst-ctn-kix_w2x0s74x94go-8 0;
      }
      .lst-kix_e9f8hgxz8m9q-2 > li {
        counter-increment: lst-ctn-kix_e9f8hgxz8m9q-2;
      }
      li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 15pt;
      }
      ol.lst-kix_2z0rpj1q4k1z-1 {
        list-style-type: none;
      }
      ol.lst-kix_4ok82ztopnic-6.start {
        counter-reset: lst-ctn-kix_4ok82ztopnic-6 0;
      }
      .lst-kix_f08e3j4nu4u-4 > li {
        counter-increment: lst-ctn-kix_f08e3j4nu4u-4;
      }
      .lst-kix_4ok82ztopnic-0 > li:before {
        content: "" counter(lst-ctn-kix_4ok82ztopnic-0, decimal) ". ";
      }
      .lst-kix_w1ox63mihjsr-4 > li:before {
        content: "\x025cb   ";
      }
      .lst-kix_blprch6em4x6-3 > li {
        counter-increment: lst-ctn-kix_blprch6em4x6-3;
      }
      .lst-kix_w2x0s74x94go-6 > li:before {
        content: "" counter(lst-ctn-kix_w2x0s74x94go-6, decimal) ". ";
      }
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c17 {
        border-right-style: solid;
        padding: 5pt 5pt 5pt 5pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 148.5pt;
        border-top-color: #000000;
        border-bottom-style: solid;
      }
      .c6 {
        border-right-style: solid;
        padding: 5pt 5pt 5pt 5pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 249.8pt;
        border-top-color: #000000;
        border-bottom-style: solid;
      }
      .c7 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c4 {
        margin-left: 72pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c8 {
        color: #000000;
        font-weight: 600;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 14pt;
        font-family: "Inter";
        font-style: normal;
      }
      .c2 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 10pt;
      }
      .c15 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: center;
      }
      .c9 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Inter";
        font-style: normal;
      }
      .c5 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c0 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Inter";
        font-style: normal;
      }
      .c11 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c18 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Inter";
        font-style: normal;
      }
      .c13 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 14pt;
        font-family: "Inter";
        font-style: normal;
      }
      .c16 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Inter";
        font-style: normal;
      }
      .c10 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c20 {
        margin-left: auto;
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto;
      }
      .c19 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt;
      }
      .c1 {
        padding: 0;
        margin: 0;
      }
      .c3 {
        font-weight: 700;
      }
      .c14 {
        height: 0pt;
      }
      .c12 {
        margin-left: 36pt;
      }
      .title {
        padding-top: 0pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 0pt;
        font-family: "Inter";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: center;
      }
      .subtitle {
        padding-top: 0pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 0pt;
        font-family: "Inter";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: center;
      }
      li {
        color: #000000;
        font-size: 10pt;
        font-family: "Inter";
      }
      p {
        margin: 0;
        color: #000000;
        font-size: 10pt;
        font-family: "Inter";
      }
      h1 {
        padding-top: 0pt;
        color: #000000;
        font-weight: 600;
        font-size: 18pt;
        padding-bottom: 0pt;
        font-family: "Raleway";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 0pt;
        color: #000000;
        font-weight: 600;
        font-size: 14pt;
        padding-bottom: 0pt;
        font-family: "Inter";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 0pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 0pt;
        font-family: "Inter";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 0pt;
        color: #000000;
        font-weight: 600;
        font-size: 13pt;
        padding-bottom: 0pt;
        font-family: "Inter";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 0pt;
        color: #000000;
        font-size: 12pt;
        padding-bottom: 0pt;
        font-family: "Inter";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 0pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 0pt;
        font-family: "Inter";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    </style>
  </head>
  <body class="c19 doc-content">
    <p class="c15 title" id="h.d7mgzuj1jvvu">
      <span class="c13 c3">Identity Fraud Reimbursement</span>
    </p>
    <p class="c15 title" id="h.fsdu2z4kw4su">
      <span class="c3 c13">TERMS AND CONDITIONS</span>
    </p>
    <p class="c15 subtitle" id="h.gevl0wfeffop">
      <span class="c9">Summary of Benefits</span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span
        >These Terms and Conditions are provided to inform you that as a member
        (&ldquo;Member&rdquo;) of a Participating Identity Protection Membership
        Program, you may be entitled to benefits under the Identity Fraud Policy
        issued to Generali Global Assistance, Inc., dba Iris&reg; Powered by
        Generali (hereinafter &ldquo;Iris&rdquo;) and described in these Terms
        and Conditions. Various provisions in these Terms and Conditions and in
        the Policy restrict coverage. Read the entire Terms and Conditions
        carefully to determine rights, duties and what is and is not covered.<br /><br /></span
      ><span class="c3"
        >These Terms and Conditions do not state all the terms, conditions,
        exclusions, and definitions within the Policy. Your benefits will be
        subject to all the terms, conditions, exclusions, and definitions of the
        Policy.</span
      ><span class="c0"
        >&nbsp;A complete copy of the Policy will be available upon request. If
        the Policy is terminated, your benefits under the Policy will cease
        effective that date. It is the obligation of Iris to inform you of any
        termination of the Policy.</span
      >
    </p>
    <p class="c11">
      <span
        ><br />The Identity Fraud Reimbursement benefit is underwritten and
        administered by American Bankers Insurance Company of Florida, an
        Assurant company, under a group policy issued to Iris for the benefit of
        Members. To obtain a complete copy of the Policy contact Iris at (833)
        602-5717. American Bankers Insurance Company of Florida administers all
        claims and Iris shall have no responsibility to Members with respect to
        the Identity Fraud Expense Reimbursement benefit.<br /><br />All
        references to </span
      ><span class="c3">We</span><span>, </span><span class="c3">Us</span
      ><span>, and </span><span class="c3">Our</span
      ><span
        >&nbsp;throughout this Summary of Benefits means American Bankers
        Insurance Company of Florida. All references to </span
      ><span class="c3">policyholder</span
      ><span class="c0">&nbsp;means Iris.<br /></span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <h2 class="c5" id="h.7c4dj2st7qds">
      <span class="c8">LIMITS OF INSURANCE</span>
    </h2>
    <p class="c2"><span class="c0"></span></p>
    <p class="c2"><span class="c0"></span></p>
    <a id="t.c8dbb95031f5b1757065d530e7e89ae9551e25f9"></a><a id="t.0"></a>
    <table class="c20">
      <tr class="c14">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c10"><span class="c3 c18">Coverage</span></p>
        </td>
        <td class="c6" colspan="1" rowspan="1">
          <p class="c10"><span class="c18 c3">Limit of Liability</span></p>
        </td>
      </tr>
      <tr class="c14">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c10"><span class="c0">Expense Reimbursement</span></p>
        </td>
        <td class="c6" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c0"
              >$1,000,000 Aggregate Limit per Individual/Couple Plan/Family Plan
              Membership per 12 Month Period</span
            >
          </p>
        </td>
      </tr>
      <tr class="c14">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c10"><span class="c0">Cash Recovery Aggregate</span></p>
        </td>
        <td class="c6" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c0"
              >$1,000,000 (Part of and not in addition to Expense Reimbursement
              Aggregate Limit of Liability)</span
            >
          </p>
        </td>
      </tr>
      <tr class="c14">
        <td class="c17" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c0"
              >Investment &amp; Health Savings Accounts Cash Recovery</span
            >
          </p>
        </td>
        <td class="c6" colspan="1" rowspan="1">
          <p class="c10">
            <span class="c0"
              >$1,000,000 (Part of and not in addition to Expense Reimbursement
              Aggregate Limit of Liability)</span
            >
          </p>
        </td>
      </tr>
    </table>
    <p class="c2"><span class="c0"></span></p>
    <p class="c2"><span class="c0"></span></p>
    <h2 class="c5" id="h.kvyahqgcwkan">
      <span class="c8">DUTIES WHEN LOSS OCCURS</span>
    </h2>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span>Prior to or upon knowledge or discovery of </span
      ><span class="c3">loss</span><span>&nbsp;or of an </span
      ><span class="c3">occurrence</span
      ><span
        >&nbsp;which may give rise to a claim under the terms of this coverage,
        the </span
      ><span class="c3">policyholder</span
      ><span>&nbsp;is responsible for notifying the </span
      ><span class="c3">Member</span
      ><span class="c0">&nbsp;of the following requirements:</span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_wzvrlxw34w8k-0 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c0">Give notice as soon as practicable to:</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_wzvrlxw34w8k-1 start" start="1">
      <li class="c4 li-bullet-0">
        <span class="c0"
          >the appropriate authority and affected institutions, if applicable;
          and</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_wzvrlxw34w8k-1" start="2">
      <li class="c4 li-bullet-0">
        <span
          >call (833) 602-5717 to make a valid claim within sixty (60) days of
          the </span
        ><span class="c3">date of discovery</span
        ><span class="c0">, or as soon as reasonably possible.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11 c12">
      <span>If the loss involves a violation of law, the </span
      ><span class="c3">member</span
      ><span>&nbsp;shall also notify the police. The </span
      ><span class="c3">member</span
      ><span class="c0"
        >&nbsp;must submit a copy of the police report when filing a claim;
      </span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11 c12">
      <span
        >For both Checking &amp; Savings Account Cash Recovery and Investment
        and Health Savings Accounts Cash Recovery claims, the </span
      ><span class="c3">member</span
      ><span class="c0"
        >&nbsp;must provide documentation from the financial institution that
        the funds were fraudulently removed and are non-recoverable from the
        financial institution.</span
      >
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_wzvrlxw34w8k-0" start="2">
      <li class="c7 li-bullet-0">
        <span>File detailed </span><span class="c3">proof of loss</span
        ><span
          >, duly sworn to, with us within sixty (60) days after the discovery
          of </span
        ><span class="c3">loss</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_wzvrlxw34w8k-0" start="3">
      <li class="c7 li-bullet-0">
        <span>Take all reasonable steps to mitigate </span
        ><span class="c3">loss</span><span>&nbsp;resulting from </span
        ><span class="c3">identity fraud</span
        ><span class="c0"
          >&nbsp;including, but not limited to, requesting a waiver for any
          applicable fees, loan application fees or credit bureau fees;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_wzvrlxw34w8k-0" start="4">
      <li class="c7 li-bullet-0">
        <span>Upon </span><span class="c3">our</span
        ><span>&nbsp;request, submit to examination by </span
        ><span class="c3">us</span
        ><span class="c0"
          >, and subscribe the same, under oath if required;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_wzvrlxw34w8k-0" start="5">
      <li class="c7 li-bullet-0">
        <span>Upon </span><span class="c3">our</span
        ><span>&nbsp;request, cooperate to help </span><span class="c3">us</span
        ><span class="c0"
          >&nbsp;enforce legal rights against anyone who may be liable to the
          you to include giving evidence and attending depositions, hearing and
          trials;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_wzvrlxw34w8k-0" start="6">
      <li class="c7 li-bullet-0">
        <span
          >Immediately forward to us any notices, summons or legal papers
          received by the you in connection with the </span
        ><span class="c3">loss</span><span>&nbsp;or the </span
        ><span class="c3">identity fraud</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_wzvrlxw34w8k-0" start="7">
      <li class="c7 li-bullet-0">
        <span>Produce for </span><span class="c3">our</span
        ><span class="c0">&nbsp;examination all pertinent records;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_wzvrlxw34w8k-0" start="8">
      <li class="c7 li-bullet-0">
        <span>Cooperate with </span><span class="c3">us</span
        ><span>&nbsp;in all matters pertaining to </span
        ><span class="c3">loss</span
        ><span
          >&nbsp;or claims; all at such reasonable times and places as </span
        ><span class="c3">we</span
        ><span class="c0">&nbsp;shall designate.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span>The </span><span class="c3">member</span
      ><span
        >&nbsp;shall not voluntarily assume or admit any liability, nor, except
        at said </span
      ><span class="c3">member&rsquo;s</span
      ><span
        >&nbsp;own cost, voluntarily make any payment or incur any expense
        without </span
      ><span class="c3">our</span
      ><span
        >&nbsp;prior written consent, such consent not to be unreasonably
        withheld.<br /><br />The </span
      ><span class="c3">member</span
      ><span
        >&nbsp;shall keep books, receipts, bills and other records in such a
        manner that we can accurately determine the amount of any </span
      ><span class="c3">loss</span
      ><span>. At any time, subsequent to the reporting of the </span
      ><span class="c3">loss</span><span>&nbsp;to </span
      ><span class="c3">us</span><span>, </span><span class="c3">we</span
      ><span>&nbsp;may examine and audit the </span
      ><span class="c3">member&rsquo;s</span
      ><span>&nbsp;books and records as they relate to a </span
      ><span class="c3">loss</span
      ><span class="c0">&nbsp;under the Policy.</span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c2"><span class="c0"></span></p>
    <h2 class="c5" id="h.boujq5i1d77p"><span class="c8">COVERAGE</span></h2>
    <p class="c2"><span class="c0"></span></p>
    <h3 class="c5" id="h.2coziotau5np">
      <span class="c16 c3">Identity Fraud - Expense Reimbursement</span>
    </h3>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span class="c3">We</span><span>&nbsp;will reimburse the </span
      ><span class="c3">member</span><span>&nbsp;for </span
      ><span class="c3">expenses</span><span>&nbsp;and </span
      ><span class="c3">legal costs</span><span>&nbsp;incurred by the </span
      ><span class="c3">member</span
      ><span class="c0"
        >, up to the Aggregate Limit of Liability as shown on the Limits of
        Insurance section of these Terms and Conditions from the direct result
        of the following:</span
      >
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_r7iinn8l2grv-0 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c3">Fraud or Embezzlement<br /></span><span>For </span
        ><span class="c3">loss</span><span>&nbsp;arising out of </span
        ><span class="c3">fraud or embezzlement</span
        ><span>&nbsp;perpetrated against the </span
        ><span class="c3">member</span><span>, with an </span
        ><span class="c3">occurrence date</span
        ><span class="c0">&nbsp;during the term of the membership.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_r7iinn8l2grv-0" start="2">
      <li class="c7 li-bullet-0">
        <span class="c3">Theft<br /></span><span>For </span
        ><span class="c3">loss</span
        ><span
          >&nbsp;resulting directly from theft of property related to the </span
        ><span class="c3">member&rsquo;s</span
        ><span
          >&nbsp;information, checkbook, savings record, ATM access or
          securities from the </span
        ><span class="c3">member</span><span>, with an </span
        ><span class="c3">occurrence date</span
        ><span
          >&nbsp;during the term of the membership, by a person from whom the </span
        ><span class="c3">member</span
        ><span class="c0">&nbsp;purchased goods or services.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_r7iinn8l2grv-0" start="3">
      <li class="c7 li-bullet-0">
        <span class="c3">Forgery<br /></span><span>For </span
        ><span class="c3">loss</span><span>, with an </span
        ><span class="c3">occurrence date</span
        ><span
          >&nbsp;during the term of the membership, resulting directly from </span
        ><span class="c3">forgery</span
        ><span class="c0"
          >&nbsp;or alteration of checks, drafts, promissory notes, or similar
          written promises, orders or directions to pay money that are:</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_r7iinn8l2grv-1 start" start="1">
      <li class="c4 li-bullet-0">
        <span>Made or drawn by or drawn upon the </span
        ><span class="c3">member&rsquo;s</span
        ><span class="c0">&nbsp;account; or</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_r7iinn8l2grv-1" start="2">
      <li class="c4 li-bullet-0">
        <span>Made or drawn by one purporting to act as the </span
        ><span class="c3">member&rsquo;s</span
        ><span class="c0">&nbsp;agent.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_r7iinn8l2grv-0" start="4">
      <li class="c7 li-bullet-0">
        <span class="c3">Data Breach<br /></span><span>For </span
        ><span class="c3">loss</span><span>, with an </span
        ><span class="c3">occurrence date</span
        ><span
          >&nbsp;during the term of the membership, resulting directly from the
          misuse of the </span
        ><span class="c3">member&rsquo;s</span
        ><span
          >&nbsp;information as a result of a data compromise of information
          from a financial institution, a credit reporting agency, a credit
          grantor, a securities firm, employer or other institution/company
          maintaining the </span
        ><span class="c3">member&rsquo;s</span
        ><span
          >&nbsp;personal information, that results in monies stolen from the </span
        ><span class="c3">member&rsquo;s</span
        ><span
          >&nbsp;accounts or misuse of data to obtain property, credit or monies
          using the </span
        ><span class="c3">member&rsquo;s</span
        ><span class="c0">&nbsp;information.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_r7iinn8l2grv-0" start="5">
      <li class="c7 li-bullet-0">
        <span class="c3">Stolen Identity Event<br /></span><span>For </span
        ><span class="c3">loss</span><span>&nbsp;resulting from a </span
        ><span class="c3">stolen identity event</span
        ><span>, including but not limited to a </span
        ><span class="c3">stolen identity event</span
        ><span
          >&nbsp;occurring on or arising out of the use of the Internet. The </span
        ><span class="c3">occurrence date</span
        ><span class="c0"
          >&nbsp;must be during the term of the membership.</span
        >
      </li>
    </ol>
    <ul class="c1 lst-kix_86e2w64ccxrx-0 start">
      <li class="c7 li-bullet-0"><span class="c0">&nbsp;</span></li>
    </ul>
    <h3 class="c5" id="h.shu2malj8emc">
      <span class="c3 c16">Cash Recovery Aggregate</span>
    </h3>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span>We will reimburse the </span><span class="c3">member</span
      ><span
        >, up to the Aggregate Limit of Liability a Cash Recovery Aggregate
        benefit limited to payment for one (1) occurrence within any twelve (12)
        consecutive months of the term of the membership, for recovery of
        traditional credit cards, pre-paid credit cards and debit card
        deductibles or in the event of an </span
      ><span class="c3">unauthorized electronic fund transfer</span
      ><span
        >, reimbursement for unrecoverable funds, exclusive of interest,
        directly drawn from or removed through an </span
      ><span class="c3">unauthorized electronic fund transfer</span
      ><span>&nbsp;from the </span><span class="c3">member&rsquo;s</span
      ><span>&nbsp;mobile device (smart phone) or other financial </span
      ><span class="c3">account</span><span>&nbsp;due to an act of </span
      ><span class="c3">fraud</span><span>, </span
      ><span class="c3">embezzlement</span><span>, </span
      ><span class="c3">theft</span><span>, </span
      ><span class="c3">forgery</span><span>&nbsp;or other </span
      ><span class="c3">data breach</span
      ><span
        >&nbsp;which have not been recovered.<br /><br />Cash Recovery Aggregate
        does not cover unrecoverable funds for which the </span
      ><span class="c3">member</span
      ><span
        >&nbsp;did not seek reimbursement from the financial institution, or
        other provider which issued the </span
      ><span class="c3">access device</span><span>&nbsp;and holds the </span
      ><span class="c3">account</span
      ><span>&nbsp;from which funds were stolen, and for which the </span
      ><span class="c3">member</span
      ><span
        >&nbsp;has not received reimbursement from any other source.
        <br /><br />The </span
      ><span class="c3">member</span
      ><span
        >&nbsp;will only be covered from an unauthorized removal of funds or an </span
      ><span class="c3">unauthorized electronic fund transfer</span
      ><span
        >&nbsp;if the loss first occurs on or after the effective date of the
        Policy under which this coverage is provided, while the </span
      ><span class="c3">member</span><span>&nbsp;is a paid </span
      ><span class="c3">member</span><span>&nbsp;by the </span
      ><span class="c3">policyholder</span><span>, and the </span
      ><span class="c3">loss</span><span>&nbsp;is reported to </span
      ><span class="c3">us</span
      ><span>&nbsp;within ninety (90) days of discovery.<br /><br />The </span
      ><span class="c3">member</span
      ><span>&nbsp;is not covered if the unauthorized removal of funds or </span
      ><span class="c3">unauthorized electronic fund transfer</span
      ><span
        >&nbsp;first occurs after the termination of the Policy under which this
        coverage is provided, or the </span
      ><span class="c3">member</span
      ><span>&nbsp;is not an active and paid </span
      ><span class="c3">member</span><span>&nbsp;of the </span
      ><span class="c3">policyholder</span
      ><span>. <br /><br />For Individual, </span
      ><span class="c3">Couple Plans</span><span>&nbsp;and </span
      ><span class="c3">Family Plans</span
      ><span
        >, the Cash Recovery Aggregate benefit is limited to payment for one (1) </span
      ><span class="c3">occurrence</span
      ><span class="c0"
        >&nbsp;within twelve (12) consecutive months, not to exceed the Limits
        of Liability as shown on the Limits of Insurance section of these Terms
        and Conditions.<br /><br />The Cash Recovery Aggregate benefit is part
        of, and not in addition to the Expense Reimbursement Aggregate Limit of
        Liability.</span
      >
    </p>
    <p class="c2"><span class="c0"></span></p>
    <h3 class="c5" id="h.1wh3ylttbipg">
      <span class="c16 c3"
        >Investment &amp; Health Savings Accounts Cash Recovery</span
      >
    </h3>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span class="c3">We</span><span>&nbsp;will reimburse the </span
      ><span class="c3">member</span
      ><span
        >, up to the Aggregate Limit of Liability as shown on the Limits of
        Insurance section of these Terms and Conditions, an Investment &amp;
        Health Savings Accounts Cash Recovery benefit limited to payment for one
        (1) occurrence within any twelve (12) consecutive months of the term of
        the membership, for unrecoverable funds, exclusive of interest, removed
        through an </span
      ><span class="c3">unauthorized electronic fund transfer</span
      ><span>&nbsp;from the </span
      ><span class="c3"
        >member&rsquo;s investment or health savings account</span
      ><span>, due to an act of </span><span class="c3">fraud</span
      ><span>, </span><span class="c3">embezzlement</span><span>, </span
      ><span class="c3">theft</span><span>, </span
      ><span class="c3">forgery</span><span>&nbsp;or other </span
      ><span class="c3">data breach</span
      ><span
        >, which have not been recovered. <br /><br />Investment &amp; Health
        Savings Accounts Cash Recovery does not cover unrecoverable funds for
        which the </span
      ><span class="c3">member</span
      ><span
        >&nbsp;did not seek reimbursement from the financial institution, or
        other provider which issued the access device and holds the </span
      ><span class="c3">investment or health savings account</span
      ><span>&nbsp;from which funds were stolen, and for which the </span
      ><span class="c3">member</span
      ><span
        >&nbsp;has not received reimbursement from any other source.<br /><br />The </span
      ><span class="c3">member</span
      ><span>&nbsp;will only be covered from an </span
      ><span class="c3">unauthorized electronic fund transfer</span
      ><span
        >&nbsp;if the loss first occurs on or after the effective date of the
        Policy under which this coverage is provided, while the </span
      ><span class="c3">member</span><span>&nbsp;is a </span
      ><span class="c3">paid member</span><span>&nbsp;by the </span
      ><span class="c3">policyholder</span><span>, and the </span
      ><span class="c3">loss</span><span>&nbsp;is reported to </span
      ><span class="c3">us</span
      ><span>&nbsp;within ninety (90) days of discovery.<br /><br />The </span
      ><span class="c3">member</span><span>&nbsp;is not covered if the </span
      ><span class="c3">unauthorized electronic fund transfer</span
      ><span
        >&nbsp;first occurs after the termination of the Policy under which this
        coverage is provided, or the </span
      ><span class="c3">member</span
      ><span>&nbsp;is not an active and paid </span
      ><span class="c3">member</span><span>&nbsp;of the </span
      ><span class="c3">policyholder</span
      ><span>. <br /><br />For Individual, </span
      ><span class="c3">Couple Plans</span><span>&nbsp;and </span
      ><span class="c3">Family Plans</span
      ><span
        >, the Investment &amp; Health Savings Accounts Cash Recovery benefit is
        limited to payment for one (1) </span
      ><span class="c3">occurrence</span
      ><span class="c0"
        >&nbsp;within twelve (12) consecutive months of the term of the Policy,
        not to exceed the limit of liability.<br /><br />The Investment &amp;
        Health Savings Accounts Cash Recovery benefit is part of, and not in
        addition to the Expense Reimbursement Aggregate Limit of Liability.<br
      /></span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <h2 class="c5" id="h.8rl9ap4hdojb"><span class="c8">EXCLUSIONS</span></h2>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11"><span class="c0">The following is not covered:</span></p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c3">loss</span
        ><span
          >&nbsp;due to any fraudulent, dishonest or criminal act by the </span
        ><span class="c3">member</span
        ><span>&nbsp;or any person acting in concert with the </span
        ><span class="c3">member</span><span>, or immediate </span
        ><span class="c3">family member</span
        ><span class="c0"
          >, whether acting alone or in collusion with others;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="2">
      <li class="c7 li-bullet-0">
        <span class="c3">loss</span
        ><span class="c0"
          >&nbsp;resulting directly or indirectly from any errors or omissions
          occurring in the following actions:</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-1 start" start="1">
      <li class="c4 li-bullet-0">
        <span class="c0">the input of data to any computer system; or</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-1" start="2">
      <li class="c4 li-bullet-0">
        <span class="c0"
          >the processing of data by any computer system; or</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-1" start="3">
      <li class="c4 li-bullet-0">
        <span class="c0"
          >the manual or electronic processing of any output produced by any
          computer system;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="3">
      <li class="c7 li-bullet-0">
        <span class="c3">loss</span
        ><span
          >&nbsp;resulting directly or indirectly from the voluntary
          surrendering by the </span
        ><span class="c3">member</span><span>&nbsp;or </span
        ><span class="c3">family member</span><span>&nbsp;of any </span
        ><span class="c3">access device</span
        ><span class="c0">, in whole or in part, to any person or entity;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="4">
      <li class="c7 li-bullet-0">
        <span class="c3">loss</span
        ><span
          >&nbsp;resulting from any unintentional clerical error in the transfer
          from or debit of any account of the </span
        ><span class="c3">member</span
        ><span class="c0"
          >&nbsp;which is initiated by a financial institution, or any
          employee(s) thereof. However, this exclusion shall not apply to a
          fraudulent act of an employee(s) of a financial institution where said
          employee(s) is acting without the permission or instruction of their
          employer;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="5">
      <li class="c7 li-bullet-0">
        <span class="c3">loss</span
        ><span
          >&nbsp;in connection with any pre-authorized transfer from any account
          to or for the benefit of a financial institution, or to any other
          account of the </span
        ><span class="c3">member</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="6">
      <li class="c7 li-bullet-0">
        <span>indirect or consequential </span><span class="c3">loss</span
        ><span class="c0">&nbsp;of any nature;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="7">
      <li class="c7 li-bullet-0">
        <span class="c3">loss</span
        ><span>&nbsp;of potential income not realized by the </span
        ><span class="c3">member</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="8">
      <li class="c7 li-bullet-0">
        <span class="c3">loss</span><span>&nbsp;resulting from an </span
        ><span class="c3">identity fraud</span
        ><span class="c0"
          >&nbsp;that was discovered prior to the effective date of the Policy
          under which this coverage is provided;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="9">
      <li class="c7 li-bullet-0">
        <span class="c3">loss</span
        ><span>&nbsp;arising out of business pursuits of the </span
        ><span class="c3">member</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="10">
      <li class="c7 li-bullet-0">
        <span class="c3">loss</span
        ><span class="c0"
          >&nbsp;of valuable papers, valuable documents, jewelry, silverware and
          other personal property including the philatelic value of stamps and
          the numismatic value of coins not in circulation;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="11">
      <li class="c7 li-bullet-0">
        <span class="c0"
          >property damage, bodily injury or personal injury;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="12">
      <li class="c7 li-bullet-0">
        <span class="c3">losses</span
        ><span class="c0"
          >&nbsp;incurred from financial performance of any investment of
          financial product;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="13">
      <li class="c7 li-bullet-0">
        <span class="c3">loss</span
        ><span class="c0">&nbsp;from games of chance;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="14">
      <li class="c7 li-bullet-0">
        <span>for Cash Recovery Aggregate </span><span class="c3">losses</span
        ><span
          >&nbsp;other than traditional credit cards, pre-paid credit cards and
          debit card deductibles or unrecoverable funds, exclusive of interest,
          directly drawn from or removed through an </span
        ><span class="c3">unauthorized electronic fund transfer</span
        ><span>&nbsp;from the </span><span class="c3">member&rsquo;s</span
        ><span>&nbsp;mobile device (smart phone), or other financial </span
        ><span class="c3">account</span
        ><span
          >, and for Investment &amp; Health Savings Accounts Cash Recovery </span
        ><span class="c3">losses</span
        ><span
          >&nbsp;other unrecoverable funds, exclusive of interest, removed
          through an </span
        ><span class="c3">unauthorized electronic fund transfer</span
        ><span>&nbsp;from the </span
        ><span class="c3"
          >member&rsquo;s investment or health savings account</span
        ><span class="c0">. All other monetary devices are excluded;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="15">
      <li class="c7 li-bullet-0">
        <span>any </span><span class="c3">loss</span
        ><span class="c0"
          >, claims or damages that are not covered under the terms and
          provisions of the Policy under which this coverage is provided;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="16">
      <li class="c7 li-bullet-0">
        <span class="c0">legal fees in excess of $125 per hour;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="17">
      <li class="c7 li-bullet-0">
        <span>any </span><span class="c3">loss</span
        ><span>&nbsp;which occurred while the </span
        ><span class="c3">member</span><span>&nbsp;was not a paid </span
        ><span class="c3">member</span><span>&nbsp;of the </span
        ><span class="c3">policyholder</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="18">
      <li class="c7 li-bullet-0">
        <span>For Investment &amp; Health Savings Accounts Cash Recovery </span
        ><span class="c3">unauthorized electronic fund transfers</span
        ><span>&nbsp;from </span
        ><span class="c3">investment &amp; heath savings accounts</span
        ><span>&nbsp;for which the </span><span class="c3">member</span
        ><span
          >&nbsp;activates online access, and a two-factor authentication,
          knowledge-based authentication (KBA) or other authentication process
          is provided by the financial institution or the </span
        ><span class="c3">HSA Custodian/Administrator</span
        ><span>&nbsp;and it has not been activated by the </span
        ><span class="c3">member</span
        ><span
          >, or authentication is not required by the financial institution or
          the </span
        ><span class="c3">HSA Custodian/Administrator</span
        ><span class="c0">.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="19">
      <li class="c7 li-bullet-0">
        <span>For Investment &amp; Health Savings Accounts Cash Recovery </span
        ><span class="c3">unauthorized electronic fund transfers</span
        ><span
          >&nbsp;resulting from the financial institution&rsquo;s or the </span
        ><span class="c3">HSA Custodian/ Administrator&rsquo;s</span
        ><span>, and/or the </span><span class="c3">member&rsquo;s</span
        ><span
          >&nbsp;failure to comply with standard or customary transfer
          instruction verification and approval processes and procedures,
          including verification and approval by the financial institution, or
          the </span
        ><span class="c3">HSA Custodian/Administrator</span
        ><span>, and/or the </span><span class="c3">member</span
        ><span class="c0"
          >&nbsp;on subsequent changes to transfer instructions.</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_f08e3j4nu4u-0" start="20">
      <li class="c7 li-bullet-0">
        <span class="c0"
          >For Cash Recovery Aggregate and Investment &amp; Health Savings
          Accounts Cash Recovery loss resulting from unauthorized electronic
          transfers of digital currency or cryptocurrency such as Bitcoin,
          Ethereum, or Litecoin, or any currency not regulated or supervised by
          a government agency.</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <h2 class="c5" id="h.qzq3ghee6a8"><span class="c8">DEFINITIONS</span></h2>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span class="c3">Access Device</span
      ><span
        >&nbsp;means a card (including credit, debit and ATM cards), code, PIN,
        password, personal check or other similar means of access to the </span
      ><span class="c3">member&rsquo;s</span
      ><span
        >&nbsp;account at a financial institution that may be used by the </span
      ><span class="c3">member</span
      ><span
        >&nbsp;to gain access to said account for the purpose of withdrawing or
        transferring funds, making purchases, or making long distance or
        cellular/digital (wireless) telephone calls.<br /><br /></span
      ><span class="c3">Account</span
      ><span
        >&nbsp;means a cash, credit card, demand deposit (checking), savings, or
        money market </span
      ><span class="c3">account</span><span>&nbsp;of the </span
      ><span class="c3">members</span
      ><span
        >&nbsp;held directly or indirectly by a financial institution and
        established primary for personal, family or household purposes.<br /><br /></span
      ><span class="c3">Data Breach</span
      ><span>&nbsp;means the misuse of the </span
      ><span class="c3">member&rsquo;s</span
      ><span
        >&nbsp;information as a result of a data compromise of information from
        a financial institution, a credit reporting agency, a credit grantor, a
        securities firm, employer or other institution/company maintaining the </span
      ><span class="c3">member&rsquo;s</span
      ><span
        >&nbsp;personal information, that results in monies stolen from the </span
      ><span class="c3">member&rsquo;s</span
      ><span
        >&nbsp;accounts or misuse of data to obtain credit or monies using the </span
      ><span class="c3">member&rsquo;s</span
      ><span>&nbsp;information.<br /><br /></span
      ><span class="c3">Date of Discovery</span
      ><span>&nbsp;occurs when the </span><span class="c3">member</span
      ><span
        >&nbsp;first becomes aware of facts which would cause a reasonable
        person to assume that a </span
      ><span class="c3">loss</span
      ><span
        >&nbsp;covered by this insurance has been or will be incurred, even
        though the exact amount or details of </span
      ><span class="c3">loss</span
      ><span>&nbsp;may not then be known. Discovery also occurs when the </span
      ><span class="c3">member</span
      ><span
        >&nbsp;receives notice of an actual or potential claim against the </span
      ><span class="c3">member</span><span>&nbsp;involving </span
      ><span class="c3">loss</span
      ><span>&nbsp;covered under this insurance.<br /><br /></span
      ><span class="c3">Domestic Partner</span
      ><span>&nbsp;means a person designated in writing by the </span
      ><span class="c3">primary member</span
      ><span>&nbsp;who is registered as a </span
      ><span class="c3">domestic partner</span
      ><span class="c0"
        >&nbsp;or legal equivalent under laws of the governing jurisdiction or
        who:</span
      >
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_c8tyj5o2bti-0 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c0"
          >is at least 18 years of age and competent to enter into a
          contract;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_c8tyj5o2bti-0" start="2">
      <li class="c7 li-bullet-0">
        <span>is not related to the </span><span class="c3">primary member</span
        ><span class="c0">&nbsp;by blood;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_c8tyj5o2bti-0" start="3">
      <li class="c7 li-bullet-0">
        <span>has exclusively lived with the </span
        ><span class="c3">primary member</span
        ><span class="c0"
          >&nbsp;for at least twelve (12) consecutive months prior to the
          effective date of coverage;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_c8tyj5o2bti-0" start="4">
      <li class="c7 li-bullet-0">
        <span class="c0">is not legally married or separated; and</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_c8tyj5o2bti-0" start="5">
      <li class="c7 li-bullet-0">
        <span>as of the date of coverage, has with the </span
        ><span class="c3">primary member</span
        ><span class="c0"
          >&nbsp;at least two (2) of the following financial arrangements:</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_c8tyj5o2bti-1 start" start="1">
      <li class="c4 li-bullet-0">
        <span class="c0">a joint mortgage or lease;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_c8tyj5o2bti-1" start="2">
      <li class="c4 li-bullet-0">
        <span class="c0">a joint bank account;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_c8tyj5o2bti-1" start="3">
      <li class="c4 li-bullet-0">
        <span class="c0"
          >joint title or ownership of a home, motor vehicle or status as joint
          lessee on a motor vehicle lease;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_c8tyj5o2bti-1" start="4">
      <li class="c4 li-bullet-0">
        <span class="c0"
          >a joint credit card account with a financial institution.</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span class="c3">Expenses</span><span class="c0">&nbsp;mean:</span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_tku7q72rmqwe-0 start" start="1">
      <li class="c7 li-bullet-0">
        <span>Costs incurred by the </span><span class="c3">member</span
        ><span
          >&nbsp;for re-filing applications for loans, grants, or other credit
          that are rejected solely because the lender received from any source
          incorrect information as a result of the </span
        ><span class="c3">identity fraud</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_tku7q72rmqwe-0" start="2">
      <li class="c7 li-bullet-0">
        <span
          >Costs for notarizing affidavits or other similar documents, long
          distance telephone calls, travel and postage reasonably incurred as a
          result of the </span
        ><span class="c3">member&#39;s</span
        ><span>&nbsp;efforts to report an </span
        ><span class="c3">identity fraud</span
        ><span>&nbsp;or amend or rectify records as to the </span
        ><span class="c3">member&rsquo;s</span
        ><span>&nbsp;true name or identity as a result of an </span
        ><span class="c3">identity fraud</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_tku7q72rmqwe-0" start="3">
      <li class="c7 li-bullet-0">
        <span>Reasonable costs incurred by the </span
        ><span class="c3">member</span
        ><span
          >&nbsp;for up to six (6) credit reports from established credit
          bureaus (with no more than two (2) reports from any one credit bureau)
          dated within twelve (12) months after the </span
        ><span class="c3">member&rsquo;s</span
        ><span>&nbsp;discovery of an </span
        ><span class="c3">identity fraud</span
        ><span
          >, and costs incurred for contesting the accuracy or completeness of
          any information contained in a credit report following an </span
        ><span class="c3">identity fraud</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_tku7q72rmqwe-0" start="4">
      <li class="c7 li-bullet-0">
        <span>Payment for reasonable </span><span class="c3">expenses</span
        ><span>&nbsp;incurred that were a result of recovery from an </span
        ><span class="c3">identity fraud</span
        ><span class="c0"
          >&nbsp;such as; credit freeze, credit thaw costs, transcript costs,
          appeal bond, court filing fees, expert witness or courier fees;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_tku7q72rmqwe-0" start="5">
      <li class="c7 li-bullet-0">
        <span
          >Actual lost base wages that would have been earned in the United
          States, for time reasonably and necessarily taken off work solely as a
          result of efforts to amend or rectify records as to the </span
        ><span class="c3">member&rsquo;s</span
        ><span>&nbsp;true name or identity as a result of an </span
        ><span class="c3">identity fraud</span
        ><span
          >. Actual lost wages includes remuneration for vacation days,
          discretionary days, floating holidays, and paid personal days and
          excludes sick days, business interruption and future earnings of a
          self-employed professional. Coverage is limited to base wages within
          twelve (12) months after discovery of an </span
        ><span class="c3">identity fraud</span
        ><span class="c0"
          >. Base wages must be supported by and based on the prior year tax
          return.</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_tku7q72rmqwe-0" start="6">
      <li class="c7 li-bullet-0">
        <span
          >Child or elderly care costs that would have otherwise not been
          incurred, resulting from time reasonably and necessarily taken away
          from providing such care as a result of efforts to amend or rectify
          records as to the </span
        ><span class="c3">association member&rsquo;s</span
        ><span>&nbsp;identity as a result of an </span
        ><span class="c3">identity fraud</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_tku7q72rmqwe-0" start="7">
      <li class="c7 li-bullet-0">
        <span>Reasonable and necessary costs incurred by the </span
        ><span class="c3">member</span
        ><span
          >&nbsp;for ordering medical records for the purpose of amending and/or
          rectifying these documents as a result of an </span
        ><span class="c3">identity fraud</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_tku7q72rmqwe-0" start="8">
      <li class="c7 li-bullet-0">
        <span>Reasonable and necessary costs incurred by </span
        ><span class="c3">member</span
        ><span
          >&nbsp;for the replacement of identification cards, driver licenses
          and passports as a result of an </span
        ><span class="c3">identity fraud</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_tku7q72rmqwe-0" start="9">
      <li class="c7 li-bullet-0">
        <span
          >Reasonable and necessary costs, up to a maximum of $125 per hour,
          incurred by the </span
        ><span class="c3">member</span
        ><span
          >&nbsp;for use of any investigative agency or private investigator
          engaged to amend or rectify records as to the </span
        ><span class="c3">member&rsquo;s</span
        ><span>&nbsp;true name or identity as a result of an </span
        ><span class="c3">identity fraud</span><span>. </span
        ><span class="c3">We</span
        ><span
          >&nbsp;reserve the right to select such investigative agency or
          private investigator; however, with </span
        ><span class="c3">our</span
        ><span>&nbsp;express prior written consent, </span
        ><span class="c3">member</span
        ><span class="c0"
          >&nbsp;may elect such investigative agency or private
          investigator.</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_tku7q72rmqwe-0" start="10">
      <li class="c7 li-bullet-0">
        <span
          >Reasonable and necessary costs, up to a maximum of $125 per hour,
          incurred by the </span
        ><span class="c3">member</span
        ><span
          >&nbsp;associated with the use of any certified public accountant
          engaged to amend or rectify records as to the </span
        ><span class="c3">member&rsquo;s</span
        ><span>&nbsp;true name or identity as a result of an </span
        ><span class="c3">identity fraud</span><span>. </span
        ><span class="c3">We</span
        ><span
          >&nbsp;reserve the right to select such certified public accountant;
          however, with </span
        ><span class="c3">our</span
        ><span>&nbsp;express prior written consent, </span
        ><span class="c3">member</span
        ><span class="c0"
          >&nbsp;may elect such certified public accountant.</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span class="c3">Family Member</span><span>&nbsp;means the </span
      ><span class="c3">primary member&rsquo;s spouse</span
      ><span>&nbsp;or </span><span class="c3">domestic partner</span
      ><span
        >, parent, siblings, children and/or any other member of, or dependent
        persons residing in the </span
      ><span class="c3">primary member&rsquo;s</span
      ><span>&nbsp;household. </span><span class="c3">Family member</span
      ><span>&nbsp;also includes a </span
      ><span class="c3">primary member&rsquo;s spouse</span
      ><span>&nbsp;or </span><span class="c3">domestic partner&rsquo;s</span
      ><span class="c0">:</span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_w2x0s74x94go-0 start" start="1">
      <li class="c7 li-bullet-0">
        <span>unmarried children (including those who the </span
        ><span class="c3">member</span
        ><span class="c0"
          >&nbsp;is their legal guardian) under twenty-one (21) years of
          age.</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_w2x0s74x94go-0" start="2">
      <li class="c7 li-bullet-0">
        <span>unmarried children (including those who the </span
        ><span class="c3">member</span
        ><span class="c0"
          >&nbsp;is a legal guardian) under twenty-six (26) years of age if a
          full-time student at an accredited college or university.</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_w2x0s74x94go-0" start="3">
      <li class="c7 li-bullet-0">
        <span
          >dependent(s) with documented disabilities who have the same primary
          residence as the </span
        ><span class="c3">member</span><span>&nbsp;and who relies on the </span
        ><span class="c3">member</span
        ><span class="c0">&nbsp;for maintenance and support.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span class="c3">Fraud or Embezzlement</span
      ><span class="c0">&nbsp;means:</span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_e9f8hgxz8m9q-0 start" start="1">
      <li class="c7 li-bullet-0">
        <span
          >An electronic, telegraphic cable, teletype tele facsimile, or
          telephone instruction which purports to have been transmitted by the </span
        ><span class="c3">member</span
        ><span
          >, but which was in fact fraudulently transmitted by someone else
          without </span
        ><span class="c3">member&rsquo;s</span
        ><span class="c0">&nbsp;knowledge or consent; or</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_e9f8hgxz8m9q-0" start="2">
      <li class="c7 li-bullet-0">
        <span>A written instruction issued by the </span
        ><span class="c3">member</span
        ><span>, which was altered by someone other than the </span
        ><span class="c3">member</span
        ><span>, or purported to be issued by the </span
        ><span class="c3">member</span
        ><span>&nbsp;but was forged or fraudulently issued without the </span
        ><span class="c3">member&rsquo;s</span
        ><span class="c0">&nbsp;knowledge or consent; or</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_e9f8hgxz8m9q-0" start="3">
      <li class="c7 li-bullet-0">
        <span
          >An electronic, telegraphic, cable, teletype, tele facsimile,
          telephone or written instruction initially received by the </span
        ><span class="c3">member</span
        ><span
          >&nbsp;which purports to have been transmitted by an employee but
          which was in fact fraudulently transmitted by someone else without </span
        ><span class="c3">member&rsquo;s</span
        ><span class="c0">, or employee&rsquo;s knowledge or consent. </span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span class="c3">HSA Custodian/Administrator</span
      ><span
        >&nbsp;means a bank, credit union, insurance company, brokerage or other
        IRS-approved organization that offers health savings accounts.<br /><br /></span
      ><span class="c3">Identity Fraud</span
      ><span
        >&nbsp;means the act of knowingly transferring or using, without lawful
        authority, a means of identification of the </span
      ><span class="c3">member</span
      ><span
        >&nbsp;with the intent to commit, or to aid or abet, any unlawful
        activity that constitutes a violation of Federal, State or local law.<br /><br /></span
      ><span class="c3">Investment or Health Savings Accounts</span
      ><span
        >&nbsp;means an individual retirement or employer sponsored, retirement
        or health savings account such as an IRA, Roth IRA, 401K, Roth 401K,
        Simple IRA, SEP IRA, Health Savings Account (HSA), Flexible Spending
        Account (FSA), or Health Reimbursement Account (HRA). Investment or
        Health Savings Accounts also includes Employee Stock Option Plans (ESOP)
        and taxable brokerage accounts such as individual and joint stocks,
        funds, bonds and derivatives accounts of the </span
      ><span class="c3">member</span
      ><span
        >&nbsp;held directly or indirectly by a financial institution or </span
      ><span class="c3">HSA Custodian/Administrator</span
      ><span
        >&nbsp;and established primarily for personal, family or household
        purposes.<br />
        <br /></span
      ><span class="c3">Legal Costs</span
      ><span
        >&nbsp;means costs, up to a maximum of $125 per hour, for reasonable
        fees for an attorney selected by the member and related court fees,
        incurred by the </span
      ><span class="c3">member</span><span>&nbsp;with </span
      ><span class="c3">our</span><span class="c0">&nbsp;consent, for:</span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_l1vsz70ouva-0 start" start="1">
      <li class="c7 li-bullet-0">
        <span
          >Defense of any legal action brought against the member by a merchant,
          creditor or collection agency or entity acting on their behalf for
          non-payment of goods or services or default on a loan as a result of
          the </span
        ><span class="c3">identity fraud</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_l1vsz70ouva-0" start="2">
      <li class="c7 li-bullet-0">
        <span>Removal of any civil judgments wrongly entered against the </span
        ><span class="c3">member</span><span>&nbsp;as a result of </span
        ><span class="c3">identity fraud</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_l1vsz70ouva-0" start="3">
      <li class="c7 li-bullet-0">
        <span
          >Challenging the accuracy or completeness of any information in a </span
        ><span class="c3">member&rsquo;s</span
        ><span
          >&nbsp;consumer credit report, medical history or tax history, as a
          result of </span
        ><span class="c3">identity fraud</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_l1vsz70ouva-0" start="4">
      <li class="c7 li-bullet-0">
        <span class="c3">Member&rsquo;s</span
        ><span
          >&nbsp;initial consultation with a lawyer to determine the severity of
          and appropriate response to an </span
        ><span class="c3">identity fraud</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_l1vsz70ouva-0" start="5">
      <li class="c7 li-bullet-0">
        <span>Defending criminal charges brought against the </span
        ><span class="c3">member</span><span>&nbsp;as a result of </span
        ><span class="c3">identity fraud</span><span>; provided, however, </span
        ><span class="c3">we</span
        ><span>&nbsp;will only pay criminal defense related fees and </span
        ><span class="c3">expenses</span
        ><span>&nbsp;after it has been established that the </span
        ><span class="c3">member</span
        ><span class="c0">&nbsp;was not in fact the perpetrator.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span class="c3">Loss</span><span>&nbsp;means the </span
      ><span class="c3">expenses</span><span>&nbsp;and </span
      ><span class="c3">legal costs</span><span>&nbsp;incurred by the </span
      ><span class="c3">member</span
      ><span
        >&nbsp;as the direct result of a covered transaction.<br />
        <br /></span
      ><span class="c3">Member</span><span>&nbsp;means the </span
      ><span class="c3">primary member</span><span>&nbsp;and </span
      ><span class="c3">primary member&rsquo;s spouse</span
      ><span>&nbsp;or </span><span class="c3">domestic partner</span
      ><span>&nbsp;if the </span><span class="c3">primary member</span
      ><span>&nbsp;is enrolled in or elects a </span
      ><span class="c3">Couple Plan</span
      ><span>&nbsp;membership program; or </span
      ><span class="c3">primary member&rsquo;s family member</span
      ><span>&nbsp;if the </span><span class="c3">primary member</span
      ><span>&nbsp;is enrolled in or elects a </span
      ><span class="c3">Family Plan</span
      ><span>&nbsp;membership program.<br /><br /></span
      ><span class="c3">Occurrence</span
      ><span
        >&nbsp;means an incident of an actual or attempted fraudulent, dishonest
        or criminal act or series of related acts, whether committed by one or
        more persons. <br /><br /></span
      ><span class="c3">Occurrence date</span
      ><span>&nbsp;means the earliest possible </span
      ><span class="c3">date of discovery</span><span>.<br /><br /></span
      ><span class="c3">Policyholder</span
      ><span
        >&nbsp;means Generali Global Assistance, Inc., dba Iris&reg; Powered by
        Generali. <br /><br /></span
      ><span class="c3">Primary Guardian or Caregiver</span
      ><span>&nbsp;means the </span><span class="c3">primary member</span
      ><span>&nbsp;or, or </span><span class="c3">domestic partner</span
      ><span
        >, who provides care or accepts guardianship of their elderly parents
        and/or grandparents and agrees to a higher level or responsibility
        including making medical and daily care decisions for the elderly parent
        or grandparent.<br /><br /></span
      ><span class="c3">Primary Member</span><span>&nbsp;means a </span
      ><span class="c3">member</span
      ><span
        >&nbsp;of the policyholder&rsquo;s identity protection membership
        program that includes identity theft insurance issued thereto.<br /><br /></span
      ><span class="c3">Proof of Loss</span
      ><span>&nbsp;means receipts for reasonable out of pocket </span
      ><span class="c3">expenses</span><span>.<br /><br /></span
      ><span class="c3">Residential Real Estate</span><span>&nbsp;means </span
      ><span class="c3">member&#39;s</span
      ><span
        >&nbsp;primary residence, second home, vacation home or investment
        residential properties, including vacant homes for which </span
      ><span class="c3">member&rsquo;s</span
      ><span>&nbsp;name appears on the </span
      ><span class="c3">residential real estate</span
      ><span>&nbsp;property deed. <br /><br /></span
      ><span class="c3">Spouse</span
      ><span class="c0">&nbsp;means a person who is either:</span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_4ac75qr0o3cc-0 start" start="1">
      <li class="c7 li-bullet-0">
        <span>legally married to the </span
        ><span class="c3">primary member</span><span class="c0">; or</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_4ac75qr0o3cc-0" start="2">
      <li class="c7 li-bullet-0">
        <span>meets the definition of </span
        ><span class="c3">domestic partner</span><span>&nbsp;to the </span
        ><span class="c3">primary member</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11">
      <span class="c3">Couple Plan</span
      ><span
        >&nbsp;means a membership program that extends coverage under these
        Terms and Conditions, up to the Aggregate limit of Liability shown on
        the Limits of Insurance section of these Terms and Conditions, to the </span
      ><span class="c3">primary member</span><span>&nbsp;and their </span
      ><span class="c3">spouse</span><span>&nbsp;or </span
      ><span class="c3">domestic partner</span
      ><span>&nbsp;and for which the </span><span class="c3">Couple Plan</span
      ><span
        >&nbsp;membership fee is paid.<br /><br />The Aggregate limit of
        Liability shown on the Limits of Insurance section of these Terms and
        Conditions will be the maximum amount </span
      ><span class="c3">we</span><span>&nbsp;will pay for all covered </span
      ><span class="c3">losses</span><span>&nbsp;per </span
      ><span class="c3">Couple Plan</span
      ><span>&nbsp;regardless of the number of </span
      ><span class="c3">losses</span><span>&nbsp;that occur for any one </span
      ><span class="c3">member</span
      ><span>&nbsp;per twelve (12) month period.<br /><br /></span
      ><span class="c3">Stolen Identity Event</span
      ><span>&nbsp;means the theft, unauthorized, or illegal use of the </span
      ><span class="c3">member&rsquo;s</span
      ><span
        >&nbsp;name, social security number, or other method of identifying the </span
      ><span class="c3">member</span><span>.<br /><br /></span
      ><span class="c3">Title Theft or Deed Fraud</span
      ><span>&nbsp;means an </span><span class="c3">identity theft event</span
      ><span
        >&nbsp;resulting from the fraudulent recording of a forged deed,
        initiated by a person other than the </span
      ><span class="c3">member</span
      ><span
        >, without the actual authority to initiate a change in ownership of </span
      ><span class="c3">member&rsquo;s residential real estate</span
      ><span>, and from which the </span><span class="c3">member</span
      ><span>&nbsp;receives no benefit.<br /><br /></span
      ><span class="c3">Unauthorized Electronic Fund Transfer (UEFT)</span
      ><span>&nbsp;means an electronic fund transfer from the </span
      ><span class="c3">member&rsquo;s account</span
      ><span>&nbsp;for Cash Recovery Aggregate, or </span
      ><span class="c3">investment or health savings account</span
      ><span
        >&nbsp;for Investment &amp; Health Savings Accounts Cash Recovery,
        initiated by a person other than the </span
      ><span class="c3">member</span
      ><span
        >&nbsp;without the actual authority to initiate such transfer and from
        which the </span
      ><span class="c3">member</span
      ><span>&nbsp;receives no benefit.<br /><br />An </span
      ><span class="c3">unauthorized electronic fund</span
      ><span class="c0"
        >&nbsp;transfer does not include an electronic fund transfer initiated:
      </span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_4ok82ztopnic-0 start" start="1">
      <li class="c7 li-bullet-0">
        <span>by a person who was furnished the </span
        ><span class="c3">access device</span><span>&nbsp;to the </span
        ><span class="c3">member&rsquo;s account</span><span>, unless the </span
        ><span class="c3">member</span
        ><span class="c0"
          >&nbsp;had given prior notification to the financial institution that
          transfers by such person(s) are no longer authorized;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_4ok82ztopnic-0" start="2">
      <li class="c7 li-bullet-0">
        <span>with fraudulent intent by the </span><span class="c3">member</span
        ><span>&nbsp;or any person acting in concert with the </span
        ><span class="c3">member</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_4ok82ztopnic-0" start="3">
      <li class="c7 li-bullet-0">
        <span class="c0"
          >by the financial institution or its employees; or</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_4ok82ztopnic-0" start="4">
      <li class="c7 li-bullet-0">
        <span class="c0">from any business or commercial account.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <p class="c2"><span class="c0"></span></p>
    <h2 class="c5" id="h.1dso1whpp0sf"><span class="c8">CONDITIONS</span></h2>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-0 start" start="1">
      <li class="c7 li-bullet-0">
        <span class="c3">Limits of Insurance</span><span> - </span
        ><span class="c3">Our</span
        ><span> maximum limit of liability for </span
        ><span class="c3">loss</span
        ><span class="c0"
          >&nbsp;under these Terms and Conditions shall not exceed the
          applicable limit stated on the Limits of Insurance section of these
          Terms and Conditions.</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11 c12">
      <span>All </span><span class="c3">loss</span
      ><span class="c0"
        >&nbsp;incidental to an actual or attempted fraudulent, dishonest or
        criminal act or series of related acts, whether committed by one or more
        persons, shall be deemed to arise out of one occurrence.</span
      >
    </p>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11 c12">
      <span class="c3">Our</span
      ><span
        >&nbsp;total aggregate limit of liability shown on the Limits of
        Insurance section of these Terms and Conditions will be the maximum
        amount </span
      ><span class="c3">we</span><span>&nbsp;will pay for all covered </span
      ><span class="c3">losses</span><span>&nbsp;per </span
      ><span class="c3">primary member</span><span>, </span
      ><span class="c3">Couple Plan</span><span>&nbsp;or </span
      ><span class="c3">Family Plan</span
      ><span>&nbsp;membership, regardless of the number of </span
      ><span class="c3">losses</span><span>&nbsp;that occur for any one </span
      ><span class="c3">member</span
      ><span class="c0">&nbsp;per twelve (12) month period.</span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-0" start="2">
      <li class="c7 li-bullet-0">
        <span class="c3">Loss Payment</span><span>&nbsp;- </span
        ><span class="c3">We</span><span>&nbsp;will pay any </span
        ><span class="c3">loss</span
        ><span class="c0"
          >&nbsp;covered under the Policy under which this coverage is provided,
          within thirty (30) days after:</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-1 start" start="1">
      <li class="c4 li-bullet-0">
        <span class="c3">We</span><span>&nbsp;reach agreement with the </span
        ><span class="c3">member</span><span class="c0">; or</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-1" start="2">
      <li class="c4 li-bullet-0">
        <span class="c0">The entry of final judgment.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-0" start="3">
      <li class="c7 li-bullet-0">
        <span class="c3">Notice of Claim</span
        ><span>&nbsp;must be given to </span><span class="c3">us</span
        ><span>&nbsp;by the </span><span class="c3">member</span
        ><span>&nbsp;within a reasonable time period after the </span
        ><span class="c3">date of discovery</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-0" start="4">
      <li class="c7 li-bullet-0">
        <span class="c3">Settlement of Claims</span><span>&nbsp;- </span
        ><span class="c3">We</span
        ><span
          >&nbsp;will pay all covered claims within ninety (90) days from the
          date </span
        ><span class="c3">we</span><span>&nbsp;receive acceptable </span
        ><span class="c3">proof of loss</span><span>&nbsp;at </span
        ><span class="c3">our</span><span class="c0">&nbsp;office.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-0" start="5">
      <li class="c7 li-bullet-0">
        <span class="c3"
          >Transfer of Rights of Recovery Against Others to Us</span
        ><span>&nbsp;- If any person or organization to or for whom </span
        ><span class="c3">we</span
        ><span
          >&nbsp;make payment under this insurance has rights to recover damages
          from another, those rights are transferred to </span
        ><span class="c3">us</span
        ><span
          >. That person or organization must do everything necessary to secure </span
        ><span class="c3">our</span
        ><span class="c0"
          >&nbsp;rights and must do nothing to impair them.</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11 c12">
      <span
        >Recovery against persons or organizations also insured under the Policy
        under which this coverage is provided, or any other Policy issued by </span
      ><span class="c3">us</span><span>&nbsp;with respect to the same </span
      ><span class="c3">loss</span><span class="c0">&nbsp;is prohibited.</span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-0" start="6">
      <li class="c7 li-bullet-0">
        <span class="c3">Other Insurance</span
        ><span class="c0"
          >&nbsp;- This insurance is excess in the event coverage is provided
          under any other policy/certificate.</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <p class="c11 c12">
      <span>Should the </span><span class="c3">member</span
      ><span
        >&nbsp;be enrolled in more than one membership program insured by </span
      ><span class="c3">us</span><span>, </span><span class="c3">we</span
      ><span>&nbsp;will reimburse the </span><span class="c3">member</span
      ><span class="c0">&nbsp;under each membership:</span>
    </p>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-1 start" start="3">
      <li class="c4 li-bullet-0">
        <span class="c0"
          >subject to the applicable deductibles and limits of liability of each
          membership;</span
        >
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-1" start="4">
      <li class="c4 li-bullet-0">
        <span
          >but in no event shall the total amount reimbursed under all
          memberships exceed the actual amount of </span
        ><span class="c3">loss</span><span class="c0">; and</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-1" start="5">
      <li class="c4 li-bullet-0">
        <span
          >in no event shall the limit of liability under all memberships exceed
          the largest limit of liability available to the </span
        ><span class="c3">member</span
        ><span class="c0"
          >&nbsp;under any membership program insured by us.
        </span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-0" start="7">
      <li class="c7 li-bullet-0">
        <span class="c3">Action Against Us</span
        ><span>&nbsp;- No action may be brought against </span
        ><span class="c3">us</span
        ><span
          >&nbsp;unless there has been full compliance with all of the terms and
          conditions of this Summary of Benefits and suit is filed within
          twenty-four (24) months from the date of occurrence. No one will have
          the right to join </span
        ><span class="c3">us</span
        ><span>&nbsp;as a party to any against the </span
        ><span class="c3">policyholder</span><span>&nbsp;or </span
        ><span class="c3">member</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c2"><span class="c0"></span></p>
    <ol class="c1 lst-kix_blprch6em4x6-0" start="8">
      <li class="c7 li-bullet-0">
        <span class="c3">Territory</span
        ><span
          >&nbsp;- Coverage under these Terms and Conditions is extended to </span
        ><span class="c3">members</span
        ><span class="c0"
          >&nbsp;residing in the United States, its territories and possessions
          and Puerto Rico.</span
        >
      </li>
    </ol>
  </body>
</html>
`;

export default html;
