import { Select } from '@mui/material';
import { theme } from 'context/ThemeProvider';
import { FC } from 'react';
import { useCatchupPaymentContext } from './CatchupPaymentProvider';

const PartialPaymentSelect: FC = () => {
  const { catchupAmount, setCatchupAmount, pastDueAmountDollars } =
    useCatchupPaymentContext();

  const selectValues = Array.from(
    { length: pastDueAmountDollars / 10 },
    (_, i) => (i + 1) * 10,
  ).reverse();

  return (
    <Select
      id="amount"
      value={catchupAmount}
      onChange={(e) => setCatchupAmount(+e.target.value)}
      native
      sx={{
        ...theme.typography.h4,
      }}
      inputProps={{
        sx: {
          padding: '10px 16px',
          height: 'auto',
        },
      }}
    >
      {selectValues.map((amount) => (
        <option value={amount} key={amount}>
          ${amount}.00
        </option>
      ))}
    </Select>
  );
};

export default PartialPaymentSelect;
