export interface ReferralClaim {
  id: string;
  referralCodeId: string;
  referredUserId: string;
  invitingUserId: string;
  referredRewardAmount: number;
  invitingRewardAmount: number;
  referredGiftCardId: string | null;
  invitingGiftCardId: string | null;
  completedOnboardingAt: string | null;
  eligibleAt: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface ReferralClaimSummary {
  referredUserCount: number;
  eligibleUserCount: number;
}

export const INVITING_REWARD_AMOUNT = '$30';
export const REFERRED_REWARD_AMOUNT = '$10';
