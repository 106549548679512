import { Box, Card, CardContent, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';

const EligibleForRewardsBanner: React.FC = () => {
  return (
    <Card variant="gradient" sx={{ marginBottom: theme.spacing(2) }}>
      <CardContent>
        <Typography>
          <Box component="strong">You’re eligible for rewards!</Box> See below
          for rewards you can earn through the Kovo network.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EligibleForRewardsBanner;
