import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import PageLoader from 'components/PageLoader';
import { ROUTES } from 'components/Routes';
import { formatCurrency } from 'components/shared/CurrencyInput/CurrencyInput';
import StripeStatusBanner from 'components/StripeStatusBanner';
import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import usePayStripeInvoice from 'hooks/usePayStripeInvoice';
import useStripeStatus from 'hooks/useStripeStatus';

export const COMPLETED_PAYMENTS_STATUSES = ['paidOnSchedule', 'paidEarly'];
const HIDE_CARD_INFO_STATUSES = [
  'closedProductAccessRemains',
  ...COMPLETED_PAYMENTS_STATUSES,
];

const StripePaymentInfo: React.FC = () => {
  const { data, isLoading } = useStripeStatus();
  const { data: applicationData } = useApplicationStatus();

  const {
    mutate,
    isLoading: isLoadingMutation,
    error: mutationError,
  } = usePayStripeInvoice();

  const handlePayNow = (invoiceId: string) => {
    mutate(invoiceId);
  };

  if (isLoading || !data || !applicationData) {
    return <PageLoader />;
  }

  const { applicationStatus } = applicationData;
  const { status: stripeStatus } = data.subscription;

  const paymentsComplete =
    COMPLETED_PAYMENTS_STATUSES.includes(applicationStatus);

  /**
   * If we have a second product that also uses saved card information,
   * this may not end up applying to users in certain statuses, so this
   * logic may need to be revisited at that time.
   */
  const showCardInformation =
    !HIDE_CARD_INFO_STATUSES.includes(applicationStatus);

  let accountStatus: string = '';

  if (paymentsComplete) {
    accountStatus = 'paid in full';
  } else if (applicationStatus === 'closedProductAccessRemains') {
    accountStatus = 'closed';
  } else {
    accountStatus = stripeStatus;
  }

  return (
    <div data-testid="stripe-payment-info">
      {!!(data?.openInvoices && data.openInvoices.length) && (
        <>
          <Typography variant="h3" marginTop={theme.spacing(3)}>
            Past Due Payments
          </Typography>
          <Divider sx={{ marginBottom: theme.spacing(2) }} />

          <StripeStatusBanner hideButton />

          {mutationError && (
            <Alert severity="error" sx={{ marginBottom: theme.spacing(1) }}>
              {mutationError.message}
            </Alert>
          )}

          <List disablePadding>
            {data.openInvoices.map(({ id, amountDue, periodStart }) => (
              <ListItem
                key={id}
                secondaryAction={
                  <LoadingButton
                    variant="outlined"
                    onClick={() => handlePayNow(id)}
                    loading={isLoadingMutation}
                  >
                    Pay Now
                  </LoadingButton>
                }
                disablePadding
              >
                <ListItemText
                  primary={moment(periodStart).format('MM/DD/YYYY')}
                  secondary={`Amount due: ${formatCurrency(
                    (amountDue / 100).toString(),
                  )}`}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}

      {showCardInformation && (
        <>
          <Typography variant="h3" marginTop={theme.spacing(3)}>
            Payment Information
          </Typography>
          <Divider sx={{ marginBottom: theme.spacing(2) }} />

          <Typography variant="subtitle1">Card Information:</Typography>
          <Typography variant="body2" marginBottom={theme.spacing(4)}>
            Your monthly payments are set up on auto-pay with your card ending
            in {data?.paymentMethod.last4}.
          </Typography>

          <Box textAlign="center">
            <Button
              variant="contained"
              component={Link}
              to={ROUTES.INSTALLMENT_PLAN_UPDATE_CARD}
            >
              Update card
            </Button>
          </Box>
        </>
      )}

      {paymentsComplete && (
        <Alert
          severity="success"
          icon={false}
          sx={{
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(4),
          }}
        >
          Congratulations! You have completed your Installments.
        </Alert>
      )}

      <Typography variant="h3" marginTop={theme.spacing(4)}>
        Account Status
      </Typography>

      <Divider />

      <List disablePadding>
        <ListItem disablePadding>
          <ListItemText
            primary={accountStatus}
            sx={{ textTransform: 'capitalize' }}
          />
        </ListItem>
        <Divider component="li" />
      </List>

      <Typography variant="h3" marginTop={theme.spacing(4)}>
        Payment History
      </Typography>
      <Divider />

      <List disablePadding>
        {data?.charges.map(({ amount, date }: any) => (
          <React.Fragment key={date}>
            <ListItem disablePadding>
              <ListItemText
                primary={moment(date).format('MM/DD/YYYY')}
                secondary={`$${amount / 100} paid`}
                sx={{ display: 'flex', alignItems: 'center' }}
                primaryTypographyProps={{ sx: { flexGrow: '1' } }}
              />
            </ListItem>
            <Divider component="li" />
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default StripePaymentInfo;
