import { useQuery, UseQueryOptions } from 'react-query';

import { useClientsService } from 'hooks/useClientsService';
import { ClientsService } from 'libs/ClientsService';
import { KovoError } from 'libs/KovoError';
import { User } from 'types/schemas';

export const USE_CURRENT_USER_QUERY_KEY = 'currentUser';

export const getCurrentUser = async (clientsService: ClientsService) => {
  try {
    const res = await clientsService.get<User>(`/v1/me`);
    /**
     * Make sure that we are using the correct userId value if it was generated on the
     * backend. This is for backwards compatability.
     *
     * For authentication purposes, API and Servies look up the user based on the user pool user ID
     * that is parsed out from the cognito auth string.
     */
    clientsService.setHeaderOptions({
      userId: res.userId,
    });

    return res;
  } catch (error) {
    if (error instanceof KovoError) {
      throw error;
    }

    throw new KovoError('Error getting current user', { error });
  }
};

type UseCurrentUserOptions = Omit<
  UseQueryOptions<User, KovoError>,
  'queryFn' | 'enabled'
>;

function useCurrentUser(options?: UseCurrentUserOptions) {
  const clientsService = useClientsService();

  return useQuery<User, KovoError>({
    queryKey: options
      ? [USE_CURRENT_USER_QUERY_KEY, options]
      : [USE_CURRENT_USER_QUERY_KEY],
    ...options,
    queryFn: () => getCurrentUser(clientsService),
    enabled: clientsService.ready,
  });
}

export default useCurrentUser;
