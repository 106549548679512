import { API } from 'aws-amplify';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { LoanProductId } from 'types/schemas';

function useContractHtmlQuery(productId: LoanProductId) {
  const { identityId, username } = useContext(AuthContext);

  const fetchContractHtml = async () => {
    const { html } = await API.get(
      'clientsService',
      `/v1/esign/${productId}/product-contract-html`,
      {
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
          }).headers,
        },
      },
    );

    return html;
  };

  return useQuery(['product-contract', productId], () => fetchContractHtml(), {
    refetchOnWindowFocus: false,
  });
}

export default useContractHtmlQuery;
