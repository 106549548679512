import { Divider, Skeleton, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import { useGiftCards } from 'hooks/useGiftCards';

import GiftCardsGrid from './GiftCardsGrid';

const GiftCardsView = () => {
  const { data: giftCards, isLoading } = useGiftCards();

  if (!isLoading && !giftCards?.length) {
    return null;
  }

  return (
    <>
      <Typography variant="h4" marginBottom={theme.spacing(2)}>
        {isLoading ? <Skeleton /> : 'CLAIMED EARNINGS'}
      </Typography>

      {giftCards && (
        <>
          <Divider sx={{ marginBottom: theme.spacing(3) }} />

          <Typography marginBottom={theme.spacing(3)}>
            Select a card below to view more details, such as the remaining
            balance on the card.
          </Typography>

          <GiftCardsGrid giftCards={giftCards} />
        </>
      )}
    </>
  );
};

export default GiftCardsView;
