import { Alert, Grid } from '@mui/material';
import { useState } from 'react';

import { theme } from 'context/ThemeProvider';
import { parsePaymentError } from 'libs/parsePaymentError';

import ApplePayButton from './ApplePayButton';
import CheckoutCreditCardForm from './CheckoutCreditCardForm';
import CheckoutPaymentFormContainer from './CheckoutPaymentFormContainer';
import GooglePayButton from './GooglePayButton';
import { PaymentMode, onConfirmPaymentMethod } from './types';

export enum PaymentMethodStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Loading = 'loading',
}

export type PaymentMethods = {
  creditCard: PaymentMethodStatus;
  applePay: PaymentMethodStatus;
  googlePay: PaymentMethodStatus;
};

export interface CheckoutPaymentMethodsProps {
  /**
   * If true, the Apple Pay and Google Pay buttons will be disabled.
   * This is only used for unit testing.
   */
  walletsDisabled?: boolean;
  onConfirmPaymentMethod: onConfirmPaymentMethod;
  amountInDollars: number;
  paymentMode: PaymentMode;
  googlePayDisabled?: boolean;
}

/**
 * This component is responsible for rendering the Checkout credit card form
 * and the Apple Pay and Google Pay buttons, if they are enabled and supported
 * by the browser.
 */
const CheckoutPaymentMethods: React.FC<CheckoutPaymentMethodsProps> = ({
  walletsDisabled = false,
  amountInDollars,
  onConfirmPaymentMethod,
  paymentMode,
  googlePayDisabled,
}) => {
  const [error, setError] = useState<string | Error>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethods>({
    creditCard: PaymentMethodStatus.Enabled,
    applePay: walletsDisabled
      ? PaymentMethodStatus.Disabled
      : PaymentMethodStatus.Loading,
    googlePay: walletsDisabled
      ? PaymentMethodStatus.Disabled
      : PaymentMethodStatus.Loading,
  });

  const creditCardButtonLabel =
    paymentMode === PaymentMode.UPDATE_METHOD
      ? 'Update'
      : `Pay $${amountInDollars.toFixed(2)}`;

  return (
    <>
      {error && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(3) }}
        >
          {parsePaymentError(error)}
        </Alert>
      )}

      <Grid container rowSpacing={1.5}>
        {/* Apple Pay */}
        <Grid
          sx={{
            display:
              paymentMethods.applePay === PaymentMethodStatus.Enabled
                ? 'initial'
                : 'none',
          }}
          data-testid="apple-pay-container"
          data-state={paymentMethods.applePay}
          item
          xs={12}
        >
          <ApplePayButton
            paymentMode={paymentMode}
            disabled={walletsDisabled}
            onError={setError}
            onLoad={(isEnabled) => {
              const newStatus = isEnabled
                ? PaymentMethodStatus.Enabled
                : PaymentMethodStatus.Disabled;

              if (newStatus === paymentMethods.applePay) return;

              setPaymentMethods((paymentMethods) => ({
                ...paymentMethods,
                applePay: newStatus,
              }));
            }}
            onConfirmPaymentMethod={onConfirmPaymentMethod}
            amountInDollars={amountInDollars}
          />
        </Grid>

        {/* Google Pay */}
        <Grid
          sx={{
            display:
              paymentMethods.googlePay === PaymentMethodStatus.Enabled
                ? 'initial'
                : 'none',
          }}
          data-testid="google-pay-container"
          data-state={paymentMethods.googlePay}
          item
          xs={12}
        >
          <GooglePayButton
            paymentMode={paymentMode}
            disabled={walletsDisabled || googlePayDisabled}
            onError={setError}
            onLoad={(isEnabled) => {
              const newStatus = isEnabled
                ? PaymentMethodStatus.Enabled
                : PaymentMethodStatus.Disabled;

              if (newStatus === paymentMethods.googlePay) return;

              setPaymentMethods((paymentMethods) => ({
                ...paymentMethods,
                googlePay: newStatus,
              }));
            }}
            onConfirmPaymentMethod={onConfirmPaymentMethod}
            amountInDollars={amountInDollars}
          />
        </Grid>

        <Grid item xs={12}>
          <CheckoutPaymentFormContainer
            walletsDisabled={walletsDisabled}
            paymentMethods={paymentMethods}
          >
            <CheckoutCreditCardForm
              onConfirmPaymentMethod={onConfirmPaymentMethod}
              buttonLabel={creditCardButtonLabel}
            />
          </CheckoutPaymentFormContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default CheckoutPaymentMethods;
