import config from '../config';

/**
 * Construct the init object for AWS Amplify. This adds the
 * cognito fake id for serverless offline only for local dev.
 *
 * https://docs.amplify.aws/lib/restapi/fetch/q/platform/js
 */
export const amplifyRequestContext = (
  identityId: string,
  username: string,
  clientsServiceConfig?: {
    'x-api-key': string;
    /**
     * The userId is passed through to the backend in services in some scenarios. This should
     * not be relied upon and instead we should only rely on "x-cognito-authprovider".
     */
    'x-user-id': string;
    [key: string]: string;
  },
) => {
  if (config.VITE_STAGE === 'local' && username) {
    const cognitoAuthenticationProvider = `cognito-idp.us-east-1.amazonaws.com/us-east-1_xxxxxxxxx,cognito-idp.us-east-1.amazonaws.com/us-east-1_aaaaaaaaa:CognitoSignIn:${username}`;
    return {
      headers: {
        /**
         * API repo serverless mocks
         *
         * https://www.serverless.com/plugins/serverless-offline#custom-headers
         */
        'cognito-identity-id': identityId,
        'cognito-authentication-provider': cognitoAuthenticationProvider,

        /**
         * Services repo endpoint mocks
         *
         * This matches the header that's populated by the service-clients-prod API Gateway
         */
        'x-cognito-authprovider': cognitoAuthenticationProvider,
        'x-user-id': identityId,

        /**
         * Extra headers for services repo endpoints
         */
        ...clientsServiceConfig,
      },
    };
  }

  return {};
};
