import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import moment from 'moment';

import TransactionAmount from 'components/TransactionAmount';
import { theme } from 'context/ThemeProvider';
import useCheckoutStatusV2 from 'hooks/useCheckoutStatusV2';

const PaymentHistoryCard: React.FC = () => {
  const { data, isLoading } = useCheckoutStatusV2();

  if (isLoading) {
    return <Skeleton variant="rounded" height={125} />;
  }

  if (!data) {
    return null;
  }

  const isV2Response = 'loanProvider' in data;

  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h5">PAYMENT HISTORY</Typography>

        <Divider sx={{ margin: theme.spacing(2, 0) }} />

        {isV2Response && data.transactions.length === 0 && (
          <Typography variant="footnote">
            There are no transactions to display.
          </Typography>
        )}

        <List disablePadding>
          {isV2Response &&
            data.transactions.map(
              ({ id, type, trigger, amount, effectiveAt }) => {
                const listItemHeader =
                  type === 'refund'
                    ? 'Refund'
                    : trigger === 'autopay'
                    ? 'AutoPay'
                    : 'Payment';
                const formattedDate = moment(effectiveAt).format('MM/DD/YY');

                return (
                  <ListItem key={id} disableGutters disablePadding>
                    <ListItemText>
                      <Box sx={{ display: 'flex' }}>
                        <Typography marginRight="auto">
                          {listItemHeader}
                        </Typography>
                        <TransactionAmount
                          amountCents={-amount} // We're getting payments as positive values but we want to display them as negative
                        />
                      </Box>

                      <Typography variant="footnote">
                        {formattedDate}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                );
              },
            )}
        </List>
      </CardContent>
    </Card>
  );
};

export default PaymentHistoryCard;
