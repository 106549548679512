import { Auth, CognitoUser } from '@aws-amplify/auth';
import { useMutation } from 'react-query';

interface MutationArgument {
  oldPassword: string;
  newPassword: string;
}

function useChangePassword(
  currentUser: CognitoUser | null | undefined,
  options?: any,
) {
  const changePassword = async ({
    oldPassword,
    newPassword,
  }: MutationArgument) => {
    if (!currentUser) {
      throw new Error('Unexpected logged out password change attempt');
    }

    await Auth.changePassword(currentUser, oldPassword, newPassword);
  };

  return useMutation<void, Error, MutationArgument>(changePassword, {
    ...(options && { ...options }),
  });
}

export default useChangePassword;
