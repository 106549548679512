import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

import { theme } from 'context/ThemeProvider';
import { Course as CourseType } from 'hooks/queries/useGetCourses';

import './Course.css';

export interface Props {
  course: CourseType;
}

const Course: React.FC<Props> = ({ course }) => {
  let courseFinishedPercentage = 0;

  // This will prevent 0/0 = NaN
  if (course.activityCount && course.finishedActivityCount) {
    courseFinishedPercentage =
      (course.finishedActivityCount / course.activityCount) * 100;
  }

  // Set max completed percentage to 100.
  if (courseFinishedPercentage > 100) {
    courseFinishedPercentage = 100;
  }

  const hasStartedCourse = courseFinishedPercentage > 0;

  return (
    <Card
      elevation={0}
      sx={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardMedia
        component="img"
        height="193"
        src={course.imageUrl}
        alt={course.name}
        sx={{
          marginBottom: theme.spacing(2),
          borderRadius: theme.spacing(1),
        }}
      />

      <CardContent
        sx={{
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography variant="h3" marginBottom={theme.spacing(2)}>
          {course.name}
        </Typography>

        <Typography>{course.description}</Typography>

        {hasStartedCourse && (
          <Box
            sx={{
              position: 'absolute',
              top: theme.spacing(4),
              right: theme.spacing(4),
              backgroundColor: 'rgba(27, 18, 48, 0.7)',
              padding: theme.spacing(0.5, 1),
              borderRadius: theme.spacing(1),
            }}
          >
            <Typography color={theme.palette.common.white}>
              <Box component="strong">
                {`${Math.round(courseFinishedPercentage)}%`}
              </Box>
            </Typography>
          </Box>
        )}
      </CardContent>
      <CardActions sx={{ marginTop: 'auto' }}>
        <Button
          variant="outlined-gradient"
          href={course.enrollmentLink}
          color={hasStartedCourse ? 'primary' : 'info'}
          data-testid="Course__button"
          fullWidth
        >
          {hasStartedCourse ? 'Continue' : 'Start'}
        </Button>
      </CardActions>
    </Card>
  );
};

export default Course;
