import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { AxiosError } from 'axios';
import { USE_CURRENT_USER_QUERY_KEY } from 'hooks/queries/useCurrentUser';
import { KovoAddressUpdateError } from 'hooks/useApplicationSubmit';
import { useClientsService } from 'hooks/useClientsService';
import { KovoError } from 'libs/KovoError';

type PatchUserInput = {
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  appDownloadPromptSeenAt?: string;
};

function usePatchUser(
  options?: UseMutationOptions<any, unknown, PatchUserInput, unknown>,
) {
  const clientsService = useClientsService();

  const queryClient = useQueryClient();

  const patchUser = async (input: PatchUserInput) => {
    try {
      return await clientsService.patch('/v1/me', input);
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      const patchUserError = new KovoAddressUpdateError(
        'Error updating user',
      ).setError(error);

      if (error instanceof AxiosError && error.response?.data.data) {
        const data = error.response.data.data;

        if (data.message) {
          patchUserError.setDisplayMessage(data.message);
        }

        if (data.missingComponentTypes) {
          patchUserError.addMetadata({
            missingComponentTypes: data.missingComponentTypes,
          });
        }

        if (data.formattedAddress) {
          patchUserError.addMetadata({
            formattedAddress: data.formattedAddress,
          });
        }
      }

      throw patchUserError;
    }
  };

  const onSuccess = (
    data: any,
    variables: PatchUserInput,
    context: unknown,
  ) => {
    queryClient.invalidateQueries({
      queryKey: [USE_CURRENT_USER_QUERY_KEY],
    });
    if (options?.onSuccess) {
      options.onSuccess(data, variables, context);
    }
  };

  return useMutation<any, KovoAddressUpdateError, any>(patchUser, {
    ...(options && { ...options }),
    onSuccess,
  });
}

export default usePatchUser;
