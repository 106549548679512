import { Typography } from '@mui/material';

import ContractDialog from 'components/ContractDialog';
import { KOVO_BLUE, theme } from 'context/ThemeProvider';
import useGetApplications from 'hooks/queries/useGetApplications';
import { CHARGE_ACCOUNT_PRODUCT_ID } from 'types/schemas';

const ChargeAccountContractDownload: React.FC = () => {
  const { data } = useGetApplications();

  if (!data) {
    return null;
  }

  const { chargeAccountApplication } = data;

  if (
    !chargeAccountApplication ||
    chargeAccountApplication.status !== 'completed'
  ) {
    return null;
  }

  return (
    <ContractDialog productId={CHARGE_ACCOUNT_PRODUCT_ID}>
      <Typography marginTop={theme.spacing(2)} color={KOVO_BLUE}>
        Revolving Credit Agreement
      </Typography>
    </ContractDialog>
  );
};

export default ChargeAccountContractDownload;
