import { GREY, theme } from 'context/ThemeProvider';
import { LoanAccountStatus } from 'hooks/useCheckoutStatusV2';

export const loanAccountStatusToLabelMap: {
  [key in LoanAccountStatus]: {
    label: string;
    color: string;
  };
} = {
  creating: { label: 'Creating', color: GREY },
  processing: { label: 'Payment processing', color: GREY },
  active: { label: 'Current', color: theme.palette.success.main },
  past_due: { label: 'Past due', color: theme.palette.error.main },
  closed: { label: 'Closed', color: theme.palette.error.main },
};
