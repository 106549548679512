import { useRifm } from 'rifm';

import TextField from 'components/shared/TextField';
import { parseDigits } from 'helpers/utils';

interface Props {
  name: string;
  label: string;
  value: string;
  error?: boolean;
  errorText?: string;
  setFieldValue: (field: string, value: string) => void;
  onBlur: (e: React.ChangeEvent<any>) => void;
  fullWidth?: boolean;
}

const formatInteger = (string: string) => {
  const parsed = parseDigits(string);
  const number = Number.parseInt(parsed, 10);
  if (Number.isNaN(number)) {
    return '';
  }
  return number.toLocaleString('en');
};

export const formatCurrency = (string: string) =>
  string ? '$' + formatInteger(string) : '';

const CurrencyInput: React.FC<Props> = ({
  name,
  label,
  value,
  error,
  errorText,
  setFieldValue,
  onBlur,
  fullWidth,
}) => {
  const rifm = useRifm({
    value,
    onChange: (value: string) => {
      setFieldValue(name, value);
    },
    format: formatCurrency,
  });

  return (
    <TextField
      type="tel"
      pattern="[0-9\$,]*"
      name={name}
      label={label}
      value={rifm.value}
      error={error}
      errorText={errorText}
      onChange={rifm.onChange}
      onBlur={onBlur}
      fullWidth={fullWidth}
    />
  );
};

export default CurrencyInput;
