import { Link as MuiLink, Typography } from '@mui/material';
import { Link, Redirect } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';

import OnboardingPage from 'components/OnboardingPage';
import ProductBenefitsTable from 'components/ProductBenefitsTable';
import { ProductBenefit } from 'components/ProductBenefitsTable/ProductBenefitsTable';
import UpfrontPayment from 'components/UpfrontPayment';
import config from 'config';
import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';

const UPFRONT_PAYMENT_BENEFITS: Array<ProductBenefit> = [
  {
    title: 'Unlimited Referrals',
    subtitle: 'Get $30 for every friend you refer',
  },
  {
    title: 'Personal Growth Tools',
    subtitle: 'Lifetime access to support your journey',
  },
];

const UpfrontPaymentPage: React.FC = () => {
  const { data, isFetching } = useApplicationStatus();

  if (!isFetching && data?.applicationStatus !== 'new') {
    return <Redirect to="/" />;
  }

  return (
    <OnboardingPage supportSubject="upfront-payment">
      <Typography variant="h1" marginBottom={theme.spacing(3)}>
        Upfront payment
      </Typography>

      <ProductBenefitsTable
        benefits={UPFRONT_PAYMENT_BENEFITS}
        totalString="$240.00"
        totalExplainer={
          <>
            <Typography variant="footnote" marginBottom={theme.spacing(1.5)}>
              Note: this $240 payment does not build credit.
            </Typography>
            <Typography variant="footnote">
              For credit building,{' '}
              <MuiLink component={Link} to="/apply">
                apply to the Kovo Installment Credit Account
              </MuiLink>{' '}
              . That will enable you to build credit history while paying
              $10/month over 24 months.
            </Typography>
          </>
        }
      />

      <StripeProvider apiKey={config.STRIPE_KEY}>
        <Elements>
          <UpfrontPayment />
        </Elements>
      </StripeProvider>
    </OnboardingPage>
  );
};

export default UpfrontPaymentPage;
