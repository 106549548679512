import { useRifm } from 'rifm';

import TextField from 'components/shared/TextField';
import { parseDigits } from 'helpers/utils';

interface Props {
  name: string;
  label: string;
  value: string;
  error?: boolean;
  errorText?: string;
  setFieldValue: (field: string, value: string) => void;
  onBlur: (e: React.ChangeEvent<any>) => void;
  fullWidth?: boolean;
}

const formatSsn = (ssn: string): string => {
  const digits = parseDigits(ssn);
  const chars = digits.split('');
  const formatted = chars
    .reduce(
      (prev, current, index) =>
        index === 3 || index === 5 ? `${prev}-${current}` : `${prev}${current}`,
      '',
    )
    .substring(0, 11);

  return formatted;
};

const formatSsnWithAppend = (ssn: string): string => {
  const formatted = formatSsn(ssn);

  if (ssn.endsWith('-')) {
    if (formatted.length === 3 || formatted.length === 6) {
      return `${formatted}-`;
    }
  }

  return formatted;
};

const appendDash = (value: string): string =>
  value.length === 3 || value.length === 6 ? `${value}-` : value;

const SsnInput: React.FC<Props> = ({
  name,
  label,
  value,
  error,
  errorText,
  setFieldValue,
  onBlur,
  fullWidth,
}) => {
  const rifm = useRifm({
    value,
    onChange: (value: string) => {
      setFieldValue(name, value);
    },
    mask: 11 <= value.length,
    format: formatSsnWithAppend,
    append: appendDash,
  });

  return (
    <TextField
      type="tel"
      pattern="[0-9\-]*"
      name={name}
      label={label}
      value={rifm.value}
      error={error}
      errorText={errorText}
      onChange={rifm.onChange}
      onBlur={onBlur}
      fullWidth={fullWidth}
    />
  );
};

export default SsnInput;
