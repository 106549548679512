import { Box, ButtonBase, Card, CardContent, Typography } from '@mui/material';

import { GREY, KOVO_LIGHT_GREEN, theme } from 'context/ThemeProvider';

import CheckmarkCircle from 'assets/img/icons/checkmark-circle-green.svg';
import ChevronDown from 'assets/img/icons/chevron-down.svg';
import { trackProductAnalytics } from 'libs/productAnalyticsTracking';
import { useState } from 'react';

export type ProductBenefit = {
  title: string;
  subtitle: string;
};

type ProductBenefitsTableProps = {
  benefits: Array<ProductBenefit>;
  totalString: string;
  totalExplainer: string | React.ReactNode;
};

export const ProductBenefitsTable = ({
  benefits,
  totalString,
  totalExplainer,
}: ProductBenefitsTableProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const benefitsToShow = isExpanded ? benefits : benefits.slice(0, 3);

  return (
    <Card
      elevation={0}
      sx={{
        marginBottom: theme.spacing(3),
        padding: 0,
      }}
    >
      <CardContent>
        <Box
          sx={{
            backgroundColor: GREY,
            padding: theme.spacing(1.75, 2),
          }}
        >
          {benefitsToShow.map(({ title, subtitle }, index) => (
            <Box
              sx={{
                marginBottom:
                  index === benefitsToShow.length - 1 ? 0 : theme.spacing(1.5),
              }}
              key={title}
            >
              <Typography
                display="flex"
                sx={{
                  fontWeight: 700,
                }}
              >
                {title}
                <Box
                  component="img"
                  src={CheckmarkCircle}
                  alt=""
                  marginLeft="auto"
                />
              </Typography>
              <Typography variant="footnote">{subtitle}</Typography>
            </Box>
          ))}

          {benefits.length > 3 && !isExpanded && (
            <Box
              sx={{
                marginTop: theme.spacing(2.5),
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ButtonBase
                onClick={() => {
                  trackProductAnalytics({
                    namespace: 'ui',
                    event: 'product-benefits-table.view-more.clicked',
                  });
                  setIsExpanded(true);
                }}
              >
                <Typography variant="footnote" display="flex">
                  View More Benefits
                  <Box
                    component="img"
                    src={ChevronDown}
                    alt=""
                    marginLeft={theme.spacing(0.5)}
                  />
                </Typography>
              </ButtonBase>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            backgroundColor: KOVO_LIGHT_GREEN,
            padding: theme.spacing(1.75, 2),
          }}
        >
          <Typography
            display="flex"
            sx={{
              marginBottom: theme.spacing(1.5),
            }}
          >
            <strong>Total</strong>
            <Box component="strong" marginLeft="auto">
              {totalString}
            </Box>
          </Typography>
          {typeof totalExplainer === 'string' && (
            <Typography variant="footnote">{totalExplainer}</Typography>
          )}
          {typeof totalExplainer !== 'string' && totalExplainer}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductBenefitsTable;
