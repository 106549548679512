import { API } from 'aws-amplify';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { KovoError } from './KovoError';

interface ClientsServiceHeaderOptions {
  userId?: string;
  userPoolUserId?: string;
}

export class ClientsService {
  api: typeof API;
  apiKey: string;
  userId: string;
  userPoolUserId: string;

  constructor(
    api: typeof API,
    apiKey: string,
    userId: string,
    userPoolUserId: string,
  ) {
    this.api = api;
    this.userId = userId;
    this.apiKey = apiKey;
    this.userPoolUserId = userPoolUserId;
  }

  get<Response extends object = object>(endpoint: string): Promise<Response> {
    return this.api.get('clientsService', endpoint, {
      headers: {
        ...this.headers,
      },
    });
  }

  patch<Response extends object = object>(
    endpoint: string,
    body: object,
  ): Promise<Response> {
    return this.api.patch('clientsService', endpoint, {
      body,
      headers: {
        ...this.headers,
      },
    });
  }

  post<Response extends object = object>(
    endpoint: string,
    body: object,
    options: {
      public?: boolean;
    } = {},
  ): Promise<Response> {
    return this.api.post('clientsService', endpoint, {
      body,
      headers: {
        ...(options.public ? {} : this.headers),
      },
    });
  }

  get headers() {
    if (!this.ready) {
      throw new KovoError('Not authenticated').setLogLevel('warn');
    }

    const { headers } = amplifyRequestContext(
      this.userId,
      this.userPoolUserId,
      {
        'x-api-key': this.apiKey,
        'x-user-id': this.userId,
        'content-type': 'application/json',
      },
    );

    return headers;
  }

  setHeaderOptions(options: ClientsServiceHeaderOptions) {
    this.userId = options.userId ?? this.userId;
    this.userPoolUserId = options.userPoolUserId ?? this.userPoolUserId;

    return this;
  }

  /*
   * This ready check here is required for running locally because we don't have API Gateway in between the client and apps-service-auth, so we have to make sure that the user pool user ID is set.
   */
  get ready() {
    return import.meta.env.STORYBOOK === 'true' || Boolean(this.userPoolUserId);
  }
}
