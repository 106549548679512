import { KovoError } from 'libs/KovoError';
import { Account } from 'types/schemas';

/**
 * Calculate the amount that the user owes in dollars.
 */
export const convertCentsToDollars = (amountDue: number) => {
  if (amountDue > 0) {
    return amountDue / 100;
  }

  return 0;
};

/**
 * From dollars, calculate how many potential payment options the user can choose for a partial payment
 *
 * For $30, the number of options should be 3
 */
export const calculatePastDuePaymentOptionsLength = (
  pastDueAmountDollars: number,
) => {
  return Math.floor(pastDueAmountDollars / 10);
};

/**
 * For options length of 3, the result should be [10, 20]
 */
export const calculatePastDuePaymentOptions = (
  pastDuePaymentOptionsLength: number,
) => {
  return Array.from(
    { length: pastDuePaymentOptionsLength - 1 },
    (_, i) => (i + 1) * 10,
  );
};

export const calculateCreditLineAmountPastDueDollars = (account: Account) => {
  if (account.balance === 0) {
    return 0;
  }

  let amountDueCents = 0;

  /**
   * If there is a schedule item that is upcoming, subtract the expected amount due from the balance.
   */
  if (
    account.currentSchedule &&
    account.currentSchedule.status === 'in_progress'
  ) {
    amountDueCents =
      account.balance + account.currentSchedule.expectedAmountDue;

    /**
     * If there is no upcoming schedule for a credit line account, then the full balance is due
     */
  } else {
    amountDueCents = account.balance;
  }

  return convertCentsToDollars(amountDueCents);
};

export const calculateInstallmentsAmountPastDueDollars = (account: Account) => {
  let amountDueCents = 0;
  if (account.currentSchedule) {
    if (
      account.currentSchedule.expectedEndBalance === 0 &&
      account.status === 'past_due'
    ) {
      // final payment is needed for installments for a past due account, so calculate this
      // differently to show the proper final amount

      amountDueCents = account.balance;
    } else {
      // For a normal case, this number should be 0 if the user is on time, and > 0 if the user
      // is late. However, their past due amount does not include the next payment that the
      // schedule is going to run because that payment attempt and billing period is in the future
      amountDueCents =
        account.balance -
        account.currentSchedule.expectedEndBalance +
        account.currentSchedule.expectedAmountDue;
    }
  }

  return convertCentsToDollars(amountDueCents);
};

export const calculateAmountPastDue = (account: Account) => {
  if (account.product.type === 'charge_revolving') {
    return calculateCreditLineAmountPastDueDollars(account);
  } else if (account.product.type === 'loan_installment') {
    return calculateInstallmentsAmountPastDueDollars(account);
  } else {
    throw new KovoError('Account type not supported');
  }
};
