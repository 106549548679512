/**
 * Wrap the given HTML in a div element with the given class.
 * Will also update the CSS to apply to the new class only.
 * Useful when loading HTML content from an API and you want to
 * ensure that the styles don't affect the rest of the page.
 *
 * @param {string} html The HTML content to wrap
 * @param {string} className The class name to use for the wrapping div
 * @return {*}  {string}
 */
export const wrapHtmlBodyInDiv = (html: string, className: string): string => {
  // Parse the HTML content
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const body = doc.querySelector('body');
  // Wrap the body content in a div with class name
  const div = document.createElement('div');
  div.classList.add(className);
  if (body) {
    div.innerHTML = body.innerHTML;
    body.innerHTML = div.outerHTML;
  }

  // Get every style tag
  doc.querySelectorAll('style').forEach((styleTag) => {
    // Update each selector to start with className
    const style = styleTag.innerHTML;
    const updatedStyle = style.replace(
      /([^\n,{}]+)(,(?=[^}]*{)|\s*{)/g,
      `.${className} $1$2`,
    );
    // Add the updated style to the head
    const newStyleTag = document.createElement('style');
    newStyleTag.innerHTML = updatedStyle;
    doc.head.appendChild(newStyleTag);
    // Remove the original style tag
    styleTag.remove();
  });
  return doc.documentElement.outerHTML;
};
