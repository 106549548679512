import { Alert, Box, Link, Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';

import { AppDownloadElement } from 'components/AppDownloadElement';
import Navigation from 'components/Navigation';
import Page from 'components/Page';
import PageLoader from 'components/PageLoader';
import { ROUTES } from 'components/Routes';
import WelcomeDialog from 'components/WelcomeDialog';
import { useAuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';
import { sendMessageToMobileApp } from 'helpers/mobile-app';
import useCurrentUser from 'hooks/queries/useCurrentUser';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { COURSES_STATUSES } from 'pages/CoursesPage';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const HomePage: React.FC = () => {
  const { email } = useAuthContext();
  const { data, isLoading, error } = useApplicationStatus();
  const { data: userData, isLoading: isLoadingUserData } = useCurrentUser();

  useEffect(() => {
    if (isLoading || !data || error || !window.ReactNativeWebView) {
      return;
    }

    const shouldPromptMobileAppForNotifications =
      data.applicationStatus === 'completed';

    if (!shouldPromptMobileAppForNotifications) {
      return;
    }

    sendMessageToMobileApp({
      eventType: 'kovo.webapp.promptNotifications',
    });
  }, [isLoading, data, error]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (error) {
    return (
      <Page hideMenuButton>
        <Alert severity="error">
          There was an error. Please contact{' '}
          <Link
            href="mailto:support@kovocredit.com?subject=Home%20page%20error"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@kovocredit.com
          </Link>
        </Alert>
      </Page>
    );
  }

  if (!data) {
    return null;
  }

  if (data.applicationStatus === 'new') {
    // don't show how it works page on mobile
    return <Redirect to={ROUTES.APPLY} />;
  }

  if (data.applicationStatus === 'detailsNeeded') {
    return <Redirect to="/apply" />;
  }

  if (
    data.applicationStatus === 'kycFailed' || // Deprecated
    data.applicationStatus === 'rejected' || // Deprecated
    data.applicationStatus === 'applicationAdverseAction'
  ) {
    return <Redirect to="/adverse-action-notice" />;
  }

  if (data.applicationStatus === 'applicationRejected') {
    return <Redirect to="/application-rejected" />;
  }

  if (data.applicationStatus === 'kycPending') {
    return <Redirect to="/apply-pending" />;
  }

  if (data.applicationStatus === 'signatureNeeded') {
    return <Redirect to="/esign" />;
  }

  if (data.applicationStatus === 'paymentNeeded') {
    return <Redirect to="/payment" />;
  }

  const shouldShowWelcomeDialog =
    !isLoadingUserData && data.applicationStatus === 'completed';

  if (COURSES_STATUSES.includes(data.applicationStatus)) {
    return (
      <>
        <Helmet>
          <title>Kovo</title>
        </Helmet>
        <Page maxWidth="746px" hideMenuButton showReferralButton>
          {shouldShowWelcomeDialog && (
            <WelcomeDialog
              welcomeCompletedAt={data.applicationDetails?.welcomeCompletedAt}
              appDownloadPromptSeenAt={userData?.appDownloadPromptSeenAt}
            />
          )}
          {data.applicationDetails?.firstName && (
            <Typography variant="h1" textAlign="center">
              {data.applicationDetails.firstName}
            </Typography>
          )}
          {data.applicationDetails?.currentEmail && (
            <Typography
              variant="footnote"
              textAlign="center"
              marginBottom={theme.spacing(3)}
              sx={{
                marginTop: '2px',

                [theme.breakpoints.up('sm')]: {
                  marginTop: '4px',
                },

                [theme.breakpoints.up('md')]: {
                  marginTop: '6px',
                },
              }}
            >
              {email}
            </Typography>
          )}
          <Navigation />
          {!window.ReactNativeWebView && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: theme.spacing(4),

                [theme.breakpoints.up('md')]: {
                  marginTop: theme.spacing(6),
                },
              }}
            >
              <AppDownloadElement />
            </Box>
          )}
        </Page>
      </>
    );
  }

  if (data.applicationStatus === 'closed') {
    return <Redirect to="/closed" />;
  }

  return <Redirect to="/unrecognized-status" />;
};

export default HomePage;
