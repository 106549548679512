import moment from 'moment';
import { useState } from 'react';

import AppReviewDialog from 'components/AppReviewDialog';
import { CREDIT_INCREASE_THRESHOLD } from 'components/CreditScoreInfo/CreditScoreInfo';
import useCurrentUser from 'hooks/queries/useCurrentUser';
import useGetAccounts from 'hooks/queries/useGetAccounts';
import useGetCreditScores from 'hooks/queries/useGetCreditScores';
import useGetMetro2Info from 'hooks/queries/useGetMetro2Info';
import useGetReviews from 'hooks/queries/useGetReviews';
import LeaveReviewButtonView from './LeaveReviewButtonView';

// should show banner when:
// [x] +10 point score increase
// [x] cra-table lookups for all accounts associated with the user
//   [x] PHP is all 0’s and B’s
//   [x] Account Status = 11
// [x] Account status (installment and credit line) = active
// [x] No credit disputes filed (via Sonnet)
// [x] Has not clicked on the 👍 prompt before
// [x] Has not clicked on the 👎 prompt before
// [x] Completed installment onboarding within 3 months
const LeaveReviewButton: React.FC = () => {
  const { data: userData } = useCurrentUser();

  const { data: metro2Info } = useGetMetro2Info();

  const { data: creditScoresData } = useGetCreditScores();

  const { data: accountsData } = useGetAccounts();

  const { data: reviewsData } = useGetReviews();

  const [isOpen, setIsOpen] = useState(false);

  if (
    !userData ||
    !metro2Info ||
    !creditScoresData ||
    !accountsData ||
    !reviewsData
  ) {
    return null;
  }

  if (
    metro2Info.hasDerogatoryMark ||
    metro2Info.hasDisputes ||
    !metro2Info.isAllAccountsActive
  ) {
    return null;
  }

  const { purchasedAt } = userData;
  const isOlderThan3Months = moment().diff(purchasedAt, 'months') > 3;

  if (isOlderThan3Months) {
    return null;
  }

  if (
    !creditScoresData.scoreIncrease ||
    creditScoresData.scoreIncrease < CREDIT_INCREASE_THRESHOLD
  ) {
    return null;
  }

  const isActive = accountsData?.accounts.every(
    ({ status }) => status === 'active',
  );

  if (!isActive) {
    return null;
  }

  if (reviewsData?.reviews.length > 0) {
    return null;
  }

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <AppReviewDialog isOpen={isOpen} onClose={handleClose} />

      <LeaveReviewButtonView handleClick={handleClick} />
    </>
  );
};

export default LeaveReviewButton;
