import { Box, Typography } from '@mui/material';

import { KOVO_GREEN, KOVO_GREY, theme } from 'context/ThemeProvider';

export interface Props {
  activeStep: 1 | 2 | 3 | 4 | 5;
}

const STEPS = [
  'Step 1 of 5',
  'Step 2 of 5',
  'Step 3 of 5',
  'Step 4 of 5',
  'Step 5 of 5',
];

const OnboardingStepIndicator: React.FC<Props> = ({ activeStep }) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {STEPS.map((step, index) => {
          const dotStyles = {
            height: '9px',
            width: '9px',
            borderRadius: '50%',

            ':not(:last-child)': {
              marginRight: theme.spacing(0.5),
            },
          };

          if (index < activeStep - 1) {
            return (
              <Box
                sx={{
                  ...dotStyles,
                  backgroundColor: KOVO_GREEN,
                }}
                key={step}
              />
            );
          }

          if (index === activeStep - 1) {
            return (
              <Box
                sx={{
                  ...dotStyles,
                  height: '14px',
                  width: '14px',
                  backgroundColor: KOVO_GREEN,
                }}
                key={step}
              />
            );
          }

          if (index > activeStep - 1) {
            return (
              <Box
                sx={{ ...dotStyles, backgroundColor: KOVO_GREY, opacity: 0.3 }}
                key={step}
              />
            );
          }

          return null;
        })}
      </Box>

      <Typography
        textAlign="center"
        variant="caption"
        display="block"
        marginTop={theme.spacing(0.5)}
      >
        {STEPS[activeStep - 1]}
      </Typography>
    </Box>
  );
};

export default OnboardingStepIndicator;
