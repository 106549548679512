import { Box, useMediaQuery } from '@mui/material';
import { theme } from 'context/ThemeProvider';

import AppStoreIcon from 'assets/img/app-download/app-store.svg';
import PlayStoreIcon from 'assets/img/app-download/google-play.svg';
import QrCodeIcon from 'assets/img/app-download/qr-code.svg';
import ScanArrowIndicatorIcon from 'assets/img/app-download/scan-arrow-indicator.svg';
import {
  APP_STORE_URL,
  PLAY_STORE_URL,
  getPlatformUserAgent,
} from 'helpers/mobile-app';

const AppDownloadElement = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const imageElement = () => {
    if (isMobile && getPlatformUserAgent() === 'android') {
      return <PlayStoreButton maxWidth="205px" />;
    } else if (isMobile && getPlatformUserAgent() === 'ios') {
      return <AppStoreButton maxWidth="180px" />;
    } else if (isMobile && getPlatformUserAgent() === 'unknown') {
      return (
        <Box display="flex" gap="15px">
          <AppStoreButton maxWidth="180px" />
          <PlayStoreButton maxWidth="205px" />
        </Box>
      );
    } else {
      return <Box component="img" alt="QR Code Download" src={QrCodeIcon} />;
    }
  };

  return isMobile ? (
    <Box
      sx={{
        marginTop: theme.spacing(1),

        [theme.breakpoints.up('sm')]: {
          marginTop: theme.spacing(2.5),
        },
      }}
    >
      {imageElement()}
    </Box>
  ) : (
    <Box
      position="relative"
      sx={{
        marginTop: theme.spacing(-2),
      }}
    >
      {imageElement()}
      <Box
        component="img"
        alt="Scan Arrow Indicator"
        src={ScanArrowIndicatorIcon}
        sx={{
          top: '35%',
          position: 'absolute',
          height: '95px',
        }}
      />
    </Box>
  );
};

export const AppStoreButton = ({ maxWidth }: { maxWidth?: string }) => (
  <Box component="a" href={APP_STORE_URL} target="_blank" rel="noreferrer">
    <Box
      width="100%"
      maxWidth={maxWidth}
      component="img"
      alt="App Store Download"
      src={AppStoreIcon}
    />
  </Box>
);

export const PlayStoreButton = ({ maxWidth }: { maxWidth?: string }) => (
  <Box component="a" href={PLAY_STORE_URL} target="_blank" rel="noreferrer">
    <Box
      width="100%"
      maxWidth={maxWidth}
      component="img"
      alt="Google Play Download"
      src={PlayStoreIcon}
    />
  </Box>
);

export default AppDownloadElement;
