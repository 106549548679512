import { API } from 'aws-amplify';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { KovoError } from 'libs/KovoError';
import { EntityMetadata, PaymentMethod } from 'types/schemas';

export type TransactionTriggers = 'manual' | 'customer_support' | 'charge';

type TransactionTriggersSystem =
  | 'autopay'
  | 'autopay_retry'
  | 'principal'
  | 'charge';

export type TransactionStatuses =
  | 'processing'
  | 'pre_authorized'
  | 'failed'
  | 'succeeded';

export type ClientTransaction = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  effectiveAt: Date;
  accountId: string;
  paymentMethodId: string | null;
  integrationId: string | null;
  integrationSecondaryId: string | null;
  integrationSourceId: string | null;
  integrationResponseCode?: string;
  integrationResponseDescription?: string;
  responseSummary?: string;
  amount: number;
  trigger: TransactionTriggers | TransactionTriggersSystem;
  status: TransactionStatuses;
  captured: boolean;
  refunded: boolean;
  shouldCapture: boolean;
  shouldRefund: boolean;
  adjustment: boolean;
  scheduleItemId: string | null;
  relatedTransactionId: string | null;
  externalId: string | null;
  metadata: EntityMetadata;
  paymentMethod?: PaymentMethod;
};
type LockKeys = 'payment';
type AdminTransaction = ClientTransaction & {
  scheduleKey: string | null;
  lockKey: LockKeys | null;
  effectiveAtAdjusted: Date | null;
};

type GetTransactionsRes = {
  transactions: AdminTransaction[];
};

export const getTransactions = async (
  accountId: string,
  identityId: string,
  username: string,
): Promise<GetTransactionsRes> => {
  try {
    const res = await API.get(
      'clientsService',
      `/v1/purchases/${accountId}/transactions`,
      {
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
          }).headers,
        },
      },
    );

    return res;
  } catch (error) {
    if (error instanceof KovoError) {
      throw error;
    }

    throw new KovoError('Failed to fetch transactions', { error });
  }
};

function useGetTransactions(accountId?: string) {
  const { identityId, username } = useContext(AuthContext);

  return useQuery<GetTransactionsRes, KovoError>(
    ['transactions', accountId, identityId],
    () => getTransactions(accountId!, identityId, username),
    {
      refetchInterval: (data) => {
        const processing = data?.transactions.some(
          (trx) => trx.status === 'processing',
        );

        return processing ? 500 : false;
      },
      enabled: !!accountId,
    },
  );
}

export default useGetTransactions;
