import { TabContext, TabPanel } from '@mui/lab';
import {
  Box,
  InputLabel,
  Link as MuiLink,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import { DARK_BLUE, GREY, KOVO_GREEN, theme } from 'context/ThemeProvider';
import { FC } from 'react';
import { CatchupPaymentPartialPaymentSelect } from '.';
import { useCatchupPaymentContext } from './CatchupPaymentProvider';

const CatchupPaymentAmountTabs: FC = () => {
  const { tab, setTab, catchupAmount } = useCatchupPaymentContext();

  return (
    <TabContext value={tab}>
      <Tabs
        value={tab}
        onChange={(_event, newValue) => setTab(newValue)}
        sx={{
          backgroundColor: GREY,
          borderRadius: '100px',
          marginBottom: theme.spacing(3),
        }}
        TabIndicatorProps={{
          style: {
            height: '100%',
            borderRadius: '100px',
            backgroundColor: tab === '0' ? KOVO_GREEN : DARK_BLUE,
          },
        }}
      >
        {['Full Catch Up', 'Partial Payment'].map((buttonText, i) => (
          <Tab
            label={buttonText}
            value={i.toString()}
            sx={{
              textTransform: 'none',
              flex: '1 1 50%',
              zIndex: 99,
              fontSize: '16px',
              letterSpacing: 0,

              '&:nth-of-type(2).Mui-selected': {
                color: theme.palette.common.white,
              },
            }}
            key={buttonText}
            disableRipple
          />
        ))}
      </Tabs>

      <TabPanel value="0" sx={{ padding: 0 }}>
        <Typography marginBottom={theme.spacing(1)} textAlign="center">
          Catch Up Payment:
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(3),
          }}
        >
          <Box
            sx={{
              border: `1px solid ${KOVO_GREEN}`,
              borderRadius: '8px',
              padding: '9px 15px',
            }}
          >
            <Typography variant="h4">${catchupAmount}.00</Typography>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value="1" sx={{ padding: 0 }}>
        <InputLabel
          htmlFor="amount"
          sx={{
            textAlign: 'center',
            marginBottom: theme.spacing(1),
            top: 0,
            lineHeight: '24px',
            color: DARK_BLUE,
          }}
        >
          Choose amount:
        </InputLabel>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(3),
          }}
        >
          <CatchupPaymentPartialPaymentSelect />
        </Box>

        <Typography variant="footnote" marginBottom={theme.spacing(3)}>
          <Box component="strong">Note</Box>: A partial payment may still leave
          a past due amount on your account. We recommend catching up in full by
          selecting the{' '}
          <MuiLink
            component="button"
            variant="footnote"
            onClick={() => setTab('0')}
            sx={{ verticalAlign: 'baseline' }}
          >
            Full Catch Up
          </MuiLink>{' '}
          option.
        </Typography>
      </TabPanel>
    </TabContext>
  );
};

export default CatchupPaymentAmountTabs;
