import { LoadingButton } from '@mui/lab';
import { Alert, Link as MuiLink, Skeleton, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import VerificationCodeInput from 'components/VerificationCodeInput';
import { theme } from 'context/ThemeProvider';
import useSavePhoneNumber from 'hooks/mutations/useSavePhoneNumber';
import useVerifyPhoneNumber from 'hooks/mutations/useVerifyPhoneNumber';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { KovoError } from 'libs/KovoError';
import { trackProductAnalytics } from 'libs/productAnalyticsTracking';

interface FormValues {
  verificationCode: string;
}

interface Props {
  goBack?: () => void;
  onSuccess: () => void;
}

const phoneUtil = PhoneNumberUtil.getInstance();

const PhoneNumberVerificationForm: React.FC<Props> = ({
  goBack,
  onSuccess,
}) => {
  const [resendError, setResendError] = useState<KovoError | null>(null);

  const { data: applicationData, isLoading: isLoadingApplication } =
    useApplicationStatus();

  const {
    mutate: verifyPhoneNumber,
    isLoading,
    error: verifyPhoneNumberError,
    reset,
  } = useVerifyPhoneNumber({ onSuccess });

  const handleResendSuccess = () => {
    reset();
  };

  const handleResendError = (error: KovoError) => {
    reset();
    setResendError(error);
  };

  const { mutate: savePhoneNumber } = useSavePhoneNumber({
    onSuccess: handleResendSuccess,
    onError: handleResendError,
  });

  const onSubmit = ({ verificationCode }: FormValues) => {
    verifyPhoneNumber(verificationCode);
  };

  const { touched, errors, values, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        verificationCode: '',
      },
      validationSchema: yup.object().shape({
        verificationCode: yup.string().required(),
      }),
      onSubmit,
      validateOnBlur: false,
    });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // only allow numerical values
    if (e.target.validity.valid) {
      setFieldValue('verificationCode', e.target.value);
    }
  };

  const onGoBackClicked = () => {
    trackProductAnalytics({
      namespace: 'ui',
      event: 'signup.verify-phone-number-go-back.clicked',
    });

    goBack?.();
  };

  const phoneNumberParsed = applicationData?.applicationDetails
    ?.phoneNumberUnverified
    ? phoneUtil.parseAndKeepRawInput(
        applicationData?.applicationDetails?.phoneNumberUnverified,
        'US',
      )
    : null;
  const formattedPhoneNumber = phoneNumberParsed
    ? phoneUtil
        .format(phoneNumberParsed, PhoneNumberFormat.INTERNATIONAL)
        .replace('+1 ', '')
    : null;

  return (
    <>
      <Typography marginBottom={theme.spacing(1)}>
        Mobile number:{' '}
        {isLoadingApplication ? (
          <Skeleton width={116} sx={{ display: 'inline-block' }} />
        ) : (
          <strong>{formattedPhoneNumber}</strong>
        )}
      </Typography>

      <Typography variant="body2" marginBottom={theme.spacing(3)}>
        Need to update your mobile number?{' '}
        {goBack ? (
          <MuiLink component="button" type="button" onClick={onGoBackClicked}>
            Go back
          </MuiLink>
        ) : (
          <MuiLink
            component={Link}
            to="/phone-number"
            onClick={onGoBackClicked}
          >
            Go back
          </MuiLink>
        )}
        .
      </Typography>

      {verifyPhoneNumberError && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(2) }}
        >
          {verifyPhoneNumberError.displayMessage}
        </Alert>
      )}

      {resendError && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(2) }}
        >
          {resendError.displayMessage}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <VerificationCodeInput
          type="tel"
          pattern="[0-9]*"
          name="verificationCode"
          label="Enter code"
          value={values.verificationCode}
          error={!!(touched.verificationCode && errors.verificationCode)}
          onChange={onChange}
          onBlur={handleBlur}
          fullWidth
          autoComplete="one-time-code"
          autoFocus
          maxLength={4}
          onResendCode={async () => {
            if (!applicationData?.applicationDetails?.phoneNumberUnverified) {
              return;
            }
            return savePhoneNumber(
              applicationData?.applicationDetails?.phoneNumberUnverified,
            );
          }}
          recentlySentLabel="Code sent. Check your phone."
          onResendCodeSuccess={handleResendSuccess}
          onResendCodeError={handleResendError}
        />

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
          sx={{ marginTop: theme.spacing(5) }}
        >
          Verify
        </LoadingButton>
      </form>
    </>
  );
};

export default PhoneNumberVerificationForm;
