import { Loader } from '@googlemaps/js-api-loader';
import { Autocomplete, TextField } from '@mui/material';
import { Ref, useEffect } from 'react';
import { useQuery } from 'react-query';

import { theme } from 'context/ThemeProvider';
import useDebounce from 'hooks/useDebounce';

interface Props {
  name: string;
  label: string;
  value: string;
  error?: boolean;
  errorText?: string;
  setFieldValue: (field: string, value: string) => void;
  onBlur: (e: React.ChangeEvent<any>) => void;
  onAddressSelect: (place: any) => void;
  addressInputRef: Ref<HTMLInputElement>;
  fullWidth?: boolean;
}

let autocompleteService: google.maps.places.AutocompleteService;
let geocoder: google.maps.Geocoder;

const parseGeocoderResult = (
  result: google.maps.GeocoderResult,
  selectedAddress: google.maps.places.AutocompletePrediction | null,
) => {
  const streetNumber =
    result.address_components.find(({ types }) =>
      types.includes('street_number'),
    )?.long_name ||
    // Sometimes Google doesn't return street_number field, but address is correct in the auto-completer
    // Use the value from autocomplete to get the street address
    selectedAddress?.terms[0].value ||
    '';

  const streetName = result.address_components.find(({ types }) =>
    types.includes('route'),
  )?.short_name;
  const city = result.address_components.find(({ types }) =>
    types.includes('locality'),
  )?.long_name;
  const state = result.address_components.find(({ types }) =>
    types.includes('administrative_area_level_1'),
  )?.short_name;
  const zipcode = result.address_components.find(({ types }) =>
    types.includes('postal_code'),
  )?.long_name;

  return {
    address: `${streetNumber} ${streetName}`,
    city,
    state,
    zipcode,
  };
};

const AddressAutocomplete: React.FC<Props> = ({
  name,
  label,
  value,
  error,
  errorText,
  setFieldValue,
  onBlur,
  onAddressSelect,
  addressInputRef,
  fullWidth,
}) => {
  const debouncedValue = useDebounce(value, 500);

  const geocodeAddress = async (
    address: google.maps.places.AutocompletePrediction,
  ) => {
    const result = (await geocoder.geocode({ placeId: address.place_id }))
      .results[0];

    return result;
  };

  const handleChange = async (
    address: google.maps.places.AutocompletePrediction | null,
  ) => {
    if (!address) {
      return onAddressSelect({});
    }

    if (!geocoder || !('geocode' in geocoder)) {
      return;
    }

    const geocodedAddress = await geocodeAddress(address);
    onAddressSelect(parseGeocoderResult(geocodedAddress, address));
  };

  const getPlacePredictions = (): Promise<
    google.maps.places.AutocompletePrediction[]
  > => {
    if (!value) return Promise.resolve([]);

    return new Promise((resolve, reject) => {
      autocompleteService?.getPlacePredictions(
        {
          input: value,
          componentRestrictions: { country: 'us' },
          types: ['address'],
        },
        (
          predictions: google.maps.places.AutocompletePrediction[] | null,
          status: google.maps.places.PlacesServiceStatus,
        ) => {
          if (
            status !== google.maps.places.PlacesServiceStatus.OK ||
            !predictions
          ) {
            return reject(status);
          }

          return resolve(predictions);
        },
      );
    });
  };

  const { data } = useQuery(['address', debouncedValue], getPlacePredictions);

  useEffect(() => {
    if (!import.meta.env.VITE_GOOGLE_MAPS_API_KEY) {
      console.error('VITE_GOOGLE_MAPS_API_KEY is undefined');
      return;
    }

    const loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
      libraries: ['places'],
    });
    loader
      .load()
      .then((google) => {
        autocompleteService = new google.maps.places.AutocompleteService();
        geocoder = new google.maps.Geocoder();
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Autocomplete
      id={name}
      inputValue={value}
      onInputChange={(_, value: string, reason) => {
        if (reason !== 'reset') {
          setFieldValue(name, value);
        }
      }}
      options={data || []}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      onChange={(
        _,
        address: google.maps.places.AutocompletePrediction | null,
      ) => {
        handleChange(address);
      }}
      forcePopupIcon={false}
      sx={{ position: 'relative' }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="filled"
            label={label}
            error={error}
            helperText={error && errorText}
            onBlur={onBlur}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'some-unexpected-value', // turn this off by passing "unexpected" value
            }}
            FormHelperTextProps={{
              sx: {
                position: 'absolute',
                top: '100%',
                margin: 0,
                marginLeft: theme.spacing(1.5),
                marginTop: theme.spacing(0.25),
              },
            }}
            inputRef={addressInputRef}
            fullWidth={fullWidth}
          />
        );
      }}
    />
  );
};

export default AddressAutocomplete;
