import {
  Box,
  List,
  ListItem,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import OnboardingPage from 'components/OnboardingPage';
import { theme } from 'context/ThemeProvider';
import { Helmet } from 'react-helmet';

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <Box
      style={{
        overflowX: 'hidden',
      }}
    >
      <Helmet>
        <title>Kovo Privacy Policy</title>
      </Helmet>
      <OnboardingPage supportSubject="Privacy policy">
        <Typography marginBottom={theme.spacing(4)} variant="h1">
          Kovo Privacy Policy & Notice
        </Typography>
        <Typography marginBottom={theme.spacing(3)} variant="body1">
          November 23, 2023
        </Typography>
        <Box display="flex" flexDirection="column" gap={theme.spacing(3)}>
          <Typography variant="body1">
            Thank you for visiting the Kovo Inc. ("Kovo") website
            (kovocredit.com) (the "Site"), contacting Kovo, and/or using any
            Kovo services (the "Services"). This Privacy Policy is intended to
            describe how Kovo handles information that you provide, or that we
            learn about the individuals who: visit our website, contact us by
            mail, email or telephone or in person, or who provide us with
            information through any other means. We recommend that you carefully
            review this Policy before providing us with any information. By
            accessing and using this Site, you agree to this Privacy Policy and
            our{' '}
            <MuiLink
              href="/legal/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </MuiLink>
            .
          </Typography>
          <Typography variant="body1">
            BY VISITING OR USING THE SERVICES, YOU EXPRESSLY CONSENT TO THE
            PROCESSING OF YOUR PERSONAL INFORMATION ACCORDING TO THIS PRIVACY
            POLICY. IF YOU DO NOT AGREE WITH OUR POLICIES AND PRACTICES, YOUR
            CHOICE IS TO NOT USE THE SERVICES.
          </Typography>

          <Typography variant="h2">
            What Information does Kovo collect?
          </Typography>
          <Typography variant="h4">Registration Information</Typography>
          <Typography variant="body1">
            When you register to create an account or use the Services, you may
            voluntarily provide us with certain information that can be used to
            contact and identify you. The information we may collect includes,
            among other things, your name, mailing address, e-mail address,
            telephone or wireless number, username, password, information about
            your interests and preferences and any other information you provide
            to us (“Registration Information”).
          </Typography>
          <Typography variant="h4">Financial Information</Typography>
          <Typography variant="body1">
            When you apply for an account you may provide us with sensitive
            financial or other information that is necessary to complete the
            agreement process. This information may include, among other things,
            your social security number, date of birth, bank account
            information, credit or debit account information, current credit
            information, information about your income (“Financial Information”)
            as well as information about your household and other information
            that will be used to determine eligibility and terms of your
            account. During your use of the Service, we may also obtain
            information about you such as credit history data and score updates
            (“Financial Information”). For more information on how we collect,
            use, store, and disclose your social security number, please review
            Social Security Number Protection located under our{' '}
            <MuiLink
              href="/legal/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </MuiLink>
            .
          </Typography>
          <Typography variant="h4">
            Information Automatically Collected through use of the Site
          </Typography>
          <Typography variant="body1">
            Like most website operators, Kovo collects non-personally
            identifying information of the sort that web browsers and servers
            typically make available, such as the browser type, language
            preference, referring site, and the date and time of each visitor
            request when visitors use the Services. Kovo’s purpose in collecting
            non-personally identifying information is to better understand how
            Kovo’s visitors use the Services. From time to time, Kovo may
            release non-personally identifying information in the aggregate,
            e.g., by publishing a report on trends in the usage of its Services.
            Kovo also collects potentially personally identifying information
            like Internet Protocol (IP) addresses and may collect statistics
            about the behavior of visitors to the Services. For instance, Kovo
            may monitor the Services to help identify spam. We also may use
            these technologies to collect information about your online
            activities over time and across third-party website or other online
            services (behavioral tracking). In addition to log data, we may also
            collect information about the device you’re using to access the
            Services, including the type of device, the operating system, device
            settings, device identifiers and crash data. Whether we collect some
            or all of this information often depends on what type of device
            you’re using and its settings. To learn more about the information
            your device makes available to us, please review the policies of
            your device manufacturer or software provider. We do not collect
            personal information automatically, but we may tie this information
            to personal information about you that we collect from other sources
            or you provide to us. When using the Site, Kovo will also collect
            information including your IP address, device, browser, geolocation
            data, and your activity on the Site. In addition, we may use third
            party services including but not limited to Google Analytics that
            collect, monitor and analyze this type of information in order to
            increase the Services’ functionality. These third-party service
            providers have their own privacy policies addressing how they use
            such information.
          </Typography>
          <Typography variant="h4">
            Information you provide about others
          </Typography>
          <Typography variant="body1">
            Sometimes you may provide contact information about others, for
            example a friend that you might refer to sign up for our Services.
          </Typography>
          <Typography variant="h4">
            Information received from third parties
          </Typography>
          <Typography variant="body1">
            We may collect Personal Information from third parties such as
            referral partners and service providers. At your direction, we may
            receive credit scores from consumer reporting agencies or identity
            protection information from third-party service providers.
          </Typography>

          <Typography variant="h2">Use of Personal Information</Typography>
          <Typography variant="body1">
            We use your Personal Information (What Information Does Kovo Collect
            collectively) to provide you with information or services you
            request, to inform you about other information and services we think
            will be of interest to you, to facilitate your use and our operation
            of our website, and to improve our products and services. Here are
            some examples of ways in which we use your personal information:
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              Creating and maintaining your login information on our site
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Contacting you regarding issues with your account or providing you
              with support
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Servicing and managing your account activities
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Authenticating your identity Sending you information about new
              product offerings
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Providing you with personalized advertising on our Services based
              on your online activity
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Providing you with account updates which can include credit scores
              or identity protection information
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Verifying your eligibility to participate in offers and rewards
              programs
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Monitoring, improving, and conducting analytics on our Services
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Interacting with our virtual assistant{' '}
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Detecting security incidents, and protecting against illegal
              activity
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Operating and improving our business{' '}
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Complying with the law
            </ListItem>
          </List>

          <Typography variant="h2">Sharing of Information</Typography>
          <Typography variant="body1">
            We may disclose your Personal Information to the following:
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              Wholly-owned subsidiaries
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Service Providers who assist or support our business operations
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Credit reporting agencies
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              For internal marketing, delivery of emails, including promotional,
              marketing and transactional materials, delivery of other
              promotional materials, including via postal mailers, SMS and text
              messaging, and online and social media promotions and advertising.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Law enforcement, applicable regulators, or in response to a
              subpoena or judicial request
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              For fraud prevention and security purposes
            </ListItem>
          </List>

          <Typography variant="h2">
            Other Ways We May Share your Personal Information
          </Typography>
          <Typography variant="body1">
            We may disclose non-personally identifiable aggregated information
            about our users without restriction.
          </Typography>
          <Typography variant="body1">
            In addition, in some cases we may choose to buy or sell assets. In
            these types of transactions, user information is typically one of
            the business assets that is transferred. Moreover, if Kovo or
            substantially all of its assets were acquired, or in the unlikely
            event that Kovo goes out of business or enters bankruptcy, user
            information would be one of the assets that is transferred or
            acquired by a third party. You acknowledge that such transfers may
            occur, and that any acquirer of Kovo may continue to use your
            personal and non-personal information only as set forth in this
            Policy. Otherwise, we will not rent or sell potentially personally
            identifying information to anyone.
          </Typography>
          <Typography variant="body1">
            We may also disclose your personally identifying information if you
            expressly consent to the disclosure.
          </Typography>

          <Typography variant="h2">Security</Typography>
          <Typography variant="body1">
            We are committed to protecting your information. We regularly test
            our facilities and use a variety of security technologies and
            procedures to help protect your personal information from
            unauthorized access, use or disclosure. We maintain administrative,
            technical and physical safeguards designed to protect your
            information’s security, confidentiality and integrity.
          </Typography>

          <Typography variant="h2">Contacting us by Telephone</Typography>
          <Typography variant="body1">
            If you communicate with us by telephone, we may monitor or record
            the call. This is done for reasons such as maintaining the integrity
            of your account, providing effective and timely service, and the
            improvement of Kovo’s products.
          </Typography>

          <Typography variant="h2">Data Retention and Disposal</Typography>
          <Typography variant="body1">
            The period during which we store your personal information varies
            depending on the purpose for the processing. For example, we store
            personal information needed to provide you with services, or to
            facilitate transactions you have requested, for so long as you are a
            customer of Kovo or as otherwise required by law. We store your
            personal information for marketing purposes until you have opted-out
            of receiving further direct marketing communications in accordance
            with applicable law. In all other cases, we store your personal
            information for as long as is needed to fulfill the purposes
            outlined in this Privacy Policy following which time it is either
            anonymized (where permitted by applicable law), deleted or
            destroyed.
          </Typography>

          <Typography variant="h2">Cookies and Other Technologies</Typography>
          <Typography variant="body1">
            We use a variety of technologies such as cookies, pixels, tags,
            beacons, and scripts to record your preferences, track the use of
            our Site, collect information, and facilitate advertising. Cookies
            are small text files that a website server stores on your computer
            or device. A cookie may convey information about how you use the
            Service (e.g., the pages you view, the links you click and other
            actions you take on the Service), and allow us or our business
            partners to track your usage of the Service over time. We may use
            both session cookies and persistent cookies. A session cookie
            disappears after you close your browser. A persistent cookie remains
            after you close your browser and may be used by your browser on
            future visits to the Site. We also use cookies and similar
            technologies hosted by third parties on the Services. We may receive
            reports based on the use of these technologies by such companies on
            an individual and aggregated basis. We may combine this
            automatically collected log information with other information we
            collect about you.
          </Typography>
          <Typography variant="body1">
            You can usually modify your browser settings to decline cookies and
            you can withdraw your consent at any time by modifying the settings
            of your browser to reject or disable cookies or by opting out of
            specific cookies through the opt-out options shared below. If you
            choose to decline cookies altogether, you may not be able to fully
            experience the features of the Site that you visit.
          </Typography>

          <Typography variant="h2">Web Analytics</Typography>
          <Typography variant="body1">
            We use different analytic tools which serve the purpose of
            measuring, analyzing and optimizing our marketing measures and
            provide you with customized advertisements that could be of
            particular interest to you. We use Google Analytics to better
            understand how you interact with our Site. The information we obtain
            through our Services may be disclosed to or collected directly by
            these third parties. To learn more about Google Analytics, please
            visit https://www.google.com/policies/privacy/partners/.
          </Typography>
          <Typography variant="body1">
            You may also see our ads on other websites because we use
            third-party ad services. Through these ad services, we may target
            our messaging to users considering demographic data, users’ inferred
            interests and browsing context. These services track your online
            activities over time and across multiple websites and apps by
            collecting information through automated means, including through
            the use of cookies, web server logs, web beacons and other similar
            technologies.
          </Typography>

          <Typography variant="h2">Your Rights and Responsibilities</Typography>
          <Typography variant="body1">
            You are permitted, and hereby agree, to only provide personal
            information to Kovo if such personal information is accurate,
            reliable, and relevant to our relationship and only to the extent
            such disclosure will not violate any applicable data protection law,
            statute, or regulation.
          </Typography>
          <Typography variant="body1">
            You may have certain rights under applicable data protection law
            with respect to personal information about you that is collected
            through the Site or when you contact or otherwise engage with us. To
            exercise any of these data privacy rights, please contact us, or
            have your authorized agent contact us, in accordance with the
            “Contact Us” section listed below. In the event you submit, or your
            authorized agent submits on your behalf, a data request, you (and
            your authorized agent) hereby acknowledge and agree, under penalty
            of perjury, that you are (or the authorized agent of) the consumer
            whose personal information is the subject of the request. We will
            respond to any data requests within the timeframes required by law,
            and we may charge a fee to facilitate your request where permitted
            by law.
          </Typography>

          <Typography variant="h2">Marketing</Typography>
          <Typography variant="body1">
            You have the right to opt-out of receiving electronic direct
            marketing communications from us. All electronic direct marketing
            communications that you may receive from us, such as e-mail
            messages, will give you an option of not receiving such
            communications from us in the future. To opt out of receiving
            marketing SMS messages you may reply STOP to any message you receive
            from us.
          </Typography>

          <Typography variant="h2">Do Not Track</Typography>
          <Typography variant="body1">
            Some web browsers may transmit “do-not-track” signals to the Site
            with which the user communicates. Because of differences in how web
            browsers incorporate and activate this feature, it is not always
            clear whether users intend for these signals to be transmitted, or
            whether they even are aware of them. We currently do not take action
            in response to these signals.
          </Typography>

          <Typography variant="h2">Links to Third Party Websites</Typography>
          <Typography variant="body1">
            The Services may contain links to other websites that are not
            operated by us. If you click on a third-party link, you will be
            directed to that third party's site. We strongly advise you to
            review the Privacy Policy of every site you visit. We have no
            control over, and assume no responsibility for the content, privacy
            policies or practices of any third-party Site or services.
          </Typography>

          <Typography variant="h2">
            Changes to your Personal Information
          </Typography>
          <Typography variant="body1">
            If you need to update any information you may do so by logging on to
            your account and making the change or by contacting us at
            support@kovocredit.com.
          </Typography>

          <Typography variant="h2">Changes to This Privacy Policy</Typography>
          <Typography variant="body1">
            We may occasionally update this Privacy Policy. When we do, we will
            revise the "last updated" date at the top of the Privacy Policy and
            take such additional steps as may be required by law.
          </Typography>

          <Typography variant="h2">Children's Privacy</Typography>
          <Typography variant="body1">
            Our services are not intended for or directed to children. We do not
            knowingly collect personal information from children. If we learn or
            are notified that we have collected this information from a child
            under the age of 13, we will promptly remove the data from our
            system. If you believe we may have information from a child, please
            contact us at support@kovocredit.com.
          </Typography>

          <Typography variant="h2">Contact Us</Typography>
          <Typography variant="body1">
            If you have questions or concerns regarding this Privacy Policy, or
            our handling of your personal information, please contact us at
            support@kovocredit.com.
          </Typography>

          <Typography variant="h2">Privacy Notice</Typography>
          <iframe
            title="Kovo Privacy Notice"
            src="https://drive.google.com/file/d/1AbnD6_-lNOwkEng1FoikS6m05g_aDcD6/preview"
            width="100%"
            height={600}
          />
        </Box>
      </OnboardingPage>
    </Box>
  );
};

export default PrivacyPolicyPage;
