import { LoadingButton } from '@mui/lab';
import { Alert, Box } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';

import ContractScrollWrapper from 'components/ContractScrollWrapper';
import EsignContract, {
  CONTRACT_WIDTH,
} from 'components/EsignContract/EsignContract';
import PageLoader from 'components/PageLoader';
import { theme } from 'context/ThemeProvider';
import useSetEsignViewed from 'hooks/mutations/useSetEsignViewed';
import useGetApplications from 'hooks/queries/useGetApplications';
import { CHARGE_ACCOUNT_PRODUCT_ID } from 'types/schemas';

const ChargeAccountContractForm: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const {
    mutate,
    isLoading: isLoadingMutation,
    error,
  } = useSetEsignViewed('charge-account');

  useEffect(() => {
    if (isScrolled) {
      buttonRef.current?.focus({ preventScroll: false });
    }
  }, [isScrolled]);

  const onSubmit = () => {
    mutate(chargeAccountApplication);
  };

  const { handleSubmit } = useFormik({ initialValues: {}, onSubmit });

  const { data, isLoading: isLoadingQuery } = useGetApplications();

  if (isLoadingQuery) {
    return <PageLoader />;
  }

  if (!data) {
    return null;
  }

  const { chargeAccountApplication } = data;

  return (
    <>
      {error && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(2) }}
        >
          There was an error. Please try again.
        </Alert>
      )}

      <Box
        sx={{
          marginLeft: theme.spacing(-1.5),
          marginRight: theme.spacing(-1.5),

          [theme.breakpoints.up('sm')]: {
            marginLeft: `calc((${CONTRACT_WIDTH} - 100vw) / 2)`,
            marginRight: `calc((${CONTRACT_WIDTH} - 100vw) / 2)`,
          },

          [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(-3),
            marginRight: theme.spacing(-3),
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <ContractScrollWrapper
            isScrolled={isScrolled}
            setIsScrolled={setIsScrolled}
          >
            <EsignContract productId={CHARGE_ACCOUNT_PRODUCT_ID} />
          </ContractScrollWrapper>

          {isScrolled && (
            <Box
              sx={{
                padding: theme.spacing(0, 1.5),

                [theme.breakpoints.up('sm')]: {
                  paddingLeft: `calc((100vw - ${CONTRACT_WIDTH}) / 2)`,
                  paddingRight: `calc((100vw - ${CONTRACT_WIDTH}) / 2)`,
                },

                [theme.breakpoints.up('md')]: {
                  padding: theme.spacing(0, 3),
                },
              }}
            >
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoadingMutation}
                ref={buttonRef}
                fullWidth
              >
                Agree
              </LoadingButton>
            </Box>
          )}
        </form>
      </Box>
    </>
  );
};

export default ChargeAccountContractForm;
