import LogOut from 'components/LogOut';
import OnboardingPage from 'components/OnboardingPage';

interface Props {}

const LogOutPage: React.FC<Props> = () => {
  return (
    <OnboardingPage centerLogo linkLogoToHomepage supportSubject="logout">
      <LogOut />
    </OnboardingPage>
  );
};

export default LogOutPage;
