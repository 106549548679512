// Not the most secure method, but should be able to use as basic check
export const isValidUrl = (value: string): boolean => {
  let url_string;
  try {
    url_string = new URL(value);
  } catch (_) {
    return false;
  }
  return url_string.protocol === 'http:' || url_string.protocol === 'https:';
};
