import { Auth } from 'aws-amplify';
import { KovoError } from 'libs/KovoError';

/**
 * Send a confirmation code for a pending email verification for an already signed up user.
 */
export const verifyEmailAttribute = async (pendingEmail: string) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();

    /**
     * Use updateUserAttributes instead of verifyUserAttribute becuase verifyUserAttribute
     * sends to the previous email.
     */
    await Auth.updateUserAttributes(currentUser, {
      email: pendingEmail,
    });
  } catch (err) {
    throw new KovoError('Error sending email verification code', {
      error: err,
    }).setDisplayMessage(
      'Error sending email verification code. Please try again.',
    );
  }
};
