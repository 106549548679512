import { Box, Grid, Typography } from '@mui/material';

import DocumentsCard from 'components/DocumentsCard';
import Page from 'components/Page';
import PageLoader from 'components/PageLoader';
import PersonalInfo from 'components/PersonalInfo';
import SecurityCard from 'components/SecurityCard';
import TermsCard from 'components/TermsCard';
import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';

const Settings: React.FC = () => {
  const { data, isLoading } = useApplicationStatus();

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Page>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            textAlign: 'center',
          },
        }}
      >
        <Typography variant="h1" marginBottom={theme.spacing(2)}>
          Settings
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {data && data.applicationStatus !== 'upfrontPaymentCompleted' && (
          <Grid item xs={12}>
            <PersonalInfo />
          </Grid>
        )}

        <Grid item xs={12}>
          <SecurityCard />
        </Grid>

        {data && data.applicationStatus !== 'upfrontPaymentCompleted' && (
          <Grid item xs={12}>
            <DocumentsCard />
          </Grid>
        )}

        <Grid item xs={12}>
          <TermsCard />
        </Grid>
      </Grid>
    </Page>
  );
};

export default Settings;
