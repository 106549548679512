import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { KOVO_LIGHT_GREEN, theme } from 'context/ThemeProvider';

type Props = {
  borderRadius?: string;
};

const CopySuccessOverlay: React.FC<Props> = ({ borderRadius }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: KOVO_LIGHT_GREEN,
        borderRadius: borderRadius || '1000px',
        width: '100%',
        textTransform: 'uppercase',
      }}
      data-testid="copy-success-overlay"
    >
      <CheckCircleIcon
        sx={{
          verticalAlign: 'top',
          marginRight: theme.spacing(1),
          fill: theme.palette.success.main,
        }}
      />
      <Typography variant="h4">Copied</Typography>
    </Box>
  );
};

export default CopySuccessOverlay;
