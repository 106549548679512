import { Box, Grid, Typography } from '@mui/material';
import { API } from 'aws-amplify';
import { useFormik } from 'formik';
import { useQuery } from 'react-query';

import OfferCard from 'components/OffersList/OfferCard';
import { Offer } from 'components/OffersList/OffersList';
import PageLoader from 'components/PageLoader';
import { theme } from 'context/ThemeProvider';

import Calendar from 'assets/img/illustrations/calendar-blank.svg';
import StateSelect from 'components/StateSelect/StateSelect';

const PublicOffersList: React.FC = () => {
  const { values, handleChange } = useFormik({
    initialValues: { state: '' },
    onSubmit: () => undefined,
  });

  const getOffers = async (state: string) => {
    if (!state) return;

    const offers = await API.get(
      'billing',
      `/billing/v2/public/offers?state=${state}`,
      {},
    );

    return offers;
  };

  const { data, isLoading } = useQuery<{ offers: Offer[] }>(
    ['public-offers', values.state],
    () => getOffers(values.state),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      <Typography variant="footnote" marginBottom={theme.spacing(2)}>
        Please select your state to see available offers.
      </Typography>

      <StateSelect
        name="state"
        label="State"
        value={values.state}
        error={false}
        onChange={handleChange}
        onBlur={() => {}}
      />

      {isLoading && <PageLoader />}

      {!isLoading && data?.offers && data?.offers.length === 0 && (
        <Box
          sx={{
            textAlign: 'center',
            marginTop: theme.spacing(3),
          }}
        >
          <Box component="img" src={Calendar} alt="" />

          <Typography variant="h6">We are sorry</Typography>

          <Typography gutterBottom>
            Kovo doesn't currently have any offers for your location.
          </Typography>

          <Typography variant="body2">Check back at a later time.</Typography>
        </Box>
      )}

      {!isLoading && data?.offers && data?.offers.length > 0 && (
        <Grid container spacing={2} marginTop={theme.spacing(2)}>
          {data?.offers.map((offer) => (
            <Grid item xs={12} md={6} key={offer.id}>
              <OfferCard offer={offer} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default PublicOffersList;
