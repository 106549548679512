import { ComponentType } from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthRoute from 'components/AuthRoute';
import ScrollToTop from 'components/ScrollToTop';
import AdverseActionPage from 'pages/AdverseAction';
import ApplicationRejectedPage from 'pages/ApplicationRejected';
import ApplyPage from 'pages/ApplyPage';
import ApplyPendingPage from 'pages/ApplyPendingPage';
import ClosedPage from 'pages/ClosedPage';
import ContractPage from 'pages/ContractPage';
import CoursesPage from 'pages/CoursesPage';
import CreditProtectionPage from 'pages/CreditProtectionPage';
import CreditScorePage from 'pages/CreditScorePage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import GiftCardsPage from 'pages/GiftCards';
import HomePage from 'pages/Home';
import InstallmentPlanPage from 'pages/InstallmentPlanPage';
import InviteFriendsPage from 'pages/InviteFriendsPage';
import LoginPage from 'pages/LogIn';
import LogOutPage from 'pages/LogOutPage';
import OfferCategoriesPage from 'pages/OfferCategoriesPage';
import OffersPage from 'pages/OffersPage';
import PaymentPage from 'pages/PaymentPage';
import PhoneNumberPage from 'pages/PhoneNumberPage';
import PhoneNumberVerificationPage from 'pages/PhoneNumberVerificationPage';
import PublicOffersPage from 'pages/PublicOffersPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import RewardsPage from 'pages/RewardsPage';
import Settings from 'pages/Settings';
import SignUpConfirmPage from 'pages/SignUpConfirmPage';
import SignUpPage from 'pages/SignUpPage';
import UnrecognizedStatusPage from 'pages/UnrecognizedStatusPage';
import UpfrontPaymentPage from 'pages/UpfrontPaymentPage';
import WalletPage from 'pages/WalletPage';
import ForgotEmailPage from 'pages/auth/ForgotEmailPage';
import {
  ChargeAccountCatchupPaymentPage,
  ChargeAccountUpdatePaymentMethodPage,
} from 'pages/charge-account';
import ChargeAccountContractPage from 'pages/charge-account/ChargeAccountContractPage';
import ChargeAccountPage from 'pages/charge-account/ChargeAccountPage';
import ChargeAccountPaymentPage from 'pages/charge-account/ChargeAccountPaymentPage';
import ChargeAccountWhatYouGet from 'pages/charge-account/ChargeAccountWhatYouGet';
import {
  InstallmentPlanCatchupPaymentPage,
  InstallmentPlanUpdatePaymentMethodPage,
} from 'pages/installment-plan';
import ESignConsentPage from 'pages/legal/ESignConsentPage';
import PrivacyPolicyPage from 'pages/legal/PrivacyPolicyPage';
import TermsOfUsePage from 'pages/legal/TermsOfUsePage';

import CreditEducation from 'pages/CreditEducation';
import DownloadPage from 'pages/Download';
import SignupPendingPage from 'pages/SignupPendingPage';
import ConfirmEmailUpdatePage from 'pages/settings/ConfirmEmailUpdatePage';
import UpdateEmailPage from 'pages/settings/UpdateEmailPage';
import { ROUTES } from './constants';

export interface RouteType {
  path: string;
  component: ComponentType;
  requiresAuthentication?: boolean;
  isPostOnboarding?: boolean;
}

const Routes: React.FC = () => {
  const routes: RouteType[] = [
    ...[
      {
        path: ROUTES.CREDIT_LINE_HOW_IT_WORKS,
        component: ChargeAccountWhatYouGet,
        isPostOnboarding: true,
        requiresAuthentication: true,
      },
      {
        path: ROUTES.CREDIT_LINE_CONTRACT,
        component: ChargeAccountContractPage,
        isPostOnboarding: true,
        requiresAuthentication: true,
      },
      {
        path: ROUTES.CREDIT_LINE_PAYMENT,
        component: ChargeAccountPaymentPage,
        isPostOnboarding: true,
        requiresAuthentication: true,
      },
      {
        path: ROUTES.CREDIT_LINE_UPDATE_CARD,
        component: ChargeAccountUpdatePaymentMethodPage,
        isPostOnboarding: true,
        requiresAuthentication: true,
      },
      {
        path: ROUTES.CREDIT_LINE_CATCHUP_PAYMENT,
        component: ChargeAccountCatchupPaymentPage,
        isPostOnboarding: true,
        requiresAuthentication: true,
      },
      {
        path: ROUTES.CREDIT_LINE,
        component: ChargeAccountPage,
        isPostOnboarding: true,
        requiresAuthentication: true,
      },
      {
        path: ROUTES.CREDIT_PROTECTION,
        component: CreditProtectionPage,
        isPostOnboarding: true,
        requiresAuthentication: true,
      },
    ],
    ...[
      {
        path: '/phone-number',
        component: PhoneNumberPage,
        requiresAuthentication: true,
      },
      {
        path: '/verify-phone-number',
        component: PhoneNumberVerificationPage,
        requiresAuthentication: true,
      },
    ],
    ...[
      {
        path: '/credit-score',
        component: CreditScorePage,
        isPostOnboarding: true,
        requiresAuthentication: true,
      },
    ],
    ...[
      {
        path: '/offer-categories/:categoryId/offers',
        component: OffersPage,
        isPostOnboarding: true,
        requiresAuthentication: true,
      },
      {
        path: '/offer-categories',
        component: OfferCategoriesPage,
        isPostOnboarding: true,
        requiresAuthentication: true,
      },
      {
        path: '/offers',
        component: PublicOffersPage,
        requiresAuthentication: false,
      },
      {
        path: '/rewards',
        component: RewardsPage,
        isPostOnboarding: true,
        requiresAuthentication: true,
      },
    ],
    ...[
      {
        path: '/gift-cards',
        component: GiftCardsPage,
        isPostOnboarding: true,
        requiresAuthentication: true,
      },
    ],
    {
      path: ROUTES.LOGIN_ROUTE,
      component: LoginPage,
      requiresAuthentication: false,
    },
    {
      path: '/forgot-password',
      component: ForgotPasswordPage,
      requiresAuthentication: false,
    },
    {
      path: '/reset-password',
      component: ResetPasswordPage,
      requiresAuthentication: false,
    },
    {
      path: '/logout',
      component: LogOutPage,
      requiresAuthentication: false,
    },
    {
      path: '/signup',
      component: SignUpPage,
      requiresAuthentication: false,
    },
    {
      path: '/signup-confirm',
      component: SignUpConfirmPage,
      requiresAuthentication: false,
    },
    {
      path: ROUTES.SIGNUP_PENDING_ROUTE,
      component: SignupPendingPage,
    },
    {
      path: ROUTES.FORGOT_EMAIL_ROUTE,
      component: ForgotEmailPage,
      requiresAuthentication: false,
    },
    {
      path: '/credit-education',
      component: CreditEducation,
      requiresAuthentication: false,
    },
    {
      path: '/apply',
      component: ApplyPage,
      requiresAuthentication: true,
    },
    {
      path: '/apply-pending',
      component: ApplyPendingPage,
      requiresAuthentication: true,
    },
    {
      path: '/esign',
      component: ContractPage,
      requiresAuthentication: true,
    },
    {
      path: '/payment',
      component: PaymentPage,
      requiresAuthentication: true,
    },
    // sub pages must be listed first if not using the children route setup
    {
      path: ROUTES.SETTINGS_UPDATE_EMAIL_CONFIRMATION,
      component: ConfirmEmailUpdatePage,
    },
    {
      path: ROUTES.SETTINGS_UPDATE_EMAIL,
      component: UpdateEmailPage,
      isPostOnboarding: true,
      requiresAuthentication: true,
    },
    {
      path: ROUTES.SETTINGS,
      component: Settings,
      isPostOnboarding: true,
      requiresAuthentication: true,
    },
    {
      path: ROUTES.INSTALLMENT_CATCHUP_PAYMENT,
      component: InstallmentPlanCatchupPaymentPage,
      isPostOnboarding: true,
      requiresAuthentication: true,
    },
    {
      path: ROUTES.INSTALLMENT_PLAN_UPDATE_CARD,
      component: InstallmentPlanUpdatePaymentMethodPage,
      isPostOnboarding: true,
      requiresAuthentication: true,
    },
    {
      path: ROUTES.INSTALLMENT_PLAN,
      component: InstallmentPlanPage,
      isPostOnboarding: true,
      requiresAuthentication: true,
    },
    {
      path: '/legal/privacy',
      component: PrivacyPolicyPage,
      requiresAuthentication: false,
    },
    {
      path: '/legal/terms',
      component: TermsOfUsePage,
      requiresAuthentication: false,
    },
    {
      path: '/legal/e-sign',
      component: ESignConsentPage,
      requiresAuthentication: false,
    },
    {
      path: '/upfront-payment',
      component: UpfrontPaymentPage,
      requiresAuthentication: true,
    },
    {
      path: '/adverse-action-notice',
      component: AdverseActionPage,
      requiresAuthentication: true,
    },
    {
      path: '/application-rejected',
      component: ApplicationRejectedPage,
      requiresAuthentication: true,
    },
    {
      path: '/unrecognized-status',
      component: UnrecognizedStatusPage,
      requiresAuthentication: false,
    },
    {
      path: ROUTES.ERROR_500_ROUTE,
      component: UnrecognizedStatusPage,
      requiresAuthentication: false,
    },
    {
      path: '/courses',
      component: CoursesPage,
      isPostOnboarding: true,
      requiresAuthentication: true,
    },
    {
      path: '/wallet',
      component: WalletPage,
      isPostOnboarding: true,
      requiresAuthentication: true,
    },
    {
      path: '/invite-friends',
      component: InviteFriendsPage,
      isPostOnboarding: true,
      requiresAuthentication: true,
    },
    {
      path: '/download',
      component: DownloadPage,
      requiresAuthentication: false,
    },
    {
      path: '/closed',
      component: ClosedPage,
      isPostOnboarding: true,
      requiresAuthentication: true,
    },
    {
      path: '/',
      component: HomePage,
      requiresAuthentication: true,
    },
  ];

  return (
    <>
      <ScrollToTop />
      <Switch>
        {routes.map(({ path, component, requiresAuthentication = true }) => {
          if (requiresAuthentication) {
            return <AuthRoute path={path} component={component} key={path} />;
          }

          return <Route path={path} component={component} key={path} />;
        })}
      </Switch>
    </>
  );
};

export default Routes;
