import config from 'config';
import { ApplicationStatusResponse } from 'context/Profile';
import * as impact from 'helpers/impact';
import { normalizeEmail, normalizePhoneNumber } from 'helpers/utils';
import { logger, logOnboardingEvent } from 'libs/logger';
import { INSTALLMENTS_PRODUCT_ID, LoanProductId } from 'types/schemas';
import { trackProductAnalytics } from './productAnalyticsTracking';

export const trackConversion = async (
  userId: string,
  username: string,
  email: string,
  productId: LoanProductId,
  applicationStatus: ApplicationStatusResponse,
) => {
  if (config.VITE_STAGE === 'prod' && productId === INSTALLMENTS_PRODUCT_ID) {
    const eventName = 'purchase';
    const eventId = `${userId}-${eventName}-installments10`;

    window.gtag('event', eventName, {
      event_id: eventId,
    });

    // Google Ads conversion
    window.gtag('event', 'conversion', {
      send_to: 'AW-721372392/Y059CKnj2KoYEOiJ_dcC',
      transaction_id: `${userId}-installments10`,
    });

    // Facebook conversion

    try {
      const hashedExternalId = await window.crypto.subtle.digest(
        'sha-256',
        new TextEncoder().encode(userId),
      );
      const hashExternalIdArray = Array.from(new Uint8Array(hashedExternalId)); // convert buffer to byte array
      const hashExternalIdHex = hashExternalIdArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join(''); // convert bytes to hex string
      fbq(
        'track',
        eventName,
        {
          external_id: hashExternalIdHex,
        },
        {
          eventID: eventId,
        },
      );
    } catch (error) {
      logger.error('Error sending event to meta via FBQ tracking', {
        error,
      });
    }

    // TikTok conversion
    const normalizedEmail = normalizeEmail(email);
    const normalizedPhoneNumber = applicationStatus.applicationDetails?.phone
      ? normalizePhoneNumber(applicationStatus.applicationDetails.phone)
      : '';
    const tikTokIdentifyData = {
      email: normalizedEmail,
      phone_number: normalizedPhoneNumber,
      external_id: userId,
    };
    window.ttq.identify(tikTokIdentifyData);

    trackProductAnalytics({
      namespace: 'purchase',
      event: 'submission.succeeded',
      attributes: {
        productId: 'service_1',
      },
      properties: {
        eventId,
      },
      sendAsConversionEventToMobileApp: true,
    });
  }

  // Trigger Impact "Initial Installment Payment" action
  impact.trackInitialPayment({
    customerId: username,
    customerEmail: email,
  });

  logOnboardingEvent({ eventName: 'payment completed', email: email! });
};
