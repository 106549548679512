import { Alert, Box, Card, FormControlLabel, Radio } from '@mui/material';

import { PaymentMethodLabel } from 'components/PaymentMethodLabel';
import { theme } from 'context/ThemeProvider';

import { LoadingButton } from '@mui/lab';
import Lock from 'assets/img/icons/lock.svg';
import { parsePaymentError } from 'libs/parsePaymentError';
import { useMutation } from 'react-query';
import { PaymentMethod } from 'types/schemas';
import { useCatchupPaymentContext } from './CatchupPaymentProvider';

interface CatchupPaymentFormProps {
  currentPaymentMethod: PaymentMethod | null;
}

const CatchupPaymentExistingPaymentMethod: React.FC<
  CatchupPaymentFormProps
> = ({ currentPaymentMethod }) => {
  const { catchupAmount, createCatchupPayment } = useCatchupPaymentContext();

  const buttonLabel = `Pay $${catchupAmount.toFixed(2)}`;

  const {
    mutate: catchup,
    isLoading,
    error,
  } = useMutation(createCatchupPayment);

  return (
    <Card
      elevation={0}
      variant="outlined"
      sx={{ marginBottom: theme.spacing(3) }}
    >
      {error && (
        <Alert
          icon={false}
          severity="error"
          sx={{ marginBottom: theme.spacing(2) }}
        >
          {parsePaymentError(error)}
        </Alert>
      )}

      <FormControlLabel
        label={
          currentPaymentMethod && (
            <PaymentMethodLabel paymentMethod={currentPaymentMethod} />
          )
        }
        control={
          <Radio
            checked
            sx={{
              '&.Mui-checked': {
                color: '#007AFF',
              },
            }}
          />
        }
        sx={{ marginBottom: theme.spacing(1) }}
      />

      <LoadingButton
        variant="contained"
        type="button"
        loading={isLoading}
        fullWidth
        onClick={() => catchup(catchupAmount)}
      >
        {buttonLabel}
        <Box
          component="img"
          src={Lock}
          sx={{
            position: 'absolute',
            right: theme.spacing(3),
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      </LoadingButton>
    </Card>
  );
};

export default CatchupPaymentExistingPaymentMethod;
