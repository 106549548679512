import { Elements, StripeProvider } from 'react-stripe-elements';

import {
  CheckoutPaymentMethods,
  PaymentMode,
  onConfirmPaymentMethod,
} from 'components/CheckoutPaymentMethods';
import PageLoader from 'components/PageLoader';
import StripeInstallment from 'components/StripeInstallment';
import config from 'config';
import { useAuthContext } from 'context/AuthProvider';
import { USE_CURRENT_USER_QUERY_KEY } from 'hooks/queries/useCurrentUser';
import { USE_ACCOUNTS_QUERY_KEY } from 'hooks/queries/useGetAccounts';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { useBillingService } from 'hooks/useBillingService';
import { KovoError } from 'libs/KovoError';
import { trackConversion } from 'libs/trackConversion';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { INSTALLMENTS_PRODUCT_ID } from 'types/schemas';

interface InstallmentsPaymentProps {
  /**
   * If true, the Apple Pay and Google Pay buttons will be disabled.
   * This is only used for unit testing.
   */
  walletsDisabled?: boolean;
}

const InstallmentPayment: React.FC<InstallmentsPaymentProps> = ({
  walletsDisabled,
}) => {
  const { email, identityId: userId, username } = useAuthContext();
  const billingService = useBillingService();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { data: applicationStatusData, isLoading } = useApplicationStatus();

  const handleConfirmPaymentMethod = useCallback<onConfirmPaymentMethod>(
    async ({ token, walletType, last4 }) => {
      try {
        if (!applicationStatusData) {
          throw new KovoError('Application status data is missing');
        }

        const url = new URL(window.location.href);
        const customProduct = url.searchParams.get('product');

        await billingService.post('/billing/checkout/confirm-payment', {
          email,
          token,
          product: customProduct,
          type: walletType,
          last4,
        });

        trackConversion(
          userId,
          username,
          email,
          INSTALLMENTS_PRODUCT_ID,
          applicationStatusData,
        );

        queryClient.invalidateQueries({
          queryKey: [USE_ACCOUNTS_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [USE_CURRENT_USER_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
        });

        history.replace('/');
      } catch (error) {
        if (error instanceof KovoError) {
          throw error;
        }

        throw new KovoError('Error completing payment')
          .addMetadata({
            walletType,
            loanProductId: 'pro_1',
          })
          .setError(error);
      }
    },
    [
      billingService,
      email,
      history,
      username,
      userId,
      queryClient,
      applicationStatusData,
    ],
  );

  if (isLoading) {
    return <PageLoader />;
  }

  if (
    applicationStatusData?.applicationDetails?.billingProvider !== 'checkout' &&
    applicationStatusData?.applicationDetails?.provider !== 'checkout'
  ) {
    return (
      <StripeProvider apiKey={config.STRIPE_KEY}>
        <Elements>
          <StripeInstallment />
        </Elements>
      </StripeProvider>
    );
  }

  return (
    <CheckoutPaymentMethods
      paymentMode={PaymentMode.PAYMENT}
      amountInDollars={10}
      onConfirmPaymentMethod={handleConfirmPaymentMethod}
      walletsDisabled={walletsDisabled}
    />
  );
};

export default InstallmentPayment;
