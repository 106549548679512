import React from 'react';

import EsignContract from 'components/EsignContract';
import PopupDialog from 'components/PopupDialog';
import { LoanProductId } from 'types/schemas';

interface Props {
  productId: LoanProductId;
  children: React.ReactNode;
}

const ContractDialog: React.FC<Props> = ({ productId, children }) => {
  return (
    <PopupDialog
      dialogText={<EsignContract productId={productId} />}
      variant="footnote"
      maxWidth="md"
    >
      {children}
    </PopupDialog>
  );
};

export default ContractDialog;
