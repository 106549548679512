import { LoadingButton } from '@mui/lab';
import { Alert, Box, Typography } from '@mui/material';
import VerificationCodeInput from 'components/VerificationCodeInput';
import { useAuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';
import { useFormik } from 'formik';
import { normalizeEmail } from 'helpers/utils';
import useConfirmUserSignup from 'hooks/useConfirmUserSignup';
import useResendEmailVerificationCode from 'hooks/useResendEmailVerificationCode';
import { KovoUnauthenticatedError } from 'libs/KovoError';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';

export interface LocationState {
  username?: string;
  password?: string;
  redirectPath?: string;
}

const SignUpConfirmForm: React.FC = () => {
  const { preConfirmationState } = useAuthContext();

  const { mutate: resendSignUpMutate, error: resendError } =
    useResendEmailVerificationCode();

  const {
    mutate: confirmUserSignup,
    isLoading,
    error: confirmUserSignupError,
  } = useConfirmUserSignup();

  const { touched, errors, values, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        code: '',
      },
      validationSchema: yup.object().shape({
        code: yup.string().required(),
      }),
      onSubmit: (values) => confirmUserSignup(values),
      validateOnBlur: false,
    });

  /**
   * Redirect to login page if there is an auth error
   */
  if (confirmUserSignupError instanceof KovoUnauthenticatedError) {
    return <Redirect to="/login" />;
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // only allow numerical values
    if (e.target.validity.valid) {
      setFieldValue('code', e.target.value);
    }
  };

  return (
    <>
      {(confirmUserSignupError || resendError) && (
        <Box sx={{ marginBottom: theme.spacing(4) }}>
          {confirmUserSignupError && (
            <Alert
              severity="error"
              icon={false}
              sx={{ marginBottom: theme.spacing(1) }}
            >
              {confirmUserSignupError.displayMessage ??
                confirmUserSignupError.message}
            </Alert>
          )}

          {resendError && (
            <Alert
              severity="error"
              icon={false}
              sx={{ marginBottom: theme.spacing(1) }}
            >
              {resendError.displayMessage ?? resendError.message}
            </Alert>
          )}
        </Box>
      )}

      <Typography marginBottom={theme.spacing(3)}>
        Check your email for a verification code.
      </Typography>
      <Typography sx={{ marginBottom: theme.spacing(3) }}>
        Email:{' '}
        <Box component="strong">
          {preConfirmationState && preConfirmationState.email
            ? normalizeEmail(preConfirmationState?.email)
            : ''}
        </Box>
      </Typography>

      <form onSubmit={handleSubmit}>
        <VerificationCodeInput
          type="tel"
          pattern="[0-9]*"
          name="code"
          label="Enter code"
          value={values.code}
          error={
            !!(
              (touched.code && errors.code) ||
              confirmUserSignupError ||
              resendError
            )
          }
          onChange={onChange}
          onBlur={handleBlur}
          fullWidth
          onResendCode={async () => {
            preConfirmationState?.email &&
              resendSignUpMutate(preConfirmationState.email);
          }}
          recentlySentLabel="Code sent. Check your email."
          autoComplete="one-time-code"
          maxLength={6}
          autoFocus
        />

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
          sx={{ marginTop: theme.spacing(5) }}
        >
          Verify Email
        </LoadingButton>
      </form>
    </>
  );
};

export default SignUpConfirmForm;
