import { CssBaseline, GlobalStyles } from '@mui/material';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from 'components/Routes';
import AdaProvider from 'context/AdaProvider';
import AuthProvider from 'context/AuthProvider';
import { GlobalRedirectContextProvider } from 'context/GlobalRedirectContext';
import ThemeProvider, { theme } from 'context/ThemeProvider';

import GradientBackgroundDesktop from 'assets/img/illustrations/gradient-background-desktop.png';
import {
  checkLastUpdateAndRefreshIfNeeded,
  setLastWebAppUpdateCookie,
} from 'helpers/outdatedWebAppHelper';
import { logger } from 'libs/logger';
import { reactQueryOnError } from 'libs/reactQueryHelpers';
import { useEffect } from 'react';

const ONE_HOUR = 1000 * 60 * 60;

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: reactQueryOnError(logger),
  }),
  // [See docs on Global callbacks](https://tanstack.com/query/latest/docs/reference/MutationCache#global-callbacks)
  mutationCache: new MutationCache({
    onError: reactQueryOnError(logger),
  }),
  defaultOptions: {
    queries: {
      staleTime: ONE_HOUR,
      // TODO: revisit these two options, currently causing issues throughout the app
      // refetchOnMount: 'always',
      // refetchOnWindowFocus: 'always',
    },
  },
});

/**
 * We experience a reference error in certain browsers where __AutoFillPopupClose__
 * is undefined. This is a workaround to prevent an error from being thrown.
 *
 * 'ReferenceError Can't find variable: __AutoFillPopupClose__'
 */
window.__AutoFillPopupClose__ = window.__AutoFillPopupClose__ ?? (() => {});

const App: React.FC = () => {
  useEffect(() => {
    setLastWebAppUpdateCookie();
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkLastUpdateAndRefreshIfNeeded();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div data-testid="app-v2">
      <CssBaseline />

      <GlobalStyles
        styles={{
          body: {
            [theme.breakpoints.up('md')]: {
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundAttachment: 'fixed',
              backgroundPosition: 'center center',
              backgroundImage: `url(${GradientBackgroundDesktop})`,
            },
          },
        }}
      />

      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ThemeProvider>
            <Router>
              <AdaProvider>
                <GlobalRedirectContextProvider>
                  <Routes />
                </GlobalRedirectContextProvider>
              </AdaProvider>
            </Router>
          </ThemeProvider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
};

export default App;
