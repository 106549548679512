import { useContext } from 'react';

import { AuthContext } from 'context/AuthProvider';
import { useRewardsQuery } from './queries/useRewardsQuery';
import { useReferralClaims } from './useReferralClaims';

interface UncalimedReferralClaimsData {
  isLoading: boolean;
  totalUnclaimedAmount: number;
  unclaimedReferralClaimIds: string[];
  claimableRewardCategoryIds: string[];
}

export const useUnclaimedReferralClaims = (): UncalimedReferralClaimsData => {
  const { identityId } = useContext(AuthContext);

  const { data: referralClaims, isLoading } = useReferralClaims();

  const { data: rewards, isLoading: isLoadingRewards } = useRewardsQuery();

  if (isLoading || isLoadingRewards) {
    return {
      isLoading: true,
      totalUnclaimedAmount: 0,
      unclaimedReferralClaimIds: [],
      claimableRewardCategoryIds: [],
    };
  }

  const filteredReferralClaims = (referralClaims || []).filter(
    (referralClaim) =>
      referralClaim.completedOnboardingAt && referralClaim.eligibleAt,
  );

  const claimableRewards = (rewards || []).filter(
    ({ status }) => status === 'claimable',
  );

  let totalUnclaimedAmount = 0;
  const unclaimedReferralClaimIds = [];
  const claimableRewardCategoryIds = [];

  for (const referralClaim of filteredReferralClaims) {
    if (
      referralClaim.referredUserId === identityId &&
      !referralClaim.referredGiftCardId
    ) {
      totalUnclaimedAmount += referralClaim.referredRewardAmount;
      unclaimedReferralClaimIds.push(referralClaim.id);
    }

    if (
      referralClaim.invitingUserId === identityId &&
      !referralClaim.invitingGiftCardId
    ) {
      totalUnclaimedAmount += referralClaim.invitingRewardAmount;
      unclaimedReferralClaimIds.push(referralClaim.id);
    }
  }

  for (const reward of claimableRewards) {
    if (reward.rewardAmountDollars) {
      const rewardAmountCents = reward.rewardAmountDollars * 100;
      totalUnclaimedAmount += rewardAmountCents;
      claimableRewardCategoryIds.push(reward.categoryId);
    }
  }

  return {
    isLoading,
    totalUnclaimedAmount: totalUnclaimedAmount / 100,
    unclaimedReferralClaimIds,
    claimableRewardCategoryIds,
  };
};
