import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import UpfrontPaymentBanner from 'components/UpfrontPaymentBanner';
import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import useStripeStatus from 'hooks/useStripeStatus';

interface Props {
  hideButton?: boolean;
  onlyShowPastDue?: boolean;
}

const StripeStatusBanner: React.FC<Props> = ({
  hideButton,
  onlyShowPastDue,
}) => {
  const { data: stripeStatus } = useStripeStatus({ retry: false });

  const { data: applicationStatus } = useApplicationStatus();

  if (
    !applicationStatus ||
    applicationStatus?.applicationStatus === 'closedProductAccessRemains'
  ) {
    return null;
  }

  if (applicationStatus.applicationStatus === 'upfrontPaymentCompleted') {
    return <UpfrontPaymentBanner />;
  }

  const isPastDue = !!stripeStatus?.openInvoices;

  if (isPastDue) {
    return (
      <Card
        elevation={0}
        sx={{
          marginBottom: theme.spacing(2),
          textAlign: 'center',
        }}
      >
        <CardContent>
          <Typography variant="h6" marginBottom={theme.spacing(1)}>
            <Box component="strong">Account Status: </Box>
            <Box component="strong" color={theme.palette.error.main}>
              Past Due
            </Box>
          </Typography>

          <Typography>Payment is required to build credit.</Typography>
          <Typography variant="footnote" marginBottom={theme.spacing(2)}>
            Non-payment can negatively impact your credit score.
          </Typography>
        </CardContent>

        {!hideButton && (
          <CardActions>
            <Button
              variant="contained"
              component={Link}
              to="/installment-plan"
              fullWidth
            >
              Pay Now
            </Button>
          </CardActions>
        )}
      </Card>
    );
  }

  if (onlyShowPastDue) {
    return null;
  }

  return (
    <Box marginBottom={theme.spacing(2)} textAlign="center">
      <Typography variant="footnote">
        Account Status:{' '}
        <Box component="strong" color={theme.palette.success.main}>
          Current
        </Box>
      </Typography>
    </Box>
  );
};

export default StripeStatusBanner;
