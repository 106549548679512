import { LoadingButton } from '@mui/lab';
import { Alert, Box, Link } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import TextField from 'components/shared/TextField';
import { theme } from 'context/ThemeProvider';
import { normalizeEmail } from 'helpers/utils';
import { KovoError } from 'libs/KovoError';
import { AuthErrorCode } from 'libs/signupHelpers/types';

const SUPPORT_EMAIL = 'support@kovocredit.com';

interface FormValues {
  email: string;
}

const ForgotPasswordForm: React.FC = () => {
  const history = useHistory();

  const onSubmit = (values: FormValues) => {
    mutate(values);
  };

  const { touched, errors, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: '',
      },
      validationSchema: yup.object().shape({
        email: yup.string().required('Email is required'),
      }),
      onSubmit,
    });

  const sendConfirmationCode = async (values: FormValues) => {
    const email = normalizeEmail(values.email);
    try {
      await Auth.forgotPassword(email);

      return { ...values, email };
    } catch (error) {
      let sendConfirmationCodeError = new KovoError(
        'Error sending forgot password code',
      )
        .setError(error)
        .addMetadata({ email });

      if (error instanceof Error) {
        switch (error.name) {
          /**
           * If the user does not exist, treat this as success. This is to prevent
           * obvious user enumeration. This is simply a visual trick as the network
           * logs would still show the original error name and message.
           */
          case 'UserNotFoundException':
            return values;
          case 'LimitExceededException':
            sendConfirmationCodeError
              .setLogLevel('warn')
              .setCode(AuthErrorCode.LIMIT_EXCEEDED)
              .setDisplayMessage(error.message);
            break;
          case 'InvalidParameterException':
            const isUnconfirmedEmailException =
              'Cannot reset password for the user as there is no registered/verified email or phone_number' ===
              error.message;

            sendConfirmationCodeError.setLogLevel('error');

            /**
             * In this case, we should use the useResendEmailVerificationCode to resent the confirmation code.
             * The issue at this point is that there is no place for a user to input the email verfication code
             * when they are in this state. Once it is possible to access the email verification page without
             * authentication, this can be handled more gracefully.
             */
            if (isUnconfirmedEmailException) {
              sendConfirmationCodeError.setCode(
                AuthErrorCode.UNCONFIRMED_EMAIL_EXCEPTION,
              );
            }

            break;
        }
      }

      throw sendConfirmationCodeError;
    }
  };

  const onSuccess = (values: FormValues) => {
    const email = normalizeEmail(values.email);
    history.replace(`/reset-password?email=${encodeURIComponent(email)}`);
  };

  const {
    mutate,
    isLoading,
    error: sendConfirmationCodeError,
  } = useMutation<FormValues, KovoError, FormValues>(sendConfirmationCode, {
    onSuccess,
  });

  return (
    <>
      {sendConfirmationCodeError && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(3) }}
        >
          {sendConfirmationCodeError.code ===
          AuthErrorCode.UNCONFIRMED_EMAIL_EXCEPTION ? (
            <>
              Please email{' '}
              <Link
                href={`mailto:${SUPPORT_EMAIL}?subject=forgot-password-issue`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {SUPPORT_EMAIL}
              </Link>{' '}
              to reset your password.
            </>
          ) : (
            sendConfirmationCodeError.displayMessage
          )}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <Box marginBottom={theme.spacing(3)}>
          <TextField
            name="email"
            label="Email"
            type="email"
            value={values.email}
            error={!!(touched.email && errors.email)}
            errorText={errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </Box>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          fullWidth
        >
          Submit
        </LoadingButton>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
