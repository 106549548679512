import moment from 'moment';
import { Account } from 'types/schemas';

export const isInstallmentAccountInGoodStanding = (
  account?: Account,
): boolean => {
  if (!account) {
    return false;
  }

  // If their installment account is active
  if (account.status === 'active') {
    return true;
  }

  // Or if it's closed with a closureCode of 'paid'
  if (
    account?.status === 'closed' &&
    account?.closureCode &&
    ['paid', 'paid_early', 'paid_on_schedule'].includes(account?.closureCode)
  ) {
    return true;
  }

  return false;
};

export const isInstallmentAccountValidForIdMonitoring = (
  account?: Account,
): boolean => {
  if (!account) {
    return false;
  }

  if (account.status !== 'active' && account.status !== 'past_due') {
    return false;
  }

  const today = moment(new Date()).startOf('day');
  const effectiveAt = moment(account.effectiveAt).startOf('day');
  // If the feature isn't enabled quite yet, let's give us a month to get it ready
  const maxAgeMonths = 24;
  // If account is older than 24 months
  if (today.diff(effectiveAt, 'months') >= maxAgeMonths) {
    return false;
  }

  return true;
};
