import { Skeleton } from '@mui/material';

import EligibleForRewardsBanner from 'components/EligibleForRewardsBanner/EligibleForRewardsBanner';
import IneligibleForRewardsBanner from 'components/IneligibleForRewardsBanner';
import PastDueRewardsBanner from 'components/PastDueRewardsBanner';
import StripeStatusBanner from 'components/StripeStatusBanner';
import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import useStripeStatus from 'hooks/useStripeStatus';

const StripeRewardBanner: React.FC = () => {
  const { data: stripeStatus, isLoading: isLoadingStripe } = useStripeStatus();

  const { data: applicationStatusData, isLoading: isLoadingApplication } =
    useApplicationStatus();

  if (isLoadingStripe || isLoadingApplication) {
    return (
      <Skeleton
        variant="rounded"
        height={72}
        sx={{ marginBottom: theme.spacing(2) }}
      />
    );
  }

  if (!stripeStatus) {
    return null;
  }

  const isEligibleForRewards =
    applicationStatusData?.rewardsStatus.isEligibleForRewards3Months;

  const isPastDue = !!stripeStatus.openInvoices;

  if (isPastDue) {
    return (
      <>
        <PastDueRewardsBanner />

        <StripeStatusBanner onlyShowPastDue />
      </>
    );
  }

  if (isEligibleForRewards) {
    return <EligibleForRewardsBanner />;
  }

  return <IneligibleForRewardsBanner />;
};

export default StripeRewardBanner;
