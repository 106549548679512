import { API } from 'aws-amplify';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { KovoError } from 'libs/KovoError';

export type Course = {
  id: string;
  northpassId: string;
  name: string;
  description: string;
  imageUrl: string;
  enrollmentLink: string;
  instructor: string;
  instructorImageUrl: string | null;
  userId?: string;
  activityCount?: number;
  finishedActivityCount?: number;
};

function useGetCourses() {
  const { identityId, username } = useContext(AuthContext);

  const getCourses = async () => {
    try {
      const res = await API.get('clientsService', `/v1/courses`, {
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
          }).headers,
        },
      });

      return res.courses;
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error getting courses', { error });
    }
  };

  return useQuery<Course[], KovoError>(['courses'], () => getCourses());
}

export default useGetCourses;
