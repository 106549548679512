import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import ApplyForm from 'components/ApplyForm';
import OnboardingPage from 'components/OnboardingPage';
import PageLoader from 'components/PageLoader/PageLoader';
import { useAuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';
import * as impact from 'helpers/impact';
import useApplicationStatus from 'hooks/useApplicationStatus';

import Checkmark from 'assets/img/icons/checkmark-green.svg';
import { trackProductAnalytics } from 'libs/productAnalyticsTracking';

const LIST_ITEMS: string[] = [
  'No credit check',
  'Instant decision',
  'Safe and secure',
  'Hassle-free',
];

const ApplyPage: React.FC = () => {
  const history = useHistory();
  const { currentUser, username, email } = useAuthContext();
  const { data, isFetching, isLoading } = useApplicationStatus();

  useEffect(() => {
    if (isFetching) {
      return;
    }

    if (data && !['new', 'detailsNeeded'].includes(data.applicationStatus)) {
      history.replace('/');
      return;
    }

    if (data && !data.applicationDetails?.phoneNumber) {
      if (data.applicationDetails?.phoneNumberUnverified) {
        history.replace('/verify-phone-number');
        return;
      }

      history.replace('/phone-number');
      return;
    }

    if (currentUser) {
      impact.identify({
        customerId: username,
        customerEmail: email,
      });
    }
  }, [history, data, currentUser, isFetching, email, username]);

  if (isLoading) {
    return <PageLoader />;
  }

  const showUpfrontPayment = !window.ReactNativeWebView;

  return (
    <OnboardingPage onboardingStep={3} supportSubject="apply">
      <Typography variant="h1" marginBottom={theme.spacing(1.5)}>
        Apply to Credit Builder
      </Typography>

      <Typography marginBottom={theme.spacing(2)}>
        Build your credit with <strong>all 4 credit bureaus</strong>:
        TransUnion®, Equifax®, Experian®, Innovis®.
      </Typography>

      <List
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          marginBottom: theme.spacing(2),

          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 1fr',
          },
        }}
      >
        {LIST_ITEMS.map((text) => (
          <ListItem disablePadding key={text}>
            <ListItemIcon sx={{ minWidth: 0, marginRight: theme.spacing(1) }}>
              <Box
                component="img"
                src={Checkmark}
                alt=""
                height="18px"
                width="22px"
              />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>

      <ApplyForm />

      <Typography variant="footnote" marginBottom={theme.spacing(3)}>
        By clicking the SUBMIT button, you are signing the consent for SSA to
        disclose your SSN Verification to Kovo Inc. and SentiLink Verification
        Services Corp. You agree that your electronic signature has the same
        legal meaning, validity, and effect as your handwritten signature.
      </Typography>
      <Typography variant="footnote" marginBottom={theme.spacing(3)}>
        There is no hard pull on your credit when applying for and opening an
        account. Approval is subject to instant background verification.
      </Typography>
      <Typography
        variant="footnote"
        marginBottom={showUpfrontPayment ? theme.spacing(3) : undefined}
      >
        On-time payments can have a positive impact on your credit score. Late
        or non-payment can negatively impact your credit score. Impact on your
        credit may vary, since credit scores are independently determined by
        credit bureaus based on many factors, including the financial decisions
        you make with other financial services organizations.
      </Typography>
      {showUpfrontPayment && (
        <Typography variant="footnote">
          If you do not want to build credit, you can make a purchase without an
          Installment Credit Account by making a one-time payment of $240{' '}
          <MuiLink
            component={Link}
            to="/upfront-payment"
            onClick={() => {
              trackProductAnalytics({
                namespace: 'ui',
                event: 'apply.upfront-payment.clicked',
              });
            }}
          >
            here
          </MuiLink>
          .
        </Typography>
      )}
    </OnboardingPage>
  );
};

export default ApplyPage;
