import './CreditScoreSVG.css';

const CreditScoreSVG: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 312 317.45">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="5.65"
          y1="157.15"
          x2="304.94"
          y2="157.15"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#cc3c28" />
          <stop offset="0.26" stopColor="#f89922" />
          <stop offset="0.49" stopColor="#f2f31b" />
          <stop offset="0.64" stopColor="#e2eb21" />
          <stop offset="0.8" stopColor="#a6ce39" />
          <stop offset="0.91" stopColor="#4eb748" />
        </linearGradient>
      </defs>
      <title>Artboard 166</title>
      <g id="artes">
        <path
          id="credit-score-svg-path"
          d="M16.05,297c153.81,0,278.49-125.24,278.49-279.73"
          style={{
            fill: 'none',
            strokeLinecap: 'round',
            strokeWidth: '20.79px',
            stroke: 'url(#linear-gradient)',
            strokeDasharray: '1000',
            strokeDashoffset: '1000',
          }}
        />
      </g>
    </svg>
  );
};

export default CreditScoreSVG;
