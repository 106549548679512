import { CSSProperties } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import Checkmark from 'assets/img/icons/checkmark-circle-green.svg';
import {
  GREEN,
  KOVO_ALERT_LIGHT_GREEN,
  KOVO_BLUE,
  KOVO_GREEN,
  KOVO_LIGHT_BLUE,
  KOVO_LIGHT_ORANGE,
  KOVO_LIGHT_RED,
  KOVO_ORANGE,
  KOVO_RED_ALERT,
  theme,
} from 'context/ThemeProvider';
import { Box, Typography } from '@mui/material';

type KovoAlertSeverity = 'success' | 'error' | 'warning' | 'info';

const getAlertStyle = (severity: KovoAlertSeverity): CSSProperties => {
  const base: CSSProperties = {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2, 1.5),
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${getBorderColor(severity)}`,
  };
  switch (severity) {
    case 'success':
      return {
        ...base,
        backgroundColor: KOVO_ALERT_LIGHT_GREEN,
        borderColor: GREEN,
      };
    case 'error':
      return {
        ...base,
        backgroundColor: KOVO_LIGHT_RED,
        borderColor: KOVO_RED_ALERT,
      };
    case 'warning':
      return {
        ...base,
        backgroundColor: KOVO_LIGHT_ORANGE,
        borderColor: KOVO_ORANGE,
      };
    case 'info':
      return {
        ...base,
        backgroundColor: KOVO_LIGHT_BLUE,
        borderColor: KOVO_BLUE,
      };
    default:
      const exhaustive: never = severity;
      throw new Error(`Unknown severity: ${exhaustive}`);
  }
};

export function KovoAlert({
  severity = 'success',
  message,
}: {
  severity: 'success' | 'error' | 'warning' | 'info';
  message: string;
}) {
  const style = getAlertStyle(severity);

  return (
    <div style={style}>
      {getAlertIcon(severity)}
      <Typography variant="body1">{message}</Typography>
    </div>
  );
}

function getAlertIcon(severity: KovoAlertSeverity) {
  switch (severity) {
    case 'success':
      return (
        <Box
          component="img"
          src={Checkmark}
          alt="Success"
          sx={{
            marginRight: '8px',
            width: '18px',
            height: '18px',
          }}
        />
      );
    case 'error':
      return (
        <WarningIcon style={{ color: KOVO_RED_ALERT, marginRight: '8px' }} />
      );
    case 'warning':
      return <InfoIcon style={{ color: KOVO_ORANGE, marginRight: '8px' }} />;
    case 'info':
      return <InfoIcon style={{ color: KOVO_BLUE, marginRight: '8px' }} />;
    default:
      const exhaustive: never = severity;
      throw new Error(`Unknown severity: ${exhaustive}`);
  }
}

function getBorderColor(severity: KovoAlertSeverity) {
  switch (severity) {
    case 'success':
      return KOVO_GREEN;
    case 'error':
      return KOVO_RED_ALERT;
    case 'warning':
      return KOVO_ORANGE;
    case 'info':
      return KOVO_BLUE;
    default:
      const exhaustive: never = severity;
      throw new Error(`Unknown severity: ${exhaustive}`);
  }
}
