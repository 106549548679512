import { Typography } from '@mui/material';

import { useAccountContext } from 'context/AccountProvider';
import { theme } from 'context/ThemeProvider';

const CheckoutPaymentUpdateInfo: React.FC = () => {
  const { account } = useAccountContext();

  if (!account) {
    return null;
  }

  return (
    <Typography
      marginBottom={theme.spacing(3)}
      data-testid="update-payment-text"
    >
      {account.primaryPaymentMethod.walletType === 'apple_pay' ? (
        'Your monthly payments are currently set up on auto-pay with Apple Pay.'
      ) : account.primaryPaymentMethod.walletType === 'google_pay' ? (
        `Your monthly payments are currently set up on auto-pay with Google Pay ••${account.primaryPaymentMethod.last4}`
      ) : (
        <>
          Your monthly payments are currently set up on auto-pay with your card
          ending in {account.primaryPaymentMethod.last4}. Please update your
          payment method below.
        </>
      )}
    </Typography>
  );
};

export default CheckoutPaymentUpdateInfo;
