import { API } from 'aws-amplify';
import moment from 'moment';
import { useContext } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';

import config from 'config';
import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { KovoError } from 'libs/KovoError';
import { PaymentMethod } from 'types/schemas';

export const getStatus = async (identityId: string, username: string) => {
  const response = await API.get(
    'billing',
    '/billing/checkout/status',
    amplifyRequestContext(identityId, username),
  );

  return response;
};

interface Transaction {
  amount: number;
  createdAt: string;
  effectiveAt: string;
  id: string;
  status: 'succeeded' | 'failed' | 'processing';
  type: 'payment' | 'refund';
  trigger:
    | 'autopay'
    | 'autopay_retry'
    | 'customer_support'
    | 'manual'
    | 'principal';
}

export type LoanAccountStatus =
  | 'creating'
  | 'active'
  | 'past_due'
  | 'processing'
  | 'closed';

export type CheckoutStatusResponseBodyV2 = {
  autopayEnabled: boolean;
  accountId: string;
  amountDueCents: number;
  paymentDueTime: string | null;
  balance: number;
  provider: string;
  loanProvider: string | null;
  status: LoanAccountStatus;
  defaultPaymentMethod: {
    last4: string;
    scheme: string;
    expiryMonth: number;
    expiryYear: number;
  };
  transactions: Transaction[];
  paymentMethod: PaymentMethod | null;
};

export interface Schedule {
  minPayDueAt: string;
  minPayCents: number;
  cycleExclusiveEnd: string;
}

export interface Payment {
  amount: number;
  createdAt: string;
  effectiveAt: string;
  id: string;
  status: 'valid' | 'pending' | 'authorized' | 'invalid' | 'declined';
  type: 'payment';
}

export interface CheckoutStatus {
  paymentMethod: {
    last4: string;
  };
  loanAccount: {
    accountId: string;
    accountStatus: string;
    autopayEnabled: boolean;
    externalAccountId: string;
    minimumPaymentCents: number;
    minimumPaymentDue: string;
    previousStatementMinPayCents: number;
    /* Kovo patch, not a Canopy field */
    patchedMinimumPaymentDue: number;
    /* Kovo patch, not a Canopy field */
    patchedHideHistory: boolean;
    summary: {
      creditLimitCents: number;
      principalCents: number;
      totalBalanceCents: number;
      totalPaidToDateCents: number;
      totalPayoffCents: number;
    };
  };
  schedule: Schedule[];
  payments: {
    pendingPayments: Payment[];
    validPayments: Payment[];
    invalidPayments: Payment[];
  };
  status: 'creating' | 'confirming' | 'activated';
  totalAmountPaid: number;
  totalAmountExpected: number;
  loanStatus: 'active' | 'past_due' | 'closed';
  amountDue: number;
}

type CheckoutStatusResponseShapes = CheckoutStatusResponseBodyV2;

const TWENTY_FIVE_HOURS = 1000 * 60 * 60 * 25;

/**
 * Fix for the V2 shape as well
 */
const patchResponseForCanopyBugV2 = (
  data: CheckoutStatusResponseBodyV2,
): CheckoutStatusResponseBodyV2 => {
  const { status, transactions, paymentDueTime, loanProvider } = data;

  // If this sort of looks like a newly opened account from Canopy
  if (
    transactions.length === 1 &&
    status === 'active' &&
    paymentDueTime &&
    loanProvider !== 'kovointernal'
  ) {
    const [payment] = transactions;
    const openDate = new Date(payment.createdAt).getTime();
    const now = new Date().getTime();

    // If this account has been opened in the last day-ish
    if (openDate + TWENTY_FIVE_HOURS > now) {
      const minPayDueAt = moment(paymentDueTime);
      // Canopy adds an extra month, so check for that here
      const diff = minPayDueAt.diff(moment(payment.createdAt), 'months', true);

      // If we have detected that the payment due date has been miscalculated by
      // Canopy in the first 24-ish hours that the account was open
      if (diff > 1) {
        data.paymentDueTime = moment(payment.createdAt)
          .add(1, 'month')
          .toISOString();
        // Fix minimum payment amount as well
        data.amountDueCents = 1000;
      }
    }
  }

  return data;
};

function useCheckoutStatusV2(
  options?: UseQueryOptions<CheckoutStatusResponseShapes, KovoError>,
) {
  const { identityId, username } = useContext(AuthContext);

  const getPaymentInfo = async (): Promise<CheckoutStatusResponseShapes> => {
    try {
      const response = await API.get(
        'billing',
        '/billing/checkout/status',
        amplifyRequestContext(identityId, username),
      );

      return patchResponseForCanopyBugV2(response);
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error getting checkout status').setError(error);
    }
  };

  const queryOptions: UseQueryOptions<CheckoutStatusResponseShapes, KovoError> =
    {
      refetchOnWindowFocus: false,
      refetchInterval: (data) => {
        if (config.VITE_STAGE === 'local') {
          return false;
        }

        // automatically refetch while status === creating or processing
        return data?.status && ['creating', 'processing'].includes(data.status)
          ? 2000
          : false;
      },
      ...options,
    };

  /**
   * TODO: Is a duplidate query ident here an issue? See useCheckoutStatus.ts as well
   */
  return useQuery<CheckoutStatusResponseShapes, KovoError>(
    ['CheckoutPaymentInfo'],
    () => getPaymentInfo(),
    queryOptions,
  );
}

export default useCheckoutStatusV2;
