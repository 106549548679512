import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { shadowLinkCard, theme } from 'context/ThemeProvider';

interface Props {
  route: string;
  icon: string;
  iconDesktop: string;
  title: string;
  subtitle?: JSX.Element;
  ctaButton?: JSX.Element;
}

const NavigationItem: React.FC<Props> = ({
  route,
  icon,
  iconDesktop,
  title,
  subtitle,
  ctaButton,
}) => {
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Card
      sx={{
        height: '100%',
        boxShadow: shadowLinkCard,
        padding: theme.spacing(0.75),
        borderRadius: theme.spacing(1.5),

        [theme.breakpoints.up('sm')]: {
          borderRadius: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
          borderRadius: theme.spacing(3),
          padding: theme.spacing(2),
        },
      }}
      elevation={0}
    >
      <CardActionArea
        component={Link}
        to={route}
        sx={{
          height: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          padding: theme.spacing(0.75),
          margin: theme.spacing(-0.75),

          [theme.breakpoints.up('md')]: {
            margin: theme.spacing(-1.5),
            padding: theme.spacing(1.5),
          },
        }}
        disableRipple
      >
        <CardContent
          sx={{
            display: 'flex',
            flex: '1 0 100%',
          }}
        >
          <Box
            component="img"
            src={isSmallAndUp ? iconDesktop : icon}
            alt=""
            sx={{
              height: '36px',
              width: '36px',
              marginRight: theme.spacing(0.5),
              flexShrink: 0,

              [theme.breakpoints.up('sm')]: {
                height: '52px',
                width: '52px',
                marginRight: theme.spacing(1),
              },

              [theme.breakpoints.up('md')]: {
                height: '72px',
                width: '72px',
                marginRight: theme.spacing(1),
              },
            }}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              flexGrow: 1,

              [theme.breakpoints.up('md')]: {
                minHeight: '72px',
              },
            }}
          >
            <Typography variant="h6">{title}</Typography>

            {subtitle && subtitle}
          </Box>
        </CardContent>

        {ctaButton && (
          <CardActions sx={{ marginTop: theme.spacing(1), flex: '1 0 100%' }}>
            {ctaButton}
          </CardActions>
        )}
      </CardActionArea>
    </Card>
  );
};

export default NavigationItem;
