import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { calculateAmountPastDue } from 'components/CatchupPayment/past-due-amount-calculations';
import { ROUTES } from 'components/Routes';
import { theme } from 'context/ThemeProvider';
import useGetAccounts from 'hooks/queries/useGetAccounts';
import { loanAccountStatusToLabelMap } from 'libs/loanAccountStatusToLabelMap';

const AccountStatusCard: React.FC = () => {
  const { data, isLoading } = useGetAccounts();

  if (isLoading) {
    return <Skeleton variant="rounded" height={153} />;
  }

  if (!data) {
    return null;
  }

  const { accounts } = data;

  const account = accounts.find(
    ({ product }) => product.type === 'loan_installment',
  );

  if (!account) {
    return null;
  }

  const nextPaymentDueDate = moment(account.currentSchedule?.autopayAt).format(
    'MM/DD/YY',
  );

  const balanceCents = account.balance;
  const remainingBalance = balanceCents;
  const remainingBalanceDollars = (remainingBalance / 100).toFixed(2);
  const pastDueAmountDollars = calculateAmountPastDue(account);

  const accountStatusLabel = loanAccountStatusToLabelMap[account.status];

  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h5">ACCOUNT INFORMATION</Typography>

        <Divider sx={{ margin: theme.spacing(2, 0) }} />

        <Typography marginBottom={theme.spacing(2)}>
          <strong>
            Status:{' '}
            <Typography
              component={'span'}
              sx={{
                color: accountStatusLabel.color,
              }}
              marginBottom={theme.spacing(2)}
            >
              <Box component="strong">
                {account.status !== 'past_due' && accountStatusLabel.label}
                {account.status === 'past_due' &&
                  `${accountStatusLabel.label} $${pastDueAmountDollars}`}
              </Box>
            </Typography>
          </strong>
        </Typography>

        {account.status === 'active' && (
          <>
            <Typography variant="footnote" gutterBottom>
              Next AutoPay: {nextPaymentDueDate}
            </Typography>
            <Typography variant="footnote">
              Remaining balance: ${remainingBalanceDollars}
            </Typography>
          </>
        )}

        {account.status === 'past_due' && (
          <Button
            variant="contained"
            component={Link}
            to={ROUTES.INSTALLMENT_CATCHUP_PAYMENT}
            fullWidth
          >
            Catch up
          </Button>
        )}

        {account.status === 'processing' && (
          <Typography variant="footnote">
            You have a payment that is currently processing. Please refresh this
            screen after a few seconds to see your updated status
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default AccountStatusCard;
