import { Box } from '@mui/material';

import { theme } from 'context/ThemeProvider';

import KovoLogoSvg from 'assets/img/logos/kovo-logo.svg';

interface Props {
  centerLogo?: boolean;
}

const KovoLogo: React.FC<Props> = ({ centerLogo }) => {
  return (
    <Box
      component="img"
      sx={{
        verticalAlign: 'bottom',
        marginRight: centerLogo ? '0' : 'auto',
        height: '35px',
        width: '48px',

        [theme.breakpoints.up('md')]: {
          height: '50px',
          width: '69px',
        },
      }}
      alt="Kovo logo"
      src={KovoLogoSvg}
    />
  );
};

export default KovoLogo;
