import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Link as MuiLink, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import CopySuccessOverlay from 'components/CopySuccessOverlay';
import OnboardingPage from 'components/OnboardingPage';
import ForgotEmailForm from 'components/ForgotEmailForm';
import { theme } from 'context/ThemeProvider';
import { copyText } from 'helpers/copyText';
import { ForgotEmailResponse } from 'types/schemas';

const ForgotEmailPage: React.FC = () => {
  const [successScreen, setSuccessScreen] = useState(false);
  const [email, setEmail] = useState('');
  const [showEmailCopied, setShowEmailCopied] = useState(false);
  const [showEmailButtonCopied, setShowEmailButtonCopied] = useState(false);

  useEffect(() => {
    if (showEmailCopied) {
      const timeout = setTimeout(() => {
        setShowEmailCopied(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [showEmailCopied]);

  useEffect(() => {
    if (showEmailButtonCopied) {
      const timeout = setTimeout(() => {
        setShowEmailButtonCopied(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [showEmailButtonCopied]);

  const handleCopyEmail = async (toggle: 'email' | 'button') => {
    const result = await copyText(email);

    if (!result) {
      return;
    }

    if (toggle === 'email') {
      setShowEmailCopied(true);
    }

    if (toggle === 'button') {
      setShowEmailButtonCopied(true);
    }
  };

  const handleSuccess = ({ email }: ForgotEmailResponse) => {
    setEmail(email);
    setSuccessScreen(true);
  };

  return (
    <OnboardingPage
      centerLogo
      linkLogoToHomepage
      supportSubject="forgot password"
    >
      {!successScreen ? (
        <>
          <MuiLink
            component={Link}
            to="/login"
            underline="none"
            variant="body1"
            color={theme.palette.primary.main}
            marginBottom={theme.spacing(2)}
            display="inline-block"
          >
            <ArrowBackIcon
              sx={{ verticalAlign: 'top', marginRight: theme.spacing(1) }}
            />
            <Box component="strong">Log in</Box>
          </MuiLink>

          <Typography variant="h1">Forgot your email?</Typography>

          <Typography
            variant="body1"
            marginTop={theme.spacing(2)}
            marginBottom={theme.spacing(2)}
          >
            Verify your identity to retrieve the email on your account.
          </Typography>

          <ForgotEmailForm onSuccess={handleSuccess} />

          <Typography
            variant="h3"
            textAlign="center"
            marginTop={theme.spacing(2)}
            marginBottom={theme.spacing(3)}
          >
            Don’t have an account?
          </Typography>

          <Button
            variant="outlined-gradient"
            component={Link}
            to="/signup"
            fullWidth
          >
            Sign Up
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h1">Your email address</Typography>

          <Typography
            variant="body1"
            marginTop={theme.spacing(2)}
            marginBottom={theme.spacing(3)}
          >
            Please use the following email to log in.
          </Typography>

          <Box
            sx={{
              position: 'relative',
              boxSizing: 'border-box',
              display: 'block',
            }}
          >
            {/**
             * For now, this button does not scroll if the text is too long. It just truncates the email address.
             */}
            <Button
              variant="outlined"
              fullWidth
              sx={{
                textTransform: 'none',
                borderRadius: theme.spacing(1),
                display: 'block',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                overflow: 'hidden',
              }}
              onClick={() => handleCopyEmail('email')}
            >
              {email}
            </Button>
            {/**
             * For now, this does not animate in or out
             */}
            {showEmailCopied && <CopySuccessOverlay borderRadius="8px" />}
          </Box>

          <Box
            sx={{
              position: 'relative',
              boxSizing: 'border-box',
              display: 'block',
              marginTop: theme.spacing(3),
            }}
          >
            <Button
              variant="outlined-gradient"
              fullWidth
              onClick={() => handleCopyEmail('button')}
            >
              Copy email
            </Button>
            {/**
             * For now, this does not animate in or out
             */}
            {showEmailButtonCopied && <CopySuccessOverlay />}
          </Box>

          <Button
            variant="contained"
            component={Link}
            to={{
              pathname: '/login',
              state: {
                loginEmail: email,
              },
            }}
            fullWidth
            sx={{ marginTop: theme.spacing(3) }}
          >
            Proceed to login
          </Button>
        </>
      )}
    </OnboardingPage>
  );
};

export default ForgotEmailPage;
