import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Skeleton,
  SxProps,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';

import UpfrontPaymentBanner from 'components/UpfrontPaymentBanner';
import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import useCheckoutStatusV2 from 'hooks/useCheckoutStatusV2';

interface Props {
  hideButton?: boolean;
  sx?: SxProps;
  onlyShowPastDue?: boolean;
}

const CheckoutStatusBanner: React.FC<Props> = ({
  hideButton,
  sx,
  onlyShowPastDue,
}) => {
  const { data: checkoutStatus, isLoading: isLoadingCheckoutStatus } =
    useCheckoutStatusV2();

  const { data: applicationStatus, isLoading: isLoadingApplicationStatus } =
    useApplicationStatus();

  if (isLoadingCheckoutStatus || isLoadingApplicationStatus) {
    return (
      <Box
        sx={{
          marginBottom: theme.spacing(2),
          textAlign: 'center',
        }}
      >
        <Typography>
          <Skeleton
            sx={{
              display: 'inline-block',
              width: '178px',
            }}
          />
        </Typography>
        <Typography>
          <Skeleton
            sx={{
              display: 'inline-block',
              width: '207px',
            }}
          />
        </Typography>
      </Box>
    );
  }

  if (!applicationStatus || !checkoutStatus) {
    return null;
  }

  const loanStatus = checkoutStatus.status;

  if (applicationStatus?.applicationStatus === 'upfrontPaymentCompleted') {
    return <UpfrontPaymentBanner />;
  }

  if (
    !checkoutStatus ||
    applicationStatus?.applicationStatus === 'closedProductAccessRemains' ||
    loanStatus === 'closed'
  ) {
    return null;
  }

  const isPastDue = loanStatus === 'past_due';
  const isProcessing = loanStatus === 'processing';

  const amountDueCents = checkoutStatus.amountDueCents;
  const amountDueDollars = (amountDueCents / 100).toFixed(2);

  /**
   * The payment date may be in progress of being created, so when rendering
   * this date, check for null to see if the billing account is still
   * 'creating'. Otherwise, the date should be populated until the account
   * is closed or autopay is disabled.
   */
  const paymentDueTime = checkoutStatus.paymentDueTime;
  const nextPaymentDueDate = moment(paymentDueTime).format('MMM DD, YYYY');

  if (!isProcessing && isPastDue) {
    return (
      <Card
        elevation={0}
        sx={{
          marginBottom: theme.spacing(2),
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '400px',
          textAlign: 'center',
        }}
      >
        <CardContent>
          <Typography variant="h6" marginBottom={theme.spacing(1)}>
            <Box component="strong">Account Status: </Box>
            <Box component="strong" color={theme.palette.error.main}>
              Past Due ${amountDueDollars}
            </Box>
          </Typography>

          <Typography>Payment is required to build credit.</Typography>
          <Typography variant="footnote" marginBottom={theme.spacing(2)}>
            Non-payment can negatively impact your credit score.
          </Typography>
        </CardContent>

        <CardActions>
          <Button
            variant="contained"
            component={Link}
            to="/installment-plan"
            fullWidth
            startIcon={
              <ArrowForwardIcon
                sx={{ [theme.breakpoints.down('sm')]: { display: 'none' } }}
              />
            }
            endIcon={
              <ArrowBackIcon
                sx={{ [theme.breakpoints.down('sm')]: { display: 'none' } }}
              />
            }
          >
            Pay Now
          </Button>
        </CardActions>
      </Card>
    );
  }

  if (onlyShowPastDue) {
    return null;
  }

  if (isProcessing) {
    return (
      <Typography
        variant="footnote"
        marginBottom={theme.spacing(2)}
        textAlign="center"
      >
        Account Status: <Box component="strong">Payment processing</Box>
      </Typography>
    );
  }

  if (!isProcessing && !isPastDue) {
    return (
      <Box marginBottom={theme.spacing(2)} textAlign="center">
        <Typography variant="footnote">
          Account Status:{' '}
          <Box component="strong" color={theme.palette.success.main}>
            Current
          </Box>
        </Typography>

        {paymentDueTime && (
          <Typography variant="footnote">
            Next AutoPay: {nextPaymentDueDate}
          </Typography>
        )}
      </Box>
    );
  }

  return null;
};

export default CheckoutStatusBanner;
