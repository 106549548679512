import { API } from 'aws-amplify';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { Reward } from 'components/RewardsList/RewardsList';
import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { KovoError } from 'libs/KovoError';

export const useRewardsQuery = () => {
  const { identityId, username } = useContext(AuthContext);

  const getRewards = async (identityId: string) => {
    try {
      const rewards = await API.get(
        'billing',
        '/billing/v2/rewards',
        amplifyRequestContext(identityId, username),
      );

      return rewards;
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Failed to fetch rewards', { error });
    }
  };

  return useQuery<Reward[]>(
    ['rewards', identityId],
    () => getRewards(identityId),
    {
      refetchOnWindowFocus: false,
    },
  );
};
