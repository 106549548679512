import { Skeleton } from '@mui/material';

import CreditScoreOnTimeMessage from 'components/CreditScoreOnTimeMessage';
import CreditScorePastDueMessage from 'components/CreditScorePastDueMessage';
import { theme } from 'context/ThemeProvider';
import useStripeStatus from 'hooks/useStripeStatus';

const CreditScoreStripeNote: React.FC = () => {
  const { data: stripeStatus, isLoading } = useStripeStatus({ retry: false });

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        height={120}
        sx={{ marginBottom: theme.spacing(2) }}
      />
    );
  }

  if (!stripeStatus) {
    return null;
  }

  const isPastDue = !!stripeStatus.openInvoices;

  if (isPastDue) {
    return <CreditScorePastDueMessage />;
  }

  return <CreditScoreOnTimeMessage />;
};

export default CreditScoreStripeNote;
