import { API } from 'aws-amplify';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { USE_APPLICATIONS_QUERY_KEY } from 'hooks/queries/useGetApplications';
import { KovoError } from 'libs/KovoError';
import { CHARGE_ACCOUNT_PRODUCT_ID } from 'types/schemas';

function useCreateApplication() {
  const { identityId, username } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const createApplication = async () => {
    try {
      const res = await API.post('clientsService', '/v1/applications', {
        body: {
          loanProductId: CHARGE_ACCOUNT_PRODUCT_ID,
        },
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
            'content-type': 'application/json',
          }).headers,
        },
      });

      return res;
    } catch (error) {
      throw new KovoError('Error creating application').setError(error);
    }
  };

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [USE_APPLICATIONS_QUERY_KEY],
    });
  };

  return useMutation(createApplication, { onSuccess });
}

export default useCreateApplication;
