import UpdatePaymentMethodPage from 'pages/account/UpdatePaymentMethodPage';

const ChargeAccountUpdatePaymentMethodPage = () => {
  return (
    <UpdatePaymentMethodPage
      productType="charge_revolving"
      billingProvider="checkout"
    />
  );
};

export default ChargeAccountUpdatePaymentMethodPage;
