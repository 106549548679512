import { API } from 'aws-amplify';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { GiftCard } from 'types';

type FetchGiftCardsResponse = {
  giftCards: GiftCard[];
};

export const useGiftCards = () => {
  const { identityId, username } = useContext(AuthContext);

  const fetchGiftCards = async (): Promise<FetchGiftCardsResponse> => {
    const data = await API.get(
      'billing',
      '/billing/gift-cards',
      amplifyRequestContext(identityId, username),
    );

    return data;
  };

  return useQuery<FetchGiftCardsResponse, Error, GiftCard[]>(
    ['giftCards'],
    () => fetchGiftCards(),
    {
      select: (data) => data.giftCards,
    },
  );
};
