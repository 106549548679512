import { API } from 'aws-amplify';
import { amplifyRequestContext } from 'helpers/amplify-request-context';

export class BillingService {
  api: typeof API;
  userId: string;
  username: string;

  constructor(api: typeof API, userId: string, username: string) {
    this.api = api;
    this.userId = userId;
    this.username = username;
  }

  post(endpoint: string, body: object) {
    return this.api.post('billing', endpoint, {
      body,
      headers: {
        ...this.headers,
      },
    });
  }

  get(endpoint: string) {
    return this.api.get('billing', endpoint, {
      headers: {
        ...this.headers,
      },
    });
  }

  get headers() {
    return amplifyRequestContext(this.userId, this.username).headers;
  }
}
