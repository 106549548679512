import { LoadingButton } from '@mui/lab';
import { Alert, Box, Link, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import { ROUTES } from 'components/Routes';
import TextField from 'components/shared/TextField';
import { theme } from 'context/ThemeProvider';
import { useConfirmEmailAttribute } from 'hooks/mutations/useConfirmEmailUpdate';
import { useVerifyEmailAttribute } from 'hooks/mutations/useVerifyEmailAttribute';

export interface Props {
  currentEmail: string;
  newEmail: string;
}

interface FormValues {
  code: string;
}

const ChangeEmailConfirmationForm: React.FC<Props> = ({
  currentEmail,
  newEmail,
}) => {
  const [resendComplete, setResendComplete] = useState(false);
  const { mutate, isLoading, error } = useConfirmEmailAttribute();
  const {
    mutate: resendCode,
    isLoading: resendCodeIsLoading,
    error: resendError,
  } = useVerifyEmailAttribute();

  const onSubmit = (values: FormValues) => {
    mutate(values);
  };

  const onResend = () => {
    resendCode();
    setResendComplete(true);
  };

  const { touched, errors, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        code: '',
      },
      validationSchema: yup.object().shape({
        code: yup.string().required('Code is required'),
      }),
      onSubmit,
    });

  const renderResendLink = () => {
    if (resendError) {
      return '';
    }

    if (resendCodeIsLoading) {
      return 'Sending...';
    }

    if (resendComplete) {
      return 'Sent!';
    }

    /**
     * TODO: why is this styling necessary, can this be done in the theme?
     */

    return (
      <Link
        type="button"
        component="button"
        sx={{
          fontSize: 'inherit',
          display: 'inline',
          lineHeight: 'inherit',
          verticalAlign: 'inherit',
        }}
        onClick={() => onResend()}
      >
        Resend
      </Link>
    );
  };

  const errorMessage = error
    ? error.displayMessage || error.message
    : undefined;

  const resendErrorMessage = resendError
    ? resendError.displayMessage || resendError.message
    : undefined;

  return (
    <form onSubmit={handleSubmit} data-testid="change-email-confirmation-form">
      {error ? (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(2) }}
        >
          {errorMessage}
        </Alert>
      ) : null}

      {resendError ? (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(2) }}
        >
          {resendErrorMessage}
        </Alert>
      ) : null}

      <Box>
        <Typography variant="footnote">Current email</Typography>
        <Typography sx={{ wordWrap: 'break-word' }}>
          <strong>{currentEmail}</strong>
        </Typography>
      </Box>

      <Box sx={{ marginTop: theme.spacing(2) }}>
        <Typography variant="footnote">New email</Typography>
        <Typography sx={{ wordWrap: 'break-word' }}>
          <strong>{newEmail}</strong>
        </Typography>
      </Box>

      <Box sx={{ marginBottom: theme.spacing(4), marginTop: theme.spacing(2) }}>
        <TextField
          name="code"
          label="Email confirmation code"
          error={!!(touched.code && errors.code)}
          errorText={errors.code}
          value={values.code}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
        />
      </Box>

      <LoadingButton
        variant="contained"
        type="submit"
        loading={isLoading}
        fullWidth
      >
        Verify
      </LoadingButton>

      <Typography variant="footnote" sx={{ marginTop: theme.spacing(4) }}>
        Didn't get the code? {renderResendLink()}
      </Typography>
      <Typography variant="footnote" sx={{ marginTop: theme.spacing(1) }}>
        Need to correct your email?{' '}
        <Link component={RouterLink} to={ROUTES.SETTINGS_UPDATE_EMAIL}>
          Go back.
        </Link>
      </Typography>
    </form>
  );
};

export default ChangeEmailConfirmationForm;
