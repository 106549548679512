import Cookies from 'js-cookie';

import config from 'config';

export const KOVO_REFERRAL_CODE_KEY = 'kovo_referral_code';

export type KovoCookieKey =
  | 'kovo_referral_code'
  | 'kovo_marketing_attributions'
  | '_fbc'
  | '_fbp';

export const getCookie = (key: KovoCookieKey): string | undefined => {
  return Cookies.get(key);
};

export const removeCookie = (key: KovoCookieKey): void => {
  const domain =
    config.VITE_STAGE === 'prod' ? 'kovocredit.com' : window.location.hostname;

  Cookies.remove(key, { path: '/', domain });
};
