import { Card, CardContent, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';

interface Props {
  scoreIncrease: number;
}

const CreditScoreIncreaseBanner: React.FC<Props> = ({ scoreIncrease }) => {
  return (
    <Card variant="gradient">
      <CardContent>
        <Typography
          color="inherit"
          variant="h2"
          textAlign="center"
          sx={{
            [theme.breakpoints.up('xs')]: {
              color: theme.palette.common.white,
              fontFamily: 'Inter',
              fontSize: '54px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '.82',
              letterSpacing: '-4px',
              marginBottom: theme.spacing(1.5),
            },
          }}
        >
          +{scoreIncrease} points!
        </Typography>
        <Typography textAlign="center">
          This is how much your score grew since you started building credit
          with Kovo.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CreditScoreIncreaseBanner;
