import {
  Box,
  List,
  ListItem,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import OnboardingPage from 'components/OnboardingPage';
import { theme } from 'context/ThemeProvider';
import { Helmet } from 'react-helmet';

export const TermsOfUsePage: React.FC = () => {
  return (
    <Box
      style={{
        overflowX: 'hidden',
      }}
    >
      <Helmet>
        <title>Kovo Terms of Use</title>
      </Helmet>
      <OnboardingPage supportSubject="Terms of Use">
        <Typography marginBottom={theme.spacing(4)} variant="h1">
          Kovo Terms of Use
        </Typography>
        <Typography marginBottom={theme.spacing(3)} variant="body1">
          November 23, 2023
        </Typography>
        <Box display="flex" flexDirection="column" gap={theme.spacing(3)}>
          <Typography variant="body1">
            These Terms of Use (“Terms”) govern your access to and use of the
            kovocredit.com website (the “Website”) and all products and services
            (collectively, the “Services”) offered by Kovo Inc. or any of its
            subsidiaries companies (collectively “Kovo”, “we” or “us”. You agree
            that by visiting the Services or by registering or using the
            Services, you are entering a legally binding agreement with Kovo,
            based on the Terms and the{' '}
            <MuiLink
              href="/legal/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy & Notice
            </MuiLink>
            , which is hereby incorporated by reference (collectively referred
            to as the “Agreement”). By accessing or using the Services you agree
            to be bound by the Agreement. If you do not agree with these terms
            and conditions, you may not access or use the Services.
          </Typography>
          <Typography variant="h2">1. Basic Terms and Eligibility</Typography>
          <Typography variant="body1">
            You are responsible for your use of the Services and for any
            consequences thereof. You will need to register for a Kovo account
            to access many aspects of our Services. By registering for an
            account you certify:
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              You are 18 years of age or older
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              You have a valid U.S. Social Security number
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              You are not barred from accessing the Services under the laws of
              the United States or other applicable jurisdiction
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              Any information you provide to us, both when you register and in
              the future, is and will be true, accurate, current and complete
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              You are only registering an account for yourself
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              You will not sell, transfer, or assign your account to anyone
              else.
            </ListItem>
          </List>
          <Typography variant="h2">2. Changes and Modifications</Typography>
          <Typography variant="body1">
            The Services are always evolving and the form and nature of the
            Services may change from time to time without prior notice to you.
            In addition, Kovo may stop (permanently or temporarily) providing
            the Services (or any features within the Services) to you or to
            users generally and may not be able to provide you with prior
            notice. We also retain the right to create limits on use and storage
            at our sole discretion at any time without prior notice to you.
          </Typography>
          <Typography variant="h2">3. Privacy</Typography>
          <Typography variant="body1">
            Any information that you provide to Kovo is subject to our Privacy
            Policy & Notice, which governs our collection and use of your
            information. Please read the{' '}
            <MuiLink
              href="/legal/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy & Notice
            </MuiLink>{' '}
            carefully for information relating to our collection, use, and
            disclosure of your personal information. You understand that through
            your use of the Services you consent to the collection and use of
            this information and represent that you have the full right and
            authority to submit information for processing, including the
            transfer of this information to the United States and/or other
            countries for storage, processing and use by Kovo. As part of
            providing you the Services, we may need to provide you with certain
            communications, such as service announcements and administrative
            messages. These communications are considered part of the Services
            and your Kovo account, which you may not be able to opt-out from
            receiving.
          </Typography>
          <Typography variant="h2">4. Usernames and Passwords</Typography>
          <Typography variant="body1">
            You will keep your password and credentials that you use to access
            the Services confidential and not share it or allow anyone else to
            log into our Services as you. You are responsible for all activities
            that occur under your account. We encourage you to use strong or
            complex passwords for your Kovo account. Kovo cannot and will not be
            liable for any loss or damage arising from your failure to comply
            with the above requirements. You agree to notify us immediately upon
            becoming aware of any breach of security or unauthorized use of your
            account.
          </Typography>
          <Typography variant="h2">5. Identity Verification</Typography>
          <Typography variant="body1">
            You authorize us directly or through third parties, to authenticate
            your identity and account information provided. This may include
            requiring you to take steps to validate ownership of your email
            address and telephone number and verifying your information against
            third party databases or through other sources.
          </Typography>
          <Typography variant="h2">
            6. Telephone and Communication Consent
          </Typography>
          <Typography variant="body1">
            You agree that we may contact you about any matter relating to your
            registration of your account, application, or questions about our
            Services and for any purpose arising out of or in connection with
            any account you may obtain from us. We may contact you for any
            purpose related to any account you have, such as payment reminders
            or suspicious activity. You agree that Kovo and our agents,
            representatives, affiliates or anyone calling or contacting you on
            our behalf may contact you on a recorded line or recorded messaging
            service, including through a virtual assistant.
          </Typography>
          <Typography variant="h3">A. Telephone Communications</Typography>
          <Typography variant="body1">
            Kovo and its agent or representative may leave messages on your
            answering machine, voice mail, or send messages including SMS text
            messages. Standard calling and text messaging rates will apply,
            based on your cellular telephone carrier and service plan and you
            are responsible for any fees imposed by your carrier. You certify
            the telephone number that you have provided to us belongs to you and
            that you are permitted to receive calls or texts and you will
            promptly notify us if you change your telephone number.
          </Typography>
          <Typography variant="body1">
            By providing us with a telephone number for a cellular phone or
            other wireless device, you agree we may contact you in any way
            including using pre recorded messages or artificial voice, and calls
            and messages delivered using an auto telephone dialing system or an
            automated texting system. Automated messages may be played when the
            telephone is answered, whether by you or someone else. In the event
            that an agent or representative calls, he or she may also leave a
            message on your answering machine, voice mail, or via text.
          </Typography>
          <Typography variant="h3">B. Marketing Calls and Texts</Typography>
          <Typography variant="body1">
            By providing us with a telephone number for a cellular phone or
            other wireless device, you consent to receive autodialed and
            pre-recorded text messages from or on behalf of Kovo at the number
            provided for marketing or promotional purposes. Message frequency
            varies. If provided, your consent will be effective even if the
            number you have provided is registered on any state or federal
            Do-Not-Call (DNC) list. This consent for telemarketing calls shall
            remain in effect until you revoke it.
          </Typography>
          <Typography variant="body1">
            To stop receiving marketing-related messages, you may reply STOP to
            any marketing-related text message you receive from us. After you
            text “STOP” to us, we will send you a text to confirm that you have
            been unsubscribed. After this, you will no longer receive text
            messages from us.
          </Typography>
          <Typography variant="body1">
            You understand that consent to marketing-related messages is not a
            condition of using the Services. You may also reach out to us to
            revoke consent at any time by email at support@kovocredit.com.
          </Typography>
          <Typography variant="h2">7. Use of Virtual Assistant</Typography>
          <Typography variant="body1">
            Our Services may use automated features such as a virtual assistant.
            Our virtual assistant is designed to provide helpful information and
            improve your experience but as artificial intelligence and related
            technologies are evolving, they may have limitations. It is possible
            that our virtual assistant may generate an answer that is incorrect.
            You can always reach out to a customer support agent to verify
            whether the information is appropriate for your circumstances.
          </Typography>
          <Typography variant="h2">
            8. Social Security Number Protection
          </Typography>
          <Typography variant="body1">
            We value your trust, and we are committed to the responsible
            protection of your Social Security number ("SSN"). This applies to
            any SSN that we collect in the course of our business such as
            providing your loan and reporting your credit to credit reporting
            bureaus. We protect the confidentiality, security and integrity of
            SSNs, including by implementing and maintaining administrative,
            technical, and physical safeguards to protect against unauthorized
            access to SSNs. We also limit access to SSNs, including by only
            granting access to SSNs to our employees who require that
            information to perform their job-related duties.
          </Typography>
          <Typography variant="h2">9. Third Party Service Providers</Typography>
          <Typography variant="body1">
            You authorize Kovo to provide your personal information to its third
            party service providers and its partners offering Services and for
            them to receive and use this information as Kovo deems reasonably
            necessary in the course of performing Service configuration,
            delivery, maintenance, and billing.
          </Typography>
          <Typography variant="h2">
            10. Credit Score Monitoring Authorization
          </Typography>
          <Typography variant="body1">
            For account holders, Kovo offers you the opportunity to obtain your
            credit score from a single credit reporting agency for the purpose
            of allowing you to monitor your credit score. You authorize and
            instruct Kovo to obtain your full credit reporting information from
            a credit reporting agency in accordance with the Fair Credit
            Reporting Act on a regular recurring basis for as long as you have a
            registered open account. The credit score obtained by Kovo and
            shared with you is for your own use and for your education.
          </Typography>
          <Typography variant="h2">
            11. Kovo Intellectual Property Rights
          </Typography>
          <Typography variant="body1">
            All right, title, and interest in and to the Services and any
            content available on the Services are and will remain the exclusive
            property of Kovo and its licensors. The Services and its content are
            protected by copyright, trademark, and other laws of both the United
            States and foreign countries. Kovo reserves all rights not expressly
            granted in these Terms. You agree not to reproduce, duplicate, copy,
            sell, resell, use or exploit for any commercial purposes, this
            website, or use of or access to the website, or any information or
            technology obtained from the website, including, but not limited to,
            Kovo’s trademarks. If you believe that material on our website
            violates your copyright, please notify us at support@kovocredit.com.
            Kovo will review and respond to all copyright infringement notices
            that it receives and respond appropriately by removing infringing
            material if applicable.
          </Typography>
          <Typography variant="body1">
            You acknowledge and agree that any feedback, comments, or
            suggestions you may provide regarding Kovo, or the Services are
            entirely voluntary and we will be free to use such feedback,
            comments or suggestions as we see fit and without any obligation to
            you.
          </Typography>
          <Typography variant="h2">12. Advertisements and Offers</Typography>
          <Typography variant="body1">
            The Services may include advertisements, which may be targeted to
            the content or information on the Services, queries made through the
            Services, or other information. The types and extent of advertising
            on the Services are subject to change. In consideration for Kovo
            granting you access to and use of the Services, you agree that Kovo,
            third party providers and partners may place such advertising on the
            Services or in connection with the display of content or information
            from the Services whether submitted by you or others. We may earn a
            marketing fee in connecting with the advertisements and offers. We
            may share your information with third parties when you use our
            website to request a rate, quote, service, approval, or information
            from those third parties (a “Request”). It is always your choice
            whether to apply for a product or service offered by our partners.
            Our presentation of the offers is not an endorsement of their
            services or products and we disclaim any liability for any
            third-party information or products offered.
          </Typography>
          <Typography variant="h2">
            13. Restrictions on Use of the Services
          </Typography>
          <Typography variant="body1">
            Kovo reserves the right to suspend, cancel, or terminate your use of
            any Kovo services if we believe you are using it for a purpose that
            is unauthorized, improper, illegal, or that could harm Kovo’s
            reputation.
          </Typography>
          <Typography variant="body1">
            You may not do any of the following while accessing or using the
            Services: (i) access, tamper with, or use non-public areas of the
            Services, Kovo’s computer systems, or the technical delivery systems
            of Kovo providers; (ii) probe, scan, or test the vulnerability of
            any system or network or breach or circumvent any security or
            authentication measures; (iii) access or search or attempt to access
            or search the Services by any means (automated or otherwise) other
            than through our currently available, published interfaces that are
            provided by Kovo (and only pursuant to those terms and conditions),
            unless you have been specifically allowed to do so in a separate
            agreement with Kovo (NOTE: scraping the Services without the prior
            consent of Kovo is expressly prohibited); (iv) forge any TCP/IP
            packet header or any part of the header information in any email or
            posting, or in any way use the Services to send altered, deceptive
            or false source-identifying information; or (v) interfere with, or
            disrupt, (or attempt to do so), the access of any user, host or
            network, including, without limitation, sending a virus,
            overloading, flooding, spamming, mail-bombing the Services, or by
            scripting the creation of content in such a manner as to interfere
            with or create an undue burden on the Services.
          </Typography>
          <Typography variant="h2">14. Termination of Account</Typography>
          <Typography variant="body1">
            The Terms will continue to apply until terminated by either you or
            Kovo as follows.
          </Typography>
          <Typography variant="body1">
            You may end your legal agreement with Kovo to use the Services at
            any time for any reason by deactivating your account(s) and
            discontinuing your use of the Services. In order to deactivate your
            account, please contact us at support@kovocredit.com. Deactivating
            your account will not have any effect on any agreements you have
            entered into with Kovo related to the Services or the amount due
            payable to Kovo.
          </Typography>
          <Typography variant="body1">
            We may suspend or terminate your accounts or cease providing you
            with all or part of the Services at any time for any reason,
            including, but not limited to, if we reasonably believe: (i) you
            have violated these Terms, (ii) you create risk or possible legal
            exposure for us; or (iii) our provision of the Services to you is no
            longer commercially viable. We will make reasonable efforts to
            notify you by the email address associated with your account or
            through the Services the next time you attempt to access your
            account.
          </Typography>
          <Typography variant="body1">
            In all such cases, the Terms shall terminate, including, without
            limitation, your license to use the Services, except those Sections
            you would expect to survive termination.
          </Typography>
          <Typography variant="body1">
            Nothing in this section shall affect Kovo’s rights to change, limit
            or stop the provision of the Services without prior notice, as
            provided above.
          </Typography>
          <Typography variant="h2">15. Accessibility</Typography>
          <Typography variant="body1">
            We are committed to ensuring digital accessibility for people with
            disabilities. If you are having difficulty viewing or navigating the
            content on this website, or notice any content, feature, or
            functionality that you believe is not fully accessible to people
            with disabilities, please call our Support Team at 855-965-5686.
          </Typography>
          <Typography variant="h2">
            16. Disclosure for Legal or Administrative Reasons
          </Typography>
          <Typography variant="body1">
            We reserve the right, but not the obligation, to monitor our
            Services electronically. We may access, use, preserve, transfer or
            disclose, at any time and without notice to you, any information
            which may include Personal Information as defined in our Privacy
            Statement in order to: (1) comply with applicable laws and subpoenas
            or other legal request, or cooperate with law enforcement or the
            government; (2) enforce this Agreement; (3) protect the integrity
            and rights of our Services or any third party; or (4) detect and
            prevent fraud, security, or technical issues related to our Services
            or our partners’ services.
          </Typography>
          <Typography variant="h2">
            17. Disclaimers, Limitations Of Liability and Indemnity
          </Typography>
          <Typography variant="body1">
            Please read this section carefully since it limits the liability of
            Kovo and its subsidiaries, affiliates, related companies, officers,
            directors, employees, agents, representatives, partners, and
            licensors (collectively, the “Kovo Entities”). Each of the
            subsections below only applies up to the maximum extent permitted
            under applicable law. Some jurisdictions do not allow the disclaimer
            of implied warranties or the limitation of liability in contracts,
            and as a result the contents of this section may not apply to you.
            Nothing in this section is intended to limit any rights you may have
            which may not be lawfully limited.
          </Typography>
          <Typography variant="h3">
            A. The Services are Available "AS-IS"
          </Typography>
          <Typography variant="body1">
            Your access to and use of the Services are at your own risk. You
            understand and agree that the Services are provided to you on an “AS
            IS” and “AS AVAILABLE” basis. Without limiting the foregoing, THE
            KOVO ENTITIES DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER
            EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, OR NON-INFRINGEMENT.
          </Typography>
          <Typography variant="body1">
            The Kovo Entities make no warranty and disclaim all responsibility
            and liability for: (i) any actions of other Kovo users; (ii) the
            completeness, accuracy, availability, timeliness, security or
            reliability of the Services or any information on the Services;
            (iii) any harm to your computer system, loss of data, or other harm
            that results from your access to or use of the Services, or any
            content; (iv) the deletion of, or the failure to store or to
            transmit, any content and other communications maintained by the
            Services; (v) whether the Services will meet your requirements or be
            available on an uninterrupted, secure, or error-free basis; (vi) any
            advice or information you receive through the Services; and (vii)
            any injuries or harm you or a third party incurs as a result of
            information or advice received through the Services. No advice or
            information, whether oral or written, obtained from the Kovo
            Entities or through the Services, will create any warranty not
            expressly made herein.
          </Typography>
          <Typography variant="h3">
            B. Links or Connections to Third Party Sites
          </Typography>
          <Typography variant="body1">
            Our Services or communications to you may contain third party
            content or links to third party sites, applications or services
            (collectively, “Third Party Services”). We do not endorse, control
            or maintain the Third Party Services, and we are not responsible or
            liable for any Third Party Services, including any damages, losses,
            failures, or problems caused by, related to, or arising from Third
            Party Services. You should review all of the relevant terms and
            conditions associated with Third Party Services, including any
            privacy policies and terms of service. You acknowledge sole
            responsibility for and assume all risk arising from your use of any
            such services or resources.
          </Typography>
          <Typography variant="h3">C. Limitation of Liability</Typography>
          <Typography variant="body1">
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE KOVO ENTITIES
            SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR
            REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF
            DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i)
            YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES;
            (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES,
            INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL
            CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY CONTENT OBTAINED
            FROM THE SERVICES; OR (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF
            YOUR TRANSMISSIONS OR CONTENT.
          </Typography>
          <Typography variant="body1">
            IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE SHORT NAME ENTITIES
            EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS (U.S. $100.00) OR THE
            AMOUNT YOU PAID KOVO, IF ANY, IN THE PAST SIX MONTHS FOR ACCESS TO
            THE SERVICES GIVING RISE TO THE CLAIM.
          </Typography>
          <Typography variant="body1">
            THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF
            LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT
            (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT THE KOVO
            ENTITIES HAVE BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE,
            AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS
            ESSENTIAL PURPOSE.
          </Typography>
          <Typography variant="h3">D. Indemnification</Typography>
          <Typography variant="body1">
            To the extent not prohibited by law, you expressly agree to
            indemnify and hold harmless the Kovo Entities from and against any
            and all liabilities, expenses, damages and costs, including, but not
            limited to, reasonable attorneys’ fees and costs, related to all
            third party claims, charges and investigations related to (1) your
            failure to comply with the Terms; (2) any information you upload to
            the Services; (3) any representations you make to Kovo; and (3) any
            activity in which you engage on or through the Services.
          </Typography>
          <Typography variant="h2">
            18. GENERAL TERMS. Waiver and Severability
          </Typography>
          <Typography variant="body1">
            The failure of Kovo to enforce any right or provision of these Terms
            will not be deemed a waiver of such right or provision. In the event
            that any provision of these Terms is held to be invalid or
            unenforceable, then that provision will be limited or eliminated to
            the minimum extent necessary, and the remaining provisions of these
            Terms will remain in full force and effect.
          </Typography>
          <Typography variant="h3">
            A. Controlling Law and Jurisdiction
          </Typography>
          <Typography variant="body1">
            These Terms and any action related thereto will be governed by the
            laws of the state of Delaware without regard to or application of
            its conflict of law provisions or your state or country of
            residence. All claims, legal proceedings or litigation arising in
            connection with the Services will be brought solely in the federal
            or state courts located in Kent County, Delaware, United States, and
            you consent to the jurisdiction of and venue in such courts and
            waive any objection as to inconvenient forum. You agree that you may
            only bring claims against Kovo related to your use of the Services
            on an individual basis and not as a plaintiff or class member in any
            purported class or representative action or proceeding.
          </Typography>
          <Typography variant="h3">B. Entire Agreement</Typography>
          <Typography variant="body1">
            These Terms and our{' '}
            <MuiLink
              href="/legal/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy & Notice
            </MuiLink>{' '}
            are the entire and exclusive agreement between Kovo and you
            regarding the Services (excluding any service for which you have a
            separate agreement with Kovo that is explicitly in addition or in
            place of these Terms), and these Terms supersede and replace any
            prior agreements between Kovo and you regarding the Services.
          </Typography>
          <Typography variant="h3">C. Compliance with Laws</Typography>
          <Typography variant="body1">
            You may use the Services only in compliance with these Terms and all
            applicable local, state, national, and international laws, rules and
            regulations.
          </Typography>
          <Typography variant="h3">D. Severability</Typography>
          <Typography variant="body1">
            If any provision of the Agreement is unlawful, void, or
            unenforceable for any reason, then that provision will be given
            effect to the greatest extent possible and the remaining provisions
            will remain in full force and effect.
          </Typography>
          <Typography variant="h3">E. Revisions</Typography>
          <Typography variant="body1">
            We may revise these Terms from time to time, the most current
            version will always be at https://kovocredit.com/legal/terms. If the
            revision, in our sole discretion, is material we will notify you via
            email to the email associated with your account or through the
            Services. If you do not wish to be bound by any such revisions to
            the Terms, you must end these Terms with us as set forth in Section
            14 above. By continuing to access or use the Services after those
            revisions become effective, you agree to be bound by the revised
            Terms.
          </Typography>
          <Typography variant="body1">
            If you have any questions about these Terms, please contact us at
            support@kovocredit.com.
          </Typography>
        </Box>
      </OnboardingPage>
    </Box>
  );
};

export default TermsOfUsePage;
