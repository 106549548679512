import { Box, Grid, Typography } from '@mui/material';

import CreditScoreCard from 'components/CreditScoreCard';
import CreditScoreInfo from 'components/CreditScoreInfo';
import CreditScoreReasons from 'components/CreditScoreReasons';
import LeaveReviewButton from 'components/LeaveReviewButton';
import Page from 'components/Page';
import { theme } from 'context/ThemeProvider';

const CreditScorePage: React.FC = () => {
  return (
    <>
      <Page
        overridePadding={{
          sm: theme.spacing(1, 1.5),
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              textAlign: 'center',
            },
          }}
        >
          <Typography variant="h1" marginBottom={theme.spacing(2)}>
            FICO® Score
          </Typography>

          <Typography marginBottom={theme.spacing(4)}>
            Kovo lets you monitor your FICO® Score for free. Your score is
            updated once a month.
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CreditScoreInfo />
          </Grid>

          <Grid item xs={12}>
            <CreditScoreCard />
          </Grid>

          <Grid item xs={12}>
            <CreditScoreReasons />
          </Grid>
        </Grid>
      </Page>

      <LeaveReviewButton />
    </>
  );
};

export default CreditScorePage;
