export const loadScript = (
  src: string,
  properties: Record<string, string> = {},
): Promise<Event> => {
  return new Promise(function (resolve, reject) {
    const s = document.createElement('script');

    Object.entries(properties).forEach(([key, value]) => {
      s.setAttribute(key, value);
    });

    s.src = src;

    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
};
