import { Card, CardContent } from '@mui/material';

import CreditScoreGraph from 'components/CreditScoreGraph';

const CreditScoreCard: React.FC = () => {
  return (
    <Card
      elevation={0}
      sx={{
        position: 'relative',
      }}
    >
      <CardContent>
        <CreditScoreGraph />
      </CardContent>
    </Card>
  );
};

export default CreditScoreCard;
