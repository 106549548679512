import { Box, Link, Skeleton, Typography } from '@mui/material';
import { theme } from 'context/ThemeProvider';

type IdMonitoringAlertCountCardProps = {
  label: string;
  isLoading: boolean;
  count?: number;
  onViewAlertsClick: () => void;
};

const IdMonitoringAlertCountCard = ({
  label,
  isLoading,
  count,
  onViewAlertsClick,
}: IdMonitoringAlertCountCardProps) => {
  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Skeleton data-testid="skeleton" variant="rounded" height={100} />
        <Skeleton variant="text" height={40} />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" alignItems="center">
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          border: `1px solid #CFCFCF`,
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          borderRadius: theme.spacing(1),
        }}
      >
        <Typography
          variant="footnote"
          textAlign="center"
          fontWeight="bold"
          marginBottom={theme.spacing(0.75)}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '36px',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '-2px',
          }}
        >
          {count}
        </Typography>
      </Box>
      {!!count && count > 0 && (
        <Link
          variant="footnote"
          component="button"
          onClick={onViewAlertsClick}
          width="fit-content"
          sx={{
            marginTop: theme.spacing(1),
          }}
        >
          View
        </Link>
      )}
    </Box>
  );
};

export default IdMonitoringAlertCountCard;
