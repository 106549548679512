import { Box, Card, CardContent, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';

const PastDueRewardsBanner: React.FC = () => {
  return (
    <Card variant="gradient" sx={{ marginBottom: theme.spacing(2) }}>
      <CardContent>
        <Typography>
          You will be eligible for rewards{' '}
          <Box component="strong">after your first 4 monthly payments</Box> and
          you’ve caught up on your past due payments.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PastDueRewardsBanner;
