import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import ChangePasswordForm from 'components/ChangePasswordForm';
import { theme } from 'context/ThemeProvider';
import { trackProductAnalytics } from 'libs/productAnalyticsTracking';

const SecurityCard: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    trackProductAnalytics({
      namespace: 'ui',
      event: 'settings.update-password.clicked',
    });
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h5">SECURITY</Typography>

        <Divider sx={{ margin: theme.spacing(2, 0) }} />

        <MuiLink component="button" variant="body1" onClick={handleClick}>
          Update password
        </MuiLink>
      </CardContent>

      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(1),
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <DialogTitle sx={{ marginTop: theme.spacing(5) }} variant="h4">
          Enter your new password
        </DialogTitle>
        <DialogContent>
          <ChangePasswordForm onSuccess={handleClose} />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default SecurityCard;
