import { useEffect } from 'react';

import useCreateApplication from 'hooks/mutations/useCreateApplication';

const ChargeAccountCreateApplication: React.FC = () => {
  const { mutate } = useCreateApplication();

  useEffect(() => {
    mutate();
  }, [mutate]);

  return null;
};

export default ChargeAccountCreateApplication;
