import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import { theme } from 'context/ThemeProvider';
import {
  INVITING_REWARD_AMOUNT,
  REFERRED_REWARD_AMOUNT,
} from 'helpers/referralClaims';
import { useReferralCode } from 'hooks/useReferralCode';

const ReferralsShareCode: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data: referralCode, isLoading } = useReferralCode();

  return (
    <div
      style={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: '100%',
      }}
    >
      <Typography variant="h6" marginBottom={theme.spacing(1)}>
        Referral Link
      </Typography>
      <Card
        sx={{
          flex: '1 0 auto',
          display: 'flex',
          alignItems: 'center',
          background: '#F7F8F8',
        }}
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Box
              component="img"
              src="/images/referral-links/affili_02b.png"
              alt="A graphic illustrating users sharing information on smartphones."
              style={{
                maxWidth: '180px',
                width: '100%',
                margin: '0 auto',
                display: 'block',
              }}
            ></Box>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6" marginBottom={theme.spacing(2)}>
              Refer your friends. We will give them {REFERRED_REWARD_AMOUNT} and
              give you {INVITING_REWARD_AMOUNT} for each referral.
            </Typography>
            <Box marginBottom={theme.spacing(2)}>
              {isLoading && (
                <Box>
                  <CircularProgress />
                </Box>
              )}
              {!isLoading && !referralCode && (
                <Typography>
                  No referral code found. Please contact support for more
                  information.
                </Typography>
              )}
              {!isLoading && referralCode && (
                <ReferralsCopyLink code={referralCode.id} />
              )}
            </Box>

            <Link
              component="button"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <Typography variant="disclaimer">Offer Terms</Typography>
            </Link>
          </Grid>
        </Grid>
      </Card>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box padding={theme.spacing(1, 2)} maxWidth="400px">
          <Typography variant="caption">
            In order to qualify for this offer, you must make your referral
            using the unique referral link provided. Upon successful sign up,
            your Referral Dashboard will show Pending Referral to show an
            attribution is in progress. Upon 3 timely payments made by your
            referred customer, the Pending Referral becomes Eligible To Be
            Redeemed. Referring customers will receive a{' '}
            {INVITING_REWARD_AMOUNT} gift card in their dashboard for each
            referral that is Eligible To Be Redeemed. There's no limit on how
            many times you can share your referral link, or how many times it
            can be used. Referred customers after 3 timely payments will also be
            rewarded with a {REFERRED_REWARD_AMOUNT} gift card in their
            Dashboard.
          </Typography>
        </Box>
      </Popover>
    </div>
  );
};

type CodeProps = {
  code: string;
};

export const ReferralsCopyLink: React.FC<CodeProps> = ({ code }) => {
  const referralLink = `kovocredit.com/r/${code}`;

  const copyLink = () => {
    const input = document.querySelector(
      '#referral-code-input',
    ) as HTMLInputElement | null;

    if (!input) {
      console.error('Could not find referral code input to copy from.');
      return;
    }
    input.value = 'https://' + input.value;
    input.select();

    /**
     * Use document.execCommand because it is supported more universally than
     * the details and permission model around navigator.clipboard. See
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
     *
     */
    document.execCommand('copy');
  };

  return (
    <>
      <OutlinedInput
        id="referral-code-input"
        sx={{
          marginBottom: theme.spacing(2),
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 'bold',
          borderRadius: '30px',
          border: '2px solid transparent',
          background:
            'linear-gradient(white, white) padding-box, linear-gradient(to right, #0877B8, #FF66CC) border-box',

          [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
            marginBottom: theme.spacing(3),
          },

          [theme.breakpoints.up('md')]: {
            fontSize: '19px',
          },
        }}
        value={referralLink}
        readOnly
        fullWidth
      />

      <Button variant="contained" onClick={copyLink} fullWidth>
        Copy
      </Button>
    </>
  );
};

export default ReferralsShareCode;
