import { API } from 'aws-amplify';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Offer } from 'components/OffersList/OffersList';
import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { KovoError } from 'libs/KovoError';

const useOffersByCategoryQuery = () => {
  const { identityId, username } = useContext(AuthContext);

  const { categoryId } = useParams<{ categoryId?: string }>();

  const getOffersByCategory = async (categoryId?: string) => {
    try {
      if (!categoryId) return;

      const offers = await API.get(
        'billing',
        `/billing/v2/offer-categories/${categoryId}/offers`,
        amplifyRequestContext(identityId, username),
      );

      return offers;
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Failed to fetch offers', { error });
    }
  };

  return useQuery<Offer[], KovoError>(
    ['offers', categoryId],
    () => getOffersByCategory(categoryId),
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useOffersByCategoryQuery;
