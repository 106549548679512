export const routeToAdaAnswerMapping: { [key: string]: string } = {
  '/contact-us': '651b21fff6fc07f5e32b819b',
  '/apply': '651b22b6973ab88934e8842a',
  '/apply-pending': '651b22eaab4f8229db76c18d',
  '/esign': '64f905467bd0aee1cf1c0254',
  '/payment': '64f904abe9b6038d7b529db3',
  '/settings': '651b23c78cc2179b4ba90e52',
  '/installment-plan': '651b21447a00a8ee05e86077',
  '/update-card': '651b23a9eba18fdb019feea0',
  '/change-password': '651b239a1b01b17699bd6696',
  '/upfront-payment': '651b238646f6844467267329',
  '/installment-plan/catchup-payment': '651b236c03cf96a213a77eee',
  '/adverse-action-notice': '651b2357f147cf9afb53b841',
  '/application-rejected': '651b2427392f2c08e51fc630',
  '/unrecognized-status': '651b231ed5233dd8accdda49',
  '/courses': '651b230b9b4b190066ce7e2f',
  '/wallet': '651b22fa1d833a2093d80e01',
  '/invite-friends': '651b22d780cfebdfc643f8cf',
  '/ssn': '651b22c1138bb5500d8d3f52',
  '/ecc': '651b22b4679dd808c6964aaa',
  '/closed': '6503c8a30d3bb423e5cffceb',
  '/offers': '651b22319dda97acb7b1bdf4',
  '/offer-categories': '651b220a86d3ecfcb16240c7',
  '/credit-score': '651b21f0a6f4be288a346d70',
  '/rewards': '651c9e7606010f8d6a6229e5',
  '/how-it-works': '651ca0a3c3c90ee3bc9c27ac',
  '/phone-number': '651cafc90b92900e5c7e9b94',
  '/verify-phone-number': '651caffd94bc4f7d692cc203',
  'personal-info': '651b23d92a5a5695acc0a1a6',
  '/': '651b22936e67ca58d4a88117',
  default: '64c7e9031d332677f8767bc2',
};
