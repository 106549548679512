import { LoadingButton } from '@mui/lab';
import { Alert, Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import SsnInput from 'components/shared/SsnInput';
import TextField from 'components/shared/TextField';
import {
  BirthdateInput,
  birthdateInputSchema,
  BIRTHDATE_INPUT_NAME,
} from 'components/shared/BirthdateInput';
import { theme } from 'context/ThemeProvider';
import { SSN_REGEX } from 'helpers/constants';
import { normalizeDate } from 'helpers/utils';
import { useForgotEmail } from 'hooks/mutations/useForgotEmail';
import type { ForgotEmailResponse } from 'types/schemas';

export type Props = {
  onSuccess: (data: ForgotEmailResponse) => void;
};

type FormValues = {
  firstName: string;
  lastName: string;
  ssn: string;
  [BIRTHDATE_INPUT_NAME]: string;
};

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  ssn: '',
  [BIRTHDATE_INPUT_NAME]: '',
};

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'First name is too short')
    .required('First name is required'),
  lastName: yup
    .string()
    .min(2, 'Last name is too short')
    .required('Last name is required'),
  ssn: yup
    .string()
    .required('Social security number is required')
    .test('ssn', 'Invalid SSN', (value = '') => SSN_REGEX.test(value)),
  [BIRTHDATE_INPUT_NAME]: birthdateInputSchema,
});

const ForgotEmailForm = ({ onSuccess }: Props) => {
  const onSubmit = (values: FormValues) => {
    mutate({
      ...values,
      [BIRTHDATE_INPUT_NAME]: normalizeDate(values[BIRTHDATE_INPUT_NAME]),
    });
  };

  const { mutate, isLoading, error } = useForgotEmail({
    onSuccess,
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  return (
    <>
      {error ? (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(3) }}
        >
          {error.displayMessage || error.message}
        </Alert>
      ) : null}

      <form onSubmit={handleSubmit} data-testid="forgot-email-form">
        <Grid
          container
          spacing={{ xs: 2, sm: 3 }}
          marginBottom={theme.spacing(3)}
        >
          <Grid item xs={12}>
            <TextField
              name="firstName"
              label="First name"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.firstName && errors.firstName)}
              errorText={errors.firstName}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="lastName"
              label="Last name"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.lastName && errors.lastName)}
              errorText={errors.lastName}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <BirthdateInput
              value={values[BIRTHDATE_INPUT_NAME]}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              error={
                !!(
                  touched[BIRTHDATE_INPUT_NAME] && errors[BIRTHDATE_INPUT_NAME]
                )
              }
              errorText={errors[BIRTHDATE_INPUT_NAME]}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <SsnInput
              name="ssn"
              label="SSN"
              value={values.ssn}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              error={!!(touched.ssn && errors.ssn)}
              errorText={errors.ssn}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              fullWidth
              sx={{
                marginBottom: theme.spacing(3),
                marginTop: theme.spacing(3),
              }}
            >
              Verify Your Identity
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ForgotEmailForm;
